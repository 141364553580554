import React, { useContext } from "react";
import { makeStyles, SimpleList } from "@sport-thieme/react-ui";
import classnames from "classnames";
import { AppContext } from "../../AppContext";
import { localize } from "@sport-thieme/util-localization";
import { SingleTemplateQuery } from "../../graphql/generated/graphql";

export default function TemplateEntryList({
  setEntryIndex,
  entries,
  entryIndex,
  templateId,
}: TemplateEntryListProps) {
  const classes = useStyles();
  const { templateState } = useContext(AppContext);
  return (
    <SimpleList
      className={classes.list}
      dense
      data={(entries || []).map(
        (
          {
            code,
            shopName,
            shopId: listShopId,
            languageName,
            languageId: listLanguageId,
          },
          i
        ) => ({
          primary: shopName,
          secondary: `${languageName}`,
          selected: i === entryIndex,
          className: classnames({
            [classes.empty]: code === null,
            [classes.changed]:
              templateState[`${templateId}-${listShopId}-${listLanguageId}`] !==
                undefined &&
              templateState[`${templateId}-${listShopId}-${listLanguageId}`] !==
                code &&
              !(
                templateState[
                  `${templateId}-${listShopId}-${listLanguageId}`
                ] === "" && code === null
              ),
          }),
          onClick: () => {
            setEntryIndex(i);
          },
        })
      )}
    />
  );
}

type TemplateEntryListProps = {
  setEntryIndex: React.Dispatch<number>;
  entries: TemplateEntries;
  entryIndex: number;
  templateId: number;
};
export type TemplateEntries = NonNullable<
  NonNullable<SingleTemplateQuery["template"]>["entries"][0]
>[];

const useStyles = makeStyles(theme => ({
  empty: {
    color: "#aaa",
  },
  list: {
    overflow: "scroll",
    height: "100%",
    paddingBottom: 100,
    paddingTop:0,
    borderTop:`1px solid ${theme.palette.grey["400"]}`
  },
  changed: {
    position: "relative",
    "&:after": {
      content: '"' + localize`unsaved changes` + '"',
      display: "block",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: 10,
      color: theme.palette.secondary.main,
    },
  },
}));
