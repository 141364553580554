import React, { useContext } from "react";
import EmptyWorkspace from "./EmptyWorkspace";
import {
  Tabs,
  Tab,
  Icon,
  Grid,
  P,
  IconButton,
  makeStyles,
  ContextMenu,
} from "@sport-thieme/react-ui";
import { AppContext } from "../AppContext";
import { localize } from "@sport-thieme/util-localization";

export default function Workspace() {
  const {
    tabs,
    closeTab,
    activeTab,
    switchActiveTab,
    closeAllTabs,
    closeAllTabsToTheRight,
    closeAllTabsToTheLeft,
  } = useContext(AppContext);
  const tabClasses = useTabStyles();
  const tabsClasses = useTabsStyles();

  if (tabs.length === 0) return <EmptyWorkspace />;

  const handleClose = (id: number) => {
    closeTab(id);
  };

  return (
    <>
      <Tabs
        indicatorColor="primary"
        variant="scrollable"
        classes={tabsClasses}
        value={activeTab || 0}
        onChange={(_, index) => switchActiveTab(tabs[index]?.id)}
      >
        {tabs.map(({ name, id }) => (
          <Tab
            key={id}
            wrapped
            title={name}
            classes={tabClasses}
            label={
              <ContextMenu
                actions={[
                  {
                    label: localize`Close`,
                    onClick: handleClose.bind(null, id),
                  },
                  {
                    label: localize`Close to the left`,
                    onClick: closeAllTabsToTheLeft.bind(null, id),
                  },
                  {
                    label: localize`Close to the right`,
                    onClick: closeAllTabsToTheRight.bind(null, id),
                  },
                  {
                    label: localize`Close all`,
                    onClick: closeAllTabs,
                  },
                ]}
              >
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <P>{name}</P>
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={handleClose.bind(null, id)}
                      component="div"
                    >
                      <Icon.Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </ContextMenu>
            }
          />
        ))}
      </Tabs>
    </>
  );
}

const useTabStyles = makeStyles(theme => ({
  root: {
    maxWidth: "inherit",
    minWidth: "inherit",
    textTransform: "inherit",
    paddingLeft: theme.spacing(5),
    paddingRight: 5,
    "& .MuiIconButton-label": {
      visibility: "hidden",
    },
    "&:hover .MuiIconButton-label": {
      visibility: "visible",
    },
    "&.Mui-selected": {
      background: theme.palette.background.default,
    },
  },
}));
const useTabsStyles = makeStyles(theme => ({
  root: {
    gridArea: "tabs",
    overflowX: "auto",
    background: theme.palette.action.hover,
    boxShadow: "inset 0 -6px 7px -10px #000",
    "& .MuiTabs-indicator": {
      bottom: "inherit",
      top: 0,
      display: theme.palette.type === "dark" ? "none" : "block",
    },
  },
}));
