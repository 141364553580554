import { gql } from "@sport-thieme/react-authenticator";

export default gql`
  query currentUser{
    currentUser {
      firstname
      image
    }
  }
`
