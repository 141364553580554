import { gql } from "@sport-thieme/react-authenticator";

export default gql`
  query listTemplates($where: TemplateWhereInput, $limit: Int, $offset: Int = 0) {
    listTemplates(where: $where, take: $limit, skip: $offset) {
      count
      templates {
        id
        name
      }
    }
  }
`;

export type ListedTemplate = {
  id: number;
  name: string;
};

export type ListTemplateResult = {
  listTemplates: {
    count: number;
    templates: ListedTemplate[];
  };
};
