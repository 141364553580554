import React, { MouseEvent, useCallback, useContext, useState } from "react";
import {
  Confirmation,
  Divider,
  Icon,
  IconButton,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@sport-thieme/react-ui";
import { localize } from "@sport-thieme/util-localization";
import {
  SingleTemplateQuery,
  useDeleteTemplateMutation,
} from "../../graphql/generated/graphql";
import DuplicateDialog from "./DuplicateDialog";
import { AppContext } from "../../AppContext";
import RenameDialog from "./RenameDialog";
import TranslateDialog from "./TranslateDialog";

export default function TemplateEditorActions({
  template,
}: TemplateEditorActionsProps) {
  const classes = useStyles();
  const [showDuplicationDialog, setShowDuplicationDialog] = useState(false);
  const [showTranslationDialog, setShowTranslationDialog] = useState(false);
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [menuRef, setMenuRef] = useState<HTMLButtonElement | null>(null);

  const { closeTab, openSnackbar, removeRecent } = useContext(AppContext);

  const [deleteTemplate] = useDeleteTemplateMutation();
  const handleDelete = useCallback(async () => {
    const id = template?.id ?? -1;
    try {
      await deleteTemplate({
        variables: {
          id,
        },
      });

      closeTab(id);
      removeRecent(id);
      openSnackbar(localize`${template?.name ?? ""} deleted`);
    } catch (error) {
      openSnackbar(localize`could not delete ${template?.name ?? ""}`, "error");
    }
  }, [
    template?.id,
    template?.name,
    deleteTemplate,
    closeTab,
    removeRecent,
    openSnackbar,
  ]);

  return (
    <>
      <IconButton
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          setMenuRef(event.currentTarget);
        }}
      >
        <Icon.MoreHorizTwoTone />
      </IconButton>
      <Menu
        open={Boolean(menuRef)}
        anchorEl={menuRef}
        onClose={() => setMenuRef(null)}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Confirmation
          title={localize`Delete ${template?.name ?? ""}`}
          question={localize`Are you sure?`}
        >
          <MenuItem
            onClick={() => handleDelete()}
            className={classes.menuItem}
            disabled={template == null}
          >
            <ListItemText primary={localize`Delete ${template?.name ?? ""}`} />
            <Icon.Delete color="action" />
          </MenuItem>
        </Confirmation>
        <Divider />
        <MenuItem
          onClick={() => setShowDuplicationDialog(true)}
          className={classes.menuItem}
          disabled={template == null}
        >
          <ListItemText primary={localize`Duplicate ${template?.name ?? ""}`} />
          <Icon.FileCopy color="action" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => setShowRenameDialog(true)}
          className={classes.menuItem}
          disabled={template == null}
        >
          <ListItemText primary={localize`Rename ${template?.name ?? ""}`} />
          <Icon.Edit color="action" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => setShowTranslationDialog(true)}
          className={classes.menuItem}
          disabled={template == null}
        >
          <ListItemText primary={localize`Translate ${template?.name ?? ""}`} />
          <Icon.Translate color="action" />
        </MenuItem>
      </Menu>
      <DuplicateDialog
        template={template}
        open={showDuplicationDialog}
        onClose={() => {
          setMenuRef(null);
          setShowDuplicationDialog(false);
        }}
      />
      <RenameDialog
        template={template}
        open={showRenameDialog}
        onClose={() => {
          setMenuRef(null);
          setShowRenameDialog(false);
        }}
      />
      <TranslateDialog
        template={template}
        open={showTranslationDialog}
        onClose={() => {
          setMenuRef(null);
          setShowTranslationDialog(false);
        }}
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    alignItems: "content",
  },
}));

export type TemplateEditorActionsProps = {
  template: SingleTemplateQuery["template"];
};
