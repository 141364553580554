import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Grid,
  Select,
  SimpleDialog,
  SwitchButton,
} from "@sport-thieme/react-ui";
import { localize } from "@sport-thieme/util-localization";
import {
  SingleTemplateDocument,
  SingleTemplateQuery,
  useTranslateTemplateCodeMutation,
} from "../../graphql/generated/graphql";
import { SelectOption } from "@sport-thieme/react-ui/components/Select/Select";

export default function TranslateDialog({
  template,
  open,
  onClose,
}: TranslateDialogProps) {
  const [targetLanguageIds, setTargetLanguageIds] = useState<number[]>([2, 3]);
  const [sourceLanguageId, setSourceLanguageId] = useState<string>();

  const languageId = useMemo(() => {
    const value = sourceLanguageId?.split("-")[0];

    return value ? parseInt(value, 10) : 1;
  }, [sourceLanguageId]);

  const [translate, { loading, error }] = useTranslateTemplateCodeMutation({
    refetchQueries: [
      { query: SingleTemplateDocument, variables: { id: template?.id } },
    ],
    variables: {
      id: template?.id ?? -1,
      sourceLanguageId: languageId,
      targetLanguageIds,
    },
  });

  const entries = useMemo(() => {
    return (template?.entries ?? []).filter(
      entry =>
        entry != null &&
        languageId !== entry.languageId &&
        entry.languageId !== 0
    ) as NonNullableEntries;
  }, [languageId, template?.entries]);

  const sourceLanguages: SelectOption[] = useMemo(() => {
    return (
      (template?.entries ?? []).filter(
        entry => entry != null && `${entry.code ?? ""}`.trim() !== ""
      ) as NonNullableEntries
    ).map(entry => ({
      value: `${entry.languageId}-${entry.shopId}`,
      label: entry.languageName ?? "",
    }));
  }, [template?.entries]);

  useEffect(() => {
    if (sourceLanguageId !== undefined) return;
    const german = sourceLanguages.find(entry => entry.value === "1-1");

    setSourceLanguageId(german?.value ?? sourceLanguages[0]?.value);
  }, [sourceLanguageId, sourceLanguages]);

  return (
    <SimpleDialog
      open={open}
      maxWidth="sm"
      fullWidth
      title={localize`Translate ${template?.name ?? ""}`}
      onClose={() => undefined}
      actions={[
        {
          title: localize`cancel`,
          onClick: () => {
            onClose();
          },
          variant: "outlined",
        },
        {
          title: `${localize`Translate`}`,
          destructive: true,
          loading,
          disabled: loading || template == null,
          onClick: async () => {
            await translate();
            onClose();
          },
        },
      ]}
    >
      {error && <Alert severity="error">{error.message}</Alert>}
      <Select
        value={sourceLanguageId ?? ""}
        options={sourceLanguages}
        label={localize`Source language`}
        onChange={value => {
          setSourceLanguageId(value === "" ? undefined : value);
        }}
      />
      <br />
      <br />
      <Grid container>
        {entries.map(entry => (
          <Grid item key={`${entry.languageId}-${entry.shopId}`} xs={12} sm={6}>
            <SwitchButton
              checked={targetLanguageIds.includes(entry.languageId)}
              label={entry.languageName ?? ""}
              onChange={(_, value) => {
                if (value) {
                  setTargetLanguageIds([
                    ...targetLanguageIds,
                    entry.languageId,
                  ]);
                } else {
                  setTargetLanguageIds(ids =>
                    ids.filter(id => id !== entry.languageId)
                  );
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
    </SimpleDialog>
  );
}
export type TranslateDialogProps = {
  template: SingleTemplateQuery["template"];
  open: boolean;
  onClose: () => void;
};

type NonNullableEntries = NonNullable<
  NonNullable<NonNullable<SingleTemplateQuery["template"]>["entries"]>[0]
>[];
