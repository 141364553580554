import React, { useState, useContext } from "react";
import {
  SimpleDialog,
  TextField,
  Icon,
  FormHelperText,
  Tooltip,
  FloatingActionButtonWrapper,
  FloatingActionButton,
} from "@sport-thieme/react-ui";
import { useMutation } from "@sport-thieme/react-authenticator";
import addTemplateMutation from "../../graphql/addTemplate";
import { localize } from "@sport-thieme/util-localization";
import { AppContext } from "../../AppContext";

export default function AddDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { openSnackbar, openTab } = useContext(AppContext);

  const [addTemplate] = useMutation(addTemplateMutation, {
    onError: err => {
      setError(true);
      setErrorMessage(err.message);
    },
    onCompleted: res => {
      setError(false);
      setErrorMessage("");
      setIsOpen(false);
      openSnackbar(localize`successfully added: ${name}`);
      openTab({ id: res.addTemplate.id, name: res.addTemplate.name });
    },
  });

  const save = () => {
    if (name.length === 0) {
      setError(true);
      setErrorMessage(localize`Please enter a template name`);
      return;
    }
    addTemplate({
      variables: {
        name,
      },
    });
  };

  return (
    <>
      <FloatingActionButtonWrapper>
        <Tooltip title={localize`New template`} arrow enterDelay={1000}>
          <FloatingActionButton
            color="primary"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <Icon.Add />
          </FloatingActionButton>
        </Tooltip>
      </FloatingActionButtonWrapper>
      <SimpleDialog
        open={isOpen}
        title={localize`New template`}
        onClose={() => undefined}
        actions={[
          {
            title: localize`cancel`,
            onClick: () => {
              setName("");
              setError(false);
              setIsOpen(false);
            },
            variant: "outlined",
          },
          { title: `${localize`add`}`, onClick: save },
        ]}
      >
        <TextField
          error={error}
          autoFocus
          value={name}
          placeholder={localize`template name`}
          label={localize`template name`}
          onChange={event => {
            setName(event.target.value);
          }}
          onEnter={save}
        />
        {error && <FormHelperText error>{errorMessage}</FormHelperText>}
      </SimpleDialog>
    </>
  );
}
