import { gql } from "@sport-thieme/react-authenticator";

export default gql`
  mutation addTemplate($name: String!) {
    addTemplate: createTemplate(data: { name: $name }) {
      id
      name
    }
  }
`;
