import React, { ReactNode, useContext } from "react";
import {
  Avatar,
  H6,
  Icon,
  makeStyles,
  SimpleList,
} from "@sport-thieme/react-ui";
import { ListedTemplate } from "../../graphql/listTemplates";
import { AppContext } from "../../AppContext";
import classNames from "classnames";
import { localize } from "@sport-thieme/util-localization";

type SuggestListProps = {
  title: ReactNode;
  templates: ListedTemplate[];
  icon?: ReactNode;
};

export default function SuggestList({
  title,
  templates,
  icon = <Icon.Code />,
}: SuggestListProps) {
  const { openTab, tabs } = useContext(AppContext);
  const openIds = tabs.map(({ id }) => id);
  const classes = useStyles();
  const handleClick = (id: number, name: string) => () => {
    openTab({ id, name });
  };

  if (templates.length === 0) {
    return <></>;
  }

  return (
    <>
      <H6>{title}</H6>
      <SimpleList
        dense
        data={templates.map(({ name, id }: ListedTemplate) => ({
          icon: (
            <Avatar
              className={classNames({
                [classes.activeAvatar]: openIds.includes(id),
              })}
            >
              {icon}
            </Avatar>
          ),
          primary: name,
          secondary: openIds.includes(id)
            ? localize`Switch to open tab`
            : undefined,
          onClick: handleClick(id, name),
          button: true,
        }))}
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  activeAvatar: {
    background: theme.palette.primary.main,
  },
}));
