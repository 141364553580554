import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Authenticator, {
  GraphProvider,
} from "@sport-thieme/react-authenticator";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@sport-thieme/react-ui";
import LogCollector from "@sport-thieme/react-log-collector";
import AppContextProvider from "./AppContext";
import localization from "@sport-thieme/util-localization";
localization.addTranslations("de", {});

const theme = {
  palette: {
    primary: {
      main: "#f99947",
    },
    secondary: {
      main: "#50a73d",
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <LogCollector>
      <ThemeProvider theme={theme}>
        <Authenticator>
          <GraphProvider>
            <AppContextProvider>
              <App />
            </AppContextProvider>
          </GraphProvider>
        </Authenticator>
      </ThemeProvider>
    </LogCollector>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
