import { gql } from '@sport-thieme/react-authenticator';

export default gql`
	mutation changeTemplateEntry($id: Int!, $shopId: Int!, $languageId: Int!, $code: String!, $isGzipped: Boolean!) {
		changeTemplateEntry: upsertTemplateCode(
			id: $id
			shopId: $shopId
			languageId: $languageId
			code: $code
			isGzipped: $isGzipped
		) {
			code
			shopId
			languageId
		}
	}
`;
