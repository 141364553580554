import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Icon,
  LinearProgress,
  makeStyles,
  Paper,
} from "@sport-thieme/react-ui";
import { useLazyQuery } from "@sport-thieme/react-authenticator";
import listTemplatesQuery, {
  ListTemplateResult,
} from "../../graphql/listTemplates";
import SuggestList from "./SuggestList";
import { AppContext } from "../../AppContext";
import { localize } from "@sport-thieme/util-localization";

export default React.memo(function Suggest() {
  const classes = useStyles();
  const { searchIsFocused, search, recentTemplates } = useContext(AppContext);
  const [cachedSearch, setCachedSearch] = useState(() => search);
  const [runNameSearch, { data: namedData, loading: loadingNamed }] =
    useLazyQuery<ListTemplateResult>(listTemplatesQuery);
  const [runCodeSearch, { data: codeData, loading: loadingCode }] =
    useLazyQuery<ListTemplateResult>(listTemplatesQuery);

  useEffect(() => {
    if (search.trim() !== "") {
      const timeout = setTimeout(() => {
        setCachedSearch(search);
        runCodeSearch({
          variables: {
            where: {
              templateEntry: {
                some: {
                  code: { contains: search.trim() },
                },
              },
            },
            limit: 20,
          },
        });
        runNameSearch({
          variables: {
            where: {
              name: { contains: search.trim() },
            },
            limit: 20,
          },
        });
      }, 800);

      return () => clearTimeout(timeout);
    }
  }, [search, runCodeSearch, runNameSearch, setCachedSearch, searchIsFocused]);

  const byName = namedData?.listTemplates?.templates || [];
  const byCode = codeData?.listTemplates?.templates || [];

  const hasData =
    byName.length > 0 || byCode.length > 0 || recentTemplates.length > 0;

  if (false === hasData) {
    return <></>;
  }
  return (
    <>
      {searchIsFocused && (
        <Paper elevation={8} className={classes.paper} withPadding>
          {(loadingCode || loadingNamed) && (
            <LinearProgress
              variant="indeterminate"
              className={classes.linearProgress}
            />
          )}
          {search === cachedSearch &&
            cachedSearch.trim() !== "" &&
            (loadingNamed || loadingCode) === false &&
            byName.length + byCode.length === 0 && (
              <Alert severity="info">{localize`Not a single Template could be found for the keyword "${cachedSearch}"`}</Alert>
            )}
          {search.trim() === "" &&
            (loadingNamed || loadingCode) === false &&
            byName.length + byCode.length + recentTemplates.length === 0 && (
              <Alert severity="info">{localize`Type to search`}</Alert>
            )}
          {search.trim() !== "" && byName.length + byCode.length > 0 && (
            <>
              <SuggestList
                title="Found in name"
                templates={byName}
                icon={<Icon.List />}
              />

              <SuggestList title="Found in code" templates={byCode} />
            </>
          )}
          {recentTemplates.length > 0 && (
            <SuggestList
              title="Recent templates"
              templates={[...recentTemplates].reverse()}
              icon={<Icon.CollectionsBookmark />}
            />
          )}
        </Paper>
      )}
    </>
  );
});

const useStyles = makeStyles({
  paper: {
    position: "fixed",
    minWidth: 400,
    maxWidth: "100vw",
    maxHeight: "calc(100vh - 100px)",
    overflow: "auto",
    top: 60,
    right: 80,
    zIndex: 1300,
  },
  linearProgress: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
});
