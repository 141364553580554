import React, { useState, useContext } from "react";
import {
  SimpleDialog,
  TextField,
  FormHelperText,
} from "@sport-thieme/react-ui";
import { useMutation } from "@sport-thieme/react-authenticator";
import { localize } from "@sport-thieme/util-localization";
import { AppContext } from "../../AppContext";
import renameTemplateMutation from "../../graphql/renameTemplate";
import { SingleTemplateQuery } from "../../graphql/generated/graphql";

export default function RenameDialog({
  template,
  open,
  onClose,
}: RenameDialogProps) {
  const [name, setName] = useState(template?.name ?? "");
  const [initialName, setInitialName] = useState(name);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("noerror");
  const { openSnackbar, renameTab, renameRecent } = useContext(AppContext);
  const id = template?.id ?? -1;
  const optimisticResponse = {
    optimisticResponse: {
      __typename: "Mutation",
      renameTemplate: {
        id,
        __typename: "Template",
        name,
      },
    },
  };

  const [renameTemplate] = useMutation(renameTemplateMutation, {
    optimisticResponse,
    onError: err => {
      setError(true);
      setErrorMessage(err.message);
    },
    onCompleted: () => {
      setError(false);
      setErrorMessage("");
      onClose();
      renameTab({ id, name });
      renameRecent({ id, name });
      openSnackbar(localize`successfully renamed to ${name}`);
      if (initialName !== name) {
        setInitialName(name);
      }
    },
  });

  const save = () => {
    if (name.length === 0) {
      setError(true);
      setErrorMessage(localize`Please enter a template name`);
      return;
    }
    renameTemplate({
      variables: {
        id,
        name,
      },
    });
  };

  return (
    <SimpleDialog
      open={open}
      maxWidth="xs"
      fullWidth
      title={`${localize`rename`} ${template?.name ?? ""}`}
      onClose={() => undefined}
      actions={[
        {
          title: localize`cancel`,
          onClick: () => {
            setName(initialName);
            setError(false);
            onClose();
          },
          variant: "outlined",
        },
        { title: `${localize`rename`}`, destructive: true, onClick: save },
      ]}
    >
      <TextField
        error={error}
        value={name}
        label={localize`new template name`}
        onChange={event => {
          setName(event.target.value);
        }}
        onEnter={save}
      />
      {error && (
        <FormHelperText error>{localize`${errorMessage}`}</FormHelperText>
      )}
    </SimpleDialog>
  );
}
export type RenameDialogProps = {
  template: SingleTemplateQuery["template"];
  open: boolean;
  onClose: () => void;
};
