import { gql } from "@sport-thieme/react-authenticator";

export default gql`
  query singleTemplate($id: Int) {
    template(where: { id: $id }) {
      id
      name
      entries {
        shopId
        languageId
        shopName
        languageName
        code
      }
    }
  }
`;
