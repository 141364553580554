import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AdUser = {
  __typename?: "ADUser";
  department?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  mail?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  userPrincipalName?: Maybe<Scalars["String"]>;
};

export type ActiveDirectoryGroup = {
  __typename?: "ActiveDirectoryGroup";
  description: Scalars["String"];
  group: Array<Group>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ActiveDirectoryGroupGroupArgs = {
  after?: InputMaybe<GroupWhereUniqueInput>;
  before?: InputMaybe<GroupWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ActiveDirectoryGroupOrderByInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ActiveDirectoryGroupWhereInput = {
  AND?: InputMaybe<Array<ActiveDirectoryGroupWhereInput>>;
  NOT?: InputMaybe<Array<ActiveDirectoryGroupWhereInput>>;
  OR?: InputMaybe<Array<ActiveDirectoryGroupWhereInput>>;
  description?: InputMaybe<StringFilter>;
  group?: InputMaybe<GroupListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ActiveDirectoryGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  function?: Maybe<Scalars["String"]>;
  lastname?: Maybe<Scalars["String"]>;
  packstationId?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  postnumber?: Maybe<Scalars["String"]>;
  salutation?: Maybe<Salutation>;
  street?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  vatNumber?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  addressType: AddressInputType;
  city?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  firstname?: InputMaybe<Scalars["String"]>;
  fullname?: InputMaybe<Scalars["String"]>;
  function?: InputMaybe<Scalars["String"]>;
  lastname?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  streetNo?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export enum AddressInputType {
  All = "ALL",
  Billing = "BILLING",
  Shipping = "SHIPPING",
}

export type AdyenPaymentData = {
  __typename?: "AdyenPaymentData";
  amount?: Maybe<Scalars["Float"]>;
  date?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
  transactionId?: Maybe<Scalars["String"]>;
  transactionType?: Maybe<Scalars["String"]>;
};

export type AmazonOrder = Order & {
  __typename?: "AmazonOrder";
  amazonShippingType?: Maybe<AmazonShippingType>;
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type AmazonOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export enum AmazonShippingType {
  Fba = "FBA",
  Fbm = "FBM",
}

export type App = {
  __typename?: "App";
  description?: Maybe<Scalars["String"]>;
  favouriteApp?: Maybe<Array<Maybe<FavouriteApp>>>;
  id: Scalars["Int"];
  image?: Maybe<Scalars["String"]>;
  maintenanceInfoDate?: Maybe<Scalars["DateTime"]>;
  maintenanceMessage?: Maybe<Scalars["String"]>;
  maintenanceModeActivated?: Maybe<Scalars["Boolean"]>;
  maintenanceNotificationMessage?: Maybe<Scalars["String"]>;
  maintenanceStartDate?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  service: Array<Service>;
  slug: Scalars["String"];
};

export type AppServiceArgs = {
  after?: InputMaybe<ServiceWhereUniqueInput>;
  before?: InputMaybe<ServiceWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AppAttributeUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["String"]>;
  maintenanceInfoDate?: InputMaybe<Scalars["DateTime"]>;
  maintenanceMessage?: InputMaybe<Scalars["String"]>;
  maintenanceModeActivated?: InputMaybe<Scalars["Boolean"]>;
  maintenanceNotificationMessage?: InputMaybe<Scalars["String"]>;
  maintenanceStartDate?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Array<Scalars["Int"]>>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type AppListRelationFilter = {
  every?: InputMaybe<AppWhereInput>;
  none?: InputMaybe<AppWhereInput>;
  some?: InputMaybe<AppWhereInput>;
};

export type AppOrderByInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  maintenanceInfoDate?: InputMaybe<SortOrder>;
  maintenanceMessage?: InputMaybe<SortOrder>;
  maintenanceModeActivated?: InputMaybe<SortOrder>;
  maintenanceNotificationMessage?: InputMaybe<SortOrder>;
  maintenanceStartDate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type AppWhereInput = {
  AND?: InputMaybe<Array<AppWhereInput>>;
  NOT?: InputMaybe<Array<AppWhereInput>>;
  OR?: InputMaybe<Array<AppWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  favouriteApp?: InputMaybe<FavouriteAppListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  maintenanceInfoDate?: InputMaybe<DateTimeNullableFilter>;
  maintenanceMessage?: InputMaybe<StringNullableFilter>;
  maintenanceModeActivated?: InputMaybe<BoolNullableFilter>;
  maintenanceNotificationMessage?: InputMaybe<StringNullableFilter>;
  maintenanceStartDate?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type AppWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type ArchiveNavItem = {
  __typename?: "ArchiveNavItem";
  childCount?: Maybe<Scalars["Int"]>;
  children?: Maybe<Array<Maybe<ArchiveNavItem>>>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["Int"]>;
  pathIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type AttributeType = {
  __typename?: "AttributeType";
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export enum AuroraHealthResult {
  Bad = "BAD",
  Good = "GOOD",
}

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type Brand = BrandInterface & {
  __typename?: "Brand";
  description?: Maybe<Text>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  resource?: Maybe<Resource>;
};

export type BrandConstraintInput = {
  id: Scalars["Int"];
};

export type BrandCreateManyDescriptionInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  resource_id?: InputMaybe<Scalars["Int"]>;
};

export type BrandCreateManyDescriptionInputEnvelope = {
  data?: InputMaybe<Array<BrandCreateManyDescriptionInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type BrandCreateManyResourceInput = {
  description_text_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type BrandCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<BrandCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type BrandCreateNestedManyWithoutDescriptionInput = {
  connect?: InputMaybe<Array<BrandWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BrandCreateOrConnectWithoutDescriptionInput>
  >;
  create?: InputMaybe<Array<BrandCreateWithoutDescriptionInput>>;
  createMany?: InputMaybe<BrandCreateManyDescriptionInputEnvelope>;
};

export type BrandCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<BrandWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BrandCreateOrConnectWithoutResourceInput>>;
  create?: InputMaybe<Array<BrandCreateWithoutResourceInput>>;
  createMany?: InputMaybe<BrandCreateManyResourceInputEnvelope>;
};

export type BrandCreateOrConnectWithoutDescriptionInput = {
  create: BrandCreateWithoutDescriptionInput;
  where: BrandWhereUniqueInput;
};

export type BrandCreateOrConnectWithoutResourceInput = {
  create: BrandCreateWithoutResourceInput;
  where: BrandWhereUniqueInput;
};

export type BrandCreateWithoutDescriptionInput = {
  name?: InputMaybe<Scalars["String"]>;
  resource?: InputMaybe<ResourceCreateNestedOneWithoutBrandInput>;
};

export type BrandCreateWithoutResourceInput = {
  description?: InputMaybe<TextCreateNestedOneWithoutBrandInput>;
  name?: InputMaybe<Scalars["String"]>;
};

export type BrandEligibleForVoucher = {
  __typename?: "BrandEligibleForVoucher";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type BrandEligibleForVoucherWhereInput = {
  AND?: InputMaybe<Array<BrandEligibleForVoucherWhereInput>>;
  NOT?: InputMaybe<Array<BrandEligibleForVoucherWhereInput>>;
  OR?: InputMaybe<Array<BrandEligibleForVoucherWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  voucherBrand?: InputMaybe<VoucherBrandListRelationFilter>;
};

export type BrandInterface = {
  id?: Maybe<Scalars["Int"]>;
};

export type BrandListRelationFilter = {
  every?: InputMaybe<BrandWhereInput>;
  none?: InputMaybe<BrandWhereInput>;
  some?: InputMaybe<BrandWhereInput>;
};

export type BrandOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type BrandScalarWhereInput = {
  AND?: InputMaybe<Array<BrandScalarWhereInput>>;
  NOT?: InputMaybe<Array<BrandScalarWhereInput>>;
  OR?: InputMaybe<Array<BrandScalarWhereInput>>;
  description_text_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  resource_id?: InputMaybe<IntNullableFilter>;
};

export type BrandUpdateManyMutationInput = {
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type BrandUpdateManyWithWhereWithoutDescriptionInput = {
  data: BrandUpdateManyMutationInput;
  where: BrandScalarWhereInput;
};

export type BrandUpdateManyWithWhereWithoutResourceInput = {
  data: BrandUpdateManyMutationInput;
  where: BrandScalarWhereInput;
};

export type BrandUpdateManyWithoutDescriptionInput = {
  connect?: InputMaybe<Array<BrandWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BrandCreateOrConnectWithoutDescriptionInput>
  >;
  create?: InputMaybe<Array<BrandCreateWithoutDescriptionInput>>;
  createMany?: InputMaybe<BrandCreateManyDescriptionInputEnvelope>;
  delete?: InputMaybe<Array<BrandWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BrandScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BrandWhereUniqueInput>>;
  set?: InputMaybe<Array<BrandWhereUniqueInput>>;
  update?: InputMaybe<Array<BrandUpdateWithWhereUniqueWithoutDescriptionInput>>;
  updateMany?: InputMaybe<
    Array<BrandUpdateManyWithWhereWithoutDescriptionInput>
  >;
  upsert?: InputMaybe<Array<BrandUpsertWithWhereUniqueWithoutDescriptionInput>>;
};

export type BrandUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<BrandWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BrandCreateOrConnectWithoutResourceInput>>;
  create?: InputMaybe<Array<BrandCreateWithoutResourceInput>>;
  createMany?: InputMaybe<BrandCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<BrandWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BrandScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BrandWhereUniqueInput>>;
  set?: InputMaybe<Array<BrandWhereUniqueInput>>;
  update?: InputMaybe<Array<BrandUpdateWithWhereUniqueWithoutResourceInput>>;
  updateMany?: InputMaybe<Array<BrandUpdateManyWithWhereWithoutResourceInput>>;
  upsert?: InputMaybe<Array<BrandUpsertWithWhereUniqueWithoutResourceInput>>;
};

export type BrandUpdateWithWhereUniqueWithoutDescriptionInput = {
  data: BrandUpdateWithoutDescriptionInput;
  where: BrandWhereUniqueInput;
};

export type BrandUpdateWithWhereUniqueWithoutResourceInput = {
  data: BrandUpdateWithoutResourceInput;
  where: BrandWhereUniqueInput;
};

export type BrandUpdateWithoutDescriptionInput = {
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resource?: InputMaybe<ResourceUpdateOneWithoutBrandInput>;
};

export type BrandUpdateWithoutResourceInput = {
  description?: InputMaybe<TextUpdateOneWithoutBrandInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type BrandUpsertWithWhereUniqueWithoutDescriptionInput = {
  create: BrandCreateWithoutDescriptionInput;
  update: BrandUpdateWithoutDescriptionInput;
  where: BrandWhereUniqueInput;
};

export type BrandUpsertWithWhereUniqueWithoutResourceInput = {
  create: BrandCreateWithoutResourceInput;
  update: BrandUpdateWithoutResourceInput;
  where: BrandWhereUniqueInput;
};

export type BrandWhereInput = {
  AND?: InputMaybe<Array<BrandWhereInput>>;
  NOT?: InputMaybe<Array<BrandWhereInput>>;
  OR?: InputMaybe<Array<BrandWhereInput>>;
  description?: InputMaybe<TextWhereInput>;
  description_text_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntNullableFilter>;
};

export type BrandWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type BuergelInfo = {
  __typename?: "BuergelInfo";
  addressOrigin: BuergelInfoEntry;
  identification: BuergelInfoEntry;
  risk: BuergelInfoEntry;
  score: Scalars["Float"];
};

export type BuergelInfoEntry = {
  __typename?: "BuergelInfoEntry";
  code: Scalars["String"];
  text: Scalars["String"];
};

export type CResourceText = {
  __typename?: "CResourceText";
  id?: Maybe<Scalars["Int"]>;
  languageId?: Maybe<Scalars["Int"]>;
  resource?: Maybe<Resource>;
  resourceId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with resourceId */
  resource_id?: Maybe<Scalars["Int"]>;
  text?: Maybe<Scalars["String"]>;
  textAttribute?: Maybe<TextAttribute>;
  textId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with textId */
  text_id?: Maybe<Scalars["Int"]>;
  type?: Maybe<ResourceTextType>;
  x?: Maybe<Scalars["Int"]>;
  y?: Maybe<Scalars["Int"]>;
};

export type Campaign = {
  __typename?: "Campaign";
  archive?: Maybe<Scalars["Boolean"]>;
  campaignId: Scalars["Int"];
  /** @deprecated replaced with campaignId */
  campaign_id?: Maybe<Scalars["Int"]>;
  companyId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with companyId */
  company_id?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with createdAt */
  create_date?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  faceliftCampaignId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with faceliftCampaignId */
  facelift_campaign_id?: Maybe<Scalars["Int"]>;
  hasInvalidTeaserLinks?: Maybe<Scalars["Boolean"]>;
  /** @deprecated replaced with hasInvalidTeaserLinks */
  has_invalid_teaser_links?: Maybe<Scalars["Boolean"]>;
  isVersionOfCampaignId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with isVersionOfCampaignId */
  is_version_of_campaign_id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  publishedCampaignId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with publishedCampaignId */
  published_campaign_id?: Maybe<Scalars["Int"]>;
  refreshInterval?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with refreshInterval */
  refresh_interval?: Maybe<Scalars["Int"]>;
  responsibleUserId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with responsibleUserId */
  responsible_user_id?: Maybe<Scalars["Int"]>;
  status: CampaignStatus;
  templateName?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with templateName */
  template_name?: Maybe<Scalars["String"]>;
  type: CampaignType;
  /** @deprecated replaced with updatedAt */
  update_timestamp?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
  useAsTemplate?: Maybe<Scalars["Boolean"]>;
  /** @deprecated replaced with useAsTemplate */
  use_as_template?: Maybe<Scalars["Boolean"]>;
};

export type CampaignDefinition = {
  __typename?: "CampaignDefinition";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type CampaignDefinitionCreateNestedOneWithoutCustomersInput = {
  connect?: InputMaybe<CampaignDefinitionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignDefinitionCreateOrConnectWithoutCustomersInput>;
  create?: InputMaybe<CampaignDefinitionCreateWithoutCustomersInput>;
};

export type CampaignDefinitionCreateOrConnectWithoutCustomersInput = {
  create: CampaignDefinitionCreateWithoutCustomersInput;
  where: CampaignDefinitionWhereUniqueInput;
};

export type CampaignDefinitionCreateWithoutCustomersInput = {
  name: Scalars["String"];
};

export type CampaignDefinitionOrderByInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type CampaignDefinitionUpdateOneWithoutCustomersInput = {
  connect?: InputMaybe<CampaignDefinitionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignDefinitionCreateOrConnectWithoutCustomersInput>;
  create?: InputMaybe<CampaignDefinitionCreateWithoutCustomersInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CampaignDefinitionUpdateWithoutCustomersInput>;
  upsert?: InputMaybe<CampaignDefinitionUpsertWithoutCustomersInput>;
};

export type CampaignDefinitionUpdateWithoutCustomersInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CampaignDefinitionUpsertWithoutCustomersInput = {
  create: CampaignDefinitionCreateWithoutCustomersInput;
  update: CampaignDefinitionUpdateWithoutCustomersInput;
};

export type CampaignDefinitionWhereInput = {
  AND?: InputMaybe<Array<CampaignDefinitionWhereInput>>;
  NOT?: InputMaybe<Array<CampaignDefinitionWhereInput>>;
  OR?: InputMaybe<Array<CampaignDefinitionWhereInput>>;
  customers?: InputMaybe<OciCustomerListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type CampaignDefinitionWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export enum CampaignStatus {
  Confirmed = "confirmed",
  Unconfirmed = "unconfirmed",
}

export enum CampaignType {
  Brand = "brand",
  Campaign = "campaign",
  Category = "category",
  Search = "search",
  TargetGroup = "targetGroup",
  TopicGroup = "topicGroup",
}

export enum Carrier {
  Dax = "DAX",
  Dhl = "DHL",
  Emo = "EMO",
  Emon = "EMON",
  Gls = "GLS",
  Gp = "GP",
  Gpe = "GPE",
  Gpq = "GPQ",
  Pst = "PST",
  Uni = "UNI",
}

export type Catalog = {
  __typename?: "Catalog";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  mainCatalog: Array<OciCustomer>;
  name?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  runtimeFrom?: Maybe<Scalars["DateTime"]>;
  runtimeTo?: Maybe<Scalars["DateTime"]>;
  specialCatalog: Array<OciCustomer>;
};

export type CatalogMainCatalogArgs = {
  after?: InputMaybe<OciCustomerWhereUniqueInput>;
  before?: InputMaybe<OciCustomerWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type CatalogSpecialCatalogArgs = {
  after?: InputMaybe<OciCustomerWhereUniqueInput>;
  before?: InputMaybe<OciCustomerWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type CatalogCreateNestedOneWithoutMainCatalogInput = {
  connect?: InputMaybe<CatalogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CatalogCreateOrConnectWithoutMainCatalogInput>;
  create?: InputMaybe<CatalogCreateWithoutMainCatalogInput>;
};

export type CatalogCreateNestedOneWithoutSpecialCatalogInput = {
  connect?: InputMaybe<CatalogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CatalogCreateOrConnectWithoutSpecialCatalogInput>;
  create?: InputMaybe<CatalogCreateWithoutSpecialCatalogInput>;
};

export type CatalogCreateOrConnectWithoutMainCatalogInput = {
  create: CatalogCreateWithoutMainCatalogInput;
  where: CatalogWhereUniqueInput;
};

export type CatalogCreateOrConnectWithoutSpecialCatalogInput = {
  create: CatalogCreateWithoutSpecialCatalogInput;
  where: CatalogWhereUniqueInput;
};

export type CatalogCreateWithoutMainCatalogInput = {
  alternativeShopId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  reference?: InputMaybe<Scalars["String"]>;
  runtimeFrom?: InputMaybe<Scalars["DateTime"]>;
  runtimeTo?: InputMaybe<Scalars["DateTime"]>;
  shopId: Scalars["Int"];
  specialCatalog?: InputMaybe<OciCustomerCreateNestedManyWithoutSpecialCatalogInput>;
};

export type CatalogCreateWithoutSpecialCatalogInput = {
  alternativeShopId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  mainCatalog?: InputMaybe<OciCustomerCreateNestedManyWithoutMainCatalogInput>;
  name?: InputMaybe<Scalars["String"]>;
  reference?: InputMaybe<Scalars["String"]>;
  runtimeFrom?: InputMaybe<Scalars["DateTime"]>;
  runtimeTo?: InputMaybe<Scalars["DateTime"]>;
  shopId: Scalars["Int"];
};

export type CatalogOrderByInput = {
  alternativeShopId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  reference?: InputMaybe<SortOrder>;
  runtimeFrom?: InputMaybe<SortOrder>;
  runtimeTo?: InputMaybe<SortOrder>;
  shopId?: InputMaybe<SortOrder>;
};

export type CatalogUpdateOneWithoutMainCatalogInput = {
  connect?: InputMaybe<CatalogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CatalogCreateOrConnectWithoutMainCatalogInput>;
  create?: InputMaybe<CatalogCreateWithoutMainCatalogInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CatalogUpdateWithoutMainCatalogInput>;
  upsert?: InputMaybe<CatalogUpsertWithoutMainCatalogInput>;
};

export type CatalogUpdateOneWithoutSpecialCatalogInput = {
  connect?: InputMaybe<CatalogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CatalogCreateOrConnectWithoutSpecialCatalogInput>;
  create?: InputMaybe<CatalogCreateWithoutSpecialCatalogInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CatalogUpdateWithoutSpecialCatalogInput>;
  upsert?: InputMaybe<CatalogUpsertWithoutSpecialCatalogInput>;
};

export type CatalogUpdateWithoutMainCatalogInput = {
  alternativeShopId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  runtimeFrom?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  runtimeTo?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shopId?: InputMaybe<IntFieldUpdateOperationsInput>;
  specialCatalog?: InputMaybe<OciCustomerUpdateManyWithoutSpecialCatalogInput>;
};

export type CatalogUpdateWithoutSpecialCatalogInput = {
  alternativeShopId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mainCatalog?: InputMaybe<OciCustomerUpdateManyWithoutMainCatalogInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  runtimeFrom?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  runtimeTo?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shopId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CatalogUpsertWithoutMainCatalogInput = {
  create: CatalogCreateWithoutMainCatalogInput;
  update: CatalogUpdateWithoutMainCatalogInput;
};

export type CatalogUpsertWithoutSpecialCatalogInput = {
  create: CatalogCreateWithoutSpecialCatalogInput;
  update: CatalogUpdateWithoutSpecialCatalogInput;
};

export type CatalogWhereInput = {
  AND?: InputMaybe<Array<CatalogWhereInput>>;
  NOT?: InputMaybe<Array<CatalogWhereInput>>;
  OR?: InputMaybe<Array<CatalogWhereInput>>;
  alternativeShopId?: InputMaybe<IntNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  mainCatalog?: InputMaybe<OciCustomerListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  reference?: InputMaybe<StringNullableFilter>;
  runtimeFrom?: InputMaybe<DateTimeNullableFilter>;
  runtimeTo?: InputMaybe<DateTimeNullableFilter>;
  shopId?: InputMaybe<IntFilter>;
  specialCatalog?: InputMaybe<OciCustomerListRelationFilter>;
};

export type CatalogWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type Classification = {
  __typename?: "Classification";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type ClassificationCreateNestedOneWithoutOciCustomerInput = {
  connect?: InputMaybe<ClassificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClassificationCreateOrConnectWithoutOciCustomerInput>;
  create?: InputMaybe<ClassificationCreateWithoutOciCustomerInput>;
};

export type ClassificationCreateOrConnectWithoutOciCustomerInput = {
  create: ClassificationCreateWithoutOciCustomerInput;
  where: ClassificationWhereUniqueInput;
};

export type ClassificationCreateWithoutOciCustomerInput = {
  ShopLanguages?: InputMaybe<ShopLanguagesCreateNestedOneWithoutClassificationInput>;
  name?: InputMaybe<Scalars["String"]>;
};

export type ClassificationListRelationFilter = {
  every?: InputMaybe<ClassificationWhereInput>;
  none?: InputMaybe<ClassificationWhereInput>;
  some?: InputMaybe<ClassificationWhereInput>;
};

export type ClassificationOrderByInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shopLanguageId?: InputMaybe<SortOrder>;
};

export type ClassificationUpdateOneWithoutOciCustomerInput = {
  connect?: InputMaybe<ClassificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClassificationCreateOrConnectWithoutOciCustomerInput>;
  create?: InputMaybe<ClassificationCreateWithoutOciCustomerInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ClassificationUpdateWithoutOciCustomerInput>;
  upsert?: InputMaybe<ClassificationUpsertWithoutOciCustomerInput>;
};

export type ClassificationUpdateWithoutOciCustomerInput = {
  ShopLanguages?: InputMaybe<ShopLanguagesUpdateOneWithoutClassificationInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ClassificationUpsertWithoutOciCustomerInput = {
  create: ClassificationCreateWithoutOciCustomerInput;
  update: ClassificationUpdateWithoutOciCustomerInput;
};

export type ClassificationWhereInput = {
  AND?: InputMaybe<Array<ClassificationWhereInput>>;
  NOT?: InputMaybe<Array<ClassificationWhereInput>>;
  OCICustomer?: InputMaybe<OciCustomerListRelationFilter>;
  OR?: InputMaybe<Array<ClassificationWhereInput>>;
  ShopLanguages?: InputMaybe<ShopLanguagesWhereInput>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  shopLanguageId?: InputMaybe<IntNullableFilter>;
};

export type ClassificationWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type CognitoUser = {
  __typename?: "CognitoUser";
  department?: Maybe<Scalars["String"]>;
  departmentid?: Maybe<Array<Maybe<Scalars["String"]>>>;
  email?: Maybe<Scalars["String"]>;
  family_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  given_name?: Maybe<Scalars["String"]>;
  group?: Maybe<Scalars["String"]>;
  jobtitle?: Maybe<Scalars["String"]>;
};

export type CollectionResourceCollectionIdResourceIdCompoundUniqueInput = {
  collectionId: Scalars["Int"];
  resourceId: Scalars["Int"];
};

export type CollectionResourceCreateManyCollectionInput = {
  resourceId: Scalars["Int"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CollectionResourceCreateManyCollectionInputEnvelope = {
  data?: InputMaybe<Array<CollectionResourceCreateManyCollectionInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CollectionResourceCreateManyResourceInput = {
  collectionId: Scalars["Int"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CollectionResourceCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<CollectionResourceCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CollectionResourceCreateNestedManyWithoutCollectionInput = {
  connect?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CollectionResourceCreateOrConnectWithoutCollectionInput>
  >;
  create?: InputMaybe<Array<CollectionResourceCreateWithoutCollectionInput>>;
  createMany?: InputMaybe<CollectionResourceCreateManyCollectionInputEnvelope>;
};

export type CollectionResourceCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CollectionResourceCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<CollectionResourceCreateWithoutResourceInput>>;
  createMany?: InputMaybe<CollectionResourceCreateManyResourceInputEnvelope>;
};

export type CollectionResourceCreateOrConnectWithoutCollectionInput = {
  create: CollectionResourceCreateWithoutCollectionInput;
  where: CollectionResourceWhereUniqueInput;
};

export type CollectionResourceCreateOrConnectWithoutResourceInput = {
  create: CollectionResourceCreateWithoutResourceInput;
  where: CollectionResourceWhereUniqueInput;
};

export type CollectionResourceCreateWithoutCollectionInput = {
  resource: ResourceCreateNestedOneWithoutCollectionsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CollectionResourceCreateWithoutResourceInput = {
  collection: ResourceCollectionCreateNestedOneWithoutResourcesInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CollectionResourceListRelationFilter = {
  every?: InputMaybe<CollectionResourceWhereInput>;
  none?: InputMaybe<CollectionResourceWhereInput>;
  some?: InputMaybe<CollectionResourceWhereInput>;
};

export type CollectionResourceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CollectionResourceScalarWhereInput = {
  AND?: InputMaybe<Array<CollectionResourceScalarWhereInput>>;
  NOT?: InputMaybe<Array<CollectionResourceScalarWhereInput>>;
  OR?: InputMaybe<Array<CollectionResourceScalarWhereInput>>;
  collectionId?: InputMaybe<IntFilter>;
  resourceId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CollectionResourceUpdateManyMutationInput = {
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CollectionResourceUpdateManyWithWhereWithoutCollectionInput = {
  data: CollectionResourceUpdateManyMutationInput;
  where: CollectionResourceScalarWhereInput;
};

export type CollectionResourceUpdateManyWithWhereWithoutResourceInput = {
  data: CollectionResourceUpdateManyMutationInput;
  where: CollectionResourceScalarWhereInput;
};

export type CollectionResourceUpdateManyWithoutCollectionInput = {
  connect?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CollectionResourceCreateOrConnectWithoutCollectionInput>
  >;
  create?: InputMaybe<Array<CollectionResourceCreateWithoutCollectionInput>>;
  createMany?: InputMaybe<CollectionResourceCreateManyCollectionInputEnvelope>;
  delete?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CollectionResourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  set?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CollectionResourceUpdateWithWhereUniqueWithoutCollectionInput>
  >;
  updateMany?: InputMaybe<
    Array<CollectionResourceUpdateManyWithWhereWithoutCollectionInput>
  >;
  upsert?: InputMaybe<
    Array<CollectionResourceUpsertWithWhereUniqueWithoutCollectionInput>
  >;
};

export type CollectionResourceUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CollectionResourceCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<CollectionResourceCreateWithoutResourceInput>>;
  createMany?: InputMaybe<CollectionResourceCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CollectionResourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  set?: InputMaybe<Array<CollectionResourceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CollectionResourceUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<CollectionResourceUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<CollectionResourceUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type CollectionResourceUpdateWithWhereUniqueWithoutCollectionInput = {
  data: CollectionResourceUpdateWithoutCollectionInput;
  where: CollectionResourceWhereUniqueInput;
};

export type CollectionResourceUpdateWithWhereUniqueWithoutResourceInput = {
  data: CollectionResourceUpdateWithoutResourceInput;
  where: CollectionResourceWhereUniqueInput;
};

export type CollectionResourceUpdateWithoutCollectionInput = {
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutCollectionsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CollectionResourceUpdateWithoutResourceInput = {
  collection?: InputMaybe<ResourceCollectionUpdateOneRequiredWithoutResourcesInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CollectionResourceUpsertWithWhereUniqueWithoutCollectionInput = {
  create: CollectionResourceCreateWithoutCollectionInput;
  update: CollectionResourceUpdateWithoutCollectionInput;
  where: CollectionResourceWhereUniqueInput;
};

export type CollectionResourceUpsertWithWhereUniqueWithoutResourceInput = {
  create: CollectionResourceCreateWithoutResourceInput;
  update: CollectionResourceUpdateWithoutResourceInput;
  where: CollectionResourceWhereUniqueInput;
};

export type CollectionResourceWhereInput = {
  AND?: InputMaybe<Array<CollectionResourceWhereInput>>;
  NOT?: InputMaybe<Array<CollectionResourceWhereInput>>;
  OR?: InputMaybe<Array<CollectionResourceWhereInput>>;
  collection?: InputMaybe<ResourceCollectionWhereInput>;
  collectionId?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resourceId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CollectionResourceWhereUniqueInput = {
  collectionId_resourceId?: InputMaybe<CollectionResourceCollectionIdResourceIdCompoundUniqueInput>;
};

export type Company = {
  __typename?: "Company";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type CompanyCreateNestedOneWithoutResourceProductInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutResourceProductInput>;
  create?: InputMaybe<CompanyCreateWithoutResourceProductInput>;
};

export type CompanyCreateNestedOneWithoutResourceVariantInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutResourceVariantInput>;
  create?: InputMaybe<CompanyCreateWithoutResourceVariantInput>;
};

export type CompanyCreateOrConnectWithoutResourceProductInput = {
  create: CompanyCreateWithoutResourceProductInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutResourceVariantInput = {
  create: CompanyCreateWithoutResourceVariantInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateWithoutResourceProductInput = {
  name: Scalars["String"];
  resourceVariant?: InputMaybe<ResourceVariantCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutResourceVariantInput = {
  name: Scalars["String"];
  resourceProduct?: InputMaybe<ResourceProductCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUpdateOneWithoutResourceProductInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutResourceProductInput>;
  create?: InputMaybe<CompanyCreateWithoutResourceProductInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CompanyUpdateWithoutResourceProductInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutResourceProductInput>;
};

export type CompanyUpdateOneWithoutResourceVariantInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutResourceVariantInput>;
  create?: InputMaybe<CompanyCreateWithoutResourceVariantInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<CompanyUpdateWithoutResourceVariantInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutResourceVariantInput>;
};

export type CompanyUpdateWithoutResourceProductInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  resourceVariant?: InputMaybe<ResourceVariantUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutResourceVariantInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  resourceProduct?: InputMaybe<ResourceProductUpdateManyWithoutCompanyInput>;
};

export type CompanyUpsertWithoutResourceProductInput = {
  create: CompanyCreateWithoutResourceProductInput;
  update: CompanyUpdateWithoutResourceProductInput;
};

export type CompanyUpsertWithoutResourceVariantInput = {
  create: CompanyCreateWithoutResourceVariantInput;
  update: CompanyUpdateWithoutResourceVariantInput;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  resourceProduct?: InputMaybe<ResourceProductListRelationFilter>;
  resourceVariant?: InputMaybe<ResourceVariantListRelationFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type Contract = {
  __typename?: "Contract";
  bucket: Scalars["String"];
  from: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
  path: Scalars["String"];
  photoModelContracts: Array<PhotoModelContract>;
  /** Signed url to download the contract. The given url is only valid for 15 minutes. */
  signedUrl?: Maybe<Scalars["String"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

export type ContractPhotoModelContractsArgs = {
  after?: InputMaybe<PhotoModelContractWhereUniqueInput>;
  before?: InputMaybe<PhotoModelContractWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ContractCreateInput = {
  bucket: Scalars["String"];
  from: Scalars["DateTime"];
  name: Scalars["String"];
  path: Scalars["String"];
  photoModelContracts?: InputMaybe<PhotoModelContractCreateNestedManyWithoutContractInput>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type ContractCreateNestedOneWithoutPhotoModelContractsInput = {
  connect?: InputMaybe<ContractWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContractCreateOrConnectWithoutPhotoModelContractsInput>;
  create?: InputMaybe<ContractCreateWithoutPhotoModelContractsInput>;
};

export type ContractCreateOrConnectWithoutPhotoModelContractsInput = {
  create: ContractCreateWithoutPhotoModelContractsInput;
  where: ContractWhereUniqueInput;
};

export type ContractCreateWithoutPhotoModelContractsInput = {
  bucket: Scalars["String"];
  from: Scalars["DateTime"];
  name: Scalars["String"];
  path: Scalars["String"];
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type ContractOrderByWithRelationInput = {
  bucket?: InputMaybe<SortOrder>;
  from?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  photoModelContracts?: InputMaybe<PhotoModelContractOrderByRelationAggregateInput>;
  to?: InputMaybe<SortOrder>;
};

export type ContractUpdateInput = {
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  from?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  path?: InputMaybe<StringFieldUpdateOperationsInput>;
  photoModelContracts?: InputMaybe<PhotoModelContractUpdateManyWithoutContractInput>;
  to?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ContractUpdateOneRequiredWithoutPhotoModelContractsInput = {
  connect?: InputMaybe<ContractWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContractCreateOrConnectWithoutPhotoModelContractsInput>;
  create?: InputMaybe<ContractCreateWithoutPhotoModelContractsInput>;
  update?: InputMaybe<ContractUpdateWithoutPhotoModelContractsInput>;
  upsert?: InputMaybe<ContractUpsertWithoutPhotoModelContractsInput>;
};

export type ContractUpdateWithoutPhotoModelContractsInput = {
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  from?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  path?: InputMaybe<StringFieldUpdateOperationsInput>;
  to?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ContractUpsertWithoutPhotoModelContractsInput = {
  create: ContractCreateWithoutPhotoModelContractsInput;
  update: ContractUpdateWithoutPhotoModelContractsInput;
};

export type ContractWhereInput = {
  AND?: InputMaybe<Array<ContractWhereInput>>;
  NOT?: InputMaybe<Array<ContractWhereInput>>;
  OR?: InputMaybe<Array<ContractWhereInput>>;
  bucket?: InputMaybe<StringFilter>;
  from?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  photoModelContracts?: InputMaybe<PhotoModelContractListRelationFilter>;
  to?: InputMaybe<DateTimeNullableFilter>;
};

export type ContractWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type CountryInformation = {
  __typename?: "CountryInformation";
  distanceByAirInKm: Scalars["Float"];
  distanceByLandInKm: Scalars["Float"];
  distanceBySeaInKm: Scalars["Float"];
  fips10: Scalars["String"];
  id: Scalars["Int"];
  internationalOlympiaCountryCode: Scalars["String"];
  iso3166_1_alpha2: Scalars["String"];
  iso3166_1_alpha3: Scalars["String"];
  iso3166_1_numeric: Scalars["Int"];
  /** The country name in german */
  name: Scalars["String"];
  numberPlate: Scalars["String"];
  topLevelDomain: Scalars["String"];
};

export type CountryInformationCreateInput = {
  distanceByAirInKm: Scalars["Float"];
  distanceByLandInKm: Scalars["Float"];
  distanceBySeaInKm: Scalars["Float"];
  fips10: Scalars["String"];
  internationalOlympiaCountryCode: Scalars["String"];
  iso3166_1_alpha2: Scalars["String"];
  iso3166_1_alpha3: Scalars["String"];
  iso3166_1_numeric: Scalars["Int"];
  name: Scalars["String"];
  numberPlate: Scalars["String"];
  topLevelDomain: Scalars["String"];
};

export type CountryInformationOrderByInput = {
  distanceByAirInKm?: InputMaybe<SortOrder>;
  distanceByLandInKm?: InputMaybe<SortOrder>;
  distanceBySeaInKm?: InputMaybe<SortOrder>;
  fips10?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  internationalOlympiaCountryCode?: InputMaybe<SortOrder>;
  iso3166_1_alpha2?: InputMaybe<SortOrder>;
  iso3166_1_alpha3?: InputMaybe<SortOrder>;
  iso3166_1_numeric?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numberPlate?: InputMaybe<SortOrder>;
  topLevelDomain?: InputMaybe<SortOrder>;
};

export type CountryInformationUpdateInput = {
  distanceByAirInKm?: InputMaybe<FloatFieldUpdateOperationsInput>;
  distanceByLandInKm?: InputMaybe<FloatFieldUpdateOperationsInput>;
  distanceBySeaInKm?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fips10?: InputMaybe<StringFieldUpdateOperationsInput>;
  internationalOlympiaCountryCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso3166_1_alpha2?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso3166_1_alpha3?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso3166_1_numeric?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberPlate?: InputMaybe<StringFieldUpdateOperationsInput>;
  topLevelDomain?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CountryInformationWhereInput = {
  AND?: InputMaybe<Array<CountryInformationWhereInput>>;
  NOT?: InputMaybe<Array<CountryInformationWhereInput>>;
  OR?: InputMaybe<Array<CountryInformationWhereInput>>;
  distanceByAirInKm?: InputMaybe<FloatFilter>;
  distanceByLandInKm?: InputMaybe<FloatFilter>;
  distanceBySeaInKm?: InputMaybe<FloatFilter>;
  fips10?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  internationalOlympiaCountryCode?: InputMaybe<StringFilter>;
  iso3166_1_alpha2?: InputMaybe<StringFilter>;
  iso3166_1_alpha3?: InputMaybe<StringFilter>;
  iso3166_1_numeric?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  numberPlate?: InputMaybe<StringFilter>;
  topLevelDomain?: InputMaybe<StringFilter>;
};

export type CountryInformationWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type CreateAppInput = {
  description: Scalars["String"];
  image: Scalars["String"];
  maintenanceInfoDate?: InputMaybe<Scalars["DateTime"]>;
  maintenanceMessage?: InputMaybe<Scalars["String"]>;
  maintenanceModeActivated?: InputMaybe<Scalars["Boolean"]>;
  maintenanceNotificationMessage?: InputMaybe<Scalars["String"]>;
  maintenanceStartDate?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  serviceId?: InputMaybe<Array<Scalars["Int"]>>;
  slug: Scalars["String"];
};

export type CreateFavouriteAppInput = {
  appId: Scalars["Int"];
  email: Scalars["String"];
};

export type CreateGroupCountryInput = {
  countryCode: Scalars["String"];
  groupId: Scalars["Int"];
};

export type CreateGroupInput = {
  activeDirectoryGroupId?: InputMaybe<Scalars["String"]>;
  creator: Scalars["String"];
  slug: Scalars["String"];
};

export type CreateGroupRole = {
  groupId: Scalars["Int"];
  roleId: Scalars["Int"];
};

export type CreateGroupRoleInput = {
  groupId: Scalars["Int"];
  roleId: Scalars["Int"];
};

export type CreateMemberGroupInput = {
  email: Scalars["String"];
  groupId: Scalars["Int"];
};

export type CreatePermissionInput = {
  roleId: Scalars["Int"];
  status: PermissionStatus;
  typeCatalogId: Scalars["Int"];
};

export type CreatePermissionsInput = {
  Permissions: Array<InputMaybe<OnePermissionsInput>>;
};

export type CreatePermissionsWhere = {
  roleId: Scalars["Int"];
};

export type CreateResourceProductGroupInput = {
  hwg: Scalars["String"];
  resourceId: Scalars["Int"];
  type: ResourceProductGroupType;
  uwg?: InputMaybe<Scalars["String"]>;
  wg?: InputMaybe<Scalars["String"]>;
};

export type CreateRoleInput = {
  appId?: InputMaybe<Scalars["Int"]>;
  creator: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
};

export type CreateServiceInput = {
  appId?: InputMaybe<Array<Scalars["Int"]>>;
  name: Scalars["String"];
};

export type CreateTypeCatalogInput = {
  addedInCommit?: InputMaybe<Scalars["String"]>;
  deletedInCommit?: InputMaybe<Scalars["String"]>;
  field: Scalars["String"];
  serviceId: Scalars["Int"];
  type: Scalars["String"];
};

export type CurrentUser = {
  __typename?: "CurrentUser";
  adUserId?: Maybe<Scalars["String"]>;
  apps?: Maybe<Array<Maybe<App>>>;
  countryConstraint?: Maybe<Array<Maybe<Scalars["String"]>>>;
  email?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  groups?: Maybe<Array<Maybe<UserRole>>>;
  id?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["String"]>;
  lastname?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Array<Maybe<UserPermissions>>>;
};

export type CustomerJourney = {
  __typename?: "CustomerJourney";
  currentMarketingChannel?: Maybe<Scalars["String"]>;
  eventDate: Scalars["String"];
  previousMarketingChannel?: Maybe<Scalars["String"]>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["DateTime"]>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DecathlonOrder = Order & {
  __typename?: "DecathlonOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type DecathlonOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type DeleteAppWhere = {
  appId: Scalars["Int"];
};

export type DeleteFavouriteAppWhere = {
  appId: Scalars["Int"];
  email: Scalars["String"];
};

export type DeleteGroupCountryWhere = {
  id: Scalars["Int"];
};

export type DeleteGroupRolesInput = {
  ids: Array<InputMaybe<Scalars["Int"]>>;
};

export type DeleteGroupWhere = {
  id: Scalars["Int"];
};

export type DeleteMemberGroupWhere = {
  email: Scalars["String"];
  groupId: Scalars["Int"];
};

export type DeleteMemberGroupsWhere = {
  emails: Array<InputMaybe<Scalars["String"]>>;
  groupId: Scalars["Int"];
};

export type DeleteOfficeVisitInput = {
  date: Scalars["String"];
  locationId: Scalars["Int"];
};

export type DeletePermissionWhere = {
  roleId: Scalars["Int"];
  typeCatalogId: Scalars["Int"];
};

export type DeletePermissionsWhere = {
  permissions: Array<InputMaybe<PermissionId>>;
  /** delete all subfields from a type */
  shouldDeleteRelatedSubfields?: InputMaybe<Scalars["Boolean"]>;
};

export type DeleteRoleWhere = {
  id: Scalars["Int"];
};

export type DeleteServiceWhere = {
  id: Scalars["Int"];
};

export type DeleteTypeCatalogWhere = {
  id: Scalars["Int"];
};

export type DeliveryEstimation = {
  __typename?: "DeliveryEstimation";
  departureDate?: Maybe<Scalars["String"]>;
  earliestDate?: Maybe<Scalars["String"]>;
  latestDate?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

export type DeliveryEstimationUpdate = {
  __typename?: "DeliveryEstimationUpdate";
  expectedDepartureDate?: Maybe<Scalars["String"]>;
  originalDepartureDate?: Maybe<Scalars["String"]>;
  remainingQuantity: Scalars["Float"];
  setComponentProductNumber?: Maybe<Scalars["String"]>;
  source: Scalars["String"];
  totalQuantity: Scalars["Float"];
};

export type DiscountedProduct = {
  __typename?: "DiscountedProduct";
  name?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["Int"]>;
  variantId?: Maybe<Scalars["ID"]>;
  variantNumber?: Maybe<Scalars["String"]>;
};

/** I'm sorry. This is due to conceptional reduce the output the languages based on our domains. */
export type DomainForLanguageRelationMediaEndpoint = {
  __typename?: "DomainForLanguageRelationMediaEndpoint";
  domain?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars["Int"]>;
  port?: Maybe<Scalars["Int"]>;
  protocol?: Maybe<Scalars["String"]>;
  shopId?: Maybe<Scalars["Int"]>;
};

export type DomainForLanguageRelationMediaEndpointCreateManyLanguageInput = {
  companyId?: InputMaybe<Scalars["Int"]>;
  domain?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  port?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  testserver?: InputMaybe<Scalars["Boolean"]>;
};

export type DomainForLanguageRelationMediaEndpointCreateManyLanguageInputEnvelope =
  {
    data?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointCreateManyLanguageInput>
    >;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
  };

export type DomainForLanguageRelationMediaEndpointCreateNestedManyWithoutLanguageInput =
  {
    connect?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointWhereUniqueInput>
    >;
    connectOrCreate?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointCreateOrConnectWithoutLanguageInput>
    >;
    create?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointCreateWithoutLanguageInput>
    >;
    createMany?: InputMaybe<DomainForLanguageRelationMediaEndpointCreateManyLanguageInputEnvelope>;
  };

export type DomainForLanguageRelationMediaEndpointCreateOrConnectWithoutLanguageInput =
  {
    create: DomainForLanguageRelationMediaEndpointCreateWithoutLanguageInput;
    where: DomainForLanguageRelationMediaEndpointWhereUniqueInput;
  };

export type DomainForLanguageRelationMediaEndpointCreateWithoutLanguageInput = {
  companyId?: InputMaybe<Scalars["Int"]>;
  domain?: InputMaybe<Scalars["String"]>;
  port?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  testserver?: InputMaybe<Scalars["Boolean"]>;
};

export type DomainForLanguageRelationMediaEndpointListRelationFilter = {
  every?: InputMaybe<DomainForLanguageRelationMediaEndpointWhereInput>;
  none?: InputMaybe<DomainForLanguageRelationMediaEndpointWhereInput>;
  some?: InputMaybe<DomainForLanguageRelationMediaEndpointWhereInput>;
};

export type DomainForLanguageRelationMediaEndpointOrderByRelationAggregateInput =
  {
    _count?: InputMaybe<SortOrder>;
    count?: InputMaybe<SortOrder>;
  };

export type DomainForLanguageRelationMediaEndpointOrderByWithRelationInput = {
  companyId?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<LanguageOrderByWithRelationInput>;
  languageId?: InputMaybe<SortOrder>;
  port?: InputMaybe<SortOrder>;
  protocol?: InputMaybe<SortOrder>;
  shopId?: InputMaybe<SortOrder>;
  testserver?: InputMaybe<SortOrder>;
};

export type DomainForLanguageRelationMediaEndpointScalarWhereInput = {
  AND?: InputMaybe<
    Array<DomainForLanguageRelationMediaEndpointScalarWhereInput>
  >;
  NOT?: InputMaybe<
    Array<DomainForLanguageRelationMediaEndpointScalarWhereInput>
  >;
  OR?: InputMaybe<
    Array<DomainForLanguageRelationMediaEndpointScalarWhereInput>
  >;
  companyId?: InputMaybe<IntNullableFilter>;
  domain?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  languageId?: InputMaybe<IntNullableFilter>;
  port?: InputMaybe<IntNullableFilter>;
  protocol?: InputMaybe<StringNullableFilter>;
  shopId?: InputMaybe<IntNullableFilter>;
  testserver?: InputMaybe<BoolNullableFilter>;
};

export type DomainForLanguageRelationMediaEndpointUpdateManyMutationInput = {
  companyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  port?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  protocol?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shopId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  testserver?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DomainForLanguageRelationMediaEndpointUpdateManyWithWhereWithoutLanguageInput =
  {
    data: DomainForLanguageRelationMediaEndpointUpdateManyMutationInput;
    where: DomainForLanguageRelationMediaEndpointScalarWhereInput;
  };

export type DomainForLanguageRelationMediaEndpointUpdateManyWithoutLanguageInput =
  {
    connect?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointWhereUniqueInput>
    >;
    connectOrCreate?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointCreateOrConnectWithoutLanguageInput>
    >;
    create?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointCreateWithoutLanguageInput>
    >;
    createMany?: InputMaybe<DomainForLanguageRelationMediaEndpointCreateManyLanguageInputEnvelope>;
    delete?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointWhereUniqueInput>
    >;
    deleteMany?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointWhereUniqueInput>
    >;
    set?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointWhereUniqueInput>
    >;
    update?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointUpdateWithWhereUniqueWithoutLanguageInput>
    >;
    updateMany?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointUpdateManyWithWhereWithoutLanguageInput>
    >;
    upsert?: InputMaybe<
      Array<DomainForLanguageRelationMediaEndpointUpsertWithWhereUniqueWithoutLanguageInput>
    >;
  };

export type DomainForLanguageRelationMediaEndpointUpdateWithWhereUniqueWithoutLanguageInput =
  {
    data: DomainForLanguageRelationMediaEndpointUpdateWithoutLanguageInput;
    where: DomainForLanguageRelationMediaEndpointWhereUniqueInput;
  };

export type DomainForLanguageRelationMediaEndpointUpdateWithoutLanguageInput = {
  companyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  port?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  protocol?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shopId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  testserver?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DomainForLanguageRelationMediaEndpointUpsertWithWhereUniqueWithoutLanguageInput =
  {
    create: DomainForLanguageRelationMediaEndpointCreateWithoutLanguageInput;
    update: DomainForLanguageRelationMediaEndpointUpdateWithoutLanguageInput;
    where: DomainForLanguageRelationMediaEndpointWhereUniqueInput;
  };

export type DomainForLanguageRelationMediaEndpointWhereInput = {
  AND?: InputMaybe<Array<DomainForLanguageRelationMediaEndpointWhereInput>>;
  NOT?: InputMaybe<Array<DomainForLanguageRelationMediaEndpointWhereInput>>;
  OR?: InputMaybe<Array<DomainForLanguageRelationMediaEndpointWhereInput>>;
  companyId?: InputMaybe<IntNullableFilter>;
  domain?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  language?: InputMaybe<LanguageWhereInput>;
  languageId?: InputMaybe<IntNullableFilter>;
  port?: InputMaybe<IntNullableFilter>;
  protocol?: InputMaybe<StringNullableFilter>;
  shopId?: InputMaybe<IntNullableFilter>;
  testserver?: InputMaybe<BoolNullableFilter>;
};

export type DomainForLanguageRelationMediaEndpointWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type DomainListRelationFilter = {
  every?: InputMaybe<DomainWhereInput>;
  none?: InputMaybe<DomainWhereInput>;
  some?: InputMaybe<DomainWhereInput>;
};

export type DomainWhereInput = {
  AND?: InputMaybe<Array<DomainWhereInput>>;
  NOT?: InputMaybe<Array<DomainWhereInput>>;
  OR?: InputMaybe<Array<DomainWhereInput>>;
  Shop?: InputMaybe<ShopWhereInput>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  shopId?: InputMaybe<IntFilter>;
};

export type DuplicateGroupAttributeInput = {
  creator: Scalars["String"];
  slug: Scalars["String"];
};

export type DuplicateGroupInput = {
  id: Scalars["Int"];
};

export type EInvoicongData = {
  __typename?: "EInvoicongData";
  additionalData?: Maybe<Scalars["String"]>;
  customerReference?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type EmailTemplate = {
  __typename?: "EmailTemplate";
  entries: Array<Maybe<EmailTemplateCode>>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type EmailTemplateCode = {
  __typename?: "EmailTemplateCode";
  bcc?: Maybe<Scalars["String"]>;
  cc?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  from?: Maybe<Scalars["String"]>;
  languageId: Scalars["Int"];
  languageName?: Maybe<Scalars["String"]>;
  replyTo?: Maybe<Scalars["String"]>;
  shopId: Scalars["Int"];
  shopName?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  to?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type EmailTemplateCreateInput = {
  comment?: InputMaybe<Scalars["String"]>;
  emailEntry?: InputMaybe<EmailTemplateEntryCreateNestedManyWithoutEmailInput>;
  name?: InputMaybe<Scalars["String"]>;
};

export type EmailTemplateEntryCreateManyEmailInput = {
  bcc?: InputMaybe<Scalars["String"]>;
  cc?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["String"]>;
  languageId: Scalars["Int"];
  replyTo?: InputMaybe<Scalars["String"]>;
  shopId: Scalars["Int"];
  subject?: InputMaybe<Scalars["String"]>;
  to?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EmailTemplateEntryType>;
};

export type EmailTemplateEntryCreateManyEmailInputEnvelope = {
  data?: InputMaybe<Array<EmailTemplateEntryCreateManyEmailInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EmailTemplateEntryCreateNestedManyWithoutEmailInput = {
  connect?: InputMaybe<Array<EmailTemplateEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EmailTemplateEntryCreateOrConnectWithoutEmailInput>
  >;
  create?: InputMaybe<Array<EmailTemplateEntryCreateWithoutEmailInput>>;
  createMany?: InputMaybe<EmailTemplateEntryCreateManyEmailInputEnvelope>;
};

export type EmailTemplateEntryCreateOrConnectWithoutEmailInput = {
  create: EmailTemplateEntryCreateWithoutEmailInput;
  where: EmailTemplateEntryWhereUniqueInput;
};

export type EmailTemplateEntryCreateWithoutEmailInput = {
  bcc?: InputMaybe<Scalars["String"]>;
  cc?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["String"]>;
  languageId: Scalars["Int"];
  replyTo?: InputMaybe<Scalars["String"]>;
  shopId: Scalars["Int"];
  subject?: InputMaybe<Scalars["String"]>;
  to?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EmailTemplateEntryType>;
};

export type EmailTemplateEntryEmailEntryIdCompoundUniqueInput = {
  id: Scalars["Int"];
  languageId: Scalars["Int"];
  shopId: Scalars["Int"];
};

export type EmailTemplateEntryListRelationFilter = {
  every?: InputMaybe<EmailTemplateEntryWhereInput>;
  none?: InputMaybe<EmailTemplateEntryWhereInput>;
  some?: InputMaybe<EmailTemplateEntryWhereInput>;
};

export type EmailTemplateEntryScalarWhereInput = {
  AND?: InputMaybe<Array<EmailTemplateEntryScalarWhereInput>>;
  NOT?: InputMaybe<Array<EmailTemplateEntryScalarWhereInput>>;
  OR?: InputMaybe<Array<EmailTemplateEntryScalarWhereInput>>;
  bcc?: InputMaybe<StringNullableFilter>;
  cc?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringNullableFilter>;
  from?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  languageId?: InputMaybe<IntFilter>;
  replyTo?: InputMaybe<StringNullableFilter>;
  shopId?: InputMaybe<IntFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  to?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumEmailTemplateEntryTypeFilter>;
};

export enum EmailTemplateEntryType {
  Html = "html",
  Text = "text",
}

export type EmailTemplateEntryUpdateManyMutationInput = {
  bcc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  languageId?: InputMaybe<IntFieldUpdateOperationsInput>;
  replyTo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shopId?: InputMaybe<IntFieldUpdateOperationsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEmailTemplateEntryTypeFieldUpdateOperationsInput>;
};

export type EmailTemplateEntryUpdateManyWithWhereWithoutEmailInput = {
  data: EmailTemplateEntryUpdateManyMutationInput;
  where: EmailTemplateEntryScalarWhereInput;
};

export type EmailTemplateEntryUpdateManyWithoutEmailInput = {
  connect?: InputMaybe<Array<EmailTemplateEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EmailTemplateEntryCreateOrConnectWithoutEmailInput>
  >;
  create?: InputMaybe<Array<EmailTemplateEntryCreateWithoutEmailInput>>;
  createMany?: InputMaybe<EmailTemplateEntryCreateManyEmailInputEnvelope>;
  delete?: InputMaybe<Array<EmailTemplateEntryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailTemplateEntryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailTemplateEntryWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailTemplateEntryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<EmailTemplateEntryUpdateWithWhereUniqueWithoutEmailInput>
  >;
  updateMany?: InputMaybe<
    Array<EmailTemplateEntryUpdateManyWithWhereWithoutEmailInput>
  >;
  upsert?: InputMaybe<
    Array<EmailTemplateEntryUpsertWithWhereUniqueWithoutEmailInput>
  >;
};

export type EmailTemplateEntryUpdateWithWhereUniqueWithoutEmailInput = {
  data: EmailTemplateEntryUpdateWithoutEmailInput;
  where: EmailTemplateEntryWhereUniqueInput;
};

export type EmailTemplateEntryUpdateWithoutEmailInput = {
  bcc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  languageId?: InputMaybe<IntFieldUpdateOperationsInput>;
  replyTo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shopId?: InputMaybe<IntFieldUpdateOperationsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumEmailTemplateEntryTypeFieldUpdateOperationsInput>;
};

export type EmailTemplateEntryUpsertWithWhereUniqueWithoutEmailInput = {
  create: EmailTemplateEntryCreateWithoutEmailInput;
  update: EmailTemplateEntryUpdateWithoutEmailInput;
  where: EmailTemplateEntryWhereUniqueInput;
};

export type EmailTemplateEntryWhereInput = {
  AND?: InputMaybe<Array<EmailTemplateEntryWhereInput>>;
  NOT?: InputMaybe<Array<EmailTemplateEntryWhereInput>>;
  OR?: InputMaybe<Array<EmailTemplateEntryWhereInput>>;
  bcc?: InputMaybe<StringNullableFilter>;
  cc?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<EmailTemplateWhereInput>;
  from?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  languageId?: InputMaybe<IntFilter>;
  replyTo?: InputMaybe<StringNullableFilter>;
  shopId?: InputMaybe<IntFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  to?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumEmailTemplateEntryTypeFilter>;
};

export type EmailTemplateEntryWhereUniqueInput = {
  emailEntryId?: InputMaybe<EmailTemplateEntryEmailEntryIdCompoundUniqueInput>;
};

export type EmailTemplateOrderByInput = {
  comment?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type EmailTemplateUpdateInput = {
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailEntry?: InputMaybe<EmailTemplateEntryUpdateManyWithoutEmailInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EmailTemplateWhereInput = {
  AND?: InputMaybe<Array<EmailTemplateWhereInput>>;
  NOT?: InputMaybe<Array<EmailTemplateWhereInput>>;
  OR?: InputMaybe<Array<EmailTemplateWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  emailEntry?: InputMaybe<EmailTemplateEntryListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
};

export type EmailTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type EnumEmailTemplateEntryTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<EmailTemplateEntryType>;
};

export type EnumEmailTemplateEntryTypeFilter = {
  equals?: InputMaybe<EmailTemplateEntryType>;
  in?: InputMaybe<Array<EmailTemplateEntryType>>;
  not?: InputMaybe<NestedEnumEmailTemplateEntryTypeFilter>;
  notIn?: InputMaybe<Array<EmailTemplateEntryType>>;
};

export type EnumMaintenanceGroupUserTypeFilter = {
  equals?: InputMaybe<MaintenanceGroupUserType>;
  in?: InputMaybe<Array<MaintenanceGroupUserType>>;
  not?: InputMaybe<NestedEnumMaintenanceGroupUserTypeFilter>;
  notIn?: InputMaybe<Array<MaintenanceGroupUserType>>;
};

export type EnumMaintenanceReportServiceStatusFilter = {
  equals?: InputMaybe<MaintenanceReportServiceStatus>;
  in?: InputMaybe<Array<MaintenanceReportServiceStatus>>;
  not?: InputMaybe<NestedEnumMaintenanceReportServiceStatusFilter>;
  notIn?: InputMaybe<Array<MaintenanceReportServiceStatus>>;
};

export type EnumMaintenanceReportTypeFilter = {
  equals?: InputMaybe<MaintenanceReportType>;
  in?: InputMaybe<Array<MaintenanceReportType>>;
  not?: InputMaybe<NestedEnumMaintenanceReportTypeFilter>;
  notIn?: InputMaybe<Array<MaintenanceReportType>>;
};

export type EnumMaintenanceSecondRepairReasonFilter = {
  equals?: InputMaybe<MaintenanceSecondRepairReason>;
  in?: InputMaybe<Array<MaintenanceSecondRepairReason>>;
  not?: InputMaybe<NestedEnumMaintenanceSecondRepairReasonFilter>;
  notIn?: InputMaybe<Array<MaintenanceSecondRepairReason>>;
};

export type EnumPermissionStatusFilter = {
  equals?: InputMaybe<PermissionStatus>;
  in?: InputMaybe<Array<PermissionStatus>>;
  not?: InputMaybe<NestedEnumPermissionStatusFilter>;
  notIn?: InputMaybe<Array<PermissionStatus>>;
};

export type EnumProductIncentiveTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductIncentiveType>;
};

export type EnumProductIncentiveTypeFilter = {
  equals?: InputMaybe<ProductIncentiveType>;
  in?: InputMaybe<Array<ProductIncentiveType>>;
  not?: InputMaybe<NestedEnumProductIncentiveTypeFilter>;
  notIn?: InputMaybe<Array<ProductIncentiveType>>;
};

export type EnumProductTypeNullableFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeNullableFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type EnumReportServiceSparePartResourceTypeFilter = {
  equals?: InputMaybe<ReportServiceSparePartResourceType>;
  in?: InputMaybe<Array<ReportServiceSparePartResourceType>>;
  not?: InputMaybe<NestedEnumReportServiceSparePartResourceTypeFilter>;
  notIn?: InputMaybe<Array<ReportServiceSparePartResourceType>>;
};

export type EnumResourceProductGroupTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ResourceProductGroupType>;
};

export type EnumResourceProductGroupTypeFilter = {
  equals?: InputMaybe<ResourceProductGroupType>;
  in?: InputMaybe<Array<ResourceProductGroupType>>;
  not?: InputMaybe<NestedEnumResourceProductGroupTypeFilter>;
  notIn?: InputMaybe<Array<ResourceProductGroupType>>;
};

export type EnumResourceTextTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ResourceTextType>;
};

export type EnumResourceTextTypeFilter = {
  equals?: InputMaybe<ResourceTextType>;
  in?: InputMaybe<Array<ResourceTextType>>;
  not?: InputMaybe<NestedEnumResourceTextTypeFilter>;
  notIn?: InputMaybe<Array<ResourceTextType>>;
};

export type EnumResourceVariantTypeNullableFilter = {
  equals?: InputMaybe<ResourceVariantType>;
  in?: InputMaybe<Array<ResourceVariantType>>;
  not?: InputMaybe<NestedEnumResourceVariantTypeNullableFilter>;
  notIn?: InputMaybe<Array<ResourceVariantType>>;
};

export type EnumSupplierProductCarbonFootprintMethodNullableFilter = {
  equals?: InputMaybe<SupplierProductCarbonFootprintMethod>;
  in?: InputMaybe<Array<SupplierProductCarbonFootprintMethod>>;
  not?: InputMaybe<NestedEnumSupplierProductCarbonFootprintMethodNullableFilter>;
  notIn?: InputMaybe<Array<SupplierProductCarbonFootprintMethod>>;
};

export type EnumTextAttributeTypeNullableFilter = {
  equals?: InputMaybe<TextAttributeType>;
  in?: InputMaybe<Array<TextAttributeType>>;
  not?: InputMaybe<NestedEnumTextAttributeTypeNullableFilter>;
  notIn?: InputMaybe<Array<TextAttributeType>>;
};

export type EnumVariantMaterialDataQualityTypeFilter = {
  equals?: InputMaybe<VariantMaterialDataQualityType>;
  in?: InputMaybe<Array<VariantMaterialDataQualityType>>;
  not?: InputMaybe<NestedEnumVariantMaterialDataQualityTypeFilter>;
  notIn?: InputMaybe<Array<VariantMaterialDataQualityType>>;
};

export type EnumVariantShippingTypeNullableFilter = {
  equals?: InputMaybe<VariantShippingType>;
  in?: InputMaybe<Array<VariantShippingType>>;
  not?: InputMaybe<NestedEnumVariantShippingTypeNullableFilter>;
  notIn?: InputMaybe<Array<VariantShippingType>>;
};

export type EnumVariantTypeNullableFilter = {
  equals?: InputMaybe<VariantType>;
  in?: InputMaybe<Array<VariantType>>;
  not?: InputMaybe<NestedEnumVariantTypeNullableFilter>;
  notIn?: InputMaybe<Array<VariantType>>;
};

export type EnumVoucherTypeFilter = {
  equals?: InputMaybe<VoucherType>;
  in?: InputMaybe<Array<VoucherType>>;
  not?: InputMaybe<NestedEnumVoucherTypeFilter>;
  notIn?: InputMaybe<Array<VoucherType>>;
};

export type FavouriteApp = {
  __typename?: "FavouriteApp";
  app?: Maybe<App>;
  appId?: Maybe<Scalars["Int"]>;
  email: Scalars["String"];
  id: Scalars["Int"];
};

export type FavouriteAppListRelationFilter = {
  every?: InputMaybe<FavouriteAppWhereInput>;
  none?: InputMaybe<FavouriteAppWhereInput>;
  some?: InputMaybe<FavouriteAppWhereInput>;
};

export type FavouriteAppWhereInput = {
  AND?: InputMaybe<Array<FavouriteAppWhereInput>>;
  NOT?: InputMaybe<Array<FavouriteAppWhereInput>>;
  OR?: InputMaybe<Array<FavouriteAppWhereInput>>;
  app?: InputMaybe<AppWhereInput>;
  appId?: InputMaybe<IntNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
};

export type FavouriteAppWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type FilterDateRange = {
  end?: InputMaybe<Scalars["String"]>;
  start: Scalars["String"];
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Float"]>;
  divide?: InputMaybe<Scalars["Float"]>;
  increment?: InputMaybe<Scalars["Float"]>;
  multiply?: InputMaybe<Scalars["Float"]>;
  set?: InputMaybe<Scalars["Float"]>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type GalaxusOrder = Order & {
  __typename?: "GalaxusOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type GalaxusOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type GraphqlMutationLog = MutationLog & {
  __typename?: "GraphqlMutationLog";
  date?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  ip?: Maybe<Scalars["String"]>;
  /** The graphql could have a list of ips because they are forwarded by api gateway and the apollo gateway */
  ips?: Maybe<Scalars["String"]>;
  /**
   * As we have staging enviroments, this can be true or false. Note, that this
   * boolean is not telling you the database connection, as the developer is in
   * charge of switching it on the endpoint itself.
   */
  isProduction?: Maybe<Scalars["Boolean"]>;
  /** This is the operation name in gql or the rest context + fieldName  */
  operation?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
  /** The graphql query */
  query?: Maybe<Scalars["String"]>;
  /** The graphql referrer */
  referrer?: Maybe<Scalars["String"]>;
  /** The passed variables for the graphql query */
  variables?: Maybe<Scalars["String"]>;
};

export type GrossNetAmount = {
  __typename?: "GrossNetAmount";
  currency?: Maybe<Scalars["String"]>;
  grossAmount?: Maybe<Scalars["Float"]>;
  itemCount?: Maybe<Scalars["Int"]>;
  netAmount?: Maybe<Scalars["Float"]>;
  taxRate?: Maybe<Scalars["Float"]>;
};

export type Group = {
  __typename?: "Group";
  activeDirectoryGroup?: Maybe<ActiveDirectoryGroup>;
  creator: Scalars["String"];
  /** Active Directory User */
  creatorADUser?: Maybe<AdUser>;
  groupCountry: Array<GroupCountry>;
  groupRole: Array<GroupRole>;
  id: Scalars["Int"];
  memberGroup: Array<MemberGroup>;
  slug: Scalars["String"];
};

export type GroupGroupCountryArgs = {
  after?: InputMaybe<GroupCountryWhereUniqueInput>;
  before?: InputMaybe<GroupCountryWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type GroupGroupRoleArgs = {
  after?: InputMaybe<GroupRoleWhereUniqueInput>;
  before?: InputMaybe<GroupRoleWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type GroupMemberGroupArgs = {
  after?: InputMaybe<MemberGroupWhereUniqueInput>;
  before?: InputMaybe<MemberGroupWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type GroupAttributeUpdateInput = {
  activeDirectoryGroupId?: InputMaybe<Scalars["String"]>;
  creator: Scalars["String"];
  slug?: InputMaybe<Scalars["String"]>;
};

export type GroupCountry = {
  __typename?: "GroupCountry";
  countryCode: Scalars["String"];
  group: Group;
  id: Scalars["Int"];
};

export type GroupCountryGroupIdCountryCodeCompoundUniqueInput = {
  countryCode: Scalars["String"];
  groupId: Scalars["Int"];
};

export type GroupCountryListRelationFilter = {
  every?: InputMaybe<GroupCountryWhereInput>;
  none?: InputMaybe<GroupCountryWhereInput>;
  some?: InputMaybe<GroupCountryWhereInput>;
};

export type GroupCountryWhereInput = {
  AND?: InputMaybe<Array<GroupCountryWhereInput>>;
  NOT?: InputMaybe<Array<GroupCountryWhereInput>>;
  OR?: InputMaybe<Array<GroupCountryWhereInput>>;
  countryCode?: InputMaybe<StringFilter>;
  group?: InputMaybe<GroupWhereInput>;
  groupId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type GroupCountryWhereUniqueInput = {
  groupId_countryCode?: InputMaybe<GroupCountryGroupIdCountryCodeCompoundUniqueInput>;
  id?: InputMaybe<Scalars["Int"]>;
};

export type GroupListRelationFilter = {
  every?: InputMaybe<GroupWhereInput>;
  none?: InputMaybe<GroupWhereInput>;
  some?: InputMaybe<GroupWhereInput>;
};

export type GroupOrderByInput = {
  activeDirectoryGroupId?: InputMaybe<SortOrder>;
  creator?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
};

export type GroupRole = {
  __typename?: "GroupRole";
  group: Group;
  groupId: Scalars["Int"];
  id: Scalars["Int"];
  role: Role;
  roleId: Scalars["Int"];
};

export type GroupRoleListRelationFilter = {
  every?: InputMaybe<GroupRoleWhereInput>;
  none?: InputMaybe<GroupRoleWhereInput>;
  some?: InputMaybe<GroupRoleWhereInput>;
};

export type GroupRoleWhereInput = {
  AND?: InputMaybe<Array<GroupRoleWhereInput>>;
  NOT?: InputMaybe<Array<GroupRoleWhereInput>>;
  OR?: InputMaybe<Array<GroupRoleWhereInput>>;
  group?: InputMaybe<GroupWhereInput>;
  groupId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  role?: InputMaybe<RoleWhereInput>;
  roleId?: InputMaybe<IntFilter>;
};

export type GroupRoleWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type GroupWhereInput = {
  AND?: InputMaybe<Array<GroupWhereInput>>;
  NOT?: InputMaybe<Array<GroupWhereInput>>;
  OR?: InputMaybe<Array<GroupWhereInput>>;
  activeDirectoryGroup?: InputMaybe<ActiveDirectoryGroupWhereInput>;
  activeDirectoryGroupId?: InputMaybe<StringNullableFilter>;
  creator?: InputMaybe<StringFilter>;
  groupCountry?: InputMaybe<GroupCountryListRelationFilter>;
  groupRole?: InputMaybe<GroupRoleListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  memberGroup?: InputMaybe<MemberGroupListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type GroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type HolidayDate = {
  __typename?: "HolidayDate";
  date: Scalars["DateTime"];
  holidayInfo?: Maybe<Scalars["String"]>;
};

export type ImageZipConsume = {
  __typename?: "ImageZipConsume";
  key: Scalars["String"];
  url: Scalars["String"];
};

export type ImageZipResourceInput = {
  versionId: Scalars["Int"];
};

export type ImageZipResult = {
  __typename?: "ImageZipResult";
  key: Scalars["String"];
};

export type ImageZipStatus = {
  __typename?: "ImageZipStatus";
  key: Scalars["String"];
  status: Scalars["String"];
};

export type Incentive = {
  __typename?: "Incentive";
  name?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["Int"]>;
  variantId?: Maybe<Scalars["ID"]>;
  variantNumber?: Maybe<Scalars["String"]>;
};

export type IncentiveVoucher = Voucher & {
  __typename?: "IncentiveVoucher";
  active?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<Scalars["String"]>;
  codes?: Maybe<VoucherCodePagination>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  incentive?: Maybe<Incentive>;
  minOrderValue?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  navisionAccount?: Maybe<Scalars["Int"]>;
  shopId?: Maybe<Scalars["Int"]>;
  statistics?: Maybe<VoucherStatistic>;
  /** Total number of times this voucher has been redeemed in an confirmed order. */
  totalRedeemCount?: Maybe<Scalars["Int"]>;
  /** Total number of redeemed codes. Please keep in mind that this number might seem wrong if codes can redeem multiple times. This query uses the boolean on voucher code. */
  totalRedeemdedCodes?: Maybe<Scalars["Int"]>;
  unique?: Maybe<Scalars["Boolean"]>;
};

export type IncentiveVoucherCodesArgs = {
  code?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  expired?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  redeemed?: InputMaybe<Scalars["Boolean"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VoucherCodeWhereInput>;
};

export type IncentiveVoucherStatisticsArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]>;
  divide?: InputMaybe<Scalars["Int"]>;
  increment?: InputMaybe<Scalars["Int"]>;
  multiply?: InputMaybe<Scalars["Int"]>;
  set?: InputMaybe<Scalars["Int"]>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type ItemCountRange = {
  max?: InputMaybe<Scalars["Float"]>;
  min: Scalars["Float"];
};

export type KauflandOrder = Order & {
  __typename?: "KauflandOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type KauflandOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type KlarnaPaymentData = {
  __typename?: "KlarnaPaymentData";
  orderId?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["String"]>;
  transactionUrl?: Maybe<Scalars["String"]>;
};

export type Language = LanguageInterface & {
  __typename?: "Language";
  abbreviation?: Maybe<Scalars["String"]>;
  fallbackLanguage?: Maybe<Language>;
  fallbackLanguageId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with fallbackLanguage */
  fallback_language?: Maybe<Language>;
  /** @deprecated replaced with fallbackLanguageId */
  fallback_language_id?: Maybe<Scalars["Int"]>;
  iso639Code?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with iso639Code */
  iso_639_code?: Maybe<Scalars["String"]>;
  languageId: Scalars["Int"];
  languageName?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with languageId */
  language_id?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with languageName */
  language_name?: Maybe<Scalars["String"]>;
  solrSuggestCollection?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with solrSuggestCollection */
  solr_suggest_collection?: Maybe<Scalars["String"]>;
};

export type LanguageCreateManyFallback_LanguageInput = {
  abbreviation?: InputMaybe<Scalars["String"]>;
  iso_639_code?: InputMaybe<Scalars["String"]>;
  language_id?: InputMaybe<Scalars["Int"]>;
  language_name?: InputMaybe<Scalars["String"]>;
  solr_suggest_collection?: InputMaybe<Scalars["String"]>;
};

export type LanguageCreateManyFallback_LanguageInputEnvelope = {
  data?: InputMaybe<Array<LanguageCreateManyFallback_LanguageInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type LanguageCreateNestedManyWithoutFallback_LanguageInput = {
  connect?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LanguageCreateOrConnectWithoutFallback_LanguageInput>
  >;
  create?: InputMaybe<Array<LanguageCreateWithoutFallback_LanguageInput>>;
  createMany?: InputMaybe<LanguageCreateManyFallback_LanguageInputEnvelope>;
};

export type LanguageCreateNestedOneWithoutLanguageInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutLanguageInput>;
  create?: InputMaybe<LanguageCreateWithoutLanguageInput>;
};

export type LanguageCreateNestedOneWithoutResourceLanguageInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutResourceLanguageInput>;
  create?: InputMaybe<LanguageCreateWithoutResourceLanguageInput>;
};

export type LanguageCreateNestedOneWithoutResourceProductInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutResourceProductInput>;
  create?: InputMaybe<LanguageCreateWithoutResourceProductInput>;
};

export type LanguageCreateNestedOneWithoutResourceVariantInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutResourceVariantInput>;
  create?: InputMaybe<LanguageCreateWithoutResourceVariantInput>;
};

export type LanguageCreateOrConnectWithoutFallback_LanguageInput = {
  create: LanguageCreateWithoutFallback_LanguageInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageCreateOrConnectWithoutLanguageInput = {
  create: LanguageCreateWithoutLanguageInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageCreateOrConnectWithoutResourceLanguageInput = {
  create: LanguageCreateWithoutResourceLanguageInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageCreateOrConnectWithoutResourceProductInput = {
  create: LanguageCreateWithoutResourceProductInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageCreateOrConnectWithoutResourceVariantInput = {
  create: LanguageCreateWithoutResourceVariantInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageCreateWithoutFallback_LanguageInput = {
  Language?: InputMaybe<LanguageCreateNestedManyWithoutFallback_LanguageInput>;
  ResourceProduct?: InputMaybe<ResourceProductCreateNestedManyWithoutLanguageInput>;
  abbreviation?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointCreateNestedManyWithoutLanguageInput>;
  iso_639_code?: InputMaybe<Scalars["String"]>;
  language_name?: InputMaybe<Scalars["String"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutLanguageInput>;
  resourceVariant?: InputMaybe<ResourceVariantCreateNestedManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<Scalars["String"]>;
};

export type LanguageCreateWithoutLanguageInput = {
  ResourceProduct?: InputMaybe<ResourceProductCreateNestedManyWithoutLanguageInput>;
  abbreviation?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointCreateNestedManyWithoutLanguageInput>;
  fallback_language?: InputMaybe<LanguageCreateNestedOneWithoutLanguageInput>;
  iso_639_code?: InputMaybe<Scalars["String"]>;
  language_name?: InputMaybe<Scalars["String"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutLanguageInput>;
  resourceVariant?: InputMaybe<ResourceVariantCreateNestedManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<Scalars["String"]>;
};

export type LanguageCreateWithoutResourceLanguageInput = {
  Language?: InputMaybe<LanguageCreateNestedManyWithoutFallback_LanguageInput>;
  ResourceProduct?: InputMaybe<ResourceProductCreateNestedManyWithoutLanguageInput>;
  abbreviation?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointCreateNestedManyWithoutLanguageInput>;
  fallback_language?: InputMaybe<LanguageCreateNestedOneWithoutLanguageInput>;
  iso_639_code?: InputMaybe<Scalars["String"]>;
  language_name?: InputMaybe<Scalars["String"]>;
  resourceVariant?: InputMaybe<ResourceVariantCreateNestedManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<Scalars["String"]>;
};

export type LanguageCreateWithoutResourceProductInput = {
  Language?: InputMaybe<LanguageCreateNestedManyWithoutFallback_LanguageInput>;
  abbreviation?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointCreateNestedManyWithoutLanguageInput>;
  fallback_language?: InputMaybe<LanguageCreateNestedOneWithoutLanguageInput>;
  iso_639_code?: InputMaybe<Scalars["String"]>;
  language_name?: InputMaybe<Scalars["String"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutLanguageInput>;
  resourceVariant?: InputMaybe<ResourceVariantCreateNestedManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<Scalars["String"]>;
};

export type LanguageCreateWithoutResourceVariantInput = {
  Language?: InputMaybe<LanguageCreateNestedManyWithoutFallback_LanguageInput>;
  ResourceProduct?: InputMaybe<ResourceProductCreateNestedManyWithoutLanguageInput>;
  abbreviation?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointCreateNestedManyWithoutLanguageInput>;
  fallback_language?: InputMaybe<LanguageCreateNestedOneWithoutLanguageInput>;
  iso_639_code?: InputMaybe<Scalars["String"]>;
  language_name?: InputMaybe<Scalars["String"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<Scalars["String"]>;
};

export type LanguageInterface = {
  languageId?: Maybe<Scalars["Int"]>;
};

export type LanguageListRelationFilter = {
  every?: InputMaybe<LanguageWhereInput>;
  none?: InputMaybe<LanguageWhereInput>;
  some?: InputMaybe<LanguageWhereInput>;
};

export type LanguageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LanguageOrderByWithRelationInput = {
  Language?: InputMaybe<LanguageOrderByRelationAggregateInput>;
  ResourceProduct?: InputMaybe<ResourceProductOrderByRelationAggregateInput>;
  abbreviation?: InputMaybe<SortOrder>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointOrderByRelationAggregateInput>;
  fallback_language?: InputMaybe<LanguageOrderByWithRelationInput>;
  fallback_language_id?: InputMaybe<SortOrder>;
  iso_639_code?: InputMaybe<SortOrder>;
  language_id?: InputMaybe<SortOrder>;
  language_name?: InputMaybe<SortOrder>;
  resourceLanguage?: InputMaybe<ResourceLanguageOrderByRelationAggregateInput>;
  resourceVariant?: InputMaybe<ResourceVariantOrderByRelationAggregateInput>;
  solr_suggest_collection?: InputMaybe<SortOrder>;
};

export type LanguageScalarWhereInput = {
  AND?: InputMaybe<Array<LanguageScalarWhereInput>>;
  NOT?: InputMaybe<Array<LanguageScalarWhereInput>>;
  OR?: InputMaybe<Array<LanguageScalarWhereInput>>;
  abbreviation?: InputMaybe<StringNullableFilter>;
  fallback_language_id?: InputMaybe<IntNullableFilter>;
  iso_639_code?: InputMaybe<StringNullableFilter>;
  language_id?: InputMaybe<IntFilter>;
  language_name?: InputMaybe<StringNullableFilter>;
  solr_suggest_collection?: InputMaybe<StringNullableFilter>;
};

export type LanguageUpdateManyMutationInput = {
  abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iso_639_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  solr_suggest_collection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LanguageUpdateManyWithWhereWithoutFallback_LanguageInput = {
  data: LanguageUpdateManyMutationInput;
  where: LanguageScalarWhereInput;
};

export type LanguageUpdateManyWithoutFallback_LanguageInput = {
  connect?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LanguageCreateOrConnectWithoutFallback_LanguageInput>
  >;
  create?: InputMaybe<Array<LanguageCreateWithoutFallback_LanguageInput>>;
  createMany?: InputMaybe<LanguageCreateManyFallback_LanguageInputEnvelope>;
  delete?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LanguageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  set?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LanguageUpdateWithWhereUniqueWithoutFallback_LanguageInput>
  >;
  updateMany?: InputMaybe<
    Array<LanguageUpdateManyWithWhereWithoutFallback_LanguageInput>
  >;
  upsert?: InputMaybe<
    Array<LanguageUpsertWithWhereUniqueWithoutFallback_LanguageInput>
  >;
};

export type LanguageUpdateOneRequiredWithoutResourceLanguageInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutResourceLanguageInput>;
  create?: InputMaybe<LanguageCreateWithoutResourceLanguageInput>;
  update?: InputMaybe<LanguageUpdateWithoutResourceLanguageInput>;
  upsert?: InputMaybe<LanguageUpsertWithoutResourceLanguageInput>;
};

export type LanguageUpdateOneWithoutLanguageInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutLanguageInput>;
  create?: InputMaybe<LanguageCreateWithoutLanguageInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<LanguageUpdateWithoutLanguageInput>;
  upsert?: InputMaybe<LanguageUpsertWithoutLanguageInput>;
};

export type LanguageUpdateOneWithoutResourceProductInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutResourceProductInput>;
  create?: InputMaybe<LanguageCreateWithoutResourceProductInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<LanguageUpdateWithoutResourceProductInput>;
  upsert?: InputMaybe<LanguageUpsertWithoutResourceProductInput>;
};

export type LanguageUpdateOneWithoutResourceVariantInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutResourceVariantInput>;
  create?: InputMaybe<LanguageCreateWithoutResourceVariantInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<LanguageUpdateWithoutResourceVariantInput>;
  upsert?: InputMaybe<LanguageUpsertWithoutResourceVariantInput>;
};

export type LanguageUpdateWithWhereUniqueWithoutFallback_LanguageInput = {
  data: LanguageUpdateWithoutFallback_LanguageInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageUpdateWithoutFallback_LanguageInput = {
  Language?: InputMaybe<LanguageUpdateManyWithoutFallback_LanguageInput>;
  ResourceProduct?: InputMaybe<ResourceProductUpdateManyWithoutLanguageInput>;
  abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointUpdateManyWithoutLanguageInput>;
  iso_639_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutLanguageInput>;
  resourceVariant?: InputMaybe<ResourceVariantUpdateManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LanguageUpdateWithoutLanguageInput = {
  ResourceProduct?: InputMaybe<ResourceProductUpdateManyWithoutLanguageInput>;
  abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointUpdateManyWithoutLanguageInput>;
  fallback_language?: InputMaybe<LanguageUpdateOneWithoutLanguageInput>;
  iso_639_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutLanguageInput>;
  resourceVariant?: InputMaybe<ResourceVariantUpdateManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LanguageUpdateWithoutResourceLanguageInput = {
  Language?: InputMaybe<LanguageUpdateManyWithoutFallback_LanguageInput>;
  ResourceProduct?: InputMaybe<ResourceProductUpdateManyWithoutLanguageInput>;
  abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointUpdateManyWithoutLanguageInput>;
  fallback_language?: InputMaybe<LanguageUpdateOneWithoutLanguageInput>;
  iso_639_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resourceVariant?: InputMaybe<ResourceVariantUpdateManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LanguageUpdateWithoutResourceProductInput = {
  Language?: InputMaybe<LanguageUpdateManyWithoutFallback_LanguageInput>;
  abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointUpdateManyWithoutLanguageInput>;
  fallback_language?: InputMaybe<LanguageUpdateOneWithoutLanguageInput>;
  iso_639_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutLanguageInput>;
  resourceVariant?: InputMaybe<ResourceVariantUpdateManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LanguageUpdateWithoutResourceVariantInput = {
  Language?: InputMaybe<LanguageUpdateManyWithoutFallback_LanguageInput>;
  ResourceProduct?: InputMaybe<ResourceProductUpdateManyWithoutLanguageInput>;
  abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointUpdateManyWithoutLanguageInput>;
  fallback_language?: InputMaybe<LanguageUpdateOneWithoutLanguageInput>;
  iso_639_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutLanguageInput>;
  solr_suggest_collection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LanguageUpsertWithWhereUniqueWithoutFallback_LanguageInput = {
  create: LanguageCreateWithoutFallback_LanguageInput;
  update: LanguageUpdateWithoutFallback_LanguageInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageUpsertWithoutLanguageInput = {
  create: LanguageCreateWithoutLanguageInput;
  update: LanguageUpdateWithoutLanguageInput;
};

export type LanguageUpsertWithoutResourceLanguageInput = {
  create: LanguageCreateWithoutResourceLanguageInput;
  update: LanguageUpdateWithoutResourceLanguageInput;
};

export type LanguageUpsertWithoutResourceProductInput = {
  create: LanguageCreateWithoutResourceProductInput;
  update: LanguageUpdateWithoutResourceProductInput;
};

export type LanguageUpsertWithoutResourceVariantInput = {
  create: LanguageCreateWithoutResourceVariantInput;
  update: LanguageUpdateWithoutResourceVariantInput;
};

export type LanguageWhereInput = {
  AND?: InputMaybe<Array<LanguageWhereInput>>;
  Language?: InputMaybe<LanguageListRelationFilter>;
  NOT?: InputMaybe<Array<LanguageWhereInput>>;
  OR?: InputMaybe<Array<LanguageWhereInput>>;
  ResourceProduct?: InputMaybe<ResourceProductListRelationFilter>;
  abbreviation?: InputMaybe<StringNullableFilter>;
  domain?: InputMaybe<DomainForLanguageRelationMediaEndpointListRelationFilter>;
  fallback_language?: InputMaybe<LanguageWhereInput>;
  fallback_language_id?: InputMaybe<IntNullableFilter>;
  iso_639_code?: InputMaybe<StringNullableFilter>;
  language_id?: InputMaybe<IntFilter>;
  language_name?: InputMaybe<StringNullableFilter>;
  resourceLanguage?: InputMaybe<ResourceLanguageListRelationFilter>;
  resourceVariant?: InputMaybe<ResourceVariantListRelationFilter>;
  solr_suggest_collection?: InputMaybe<StringNullableFilter>;
};

export type LanguageWhereUniqueInput = {
  language_id?: InputMaybe<Scalars["Int"]>;
};

export type ListMaintenanceTourStops = {
  __typename?: "ListMaintenanceTourStops";
  count?: Maybe<Scalars["Int"]>;
  stops?: Maybe<Array<Maybe<MaintenanceTourStop>>>;
};

export type Mail = ParcelLabMail | ShopMail;

export type MaintenanceAbsence = {
  __typename?: "MaintenanceAbsence";
  days: Scalars["Int"];
  email?: Maybe<Scalars["String"]>;
  end: Scalars["DateTime"];
  firstname?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastname?: Maybe<Scalars["String"]>;
  /** The reason for the absence. If the reason type is not public, you will get restricted. */
  reason: Scalars["String"];
  start: Scalars["DateTime"];
  workingDays: Scalars["Int"];
};

export type MaintenanceAppointment = {
  __typename?: "MaintenanceAppointment";
  description?: Maybe<Scalars["String"]>;
  endDate: Scalars["DateTime"];
  id: Scalars["Int"];
  /** if set, the appointment is only valid for one technician, if not, the appointment is displayed in the calendar of all technicians */
  maintenanceUser?: Maybe<MaintenanceUser>;
  readyForSync: Scalars["Boolean"];
  startDate: Scalars["DateTime"];
  title: Scalars["String"];
};

export type MaintenanceAppointmentCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  endDate: Scalars["DateTime"];
  readyForSync?: Scalars["Boolean"];
  startDate: Scalars["DateTime"];
  title: Scalars["String"];
  userId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceAppointmentListRelationFilter = {
  every?: InputMaybe<MaintenanceAppointmentWhereInput>;
  none?: InputMaybe<MaintenanceAppointmentWhereInput>;
  some?: InputMaybe<MaintenanceAppointmentWhereInput>;
};

export type MaintenanceAppointmentOrderByInput = {
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  readyForSync?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type MaintenanceAppointmentUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  readyForSync?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceAppointmentWhereInput = {
  AND?: InputMaybe<Array<MaintenanceAppointmentWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceAppointmentWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceAppointmentWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  maintenanceUser?: InputMaybe<MaintenanceUserWhereInput>;
  readyForSync?: InputMaybe<BoolFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceAppointmentWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceBlueprint = {
  __typename?: "MaintenanceBlueprint";
  deleted: Scalars["Boolean"];
  fieldCount: Scalars["Int"];
  id: Scalars["Int"];
  imageSlug: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  reportSparePartBlueprints: Array<MaintenanceReportSparePartBlueprint>;
};

export type MaintenanceBlueprintImageUrlArgs = {
  template?: Scalars["String"];
};

export type MaintenanceBlueprintReportSparePartBlueprintsArgs = {
  after?: InputMaybe<MaintenanceReportSparePartBlueprintWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportSparePartBlueprintWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceBlueprintOrderByInput = {
  deleted?: InputMaybe<SortOrder>;
  fieldCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageSlug?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type MaintenanceBlueprintWhereInput = {
  AND?: InputMaybe<Array<MaintenanceBlueprintWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceBlueprintWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceBlueprintWhereInput>>;
  deleted?: InputMaybe<BoolFilter>;
  fieldCount?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  imageSlug?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  reportSparePartBlueprints?: InputMaybe<MaintenanceReportSparePartBlueprintListRelationFilter>;
};

export type MaintenanceBlueprintWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceCustomerStopsPdf = {
  __typename?: "MaintenanceCustomerStopsPdf";
  signedUrl?: Maybe<Scalars["String"]>;
};

export type MaintenanceCustomerStopsPdfInput = {
  customerNumber: Scalars["String"];
  fromDate: Scalars["DateTime"];
  toDate: Scalars["DateTime"];
};

export type MaintenanceDateBasedStatisticPoint = {
  __typename?: "MaintenanceDateBasedStatisticPoint";
  date: Scalars["DateTime"];
  value: Scalars["Float"];
};

export type MaintenanceEmailQueue = {
  __typename?: "MaintenanceEmailQueue";
  attachments?: Maybe<Scalars["String"]>;
  bcc?: Maybe<Scalars["String"]>;
  cc?: Maybe<Scalars["String"]>;
  emailId: Scalars["Int"];
  from?: Maybe<Scalars["String"]>;
  marketplaceOrderId?: Maybe<Scalars["Int"]>;
  navisionOrderId?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["Int"]>;
  replyTo?: Maybe<Scalars["String"]>;
  sendAfter?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<MaintenanceEmailQueueStatus>;
  subject?: Maybe<Scalars["String"]>;
  templateName?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["String"]>;
  type?: Maybe<MaintenanceEmailQueueType>;
};

export enum MaintenanceEmailQueueStatus {
  Blacklist = "BLACKLIST",
  Enqueued = "ENQUEUED",
  Error = "ERROR",
  Future = "FUTURE",
  FutureUnreleased = "FUTURE_UNRELEASED",
  Sending = "SENDING",
  Sent = "SENT",
  Spam = "SPAM",
}

export enum MaintenanceEmailQueueType {
  Html = "HTML",
  Text = "TEXT",
}

export type MaintenanceGroup = {
  __typename?: "MaintenanceGroup";
  address: Scalars["String"];
  editors: Array<Maybe<MaintenanceUser>>;
  id: Scalars["Int"];
  name: Scalars["String"];
  phoneNumber: Scalars["String"];
  state?: Maybe<Scalars["String"]>;
  technicians: Array<Maybe<MaintenanceUser>>;
  telefaxNumber: Scalars["String"];
};

export type MaintenanceGroupCreateInput = {
  address: Scalars["String"];
  name: Scalars["String"];
  phoneNumber: Scalars["String"];
  state: Scalars["String"];
  telefaxNumber: Scalars["String"];
};

export type MaintenanceGroupOrderByInput = {
  address?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  telefaxNumber?: InputMaybe<SortOrder>;
};

export type MaintenanceGroupUpdateInput = {
  address?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  telefaxNumber?: InputMaybe<Scalars["String"]>;
};

export type MaintenanceGroupUser = {
  __typename?: "MaintenanceGroupUser";
  group: MaintenanceGroup;
  groupId: Scalars["Int"];
  id: Scalars["Int"];
  type: MaintenanceGroupUserType;
  user: MaintenanceUser;
  userId: Scalars["Int"];
};

export type MaintenanceGroupUserCreateInput = {
  groupId: Scalars["Int"];
  type: MaintenanceGroupUserType;
  userId: Scalars["Int"];
};

export type MaintenanceGroupUserListRelationFilter = {
  every?: InputMaybe<MaintenanceGroupUserWhereInput>;
  none?: InputMaybe<MaintenanceGroupUserWhereInput>;
  some?: InputMaybe<MaintenanceGroupUserWhereInput>;
};

export type MaintenanceGroupUserOrderByInput = {
  groupId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum MaintenanceGroupUserType {
  Editor = "EDITOR",
  Technician = "TECHNICIAN",
}

export type MaintenanceGroupUserWhereInput = {
  AND?: InputMaybe<Array<MaintenanceGroupUserWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceGroupUserWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceGroupUserWhereInput>>;
  group?: InputMaybe<MaintenanceGroupWhereInput>;
  groupId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumMaintenanceGroupUserTypeFilter>;
  user?: InputMaybe<MaintenanceUserWhereInput>;
  userId?: InputMaybe<IntFilter>;
};

export type MaintenanceGroupUserWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceGroupWhereInput = {
  AND?: InputMaybe<Array<MaintenanceGroupWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceGroupWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceGroupWhereInput>>;
  address?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  maintenanceGroupUsers?: InputMaybe<MaintenanceGroupUserListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringNullableFilter>;
  telefaxNumber?: InputMaybe<StringFilter>;
};

export type MaintenanceGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceInspectionVsRepairStatisticPoint = {
  __typename?: "MaintenanceInspectionVsRepairStatisticPoint";
  date?: Maybe<Scalars["DateTime"]>;
  /** Number of tourstops with some inpsection report */
  inspections?: Maybe<Scalars["Int"]>;
  /** Number of tourstops with some repair report */
  repairs?: Maybe<Scalars["Int"]>;
};

export type MaintenanceOfferVsOrderStatisticPoint = {
  __typename?: "MaintenanceOfferVsOrderStatisticPoint";
  date?: Maybe<Scalars["DateTime"]>;
  /** Number of tourstops with an offer id */
  offers?: Maybe<Scalars["Int"]>;
  /** Number of tourstops with an order id */
  orders?: Maybe<Scalars["Int"]>;
};

export type MaintenancePdf = {
  __typename?: "MaintenancePdf";
  signedUrl?: Maybe<Scalars["String"]>;
};

export type MaintenanceProduct = {
  __typename?: "MaintenanceProduct";
  deleted: Scalars["Boolean"];
  id: Scalars["Int"];
  imageUrl?: Maybe<Scalars["String"]>;
  maintenanceProductServices?: Maybe<Array<MaintenanceProductService>>;
  maintenanceVariants?: Maybe<Array<MaintenanceVariant>>;
  name?: Maybe<Scalars["String"]>;
  resource?: Maybe<MaintenanceRelatedResource>;
  resourceId?: Maybe<Scalars["Int"]>;
  resourceVersionId?: Maybe<Scalars["Int"]>;
  sort: Scalars["Int"];
};

export type MaintenanceProductImageUrlArgs = {
  template?: Scalars["String"];
};

export type MaintenanceProductCreateInput = {
  name?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceProductListRelationFilter = {
  every?: InputMaybe<MaintenanceProductWhereInput>;
  none?: InputMaybe<MaintenanceProductWhereInput>;
  some?: InputMaybe<MaintenanceProductWhereInput>;
};

export type MaintenanceProductOrderByInput = {
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
  sort?: InputMaybe<SortOrder>;
};

export type MaintenanceProductService = {
  __typename?: "MaintenanceProductService";
  deleted: Scalars["Boolean"];
  id: Scalars["Int"];
  maintenanceProduct?: Maybe<MaintenanceProduct>;
  maintenanceProductId: Scalars["Int"];
  maintenanceServiceSpareParts?: Maybe<Array<MaintenanceServiceSparePart>>;
  /** For services without a variant */
  name?: Maybe<Scalars["String"]>;
  productNumber: Scalars["String"];
  qualityControlPossible: Scalars["Boolean"];
  serviceVariant?: Maybe<MaintenanceRelatedShopVariant>;
  serviceVariantId?: Maybe<Scalars["Int"]>;
  /** The standard workload in hours for the service to be performed */
  standardWorkload: Scalars["Float"];
};

export type MaintenanceProductServiceCreateInput = {
  maintenanceProductId: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
  qualityControlPossible?: Scalars["Boolean"];
  serviceVariantId?: InputMaybe<Scalars["Int"]>;
  standardWorkload?: Scalars["Float"];
};

export type MaintenanceProductServiceListRelationFilter = {
  every?: InputMaybe<MaintenanceProductServiceWhereInput>;
  none?: InputMaybe<MaintenanceProductServiceWhereInput>;
  some?: InputMaybe<MaintenanceProductServiceWhereInput>;
};

export type MaintenanceProductServiceOrderByInput = {
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maintenanceProductId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  qualityControlPossible?: InputMaybe<SortOrder>;
  serviceVariantId?: InputMaybe<SortOrder>;
  standardWorkload?: InputMaybe<SortOrder>;
};

export type MaintenanceProductServiceUpdateInput = {
  maintenanceProductId?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  qualityControlPossible?: InputMaybe<Scalars["Boolean"]>;
  serviceVariantId?: InputMaybe<Scalars["Int"]>;
  standardWorkload?: InputMaybe<Scalars["Float"]>;
};

export type MaintenanceProductServiceWhereInput = {
  AND?: InputMaybe<Array<MaintenanceProductServiceWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceProductServiceWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceProductServiceWhereInput>>;
  deleted?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  maintenanceProduct?: InputMaybe<MaintenanceProductWhereInput>;
  maintenanceProductId?: InputMaybe<IntFilter>;
  maintenanceServiceSpareParts?: InputMaybe<MaintenanceServiceSparePartListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  qualityControlPossible?: InputMaybe<BoolFilter>;
  reportServices?: InputMaybe<MaintenanceReportServiceListRelationFilter>;
  serviceVariant?: InputMaybe<MaintenanceRelatedShopVariantWhereInput>;
  serviceVariantId?: InputMaybe<IntNullableFilter>;
  standardWorkload?: InputMaybe<FloatFilter>;
};

export type MaintenanceProductServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceProductUpdateInput = {
  name?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceProductWhereInput = {
  AND?: InputMaybe<Array<MaintenanceProductWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceProductWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceProductWhereInput>>;
  deleted?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  maintenanceProductServices?: InputMaybe<MaintenanceProductServiceListRelationFilter>;
  maintenanceVariants?: InputMaybe<MaintenanceVariantListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  resource?: InputMaybe<MaintenanceRelatedResourceWhereInput>;
  resourceId?: InputMaybe<IntNullableFilter>;
  sort?: InputMaybe<IntFilter>;
};

export type MaintenanceProductWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceRelatedResource = {
  __typename?: "MaintenanceRelatedResource";
  id: Scalars["Int"];
};

export type MaintenanceRelatedResourceVersion = {
  __typename?: "MaintenanceRelatedResourceVersion";
  id: Scalars["Int"];
};

export type MaintenanceRelatedResourceVersionListRelationFilter = {
  every?: InputMaybe<MaintenanceRelatedResourceVersionWhereInput>;
  none?: InputMaybe<MaintenanceRelatedResourceVersionWhereInput>;
  some?: InputMaybe<MaintenanceRelatedResourceVersionWhereInput>;
};

export type MaintenanceRelatedResourceVersionWhereInput = {
  AND?: InputMaybe<Array<MaintenanceRelatedResourceVersionWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceRelatedResourceVersionWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceRelatedResourceVersionWhereInput>>;
  createDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  maintenanceRelatedResource?: InputMaybe<MaintenanceRelatedResourceWhereInput>;
  maintenanceResources?: InputMaybe<MaintenanceResourceListRelationFilter>;
  resourceId?: InputMaybe<IntFilter>;
};

export type MaintenanceRelatedResourceWhereInput = {
  AND?: InputMaybe<Array<MaintenanceRelatedResourceWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceRelatedResourceWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceRelatedResourceWhereInput>>;
  blocked?: InputMaybe<BoolFilter>;
  create_date?: InputMaybe<DateTimeNullableFilter>;
  delete_date?: InputMaybe<DateTimeNullableFilter>;
  deleted_by_user_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  in_archive?: InputMaybe<BoolNullableFilter>;
  is_user_generated_content?: InputMaybe<BoolFilter>;
  maintenanceProducts?: InputMaybe<MaintenanceProductListRelationFilter>;
  maintenanceSmallParts?: InputMaybe<MaintenanceSmallPartListRelationFilter>;
  maintenanceVariants?: InputMaybe<MaintenanceVariantListRelationFilter>;
  noindex?: InputMaybe<BoolFilter>;
  release_date?: InputMaybe<DateTimeNullableFilter>;
  release_end_date?: InputMaybe<DateTimeNullableFilter>;
  resourceVersions?: InputMaybe<MaintenanceRelatedResourceVersionListRelationFilter>;
  update_date?: InputMaybe<DateTimeNullableFilter>;
};

export type MaintenanceRelatedShopVariant = {
  __typename?: "MaintenanceRelatedShopVariant";
  id: Scalars["Int"];
  variantNumber: Scalars["String"];
  variantNumberFull: Scalars["String"];
};

export type MaintenanceRelatedShopVariantWhereInput = {
  AND?: InputMaybe<Array<MaintenanceRelatedShopVariantWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceRelatedShopVariantWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceRelatedShopVariantWhereInput>>;
  id?: InputMaybe<IntFilter>;
  maintenanceProductServices?: InputMaybe<MaintenanceProductServiceListRelationFilter>;
  maintenanceReportSpareParts?: InputMaybe<MaintenanceReportServiceSparePartListRelationFilter>;
  maintenanceServiceSpareParts?: InputMaybe<MaintenanceServiceSparePartListRelationFilter>;
  variantNumber?: InputMaybe<StringFilter>;
  variantNumberFull?: InputMaybe<StringFilter>;
};

export type MaintenanceReport = {
  __typename?: "MaintenanceReport";
  /** will be asked for in the last step of finishing a report in the ios app */
  customerWishes?: Maybe<Scalars["String"]>;
  doneAt?: Maybe<Scalars["DateTime"]>;
  doneBy?: Maybe<MaintenanceUser>;
  doneById?: Maybe<Scalars["Int"]>;
  /** If this is an inspection, the value is always 2.0 hours. If this is a repair, this will be set to the estimated hours by report service entries. */
  estimatedHours: Scalars["Float"];
  id: Scalars["Int"];
  /** This value is the sum of all instant repairs within an inspection. Technically, it is the sum of the workload within the report service, if the service is already done and the report is an inspection. */
  instantRepairWorkload: Scalars["Float"];
  invoicePaid: Scalars["Boolean"];
  invoicePartiallyPaid: Scalars["Boolean"];
  /** Shows you if the report is done */
  isDone?: Maybe<Scalars["Boolean"]>;
  /** The customer received an email about this report */
  isSentToCustomer: Scalars["Boolean"];
  /** The report was synchronized with navision to create an offer. */
  isSentToNavision: Scalars["Boolean"];
  /** This is the minimum number of technicians resulting from the services in the report. */
  manpower: Scalars["Int"];
  /** If this is an inspection, this will be a manual added value by the technician. If this is a repair, this will be set to the estimated hours. */
  neededHours: Scalars["Float"];
  reportVariants: Array<MaintenanceReportVariant>;
  /** If one of the services requires a ladder, true is output here. */
  requiresLadder: Scalars["Boolean"];
  /** If one of the services requires a scaffold, true is output here. */
  requiresScaffold: Scalars["Boolean"];
  /** The reason why a second repair was necessary */
  secondRepairReason: MaintenanceSecondRepairReason;
  signatureName?: Maybe<Scalars["String"]>;
  signatureResource?: Maybe<MaintenanceResource>;
  stop: MaintenanceTourStop;
  stopId: Scalars["Int"];
  type: MaintenanceReportType;
  /** This is a summary of all workloads inside of the report services. */
  workloadHours: Scalars["Float"];
};

export type MaintenanceReportReportVariantsArgs = {
  after?: InputMaybe<MaintenanceReportVariantWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportVariantWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportCreateInput = {
  /** will be asked for in the last step of finishing a report in the ios app */
  customerWishes?: InputMaybe<Scalars["String"]>;
  doneAt?: InputMaybe<Scalars["DateTime"]>;
  doneById?: InputMaybe<Scalars["Int"]>;
  invoicePaid?: Scalars["Boolean"];
  invoicePartiallyPaid?: Scalars["Boolean"];
  isSentToCustomer?: Scalars["Boolean"];
  isSentToNavision?: Scalars["Boolean"];
  neededHours?: InputMaybe<Scalars["Float"]>;
  secondRepairReason?: MaintenanceSecondRepairReason;
  signatureName?: InputMaybe<Scalars["String"]>;
  signatureResourceId?: InputMaybe<Scalars["Int"]>;
  stopId: Scalars["Int"];
  type?: MaintenanceReportType;
};

export type MaintenanceReportListRelationFilter = {
  every?: InputMaybe<MaintenanceReportWhereInput>;
  none?: InputMaybe<MaintenanceReportWhereInput>;
  some?: InputMaybe<MaintenanceReportWhereInput>;
};

export type MaintenanceReportOrderByInput = {
  customerWishes?: InputMaybe<SortOrder>;
  doneAt?: InputMaybe<SortOrder>;
  doneById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoicePaid?: InputMaybe<SortOrder>;
  invoicePartiallyPaid?: InputMaybe<SortOrder>;
  isSentToCustomer?: InputMaybe<SortOrder>;
  isSentToNavision?: InputMaybe<SortOrder>;
  neededHours?: InputMaybe<SortOrder>;
  secondRepairReason?: InputMaybe<SortOrder>;
  signatureName?: InputMaybe<SortOrder>;
  signatureResourceId?: InputMaybe<SortOrder>;
  stopId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type MaintenanceReportService = {
  __typename?: "MaintenanceReportService";
  /** Will be asked for if the technician chooses the status "done with comment" or "not possible" in the ios app */
  comment?: Maybe<Scalars["String"]>;
  /** In the ios app you can add a customer information to the report service which will also be added to the pdf and to the communication with the customer */
  customerProductInformation?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  manpower: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  /** In the ios app you can also add a dedicated information for the repair either for the technician for the upcoming repair or for the editors to let the customer know something else */
  productInformation: Scalars["String"];
  quantity: Scalars["Int"];
  /** This is the workload of the report service or the standardWorkload of the relation to the MaintenanceProductService */
  recursiveWorkload: Scalars["Float"];
  reportServiceSmallParts: Array<MaintenanceReportServiceSmallPart>;
  reportServiceSpareParts: Array<MaintenanceReportServiceSparePart>;
  reportVariant?: Maybe<MaintenanceReportVariant>;
  reportVariantId?: Maybe<Scalars["Int"]>;
  requiresLadder: Scalars["Boolean"];
  requiresScaffold: Scalars["Boolean"];
  service?: Maybe<MaintenanceProductService>;
  serviceId?: Maybe<Scalars["Int"]>;
  status: MaintenanceReportServiceStatus;
  workload: Scalars["Float"];
};

export type MaintenanceReportServiceReportServiceSmallPartsArgs = {
  after?: InputMaybe<MaintenanceReportServiceSmallPartWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportServiceSmallPartWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceReportServiceSparePartsArgs = {
  after?: InputMaybe<MaintenanceReportServiceSparePartWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportServiceSparePartWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceCreateInput = {
  /** Will be asked for if the technician chooses the status "done with comment" or "not possible" in the ios app */
  comment?: InputMaybe<Scalars["String"]>;
  /** In the ios app you can add a customer information to the report service which will also be added to the pdf and to the communication with the customer */
  customerProductInformation?: InputMaybe<Scalars["String"]>;
  manpower?: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
  /** In the ios app you can also add a dedicated information for the repair either for the technician for the upcoming repair or for the editors to let the customer know something else */
  productInformation?: Scalars["String"];
  quantity?: Scalars["Int"];
  reportVariantId?: InputMaybe<Scalars["Int"]>;
  requiresLadder?: Scalars["Boolean"];
  requiresScaffold?: Scalars["Boolean"];
  serviceId?: InputMaybe<Scalars["Int"]>;
  status?: MaintenanceReportServiceStatus;
  workload?: Scalars["Float"];
};

export type MaintenanceReportServiceListRelationFilter = {
  every?: InputMaybe<MaintenanceReportServiceWhereInput>;
  none?: InputMaybe<MaintenanceReportServiceWhereInput>;
  some?: InputMaybe<MaintenanceReportServiceWhereInput>;
};

export type MaintenanceReportServiceSmallPart = {
  __typename?: "MaintenanceReportServiceSmallPart";
  id: Scalars["Int"];
  name: Scalars["String"];
  quantity: Scalars["Int"];
  reportService?: Maybe<MaintenanceReportService>;
  reportServiceId: Scalars["Int"];
  smallPart?: Maybe<MaintenanceSmallPart>;
  smallPartId?: Maybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSmallPartCreateInput = {
  name: Scalars["String"];
  quantity?: Scalars["Int"];
  reportServiceId: Scalars["Int"];
  smallPartId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSmallPartListRelationFilter = {
  every?: InputMaybe<MaintenanceReportServiceSmallPartWhereInput>;
  none?: InputMaybe<MaintenanceReportServiceSmallPartWhereInput>;
  some?: InputMaybe<MaintenanceReportServiceSmallPartWhereInput>;
};

export type MaintenanceReportServiceSmallPartOrderByInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  reportServiceId?: InputMaybe<SortOrder>;
  smallPartId?: InputMaybe<SortOrder>;
};

export type MaintenanceReportServiceSmallPartUpdateInput = {
  name?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  reportServiceId?: InputMaybe<Scalars["Int"]>;
  smallPartId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSmallPartWhereInput = {
  AND?: InputMaybe<Array<MaintenanceReportServiceSmallPartWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceReportServiceSmallPartWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceReportServiceSmallPartWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  reportService?: InputMaybe<MaintenanceReportServiceWhereInput>;
  reportServiceId?: InputMaybe<IntFilter>;
  smallPart?: InputMaybe<MaintenanceSmallPartWhereInput>;
  smallPartId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceReportServiceSmallPartWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSparePart = {
  __typename?: "MaintenanceReportServiceSparePart";
  /** The technician can add this comment on the detail view of a sparepart in the app */
  comment?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  maintenanceResources: Array<MaintenanceResource>;
  name?: Maybe<Scalars["String"]>;
  quantity: Scalars["Int"];
  reportService?: Maybe<MaintenanceReportService>;
  reportServiceId: Scalars["Int"];
  reportSparePartBlueprints: Array<MaintenanceReportSparePartBlueprint>;
  sparePart?: Maybe<MaintenanceServiceSparePart>;
  sparePartId?: Maybe<Scalars["Int"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  variant?: Maybe<MaintenanceRelatedShopVariant>;
  variantId?: Maybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSparePartMaintenanceResourcesArgs = {
  after?: InputMaybe<MaintenanceResourceWhereUniqueInput>;
  before?: InputMaybe<MaintenanceResourceWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSparePartReportSparePartBlueprintsArgs = {
  after?: InputMaybe<MaintenanceReportSparePartBlueprintWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportSparePartBlueprintWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSparePartCreateInput = {
  /** The technician can add this comment on the detail view of a sparepart in the app */
  comment?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  quantity?: Scalars["Int"];
  reportServiceId: Scalars["Int"];
  sparePartId?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSparePartListRelationFilter = {
  every?: InputMaybe<MaintenanceReportServiceSparePartWhereInput>;
  none?: InputMaybe<MaintenanceReportServiceSparePartWhereInput>;
  some?: InputMaybe<MaintenanceReportServiceSparePartWhereInput>;
};

export type MaintenanceReportServiceSparePartUpdateInput = {
  /** The technician can add this comment on the detail view of a sparepart in the app */
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  reportServiceId?: InputMaybe<Scalars["Int"]>;
  sparePartId?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportServiceSparePartWhereInput = {
  AND?: InputMaybe<Array<MaintenanceReportServiceSparePartWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceReportServiceSparePartWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceReportServiceSparePartWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  maintenanceResources?: InputMaybe<MaintenanceResourceListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  reportService?: InputMaybe<MaintenanceReportServiceWhereInput>;
  reportServiceId?: InputMaybe<IntFilter>;
  reportSparePartBlueprints?: InputMaybe<MaintenanceReportSparePartBlueprintListRelationFilter>;
  sparePart?: InputMaybe<MaintenanceServiceSparePartWhereInput>;
  sparePartId?: InputMaybe<IntNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  variant?: InputMaybe<MaintenanceRelatedShopVariantWhereInput>;
  variantId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceReportServiceSparePartWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export enum MaintenanceReportServiceStatus {
  Done = "DONE",
  Impossible = "IMPOSSIBLE",
  NotRequested = "NOT_REQUESTED",
  Open = "OPEN",
  Workshop = "WORKSHOP",
}

export type MaintenanceReportServiceUpdateInput = {
  /** Will be asked for if the technician chooses the status "done with comment" or "not possible" in the ios app */
  comment?: InputMaybe<Scalars["String"]>;
  /** In the ios app you can add a customer information to the report service which will also be added to the pdf and to the communication with the customer */
  customerProductInformation?: InputMaybe<Scalars["String"]>;
  manpower?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** In the ios app you can also add a dedicated information for the repair either for the technician for the upcoming repair or for the editors to let the customer know something else */
  productInformation?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  reportVariantId?: InputMaybe<Scalars["Int"]>;
  requiresLadder?: InputMaybe<Scalars["Boolean"]>;
  requiresScaffold?: InputMaybe<Scalars["Boolean"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<MaintenanceReportServiceStatus>;
  workload?: InputMaybe<Scalars["Float"]>;
};

export type MaintenanceReportServiceWhereInput = {
  AND?: InputMaybe<Array<MaintenanceReportServiceWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceReportServiceWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceReportServiceWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  customerProductInformation?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  manpower?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  productInformation?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  reportServiceSmallParts?: InputMaybe<MaintenanceReportServiceSmallPartListRelationFilter>;
  reportServiceSpareParts?: InputMaybe<MaintenanceReportServiceSparePartListRelationFilter>;
  reportVariant?: InputMaybe<MaintenanceReportVariantWhereInput>;
  reportVariantId?: InputMaybe<IntNullableFilter>;
  requiresLadder?: InputMaybe<BoolFilter>;
  requiresScaffold?: InputMaybe<BoolFilter>;
  service?: InputMaybe<MaintenanceProductServiceWhereInput>;
  serviceId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumMaintenanceReportServiceStatusFilter>;
  workload?: InputMaybe<FloatFilter>;
};

export type MaintenanceReportServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportSparePartBlueprint = {
  __typename?: "MaintenanceReportSparePartBlueprint";
  blueprint: MaintenanceBlueprint;
  blueprintId: Scalars["Int"];
  data: Scalars["String"];
  id: Scalars["Int"];
  reportSparePart: MaintenanceReportServiceSparePart;
  reportSparePartId: Scalars["Int"];
};

export type MaintenanceReportSparePartBlueprintCreateInput = {
  blueprintId: Scalars["Int"];
  data: Scalars["String"];
  reportSparePartId: Scalars["Int"];
};

export type MaintenanceReportSparePartBlueprintListRelationFilter = {
  every?: InputMaybe<MaintenanceReportSparePartBlueprintWhereInput>;
  none?: InputMaybe<MaintenanceReportSparePartBlueprintWhereInput>;
  some?: InputMaybe<MaintenanceReportSparePartBlueprintWhereInput>;
};

export type MaintenanceReportSparePartBlueprintOrderByInput = {
  blueprintId?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reportSparePartId?: InputMaybe<SortOrder>;
};

export type MaintenanceReportSparePartBlueprintUpdateInput = {
  blueprintId?: InputMaybe<Scalars["Int"]>;
  data?: InputMaybe<Scalars["String"]>;
  reportSparePartId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportSparePartBlueprintWhereInput = {
  AND?: InputMaybe<Array<MaintenanceReportSparePartBlueprintWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceReportSparePartBlueprintWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceReportSparePartBlueprintWhereInput>>;
  blueprint?: InputMaybe<MaintenanceBlueprintWhereInput>;
  blueprintId?: InputMaybe<IntFilter>;
  data?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  reportSparePart?: InputMaybe<MaintenanceReportServiceSparePartWhereInput>;
  reportSparePartId?: InputMaybe<IntFilter>;
};

export type MaintenanceReportSparePartBlueprintWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export enum MaintenanceReportType {
  Inspection = "INSPECTION",
  Repair = "REPAIR",
}

export type MaintenanceReportUpdateInput = {
  /** will be asked for in the last step of finishing a report in the ios app */
  customerWishes?: InputMaybe<Scalars["String"]>;
  doneAt?: InputMaybe<Scalars["DateTime"]>;
  doneById?: InputMaybe<Scalars["Int"]>;
  invoicePaid?: InputMaybe<Scalars["Boolean"]>;
  invoicePartialPaid?: InputMaybe<Scalars["Boolean"]>;
  isSentToCustomer?: InputMaybe<Scalars["Boolean"]>;
  isSentToNavision?: InputMaybe<Scalars["Boolean"]>;
  neededHours?: InputMaybe<Scalars["Float"]>;
  secondRepairReason?: InputMaybe<MaintenanceSecondRepairReason>;
  signatureName?: InputMaybe<Scalars["String"]>;
  signatureResourceId?: InputMaybe<Scalars["Int"]>;
  stopId?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<MaintenanceReportType>;
};

export type MaintenanceReportVariant = {
  __typename?: "MaintenanceReportVariant";
  id: Scalars["Int"];
  maintenanceVariant?: Maybe<MaintenanceVariant>;
  maintenanceVariantId?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  observationComment?: Maybe<Scalars["String"]>;
  quantityDamaged?: Maybe<Scalars["Int"]>;
  quantityGoodCondition?: Maybe<Scalars["Int"]>;
  quantityObservation?: Maybe<Scalars["Int"]>;
  /** Sum of all quantity fields. (quantityDamaged, quantityGoodCondition, quantityObservation) */
  quantityOverall: Scalars["Int"];
  report?: Maybe<MaintenanceReport>;
  reportId?: Maybe<Scalars["Int"]>;
  reportServices: Array<MaintenanceReportService>;
};

export type MaintenanceReportVariantReportServicesArgs = {
  after?: InputMaybe<MaintenanceReportServiceWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportServiceWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportVariantCreateInput = {
  maintenanceVariantId?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  observationComment?: InputMaybe<Scalars["String"]>;
  quantityDamaged?: Scalars["Int"];
  quantityGoodCondition?: Scalars["Int"];
  quantityObservation?: Scalars["Int"];
  reportId: Scalars["Int"];
};

export type MaintenanceReportVariantListRelationFilter = {
  every?: InputMaybe<MaintenanceReportVariantWhereInput>;
  none?: InputMaybe<MaintenanceReportVariantWhereInput>;
  some?: InputMaybe<MaintenanceReportVariantWhereInput>;
};

export type MaintenanceReportVariantUpdateInput = {
  maintenanceVariantId?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  observationComment?: InputMaybe<Scalars["String"]>;
  quantityDamaged?: InputMaybe<Scalars["Int"]>;
  quantityGoodCondition?: InputMaybe<Scalars["Int"]>;
  quantityObservation?: InputMaybe<Scalars["Int"]>;
  reportId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportVariantWhereInput = {
  AND?: InputMaybe<Array<MaintenanceReportVariantWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceReportVariantWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceReportVariantWhereInput>>;
  id?: InputMaybe<IntFilter>;
  maintenanceVariant?: InputMaybe<MaintenanceVariantWhereInput>;
  maintenanceVariantId?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  observationComment?: InputMaybe<StringNullableFilter>;
  quantityDamaged?: InputMaybe<IntNullableFilter>;
  quantityGoodCondition?: InputMaybe<IntNullableFilter>;
  quantityObservation?: InputMaybe<IntNullableFilter>;
  report?: InputMaybe<MaintenanceReportWhereInput>;
  reportId?: InputMaybe<IntNullableFilter>;
  reportServices?: InputMaybe<MaintenanceReportServiceListRelationFilter>;
};

export type MaintenanceReportVariantWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceReportWhereInput = {
  AND?: InputMaybe<Array<MaintenanceReportWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceReportWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceReportWhereInput>>;
  customerWishes?: InputMaybe<StringNullableFilter>;
  doneAt?: InputMaybe<DateTimeNullableFilter>;
  doneBy?: InputMaybe<MaintenanceUserWhereInput>;
  doneById?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  invoicePaid?: InputMaybe<BoolFilter>;
  invoicePartiallyPaid?: InputMaybe<BoolFilter>;
  isSentToCustomer?: InputMaybe<BoolFilter>;
  isSentToNavision?: InputMaybe<BoolFilter>;
  neededHours?: InputMaybe<FloatNullableFilter>;
  reportVariants?: InputMaybe<MaintenanceReportVariantListRelationFilter>;
  secondRepairReason?: InputMaybe<EnumMaintenanceSecondRepairReasonFilter>;
  signatureName?: InputMaybe<StringNullableFilter>;
  signatureResource?: InputMaybe<MaintenanceResourceWhereInput>;
  signatureResourceId?: InputMaybe<IntNullableFilter>;
  stop?: InputMaybe<MaintenanceTourStopWhereInput>;
  stopId?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumMaintenanceReportTypeFilter>;
};

export type MaintenanceReportWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceResource = {
  __typename?: "MaintenanceResource";
  /** Currently not used, but the idea is to add a comment to a taken photo etc. */
  comment?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  imageUrl: Scalars["String"];
  reportSparePart?: Maybe<MaintenanceReportServiceSparePart>;
  reportSparePartId?: Maybe<Scalars["Int"]>;
  resourceVersion?: Maybe<MaintenanceRelatedResourceVersion>;
  type: ReportServiceSparePartResourceType;
  versionId?: Maybe<Scalars["Int"]>;
};

export type MaintenanceResourceImageUrlArgs = {
  template?: Scalars["String"];
};

export type MaintenanceResourceCreateInput = {
  /** Currently not used, but the idea is to add a comment to a taken photo etc. */
  comment?: InputMaybe<Scalars["String"]>;
  reportSparePartId?: InputMaybe<Scalars["Int"]>;
  type: ReportServiceSparePartResourceType;
  versionId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceResourceListRelationFilter = {
  every?: InputMaybe<MaintenanceResourceWhereInput>;
  none?: InputMaybe<MaintenanceResourceWhereInput>;
  some?: InputMaybe<MaintenanceResourceWhereInput>;
};

export type MaintenanceResourceOrderByInput = {
  comment?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reportSparePartId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  versionId?: InputMaybe<SortOrder>;
};

export type MaintenanceResourceUpdateInput = {
  /** Currently not used, but the idea is to add a comment to a taken photo etc. */
  comment?: InputMaybe<Scalars["String"]>;
  reportSparePartId?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<ReportServiceSparePartResourceType>;
  versionId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceResourceWhereInput = {
  AND?: InputMaybe<Array<MaintenanceResourceWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceResourceWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceResourceWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  reportSparePart?: InputMaybe<MaintenanceReportServiceSparePartWhereInput>;
  reportSparePartId?: InputMaybe<IntNullableFilter>;
  reports?: InputMaybe<MaintenanceReportListRelationFilter>;
  resourceVersion?: InputMaybe<MaintenanceRelatedResourceVersionWhereInput>;
  type?: InputMaybe<EnumReportServiceSparePartResourceTypeFilter>;
  versionId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceResourceWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export enum MaintenanceSecondRepairReason {
  SelfInflictedDisposition = "SELF_INFLICTED_DISPOSITION",
  SelfInflictedTechnician = "SELF_INFLICTED_TECHNICIAN",
  ThirdPartyCustomerRequest = "THIRD_PARTY_CUSTOMER_REQUEST",
  ThirdPartyLogisticExternal = "THIRD_PARTY_LOGISTIC_EXTERNAL",
  ThirdPartyLogisticInternal = "THIRD_PARTY_LOGISTIC_INTERNAL",
  Unknown = "UNKNOWN",
}

export type MaintenanceSecondRepairReasonStatisticPoint = {
  __typename?: "MaintenanceSecondRepairReasonStatisticPoint";
  reason: Scalars["String"];
  secondRepairs: Scalars["Int"];
};

export type MaintenanceSecondRepairStatisticFilterInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]>;
  secondRepairReason?: InputMaybe<
    Array<InputMaybe<MaintenanceSecondRepairReason>>
  >;
  /** Filter by technician ids. The ids are their user_id in the user table. This filter will translate them to sales units to filter in the cube data. */
  technicianIds?: InputMaybe<Array<Scalars["Int"]>>;
  toDate?: InputMaybe<Scalars["DateTime"]>;
};

export type MaintenanceSecondRepairStatisticPoint = {
  __typename?: "MaintenanceSecondRepairStatisticPoint";
  date: Scalars["DateTime"];
  /** Rate of second repairs in this period, with respect to the filtered repair reasons */
  rate: Scalars["Float"];
  reasons: Array<Maybe<MaintenanceSecondRepairReasonStatisticPoint>>;
  /** Number of overall repairs in this period */
  repairs: Scalars["Int"];
  /** Number of follow up repairs, because something was missing or could not be repaired */
  secondRepairs: Scalars["Int"];
};

export type MaintenanceServiceSparePart = {
  __typename?: "MaintenanceServiceSparePart";
  /** If this is true, the spare part will be added automatically if a technician adds this service to a report variant. */
  autoAdd: Scalars["Boolean"];
  /** Soft Delete */
  deleted: Scalars["Boolean"];
  id: Scalars["Int"];
  maintenanceProductService: MaintenanceProductService;
  /** When you create it, it is filled with the selected product and you can manually enter a free text. */
  name?: Maybe<Scalars["String"]>;
  productNumber: Scalars["String"];
  productServiceId: Scalars["Int"];
  sparePartVariant?: Maybe<MaintenanceRelatedShopVariant>;
  sparePartVariantId?: Maybe<Scalars["Int"]>;
};

export type MaintenanceServiceSparePartCreateInput = {
  autoAdd?: Scalars["Boolean"];
  deleted?: Scalars["Boolean"];
  name?: InputMaybe<Scalars["String"]>;
  productServiceId: Scalars["Int"];
  sparePartVariantId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceServiceSparePartListRelationFilter = {
  every?: InputMaybe<MaintenanceServiceSparePartWhereInput>;
  none?: InputMaybe<MaintenanceServiceSparePartWhereInput>;
  some?: InputMaybe<MaintenanceServiceSparePartWhereInput>;
};

export type MaintenanceServiceSparePartOrderByInput = {
  autoAdd?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  productServiceId?: InputMaybe<SortOrder>;
  sparePartVariantId?: InputMaybe<SortOrder>;
};

export type MaintenanceServiceSparePartUpdateInput = {
  autoAdd?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  productServiceId?: InputMaybe<Scalars["Int"]>;
  sparePartVariantId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceServiceSparePartWhereInput = {
  AND?: InputMaybe<Array<MaintenanceServiceSparePartWhereInput>>;
  MaintenanceReportServiceSparePart?: InputMaybe<MaintenanceReportServiceSparePartListRelationFilter>;
  NOT?: InputMaybe<Array<MaintenanceServiceSparePartWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceServiceSparePartWhereInput>>;
  autoAdd?: InputMaybe<BoolFilter>;
  deleted?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  maintenanceProductService?: InputMaybe<MaintenanceProductServiceWhereInput>;
  name?: InputMaybe<StringNullableFilter>;
  productServiceId?: InputMaybe<IntFilter>;
  sparePartVariant?: InputMaybe<MaintenanceRelatedShopVariantWhereInput>;
  sparePartVariantId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceServiceSparePartWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceSmallPart = {
  __typename?: "MaintenanceSmallPart";
  deleted: Scalars["Boolean"];
  id: Scalars["Int"];
  name: Scalars["String"];
  reportServiceSmallParts: Array<MaintenanceReportServiceSmallPart>;
  resource?: Maybe<MaintenanceRelatedResource>;
  resourceId?: Maybe<Scalars["Int"]>;
};

export type MaintenanceSmallPartReportServiceSmallPartsArgs = {
  after?: InputMaybe<MaintenanceReportServiceSmallPartWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportServiceSmallPartWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceSmallPartListRelationFilter = {
  every?: InputMaybe<MaintenanceSmallPartWhereInput>;
  none?: InputMaybe<MaintenanceSmallPartWhereInput>;
  some?: InputMaybe<MaintenanceSmallPartWhereInput>;
};

export type MaintenanceSmallPartOrderByInput = {
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
};

export type MaintenanceSmallPartWhereInput = {
  AND?: InputMaybe<Array<MaintenanceSmallPartWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceSmallPartWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceSmallPartWhereInput>>;
  deleted?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  reportServiceSmallParts?: InputMaybe<MaintenanceReportServiceSmallPartListRelationFilter>;
  resource?: InputMaybe<MaintenanceRelatedResourceWhereInput>;
  resourceId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceSmallPartWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceStatisticFilterInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]>;
  /** Filter by technician ids. The ids are their user_id in the user table. This filter will translate them to sales units to filter in the cube data. */
  technicianIds?: InputMaybe<Array<Scalars["Int"]>>;
  toDate?: InputMaybe<Scalars["DateTime"]>;
};

export type MaintenanceSynchronizationLog = {
  __typename?: "MaintenanceSynchronizationLog";
  data: Scalars["String"];
  date: Scalars["DateTime"];
  id: Scalars["Int"];
  log: Scalars["String"];
  userId: Scalars["Int"];
};

export type MaintenanceSynchronizationLogOrderByInput = {
  data?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  log?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type MaintenanceSynchronizationLogWhereInput = {
  AND?: InputMaybe<Array<MaintenanceSynchronizationLogWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceSynchronizationLogWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceSynchronizationLogWhereInput>>;
  data?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  log?: InputMaybe<StringFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type MaintenanceSynchronizationLogWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceTourStop = {
  __typename?: "MaintenanceTourStop";
  attachments: Array<MaintenanceTourStopAttachment>;
  /** This comment is only for the editors and will be presented in the detail view and as an explanation mark in the calendar. */
  calendarComment: Scalars["String"];
  childStops: Array<Maybe<MaintenanceTourStop>>;
  /** This is mostly used to let the technician know about something. Instructions or something related to finding the spot. */
  comment: Scalars["String"];
  /** Returns the contact address as a string */
  contactAddressString?: Maybe<Scalars["String"]>;
  contactCity?: Maybe<Scalars["String"]>;
  contactCompany?: Maybe<Scalars["String"]>;
  contactCountry?: Maybe<Scalars["String"]>;
  contactEmail?: Maybe<Scalars["String"]>;
  contactEmailCC?: Maybe<Scalars["String"]>;
  contactMobilePhone?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  contactPhone?: Maybe<Scalars["String"]>;
  contactStreet?: Maybe<Scalars["String"]>;
  contactZip?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Customer is informed about the tour stop schedule. */
  customerIsInformed: Scalars["Boolean"];
  debitorNumber?: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  editor?: Maybe<MaintenanceUser>;
  editorId?: Maybe<Scalars["Int"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  estimatedInspectionHours?: Maybe<Scalars["Float"]>;
  hallManufacturer: Scalars["String"];
  hallSize: Scalars["String"];
  hasMaintenanceContract: Scalars["Boolean"];
  id: Scalars["Int"];
  immediatelyRepairBudget?: Maybe<Scalars["Float"]>;
  inspectionPurchasingConditions?: Maybe<Scalars["Float"]>;
  inspectionSalesConditions?: Maybe<Scalars["Float"]>;
  /** Returns the invoice address as a string */
  invoiceAddressString?: Maybe<Scalars["String"]>;
  invoiceCity?: Maybe<Scalars["String"]>;
  invoiceCompany?: Maybe<Scalars["String"]>;
  invoiceCountry?: Maybe<Scalars["String"]>;
  invoiceEmail?: Maybe<Scalars["String"]>;
  invoiceName?: Maybe<Scalars["String"]>;
  invoicePhone?: Maybe<Scalars["String"]>;
  invoiceStreet?: Maybe<Scalars["String"]>;
  invoiceZip?: Maybe<Scalars["String"]>;
  isSecondRepair: Scalars["Boolean"];
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  offerId: Scalars["String"];
  orderId: Scalars["String"];
  parentStops: Array<Maybe<MaintenanceTourStop>>;
  passengers: Array<MaintenanceTourStopPassenger>;
  /** This flag indicates whether the tour stop is ready for sync to the tablets of the technician. If false, the tour stop will not be synced to the tablets of the technician. */
  readyForSync: Scalars["Boolean"];
  reports: Array<MaintenanceReport>;
  startDate?: Maybe<Scalars["DateTime"]>;
  technician?: Maybe<MaintenanceUser>;
  technicianId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MaintenanceTourStopAttachmentsArgs = {
  after?: InputMaybe<MaintenanceTourStopAttachmentWhereUniqueInput>;
  before?: InputMaybe<MaintenanceTourStopAttachmentWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceTourStopContactAddressStringArgs = {
  separator?: Scalars["String"];
  showCompany?: Scalars["Boolean"];
  showName?: Scalars["Boolean"];
};

export type MaintenanceTourStopInvoiceAddressStringArgs = {
  separator?: Scalars["String"];
  showCompany?: Scalars["Boolean"];
  showName?: Scalars["Boolean"];
};

export type MaintenanceTourStopPassengersArgs = {
  after?: InputMaybe<MaintenanceTourStopPassengerWhereUniqueInput>;
  before?: InputMaybe<MaintenanceTourStopPassengerWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceTourStopReportsArgs = {
  after?: InputMaybe<MaintenanceReportWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceTourStopAttachment = {
  __typename?: "MaintenanceTourStopAttachment";
  /** Information about the uploaded file, field mostly used instead of the name of the file */
  comment?: Maybe<Scalars["String"]>;
  createDate: Scalars["DateTime"];
  /** The URL to download the attachment. The URL is only valid for 20 minutes and will force download with ResponseContentDisposition */
  downloadUrl?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  mimeType?: Maybe<Scalars["String"]>;
  path: Scalars["String"];
  stop?: Maybe<MaintenanceTourStop>;
  stopId?: Maybe<Scalars["Int"]>;
  synchronize: Scalars["Boolean"];
  user?: Maybe<MaintenanceUser>;
  userId?: Maybe<Scalars["Int"]>;
};

export type MaintenanceTourStopAttachmentCreateInput = {
  /** Information about the uploaded file, field mostly used instead of the name of the file */
  comment?: InputMaybe<Scalars["String"]>;
  mimeType?: InputMaybe<Scalars["String"]>;
  path: Scalars["String"];
  stopId: Scalars["Int"];
  /** If this is true, the app will fetch this files to show it to the technician on the tablet */
  synchronize?: Scalars["Boolean"];
  userId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceTourStopAttachmentListRelationFilter = {
  every?: InputMaybe<MaintenanceTourStopAttachmentWhereInput>;
  none?: InputMaybe<MaintenanceTourStopAttachmentWhereInput>;
  some?: InputMaybe<MaintenanceTourStopAttachmentWhereInput>;
};

export type MaintenanceTourStopAttachmentOrderByInput = {
  comment?: InputMaybe<SortOrder>;
  createDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  stopId?: InputMaybe<SortOrder>;
  synchronize?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type MaintenanceTourStopAttachmentUpdateInput = {
  /** Information about the uploaded file, field mostly used instead of the name of the file */
  comment?: InputMaybe<Scalars["String"]>;
  mimeType?: InputMaybe<Scalars["String"]>;
  path?: InputMaybe<Scalars["String"]>;
  /** If this is true, the app will fetch this files to show it to the technician on the tablet */
  synchronize?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceTourStopAttachmentWhereInput = {
  AND?: InputMaybe<Array<MaintenanceTourStopAttachmentWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceTourStopAttachmentWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceTourStopAttachmentWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  createDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mimeType?: InputMaybe<StringNullableFilter>;
  path?: InputMaybe<StringFilter>;
  stop?: InputMaybe<MaintenanceTourStopWhereInput>;
  stopId?: InputMaybe<IntNullableFilter>;
  synchronize?: InputMaybe<BoolFilter>;
  user?: InputMaybe<MaintenanceUserWhereInput>;
  userId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceTourStopAttachmentWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export enum MaintenanceTourStopCopyMode {
  /** Copies the stop only and resets the readyForSync, customerIsInformed and create as well as update dates. */
  Default = "DEFAULT",
  /** Copies the stop only and resets the readyForSync, customerIsInformed and create as well as update dates. */
  Repair = "REPAIR",
  /** Copies the stop only and resets the readyForSync, customerIsInformed and create as well as update dates. */
  ReRepair = "RE_REPAIR",
}

export type MaintenanceTourStopCreateInput = {
  /** This comment is only for the editors and will be presented in the detail view and as an explanation mark in the calendar. */
  calendarComment?: Scalars["String"];
  /** This is mostly used to let the technician know about something. Instructions or something related to finding the spot. */
  comment?: Scalars["String"];
  contactCity?: InputMaybe<Scalars["String"]>;
  contactCompany?: InputMaybe<Scalars["String"]>;
  contactCountry?: InputMaybe<Scalars["String"]>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactEmailCC?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  contactPhone?: InputMaybe<Scalars["String"]>;
  contactStreet?: InputMaybe<Scalars["String"]>;
  contactZip?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  customerIsInformed?: Scalars["Boolean"];
  debitorNumber?: InputMaybe<Scalars["String"]>;
  editorId?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  estimatedInspectionHours?: InputMaybe<Scalars["Float"]>;
  hallManufacturer?: Scalars["String"];
  hallSize?: Scalars["String"];
  hasMaintenanceContract?: Scalars["Boolean"];
  immediatelyRepairBudget?: InputMaybe<Scalars["Float"]>;
  inspectionPurchasingConditions?: InputMaybe<Scalars["Float"]>;
  inspectionSalesConditions?: InputMaybe<Scalars["Float"]>;
  invoiceCity?: InputMaybe<Scalars["String"]>;
  invoiceCompany?: InputMaybe<Scalars["String"]>;
  invoiceCountry?: InputMaybe<Scalars["String"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]>;
  invoiceName?: InputMaybe<Scalars["String"]>;
  invoicePhone?: InputMaybe<Scalars["String"]>;
  invoiceStreet?: InputMaybe<Scalars["String"]>;
  invoiceZip?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  offerId?: Scalars["String"];
  orderId?: Scalars["String"];
  /** Technician ids to add as passengers to the stop. **Attention:** The current technician will be omitted, as the technician can't be it's own passenger. */
  passengerIds?: Array<Scalars["Int"]>;
  readyForSync?: Scalars["Boolean"];
  startDate?: InputMaybe<Scalars["DateTime"]>;
  technicianId?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MaintenanceTourStopListRelationFilter = {
  every?: InputMaybe<MaintenanceTourStopWhereInput>;
  none?: InputMaybe<MaintenanceTourStopWhereInput>;
  some?: InputMaybe<MaintenanceTourStopWhereInput>;
};

export type MaintenanceTourStopOrderByInput = {
  calendarComment?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  contactCity?: InputMaybe<SortOrder>;
  contactCompany?: InputMaybe<SortOrder>;
  contactCountry?: InputMaybe<SortOrder>;
  contactEmail?: InputMaybe<SortOrder>;
  contactEmailCC?: InputMaybe<SortOrder>;
  contactMobilePhone?: InputMaybe<SortOrder>;
  contactName?: InputMaybe<SortOrder>;
  contactPhone?: InputMaybe<SortOrder>;
  contactStreet?: InputMaybe<SortOrder>;
  contactZip?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerIsInformed?: InputMaybe<SortOrder>;
  debitorNumber?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  editorId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  estimatedInspectionHours?: InputMaybe<SortOrder>;
  hallManufacturer?: InputMaybe<SortOrder>;
  hallSize?: InputMaybe<SortOrder>;
  hasMaintenanceContract?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  immediatelyRepairBudget?: InputMaybe<SortOrder>;
  inspectionPurchasingConditions?: InputMaybe<SortOrder>;
  inspectionSalesConditions?: InputMaybe<SortOrder>;
  invoiceCity?: InputMaybe<SortOrder>;
  invoiceCompany?: InputMaybe<SortOrder>;
  invoiceCountry?: InputMaybe<SortOrder>;
  invoiceEmail?: InputMaybe<SortOrder>;
  invoiceName?: InputMaybe<SortOrder>;
  invoicePhone?: InputMaybe<SortOrder>;
  invoiceStreet?: InputMaybe<SortOrder>;
  invoiceZip?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  offerId?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  readyForSync?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  technicianId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MaintenanceTourStopPassenger = {
  __typename?: "MaintenanceTourStopPassenger";
  id: Scalars["Int"];
  stop: MaintenanceTourStop;
  stopId: Scalars["Int"];
  technician: MaintenanceUser;
  technicianId: Scalars["Int"];
};

export type MaintenanceTourStopPassengerListRelationFilter = {
  every?: InputMaybe<MaintenanceTourStopPassengerWhereInput>;
  none?: InputMaybe<MaintenanceTourStopPassengerWhereInput>;
  some?: InputMaybe<MaintenanceTourStopPassengerWhereInput>;
};

export type MaintenanceTourStopPassengerOrderByInput = {
  id?: InputMaybe<SortOrder>;
  stopId?: InputMaybe<SortOrder>;
  technicianId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MaintenanceTourStopPassengerWhereInput = {
  AND?: InputMaybe<Array<MaintenanceTourStopPassengerWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceTourStopPassengerWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceTourStopPassengerWhereInput>>;
  id?: InputMaybe<IntFilter>;
  stop?: InputMaybe<MaintenanceTourStopWhereInput>;
  stopId?: InputMaybe<IntFilter>;
  technician?: InputMaybe<MaintenanceUserWhereInput>;
  technicianId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MaintenanceTourStopPassengerWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceTourStopRelationship = {
  __typename?: "MaintenanceTourStopRelationship";
  childStop: MaintenanceTourStop;
  childStopId: Scalars["Int"];
  id: Scalars["Int"];
  parentStop: MaintenanceTourStop;
  parentStopId: Scalars["Int"];
  timestamp: Scalars["DateTime"];
  user?: Maybe<MaintenanceUser>;
  userId?: Maybe<Scalars["Int"]>;
};

export type MaintenanceTourStopRelationshipListRelationFilter = {
  every?: InputMaybe<MaintenanceTourStopRelationshipWhereInput>;
  none?: InputMaybe<MaintenanceTourStopRelationshipWhereInput>;
  some?: InputMaybe<MaintenanceTourStopRelationshipWhereInput>;
};

export type MaintenanceTourStopRelationshipOrderByInput = {
  childStopId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parentStopId?: InputMaybe<SortOrder>;
  timestamp?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type MaintenanceTourStopRelationshipWhereInput = {
  AND?: InputMaybe<Array<MaintenanceTourStopRelationshipWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceTourStopRelationshipWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceTourStopRelationshipWhereInput>>;
  childStop?: InputMaybe<MaintenanceTourStopWhereInput>;
  childStopId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  parentStop?: InputMaybe<MaintenanceTourStopWhereInput>;
  parentStopId?: InputMaybe<IntFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<MaintenanceUserWhereInput>;
  userId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceTourStopRelationshipWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceTourStopUpdateInput = {
  /** This comment is only for the editors and will be presented in the detail view and as an explanation mark in the calendar. */
  calendarComment?: InputMaybe<Scalars["String"]>;
  /** This is mostly used to let the technician know about something. Instructions or something related to finding the spot. */
  comment?: InputMaybe<Scalars["String"]>;
  contactCity?: InputMaybe<Scalars["String"]>;
  contactCompany?: InputMaybe<Scalars["String"]>;
  contactCountry?: InputMaybe<Scalars["String"]>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactEmailCC?: InputMaybe<Scalars["String"]>;
  contactMobilePhone?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  contactPhone?: InputMaybe<Scalars["String"]>;
  contactStreet?: InputMaybe<Scalars["String"]>;
  contactZip?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  customerIsInformed?: InputMaybe<Scalars["Boolean"]>;
  debitorNumber?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  editorId?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  estimatedInspectionHours?: InputMaybe<Scalars["Float"]>;
  hallManufacturer?: InputMaybe<Scalars["String"]>;
  hallSize?: InputMaybe<Scalars["String"]>;
  hasMaintenanceContract?: InputMaybe<Scalars["Boolean"]>;
  immediatelyRepairBudget?: InputMaybe<Scalars["Float"]>;
  inspectionPurchasingConditions?: InputMaybe<Scalars["Float"]>;
  inspectionSalesConditions?: InputMaybe<Scalars["Float"]>;
  invoiceCity?: InputMaybe<Scalars["String"]>;
  invoiceCompany?: InputMaybe<Scalars["String"]>;
  invoiceCountry?: InputMaybe<Scalars["String"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]>;
  invoiceName?: InputMaybe<Scalars["String"]>;
  invoicePhone?: InputMaybe<Scalars["String"]>;
  invoiceStreet?: InputMaybe<Scalars["String"]>;
  invoiceZip?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  offerId?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["String"]>;
  /** Technician ids to add as passengers to the stop. **Attention:** The current technician will be omitted, as the technician can't be it's own passenger. If you pass null, this field will be ignored. */
  passengerIds?: InputMaybe<Array<Scalars["Int"]>>;
  readyForSync?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  technicianId?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MaintenanceTourStopWhereInput = {
  AND?: InputMaybe<Array<MaintenanceTourStopWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceTourStopWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceTourStopWhereInput>>;
  attachments?: InputMaybe<MaintenanceTourStopAttachmentListRelationFilter>;
  calendarComment?: InputMaybe<StringFilter>;
  childStopRelationships?: InputMaybe<MaintenanceTourStopRelationshipListRelationFilter>;
  comment?: InputMaybe<StringFilter>;
  contactCity?: InputMaybe<StringNullableFilter>;
  contactCompany?: InputMaybe<StringNullableFilter>;
  contactCountry?: InputMaybe<StringNullableFilter>;
  contactEmail?: InputMaybe<StringNullableFilter>;
  contactEmailCC?: InputMaybe<StringNullableFilter>;
  contactMobilePhone?: InputMaybe<StringNullableFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactPhone?: InputMaybe<StringNullableFilter>;
  contactStreet?: InputMaybe<StringNullableFilter>;
  contactZip?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  customerIsInformed?: InputMaybe<BoolFilter>;
  debitorNumber?: InputMaybe<StringNullableFilter>;
  deleted?: InputMaybe<BoolFilter>;
  editor?: InputMaybe<MaintenanceUserWhereInput>;
  editorId?: InputMaybe<IntNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  estimatedInspectionHours?: InputMaybe<FloatNullableFilter>;
  hallManufacturer?: InputMaybe<StringFilter>;
  hallSize?: InputMaybe<StringFilter>;
  hasMaintenanceContract?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  immediatelyRepairBudget?: InputMaybe<FloatNullableFilter>;
  inspectionPurchasingConditions?: InputMaybe<FloatNullableFilter>;
  inspectionSalesConditions?: InputMaybe<FloatNullableFilter>;
  invoiceCity?: InputMaybe<StringNullableFilter>;
  invoiceCompany?: InputMaybe<StringNullableFilter>;
  invoiceCountry?: InputMaybe<StringNullableFilter>;
  invoiceEmail?: InputMaybe<StringNullableFilter>;
  invoiceName?: InputMaybe<StringNullableFilter>;
  invoicePhone?: InputMaybe<StringNullableFilter>;
  invoiceStreet?: InputMaybe<StringNullableFilter>;
  invoiceZip?: InputMaybe<StringNullableFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  offerId?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  parentStopRelationships?: InputMaybe<MaintenanceTourStopRelationshipListRelationFilter>;
  passengers?: InputMaybe<MaintenanceTourStopPassengerListRelationFilter>;
  readyForSync?: InputMaybe<BoolFilter>;
  reports?: InputMaybe<MaintenanceReportListRelationFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  technician?: InputMaybe<MaintenanceUserWhereInput>;
  technicianId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type MaintenanceTourStopWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceUser = {
  __typename?: "MaintenanceUser";
  email?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  informations?: Maybe<MaintenanceUserInformation>;
  isEditor: Scalars["Boolean"];
  isTechnician: Scalars["Boolean"];
  lastname?: Maybe<Scalars["String"]>;
  signatureImageUrl?: Maybe<Scalars["String"]>;
};

export type MaintenanceUserSignatureImageUrlArgs = {
  template?: Scalars["String"];
};

export type MaintenanceUserInformation = {
  __typename?: "MaintenanceUserInformation";
  articleNumberForInspections: Scalars["String"];
  articleNumberForRepairsIncludingTravel: Scalars["String"];
  articleNumberForSmallParts: Scalars["String"];
  carNumberPlate: Scalars["String"];
  city: Scalars["String"];
  /** The number for the internal cost calclulation. */
  costCenter: Scalars["String"];
  /** Also known as customer number */
  debitorNumber: Scalars["String"];
  editorUser?: Maybe<MaintenanceUser>;
  editorUserId?: Maybe<Scalars["Int"]>;
  mobilePhoneNumber: Scalars["String"];
  phoneNumber: Scalars["String"];
  /** This is called sales unit referenced with the user for the datawarehouse. In german that is called ***Verkaufseinheit***. */
  salesUnitCode: Scalars["String"];
  secondMobilePhoneNumber: Scalars["String"];
  state?: Maybe<Scalars["String"]>;
  street: Scalars["String"];
  user: MaintenanceUser;
  userId: Scalars["Int"];
  zip: Scalars["String"];
};

export type MaintenanceUserInformationListRelationFilter = {
  every?: InputMaybe<MaintenanceUserInformationWhereInput>;
  none?: InputMaybe<MaintenanceUserInformationWhereInput>;
  some?: InputMaybe<MaintenanceUserInformationWhereInput>;
};

export type MaintenanceUserInformationOrderByInput = {
  articleNumberForInspections?: InputMaybe<SortOrder>;
  articleNumberForRepairsIncludingTravel?: InputMaybe<SortOrder>;
  articleNumberForSmallParts?: InputMaybe<SortOrder>;
  carNumberPlate?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  costCenter?: InputMaybe<SortOrder>;
  debitorNumber?: InputMaybe<SortOrder>;
  editorUserId?: InputMaybe<SortOrder>;
  mobilePhoneNumber?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  salesUnitCode?: InputMaybe<SortOrder>;
  secondMobilePhoneNumber?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type MaintenanceUserInformationUpsertDataInput = {
  articleNumberForInspections?: InputMaybe<Scalars["String"]>;
  articleNumberForRepairsIncludingTravel?: InputMaybe<Scalars["String"]>;
  articleNumberForSmallParts?: InputMaybe<Scalars["String"]>;
  carNumberPlate?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  costCenter?: InputMaybe<Scalars["String"]>;
  debitorNumber?: InputMaybe<Scalars["String"]>;
  /** Here you define which editor is responsible for this user (technician). */
  editorUserId?: InputMaybe<Scalars["Int"]>;
  mobilePhoneNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  salesUnitCode?: InputMaybe<Scalars["String"]>;
  secondMobilePhoneNumber?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type MaintenanceUserInformationUpsertWhereInput = {
  userId: Scalars["Int"];
};

export type MaintenanceUserInformationWhereInput = {
  AND?: InputMaybe<Array<MaintenanceUserInformationWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceUserInformationWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceUserInformationWhereInput>>;
  articleNumberForInspections?: InputMaybe<StringFilter>;
  articleNumberForRepairsIncludingTravel?: InputMaybe<StringFilter>;
  articleNumberForSmallParts?: InputMaybe<StringFilter>;
  carNumberPlate?: InputMaybe<StringFilter>;
  city?: InputMaybe<StringFilter>;
  costCenter?: InputMaybe<StringFilter>;
  debitorNumber?: InputMaybe<StringFilter>;
  editorUser?: InputMaybe<MaintenanceUserWhereInput>;
  editorUserId?: InputMaybe<IntNullableFilter>;
  mobilePhoneNumber?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  salesUnitCode?: InputMaybe<StringFilter>;
  secondMobilePhoneNumber?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringNullableFilter>;
  street?: InputMaybe<StringFilter>;
  user?: InputMaybe<MaintenanceUserWhereInput>;
  userId?: InputMaybe<IntFilter>;
  zip?: InputMaybe<StringFilter>;
};

export type MaintenanceUserInformationWhereUniqueInput = {
  userId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceUserOrderByInput = {
  active?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postcode?: InputMaybe<SortOrder>;
  signature_resource_id?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
};

export type MaintenanceUserUpdateInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstname?: InputMaybe<Scalars["String"]>;
  lastname?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  signature_resource_id?: InputMaybe<Scalars["Int"]>;
  street?: InputMaybe<Scalars["String"]>;
};

export type MaintenanceUserWhereInput = {
  AND?: InputMaybe<Array<MaintenanceUserWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceUserWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceUserWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  city?: InputMaybe<StringNullableFilter>;
  createdMaintenanceTourStopAttachments?: InputMaybe<MaintenanceTourStopAttachmentListRelationFilter>;
  createdMaintenanceTourStopRelationShops?: InputMaybe<MaintenanceTourStopRelationshipListRelationFilter>;
  editorMaintenanceTourStops?: InputMaybe<MaintenanceTourStopListRelationFilter>;
  editorMaintenanceUserInformations?: InputMaybe<MaintenanceUserInformationListRelationFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstname?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastname?: InputMaybe<StringNullableFilter>;
  maintenanceAppointments?: InputMaybe<MaintenanceAppointmentListRelationFilter>;
  maintenanceGroupUsers?: InputMaybe<MaintenanceGroupUserListRelationFilter>;
  maintenanceTourStopPassenger?: InputMaybe<MaintenanceTourStopPassengerListRelationFilter>;
  maintenanceUserInformations?: InputMaybe<MaintenanceUserInformationListRelationFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  postcode?: InputMaybe<StringNullableFilter>;
  reportsDoneByUser?: InputMaybe<MaintenanceReportListRelationFilter>;
  signature_resource_id?: InputMaybe<IntNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  technicianMaintenanceTourStops?: InputMaybe<MaintenanceTourStopListRelationFilter>;
};

export type MaintenanceUserWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceVariant = {
  __typename?: "MaintenanceVariant";
  deleted: Scalars["Boolean"];
  id: Scalars["Int"];
  imageUrl?: Maybe<Scalars["String"]>;
  maintenanceProduct?: Maybe<MaintenanceProduct>;
  name?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["Int"]>;
  recursiveImageUrl?: Maybe<Scalars["String"]>;
  recursiveResourceVersionId?: Maybe<Scalars["Int"]>;
  resource?: Maybe<MaintenanceRelatedResource>;
  resourceId?: Maybe<Scalars["Int"]>;
  resourceVersionId?: Maybe<Scalars["Int"]>;
};

export type MaintenanceVariantImageUrlArgs = {
  template?: Scalars["String"];
};

export type MaintenanceVariantRecursiveImageUrlArgs = {
  template?: Scalars["String"];
};

export type MaintenanceVariantCreateInput = {
  deleted?: Scalars["Boolean"];
  name?: InputMaybe<Scalars["String"]>;
  productId?: InputMaybe<Scalars["Int"]>;
  resourceId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceVariantListRelationFilter = {
  every?: InputMaybe<MaintenanceVariantWhereInput>;
  none?: InputMaybe<MaintenanceVariantWhereInput>;
  some?: InputMaybe<MaintenanceVariantWhereInput>;
};

export type MaintenanceVariantOrderByInput = {
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrder>;
};

export type MaintenanceVariantUpdateInput = {
  name?: InputMaybe<Scalars["String"]>;
  productId?: InputMaybe<Scalars["Int"]>;
  resourceId?: InputMaybe<Scalars["Int"]>;
};

export type MaintenanceVariantWhereInput = {
  AND?: InputMaybe<Array<MaintenanceVariantWhereInput>>;
  NOT?: InputMaybe<Array<MaintenanceVariantWhereInput>>;
  OR?: InputMaybe<Array<MaintenanceVariantWhereInput>>;
  deleted?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  maintenanceProduct?: InputMaybe<MaintenanceProductWhereInput>;
  name?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<IntNullableFilter>;
  reportVariants?: InputMaybe<MaintenanceReportVariantListRelationFilter>;
  resource?: InputMaybe<MaintenanceRelatedResourceWhereInput>;
  resourceId?: InputMaybe<IntNullableFilter>;
};

export type MaintenanceVariantWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MarketingChannel = {
  __typename?: "MarketingChannel";
  code: Scalars["String"];
  description: Scalars["String"];
};

export type MarketingChannelOrderByInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
};

export type MarketingChannelWhereInput = {
  AND?: InputMaybe<Array<MarketingChannelWhereInput>>;
  NOT?: InputMaybe<Array<MarketingChannelWhereInput>>;
  OR?: InputMaybe<Array<MarketingChannelWhereInput>>;
  code?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
};

export type MarketingChannelWhereUniqueInput = {
  code?: InputMaybe<Scalars["String"]>;
};

export type MaterialGroup = {
  __typename?: "MaterialGroup";
  description: Scalars["String"];
  id: Scalars["String"];
  uniqueId: Scalars["ID"];
};

export type MaterialGroupConstraint = {
  __typename?: "MaterialGroupConstraint";
  lowerMaterialGroup?: Maybe<Scalars["String"]>;
  mainMaterialGroup: Scalars["String"];
  materialGroup?: Maybe<Scalars["String"]>;
};

export type MaterialGroupConstraintInput = {
  lowerMaterialGroup?: InputMaybe<Scalars["String"]>;
  mainMaterialGroup: Scalars["String"];
  materialGroup?: InputMaybe<Scalars["String"]>;
};

export type MemberGroup = {
  __typename?: "MemberGroup";
  email: Scalars["String"];
  group: Group;
  groupId: Scalars["Int"];
};

export type MemberGroupAttributeUpdateInput = {
  email?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["Int"]>;
};

export type MemberGroupAttributeUpsertInput = {
  groupId?: InputMaybe<Scalars["Int"]>;
};

export type MemberGroupEmailGroupIdCompoundUniqueInput = {
  email: Scalars["String"];
  groupId: Scalars["Int"];
};

export type MemberGroupListRelationFilter = {
  every?: InputMaybe<MemberGroupWhereInput>;
  none?: InputMaybe<MemberGroupWhereInput>;
  some?: InputMaybe<MemberGroupWhereInput>;
};

export type MemberGroupWhereInput = {
  AND?: InputMaybe<Array<MemberGroupWhereInput>>;
  NOT?: InputMaybe<Array<MemberGroupWhereInput>>;
  OR?: InputMaybe<Array<MemberGroupWhereInput>>;
  email?: InputMaybe<StringFilter>;
  group?: InputMaybe<GroupWhereInput>;
  groupId?: InputMaybe<IntFilter>;
  user?: InputMaybe<PermissionUserWhereInput>;
};

export type MemberGroupWhereUniqueInput = {
  email_groupId?: InputMaybe<MemberGroupEmailGroupIdCompoundUniqueInput>;
};

export type MercateoElbkinderOrder = Order & {
  __typename?: "MercateoElbkinderOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type MercateoElbkinderOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type MercateoOrder = Order & {
  __typename?: "MercateoOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type MercateoOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type MercateoUniteOrder = Order & {
  __typename?: "MercateoUniteOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type MercateoUniteOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type MergeTags = {
  __typename?: "MergeTags";
  deletedTagCount: Scalars["Int"];
  movedRedirectCount: Scalars["Int"];
  movedResourceCount: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  addResourcesToCollection?: Maybe<ResourceCollection>;
  /** Add a tag with a given name */
  addTag?: Maybe<Tag>;
  /** Copies all materials from the source variant to all sibling variants. Returns the updated variants. */
  applyVariantMaterialsForAllVariants?: Maybe<Array<Maybe<Variant>>>;
  /** Connect one resource to a variant, will automatically create variant_image and product image if needed */
  connectResourceWithVariant?: Maybe<ResourceVariant>;
  copyMaintenanceTourStop?: Maybe<MaintenanceTourStop>;
  createApp?: Maybe<App>;
  createArchiveNav?: Maybe<ArchiveNavItem>;
  createContract: Contract;
  createCountryInformation: CountryInformation;
  createEmailTemplate: EmailTemplate;
  createFavouriteApp?: Maybe<FavouriteApp>;
  createGroup?: Maybe<Group>;
  createGroupCountry?: Maybe<GroupCountry>;
  /** @deprecated we already have createGroupRoles so this is obsolete */
  createGroupRole?: Maybe<GroupRole>;
  /** creates groupRoles */
  createGroupRoles?: Maybe<Array<Maybe<GroupRole>>>;
  createIncentiveVoucher?: Maybe<Voucher>;
  createMaintenanceAppointment?: Maybe<MaintenanceAppointment>;
  createMaintenanceGroup?: Maybe<MaintenanceGroup>;
  createMaintenanceGroupUser?: Maybe<MaintenanceGroupUser>;
  createMaintenanceProduct?: Maybe<MaintenanceProduct>;
  createMaintenanceProductService?: Maybe<MaintenanceProductService>;
  createMaintenanceReport?: Maybe<MaintenanceReport>;
  createMaintenanceReportService?: Maybe<MaintenanceReportService>;
  createMaintenanceReportServiceSmallPart?: Maybe<MaintenanceReportServiceSmallPart>;
  createMaintenanceReportServiceSparePart?: Maybe<MaintenanceReportServiceSparePart>;
  createMaintenanceReportSparePartBlueprint?: Maybe<MaintenanceReportSparePartBlueprint>;
  createMaintenanceReportVariant?: Maybe<MaintenanceReportVariant>;
  createMaintenanceResource?: Maybe<MaintenanceResource>;
  createMaintenanceServiceSparePart?: Maybe<MaintenanceServiceSparePart>;
  createMaintenanceTourStop?: Maybe<MaintenanceTourStop>;
  createMaintenanceTourStopAttachment?: Maybe<MaintenanceTourStopAttachment>;
  createMaintenanceVariant?: Maybe<MaintenanceVariant>;
  createManuallyVoucherCode?: Maybe<VoucherCode>;
  createMemberGroup?: Maybe<MemberGroup>;
  createMultipleVoucherCodes?: Maybe<Array<Maybe<VoucherCode>>>;
  createNavisionOffer?: Maybe<Scalars["Boolean"]>;
  createOCIcustomer: OciCustomer;
  createOrderInvoiceToken?: Maybe<Scalars["String"]>;
  createPercentageVoucher?: Maybe<Voucher>;
  /** @deprecated we already have createPermissions so this is obsolete */
  createPermission?: Maybe<Permission>;
  createPermissions?: Maybe<Array<Maybe<Permission>>>;
  createPhotoModel: PhotoModel;
  createPhotoModelContract: PhotoModelContract;
  createProductFixedDiscountVoucher?: Maybe<Voucher>;
  createProductPercentageDiscountVoucher?: Maybe<Voucher>;
  /** Create one Resource and add versions, variants, products, tags, slugs and texts */
  createResource?: Maybe<Resource>;
  createResourceCollection?: Maybe<ResourceCollection>;
  createResourceLanguage: ResourceLanguage;
  createResourceLicense: ResourceLicense;
  createResourceLicenseDefinition: ResourceLicenseDefinition;
  createResourcePhotoModel: ResourcePhotoModel;
  createResourceProduct: ResourceProduct;
  /** Create ResourceProductGroup */
  createResourceProductGroup?: Maybe<ResourceProductGroup>;
  createResourceSlug: Slug;
  createResourceTemplate: ResourceTemplate;
  /** Create one Resource Text */
  createResourceText?: Maybe<CResourceText>;
  createResourceVariant: ResourceVariant;
  createResourceVersion: ResourceVersion;
  createRole?: Maybe<Role>;
  createService?: Maybe<Service>;
  createShippingCostsVoucher?: Maybe<Voucher>;
  createTemplate: Template;
  createTypeCatalog?: Maybe<TypeCatalog>;
  createValueVoucher?: Maybe<Voucher>;
  createVariantMaterial?: Maybe<VariantMaterial>;
  createVariantMaterialDefintion?: Maybe<VariantMaterialDefinition>;
  deleteApp?: Maybe<Scalars["Boolean"]>;
  /** Delete one Contract and it's relations like PhotoModelContract. */
  deleteContract?: Maybe<Contract>;
  deleteEmailTemplate?: Maybe<EmailTemplate>;
  /** Deletes a record from the favourites app table */
  deleteFavouriteApp?: Maybe<Scalars["Boolean"]>;
  deleteFutureEmail?: Maybe<ShopMail>;
  deleteGroup?: Maybe<Scalars["Boolean"]>;
  deleteGroupCountry?: Maybe<Scalars["Boolean"]>;
  /** delete groupRoles */
  deleteGroupRoles?: Maybe<Scalars["Boolean"]>;
  deleteMaintenanceAppointment?: Maybe<MaintenanceAppointment>;
  deleteMaintenanceBlueprint?: Maybe<MaintenanceBlueprint>;
  deleteMaintenanceGroupUser?: Maybe<MaintenanceGroupUser>;
  deleteMaintenanceProduct?: Maybe<MaintenanceProduct>;
  deleteMaintenanceProductService?: Maybe<MaintenanceProductService>;
  deleteMaintenanceReport?: Maybe<MaintenanceReport>;
  deleteMaintenanceReportService?: Maybe<MaintenanceReportService>;
  deleteMaintenanceReportServiceSmallPart?: Maybe<MaintenanceReportServiceSmallPart>;
  deleteMaintenanceReportServiceSparePart?: Maybe<MaintenanceReportServiceSparePart>;
  deleteMaintenanceReportSparePartBlueprint?: Maybe<MaintenanceReportSparePartBlueprint>;
  deleteMaintenanceReportVariant?: Maybe<MaintenanceReportVariant>;
  deleteMaintenanceResource?: Maybe<MaintenanceResource>;
  deleteMaintenanceServiceSparePart?: Maybe<MaintenanceServiceSparePart>;
  deleteMaintenanceSmallPart?: Maybe<MaintenanceSmallPart>;
  deleteMaintenanceSynchronizationLog?: Maybe<MaintenanceSynchronizationLog>;
  deleteMaintenanceTourStop?: Maybe<MaintenanceTourStop>;
  deleteMaintenanceTourStopAttachment?: Maybe<MaintenanceTourStopAttachment>;
  deleteMaintenanceTourStopPassenger?: Maybe<MaintenanceTourStopPassenger>;
  deleteMaintenanceTourStopRelationship?: Maybe<MaintenanceTourStopRelationship>;
  deleteMaintenanceUserInformation?: Maybe<MaintenanceUserInformation>;
  deleteMaintenanceVariant?: Maybe<MaintenanceVariant>;
  /** @deprecated we already have deleteMemberGroups so this is obsolete */
  deleteMemberGroup?: Maybe<Scalars["Boolean"]>;
  deleteMemberGroups?: Maybe<Scalars["Boolean"]>;
  /** Soft delete existing OCI customer */
  deleteOCICustomer?: Maybe<Scalars["Boolean"]>;
  deleteOfficeVisit?: Maybe<Scalars["Boolean"]>;
  /** @deprecated we already have deletePermissions so this is obsolete */
  deletePermission?: Maybe<Scalars["Boolean"]>;
  deletePermissions?: Maybe<Scalars["Boolean"]>;
  deletePhotoModel?: Maybe<PhotoModel>;
  deletePhotoModelContract?: Maybe<PhotoModelContract>;
  /** Soft deletes one Resource */
  deleteResource?: Maybe<Resource>;
  deleteResourceCollection?: Maybe<ResourceCollection>;
  deleteResourceCollectionAccessGrant?: Maybe<ResourceCollectionAccess>;
  deleteResourceCollectionTag?: Maybe<ResourceCollectionTag>;
  deleteResourceCollections?: Maybe<Array<Maybe<ResourceCollection>>>;
  deleteResourceLanguage?: Maybe<ResourceLanguage>;
  deleteResourceLicense?: Maybe<ResourceLicense>;
  deleteResourceLicenseDefinition?: Maybe<ResourceLicenseDefinition>;
  deleteResourcePhotoModel?: Maybe<ResourcePhotoModel>;
  deleteResourceProduct?: Maybe<ResourceProduct>;
  /** Delete ResourceProductGroup by WhereUniqueInput */
  deleteResourceProductGroup?: Maybe<ResourceProductGroup>;
  deleteResourceSlug?: Maybe<Slug>;
  deleteResourceTag?: Maybe<ResourceTag>;
  deleteResourceTemplate?: Maybe<ResourceTemplate>;
  /** deletes one resource text; if no languageId is specified, the complete ResourceText will be deleted */
  deleteResourceText?: Maybe<CResourceText>;
  deleteResourceVariant?: Maybe<ResourceVariant>;
  deleteResourceVersion?: Maybe<ResourceVersion>;
  /** Soft deletes a list of Resources */
  deleteResources?: Maybe<Array<Maybe<Resource>>>;
  /** Deletes a Role and all linked GroupRole */
  deleteRole?: Maybe<Scalars["Boolean"]>;
  deleteService?: Maybe<Scalars["Boolean"]>;
  /** Delete one Tag and it's relations like redirect and resource */
  deleteTag?: Maybe<Tag>;
  deleteTemplate?: Maybe<Template>;
  deleteText?: Maybe<Text>;
  deleteTypeCatalog?: Maybe<Scalars["Boolean"]>;
  deleteVariantMaterial?: Maybe<VariantMaterial>;
  deleteVoucher?: Maybe<Voucher>;
  deleteVoucherCode?: Maybe<Array<Maybe<VoucherCode>>>;
  downloadCo2VariantMaterialStatistic?: Maybe<Scalars["String"]>;
  downloadVoucherCodes?: Maybe<Scalars["String"]>;
  /** Duplicate a Group, including all linked GroupRoles */
  duplicateGroup?: Maybe<Group>;
  /** Duplicate existing OCI customer */
  duplicateOCICustomer?: Maybe<OciCustomer>;
  /** Duplicate a resource */
  duplicateResource?: Maybe<Resource>;
  duplicateVoucher?: Maybe<Voucher>;
  grantResourceCollectionAccess?: Maybe<ResourceCollectionAccess>;
  graphqlMutationLogginIsOnline?: Maybe<Scalars["ID"]>;
  invalidateResourceCollectionAccessGrant?: Maybe<ResourceCollectionAccess>;
  /** Invalidates Version URLs on the CloudFront CDN */
  invalidateVersionURLs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Merge multiple tags by id in idsToMerge into one tag with the given id in targetId */
  mergeTags?: Maybe<MergeTags>;
  /** Reactivate an old resource version */
  reactivateVersion?: Maybe<ResourceVersion>;
  redeemVoucherCode?: Maybe<VoucherCode>;
  removeArchiveNav?: Maybe<ArchiveNavItem>;
  removeResourcesFromCollection?: Maybe<ResourceCollection>;
  renameArchiveNav?: Maybe<ArchiveNavItem>;
  /** Rename a category. Keep in mind, that all categories are stored in the voucher table. This will select all vouchers with the category name and update the category field to the new name. */
  renameCategory?: Maybe<Scalars["String"]>;
  /** Update one Tag */
  renameTag?: Maybe<Tag>;
  /** Save order */
  saveOrderEntryOrder?: Maybe<Scalars["String"]>;
  sendMaintenanceReportEmail?: Maybe<Scalars["Int"]>;
  /** This will translate the template into the given target languages if the language is possible to translate with deepl.com */
  translateTemplateCode?: Maybe<Template>;
  updateApp?: Maybe<App>;
  updateContract?: Maybe<Contract>;
  updateCountryInformation?: Maybe<CountryInformation>;
  updateEmailTemplate?: Maybe<EmailTemplate>;
  updateFutureMailsRecipient?: Maybe<Array<Maybe<ShopMail>>>;
  /** update a group entity, pass null as activeDirectoryGroup to remove the relationship */
  updateGroup?: Maybe<Group>;
  updateGroupRole?: Maybe<GroupRole>;
  updateIncentiveVoucher?: Maybe<Voucher>;
  updateMaintenanceAppointment?: Maybe<MaintenanceAppointment>;
  updateMaintenanceGroup?: Maybe<MaintenanceGroup>;
  updateMaintenanceProduct?: Maybe<MaintenanceProduct>;
  updateMaintenanceProductService?: Maybe<MaintenanceProductService>;
  updateMaintenanceReport?: Maybe<MaintenanceReport>;
  updateMaintenanceReportService?: Maybe<MaintenanceReportService>;
  updateMaintenanceReportServiceSmallPart?: Maybe<MaintenanceReportServiceSmallPart>;
  updateMaintenanceReportServiceSparePart?: Maybe<MaintenanceReportServiceSparePart>;
  updateMaintenanceReportSparePartBlueprint?: Maybe<MaintenanceReportSparePartBlueprint>;
  updateMaintenanceReportVariant?: Maybe<MaintenanceReportVariant>;
  updateMaintenanceResource?: Maybe<MaintenanceResource>;
  updateMaintenanceServiceSparePart?: Maybe<MaintenanceServiceSparePart>;
  updateMaintenanceTourStop?: Maybe<MaintenanceTourStop>;
  updateMaintenanceTourStopAttachment?: Maybe<MaintenanceTourStopAttachment>;
  updateMaintenanceUser?: Maybe<MaintenanceUser>;
  updateMaintenanceVariant?: Maybe<MaintenanceVariant>;
  /** @deprecated this mutation is to be replaced with upsertMemberGroup */
  updateMemberGroup?: Maybe<MemberGroup>;
  updateOCIcustomer?: Maybe<OciCustomer>;
  updatePercentageVoucher?: Maybe<PercentageVoucher>;
  updatePermission?: Maybe<Permission>;
  updatePhotoModel?: Maybe<PhotoModel>;
  updatePhotoModelContract?: Maybe<PhotoModelContract>;
  updateProductFixedDiscountVoucher?: Maybe<Voucher>;
  updateProductPercentageDiscountVoucher?: Maybe<Voucher>;
  /** Update one Resource and add versions, variants, products, tags, slugs and texts */
  updateResource?: Maybe<Resource>;
  updateResourceCollection?: Maybe<ResourceCollection>;
  updateResourceCollectionAccessGrant?: Maybe<ResourceCollectionAccess>;
  updateResourceLicense?: Maybe<ResourceLicense>;
  updateResourceLicenseDefinition?: Maybe<ResourceLicenseDefinition>;
  updateResourcePhotoModel?: Maybe<ResourcePhotoModel>;
  updateResourceTemplate?: Maybe<ResourceTemplate>;
  updateResourceText?: Maybe<CResourceText>;
  /** Update one Resource Version */
  updateResourceVersion?: Maybe<ResourceVersion>;
  updateRole?: Maybe<Role>;
  updateService?: Maybe<Service>;
  updateShippingCostsVoucher?: Maybe<Voucher>;
  updateTemplate?: Maybe<Template>;
  /**
   * Update one Text
   * @deprecated to be replaced with updateResourceText
   */
  updateText?: Maybe<Text>;
  /** Update one TextAttribute */
  updateTextAttribute?: Maybe<TextAttribute>;
  updateTypeCatalog?: Maybe<TypeCatalog>;
  updateValueVoucher?: Maybe<Voucher>;
  updateVariantMaterial?: Maybe<VariantMaterial>;
  updateVariantMaterialDefinition?: Maybe<VariantMaterialDefinition>;
  updateVariantSupplierCarbonFootprint?: Maybe<VariantSupplierCarbonFootprint>;
  updateVoucherCode?: Maybe<VoucherCode>;
  /** This mutation updates a email template for a constellation of shop and language id. If code is an empty string, it will be deleted. */
  upsertEmailTemplateCode?: Maybe<EmailTemplateCode>;
  upsertMaintenanceUserInformation?: Maybe<MaintenanceUserInformation>;
  /** Upsert a Member Group. */
  upsertMemberGroup?: Maybe<MemberGroup>;
  /** Upserts a memberGroup, only GroupId can be updated as updating all memberGroups to the same email would be a bad idea */
  upsertMemberGroups?: Maybe<Array<Maybe<MemberGroup>>>;
  upsertOfficeVisit?: Maybe<OfficeVisit>;
  /** Upsert a Member Group. */
  upsertPermission?: Maybe<Permission>;
  /** This mutation updates a template for a constellation of shop and language id. If code is an empty string, it will be deleted. */
  upsertTemplateCode?: Maybe<TemplateCode>;
  zipImages: ImageZipResult;
  zipImagesConsume: ImageZipConsume;
};

export type MutationAddResourcesToCollectionArgs = {
  id: Scalars["Int"];
  resourceIds: Array<Scalars["Int"]>;
};

export type MutationAddTagArgs = {
  name: Scalars["String"];
};

export type MutationApplyVariantMaterialsForAllVariantsArgs = {
  sourceVariantId: Scalars["Int"];
};

export type MutationConnectResourceWithVariantArgs = {
  resourceId: Scalars["Int"];
  type: ResourceVariantCreateTypeEnumInput;
  variantId: Scalars["Int"];
};

export type MutationCopyMaintenanceTourStopArgs = {
  id: Scalars["Int"];
  mode?: MaintenanceTourStopCopyMode;
  secondRepairReason?: InputMaybe<MaintenanceSecondRepairReason>;
};

export type MutationCreateAppArgs = {
  data: CreateAppInput;
};

export type MutationCreateArchiveNavArgs = {
  id?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type MutationCreateContractArgs = {
  data: ContractCreateInput;
};

export type MutationCreateCountryInformationArgs = {
  data: CountryInformationCreateInput;
};

export type MutationCreateEmailTemplateArgs = {
  data: EmailTemplateCreateInput;
};

export type MutationCreateFavouriteAppArgs = {
  data: CreateFavouriteAppInput;
};

export type MutationCreateGroupArgs = {
  data: CreateGroupInput;
};

export type MutationCreateGroupCountryArgs = {
  data: CreateGroupCountryInput;
};

export type MutationCreateGroupRoleArgs = {
  data: CreateGroupRoleInput;
};

export type MutationCreateGroupRolesArgs = {
  where?: InputMaybe<Array<CreateGroupRole>>;
};

export type MutationCreateIncentiveVoucherArgs = {
  active: Scalars["Boolean"];
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  incentive: Scalars["Int"];
  minOrderValue: Scalars["Float"];
  name: Scalars["String"];
  shopId: Scalars["Int"];
  unique: Scalars["Boolean"];
};

export type MutationCreateMaintenanceAppointmentArgs = {
  data: MaintenanceAppointmentCreateInput;
};

export type MutationCreateMaintenanceGroupArgs = {
  data: MaintenanceGroupCreateInput;
};

export type MutationCreateMaintenanceGroupUserArgs = {
  data: MaintenanceGroupUserCreateInput;
};

export type MutationCreateMaintenanceProductArgs = {
  data: MaintenanceProductCreateInput;
};

export type MutationCreateMaintenanceProductServiceArgs = {
  data: MaintenanceProductServiceCreateInput;
};

export type MutationCreateMaintenanceReportArgs = {
  data: MaintenanceReportCreateInput;
};

export type MutationCreateMaintenanceReportServiceArgs = {
  data: MaintenanceReportServiceCreateInput;
};

export type MutationCreateMaintenanceReportServiceSmallPartArgs = {
  data: MaintenanceReportServiceSmallPartCreateInput;
};

export type MutationCreateMaintenanceReportServiceSparePartArgs = {
  data: MaintenanceReportServiceSparePartCreateInput;
};

export type MutationCreateMaintenanceReportSparePartBlueprintArgs = {
  data: MaintenanceReportSparePartBlueprintCreateInput;
};

export type MutationCreateMaintenanceReportVariantArgs = {
  data: MaintenanceReportVariantCreateInput;
};

export type MutationCreateMaintenanceResourceArgs = {
  data: MaintenanceResourceCreateInput;
};

export type MutationCreateMaintenanceServiceSparePartArgs = {
  data: MaintenanceServiceSparePartCreateInput;
};

export type MutationCreateMaintenanceTourStopArgs = {
  data: MaintenanceTourStopCreateInput;
};

export type MutationCreateMaintenanceTourStopAttachmentArgs = {
  data: MaintenanceTourStopAttachmentCreateInput;
};

export type MutationCreateMaintenanceVariantArgs = {
  data: MaintenanceVariantCreateInput;
};

export type MutationCreateManuallyVoucherCodeArgs = {
  code: Scalars["String"];
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  voucherId: Scalars["Int"];
};

export type MutationCreateMemberGroupArgs = {
  data: CreateMemberGroupInput;
};

export type MutationCreateMultipleVoucherCodesArgs = {
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  prefix?: InputMaybe<Scalars["String"]>;
  quantity: Scalars["Int"];
  voucherId: Scalars["Int"];
};

export type MutationCreateNavisionOfferArgs = {
  reportId: Scalars["Int"];
};

export type MutationCreateOcIcustomerArgs = {
  data: OciCustomerCreateInput;
};

export type MutationCreatePercentageVoucherArgs = {
  active: Scalars["Boolean"];
  brandConstraints?: InputMaybe<Array<BrandConstraintInput>>;
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description: Scalars["String"];
  materialGroupConstraints?: InputMaybe<Array<MaterialGroupConstraintInput>>;
  minOrderValue: Scalars["Float"];
  name: Scalars["String"];
  navisionAccount: Scalars["Int"];
  percentage: Scalars["Int"];
  shopId: Scalars["Int"];
  unique: Scalars["Boolean"];
};

export type MutationCreatePermissionArgs = {
  data: CreatePermissionInput;
};

export type MutationCreatePermissionsArgs = {
  data: CreatePermissionsInput;
  where: CreatePermissionsWhere;
};

export type MutationCreatePhotoModelArgs = {
  data: PhotoModelCreateInput;
};

export type MutationCreatePhotoModelContractArgs = {
  data: PhotoModelContractCreateInput;
};

export type MutationCreateProductFixedDiscountVoucherArgs = {
  active: Scalars["Boolean"];
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  minOrderValue: Scalars["Float"];
  name: Scalars["String"];
  navisionAccount: Scalars["Int"];
  shopId: Scalars["Int"];
  unique: Scalars["Boolean"];
  validForModel: Scalars["Boolean"];
  validForVariantId: Scalars["Int"];
  validOnlyForOneVariant: Scalars["Boolean"];
  value: Scalars["Float"];
};

export type MutationCreateProductPercentageDiscountVoucherArgs = {
  active: Scalars["Boolean"];
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  minOrderValue: Scalars["Float"];
  name: Scalars["String"];
  navisionAccount: Scalars["Int"];
  percentage: Scalars["Int"];
  shopId: Scalars["Int"];
  unique: Scalars["Boolean"];
  validForModel: Scalars["Boolean"];
  validForVariantId: Scalars["Int"];
  validOnlyForOneVariant: Scalars["Boolean"];
};

export type MutationCreateResourceArgs = {
  archiveNavs?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  data: ResourceCreateInput;
  products?: InputMaybe<
    Array<InputMaybe<ResourceProductCreateWithoutResourceInput>>
  >;
  resourcePdfCategoryId?: InputMaybe<Scalars["Int"]>;
  slugs?: InputMaybe<Array<InputMaybe<SlugCreateWithoutResourceInput>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  texts?: InputMaybe<Array<InputMaybe<ResourceTextCreate>>>;
  variants?: InputMaybe<
    Array<InputMaybe<ResourceVariantCreateWithoutResourceInput>>
  >;
  versions?: InputMaybe<
    Array<InputMaybe<ResourceVersionCreateWithoutResourceInput>>
  >;
};

export type MutationCreateResourceCollectionArgs = {
  name: Scalars["String"];
  resourceIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type MutationCreateResourceLanguageArgs = {
  data: ResourceLanguageCreateInput;
};

export type MutationCreateResourceLicenseArgs = {
  data: ResourceLicenseCreateInput;
};

export type MutationCreateResourceLicenseDefinitionArgs = {
  data: ResourceLicenseDefinitionCreateInput;
};

export type MutationCreateResourcePhotoModelArgs = {
  data: ResourcePhotoModelCreateInput;
};

export type MutationCreateResourceProductArgs = {
  data: ResourceProductCreateInput;
};

export type MutationCreateResourceProductGroupArgs = {
  data: CreateResourceProductGroupInput;
};

export type MutationCreateResourceSlugArgs = {
  data: SlugCreateInput;
};

export type MutationCreateResourceTemplateArgs = {
  data: ResourceTemplateCreateInput;
};

export type MutationCreateResourceTextArgs = {
  data?: InputMaybe<ResourceTextCreate>;
};

export type MutationCreateResourceVariantArgs = {
  data: ResourceVariantCreateInput;
};

export type MutationCreateResourceVersionArgs = {
  data: ResourceVersionCreateInput;
};

export type MutationCreateRoleArgs = {
  data: CreateRoleInput;
};

export type MutationCreateServiceArgs = {
  data: CreateServiceInput;
};

export type MutationCreateShippingCostsVoucherArgs = {
  active: Scalars["Boolean"];
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  minOrderValue: Scalars["Float"];
  name: Scalars["String"];
  navisionAccount: Scalars["Int"];
  shopId: Scalars["Int"];
  unique: Scalars["Boolean"];
};

export type MutationCreateTemplateArgs = {
  data: TemplateCreateInput;
};

export type MutationCreateTypeCatalogArgs = {
  data: CreateTypeCatalogInput;
};

export type MutationCreateValueVoucherArgs = {
  active: Scalars["Boolean"];
  brandConstraints?: InputMaybe<Array<BrandConstraintInput>>;
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  minOrderValue: Scalars["Float"];
  name: Scalars["String"];
  navisionAccount: Scalars["Int"];
  shopId: Scalars["Int"];
  unique: Scalars["Boolean"];
  value: Scalars["Float"];
};

export type MutationCreateVariantMaterialArgs = {
  data: VariantMaterialCreateInput;
};

export type MutationCreateVariantMaterialDefintionArgs = {
  data: VariantMaterialDefinitionCreateInput;
};

export type MutationDeleteAppArgs = {
  where: DeleteAppWhere;
};

export type MutationDeleteContractArgs = {
  where: ContractWhereUniqueInput;
};

export type MutationDeleteEmailTemplateArgs = {
  where: EmailTemplateWhereUniqueInput;
};

export type MutationDeleteFavouriteAppArgs = {
  where: DeleteFavouriteAppWhere;
};

export type MutationDeleteFutureEmailArgs = {
  emailId: Scalars["Int"];
};

export type MutationDeleteGroupArgs = {
  where: DeleteGroupWhere;
};

export type MutationDeleteGroupCountryArgs = {
  where: DeleteGroupCountryWhere;
};

export type MutationDeleteGroupRolesArgs = {
  where: DeleteGroupRolesInput;
};

export type MutationDeleteMaintenanceAppointmentArgs = {
  where: MaintenanceAppointmentWhereUniqueInput;
};

export type MutationDeleteMaintenanceBlueprintArgs = {
  where: MaintenanceBlueprintWhereUniqueInput;
};

export type MutationDeleteMaintenanceGroupUserArgs = {
  groupId: Scalars["Int"];
  type: MaintenanceGroupUserType;
  userId: Scalars["Int"];
};

export type MutationDeleteMaintenanceProductArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMaintenanceProductServiceArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMaintenanceReportArgs = {
  where: MaintenanceReportWhereUniqueInput;
};

export type MutationDeleteMaintenanceReportServiceArgs = {
  where: MaintenanceReportServiceWhereUniqueInput;
};

export type MutationDeleteMaintenanceReportServiceSmallPartArgs = {
  where: MaintenanceReportServiceSmallPartWhereUniqueInput;
};

export type MutationDeleteMaintenanceReportServiceSparePartArgs = {
  where: MaintenanceReportServiceSparePartWhereUniqueInput;
};

export type MutationDeleteMaintenanceReportSparePartBlueprintArgs = {
  where: MaintenanceReportSparePartBlueprintWhereUniqueInput;
};

export type MutationDeleteMaintenanceReportVariantArgs = {
  where: MaintenanceReportVariantWhereUniqueInput;
};

export type MutationDeleteMaintenanceResourceArgs = {
  where: MaintenanceResourceWhereUniqueInput;
};

export type MutationDeleteMaintenanceServiceSparePartArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMaintenanceSmallPartArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMaintenanceSynchronizationLogArgs = {
  where: MaintenanceSynchronizationLogWhereUniqueInput;
};

export type MutationDeleteMaintenanceTourStopArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMaintenanceTourStopAttachmentArgs = {
  where: MaintenanceTourStopAttachmentWhereUniqueInput;
};

export type MutationDeleteMaintenanceTourStopPassengerArgs = {
  where: MaintenanceTourStopPassengerWhereUniqueInput;
};

export type MutationDeleteMaintenanceTourStopRelationshipArgs = {
  where: MaintenanceTourStopRelationshipWhereUniqueInput;
};

export type MutationDeleteMaintenanceUserInformationArgs = {
  where: MaintenanceUserInformationWhereUniqueInput;
};

export type MutationDeleteMaintenanceVariantArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMemberGroupArgs = {
  where: DeleteMemberGroupWhere;
};

export type MutationDeleteMemberGroupsArgs = {
  where: DeleteMemberGroupsWhere;
};

export type MutationDeleteOciCustomerArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteOfficeVisitArgs = {
  where: DeleteOfficeVisitInput;
};

export type MutationDeletePermissionArgs = {
  where: DeletePermissionWhere;
};

export type MutationDeletePermissionsArgs = {
  where: DeletePermissionsWhere;
};

export type MutationDeletePhotoModelArgs = {
  where: PhotoModelWhereUniqueInput;
};

export type MutationDeletePhotoModelContractArgs = {
  where: PhotoModelContractWhereUniqueInput;
};

export type MutationDeleteResourceArgs = {
  where?: InputMaybe<ResourceDeleteInput>;
};

export type MutationDeleteResourceCollectionArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteResourceCollectionAccessGrantArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteResourceCollectionTagArgs = {
  where: ResourceCollectionTagWhereUniqueInput;
};

export type MutationDeleteResourceCollectionsArgs = {
  ids: Array<Scalars["Int"]>;
};

export type MutationDeleteResourceLanguageArgs = {
  where: ResourceLanguageWhereUniqueInput;
};

export type MutationDeleteResourceLicenseArgs = {
  where: ResourceLicenseWhereUniqueInput;
};

export type MutationDeleteResourceLicenseDefinitionArgs = {
  where: ResourceLicenseDefinitionWhereUniqueInput;
};

export type MutationDeleteResourcePhotoModelArgs = {
  where: ResourcePhotoModelWhereUniqueInput;
};

export type MutationDeleteResourceProductArgs = {
  where: ResourceProductWhereUniqueInput;
};

export type MutationDeleteResourceProductGroupArgs = {
  where: ResourceProductGroupWhereUniqueInput;
};

export type MutationDeleteResourceSlugArgs = {
  where: SlugWhereUniqueInput;
};

export type MutationDeleteResourceTagArgs = {
  where: ResourceTagWhereUniqueInput;
};

export type MutationDeleteResourceTemplateArgs = {
  where: ResourceTemplateWhereUniqueInput;
};

export type MutationDeleteResourceTextArgs = {
  id: Scalars["Int"];
  languageId?: InputMaybe<Scalars["Int"]>;
};

export type MutationDeleteResourceVariantArgs = {
  where: ResourceVariantWhereUniqueInput;
};

export type MutationDeleteResourceVersionArgs = {
  where: ResourceVersionWhereUniqueInput;
};

export type MutationDeleteResourcesArgs = {
  where?: InputMaybe<ResourcesDeleteInput>;
};

export type MutationDeleteRoleArgs = {
  where: DeleteRoleWhere;
};

export type MutationDeleteServiceArgs = {
  where: DeleteServiceWhere;
};

export type MutationDeleteTagArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteTemplateArgs = {
  where: TemplateWhereUniqueInput;
};

export type MutationDeleteTextArgs = {
  where: TextWhereUniqueInput;
};

export type MutationDeleteTypeCatalogArgs = {
  where: DeleteTypeCatalogWhere;
};

export type MutationDeleteVariantMaterialArgs = {
  where: VariantMaterialDeleteInput;
};

export type MutationDeleteVoucherArgs = {
  voucherId: Scalars["Int"];
};

export type MutationDeleteVoucherCodeArgs = {
  codeIds: Array<Scalars["Int"]>;
};

export type MutationDownloadCo2VariantMaterialStatisticArgs = {
  from: Scalars["DateTime"];
  to: Scalars["DateTime"];
};

export type MutationDownloadVoucherCodesArgs = {
  fromDate?: InputMaybe<Scalars["DateTime"]>;
  toDate?: InputMaybe<Scalars["DateTime"]>;
  voucherId: Scalars["Int"];
};

export type MutationDuplicateGroupArgs = {
  data: DuplicateGroupAttributeInput;
  where: DuplicateGroupInput;
};

export type MutationDuplicateOciCustomerArgs = {
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type MutationDuplicateResourceArgs = {
  id: Scalars["Int"];
};

export type MutationDuplicateVoucherArgs = {
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type MutationGrantResourceCollectionAccessArgs = {
  company?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstname?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  lastname?: InputMaybe<Scalars["String"]>;
  privateNote?: InputMaybe<Scalars["String"]>;
  skipEmail?: InputMaybe<Scalars["Boolean"]>;
  validUntil?: InputMaybe<Scalars["DateTime"]>;
};

export type MutationInvalidateResourceCollectionAccessGrantArgs = {
  id: Scalars["Int"];
};

export type MutationInvalidateVersionUrLsArgs = {
  dry?: InputMaybe<Scalars["Boolean"]>;
  versionId: Scalars["Int"];
};

export type MutationMergeTagsArgs = {
  idsToMerge: Array<Scalars["Int"]>;
  targetId: Scalars["Int"];
};

export type MutationReactivateVersionArgs = {
  versionId: Scalars["Int"];
};

export type MutationRedeemVoucherCodeArgs = {
  code: Scalars["String"];
  navisionOrderId?: InputMaybe<Scalars["String"]>;
};

export type MutationRemoveArchiveNavArgs = {
  id: Scalars["Int"];
};

export type MutationRemoveResourcesFromCollectionArgs = {
  id: Scalars["Int"];
  resourceIds: Array<Scalars["Int"]>;
};

export type MutationRenameArchiveNavArgs = {
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type MutationRenameCategoryArgs = {
  currentName: Scalars["String"];
  newName: Scalars["String"];
};

export type MutationRenameTagArgs = {
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type MutationSaveOrderEntryOrderArgs = {
  order: OrderEntrySaveOrderInput;
};

export type MutationSendMaintenanceReportEmailArgs = {
  where: MaintenanceReportWhereUniqueInput;
};

export type MutationTranslateTemplateCodeArgs = {
  id: Scalars["Int"];
  sourceLanguageId: Scalars["Int"];
  targetLanguageIds: Array<InputMaybe<Scalars["Int"]>>;
};

export type MutationUpdateAppArgs = {
  data?: InputMaybe<AppAttributeUpdateInput>;
  where: UpdateAppWhere;
};

export type MutationUpdateContractArgs = {
  data: ContractUpdateInput;
  where: ContractWhereUniqueInput;
};

export type MutationUpdateCountryInformationArgs = {
  data: CountryInformationUpdateInput;
  where: CountryInformationWhereUniqueInput;
};

export type MutationUpdateEmailTemplateArgs = {
  data: EmailTemplateUpdateInput;
  where: EmailTemplateWhereUniqueInput;
};

export type MutationUpdateFutureMailsRecipientArgs = {
  email: Scalars["String"];
  orderId: Scalars["String"];
};

export type MutationUpdateGroupArgs = {
  data?: InputMaybe<GroupAttributeUpdateInput>;
  where: UpdateGroupWhere;
};

export type MutationUpdateGroupRoleArgs = {
  data: UpdateGroupRoleAttributeInput;
  where: UpdateGroupRoleWhere;
};

export type MutationUpdateIncentiveVoucherArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  incentive?: InputMaybe<Scalars["Int"]>;
  minOrderValue?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  unique?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateMaintenanceAppointmentArgs = {
  data: MaintenanceAppointmentUpdateInput;
  where: MaintenanceAppointmentWhereUniqueInput;
};

export type MutationUpdateMaintenanceGroupArgs = {
  data: MaintenanceGroupUpdateInput;
  where: MaintenanceGroupWhereUniqueInput;
};

export type MutationUpdateMaintenanceProductArgs = {
  data: MaintenanceProductUpdateInput;
  where: MaintenanceProductWhereUniqueInput;
};

export type MutationUpdateMaintenanceProductServiceArgs = {
  data: MaintenanceProductServiceUpdateInput;
  where: MaintenanceProductServiceWhereUniqueInput;
};

export type MutationUpdateMaintenanceReportArgs = {
  data: MaintenanceReportUpdateInput;
  where: MaintenanceReportWhereUniqueInput;
};

export type MutationUpdateMaintenanceReportServiceArgs = {
  data: MaintenanceReportServiceUpdateInput;
  where: MaintenanceReportServiceWhereUniqueInput;
};

export type MutationUpdateMaintenanceReportServiceSmallPartArgs = {
  data: MaintenanceReportServiceSmallPartUpdateInput;
  where: MaintenanceReportServiceSmallPartWhereUniqueInput;
};

export type MutationUpdateMaintenanceReportServiceSparePartArgs = {
  data: MaintenanceReportServiceSparePartUpdateInput;
  where: MaintenanceReportServiceSparePartWhereUniqueInput;
};

export type MutationUpdateMaintenanceReportSparePartBlueprintArgs = {
  data: MaintenanceReportSparePartBlueprintUpdateInput;
  where: MaintenanceReportSparePartBlueprintWhereUniqueInput;
};

export type MutationUpdateMaintenanceReportVariantArgs = {
  data: MaintenanceReportVariantUpdateInput;
  where: MaintenanceReportVariantWhereUniqueInput;
};

export type MutationUpdateMaintenanceResourceArgs = {
  data: MaintenanceResourceUpdateInput;
  where: MaintenanceResourceWhereUniqueInput;
};

export type MutationUpdateMaintenanceServiceSparePartArgs = {
  data: MaintenanceServiceSparePartUpdateInput;
  where: MaintenanceServiceSparePartWhereUniqueInput;
};

export type MutationUpdateMaintenanceTourStopArgs = {
  data: MaintenanceTourStopUpdateInput;
  where: MaintenanceTourStopWhereUniqueInput;
};

export type MutationUpdateMaintenanceTourStopAttachmentArgs = {
  data: MaintenanceTourStopAttachmentUpdateInput;
  where: MaintenanceTourStopAttachmentWhereUniqueInput;
};

export type MutationUpdateMaintenanceUserArgs = {
  data: MaintenanceUserUpdateInput;
  where: MaintenanceUserWhereUniqueInput;
};

export type MutationUpdateMaintenanceVariantArgs = {
  data: MaintenanceVariantUpdateInput;
  where: MaintenanceVariantWhereUniqueInput;
};

export type MutationUpdateMemberGroupArgs = {
  data?: InputMaybe<MemberGroupAttributeUpdateInput>;
  where: UpdateMemberGroupWhere;
};

export type MutationUpdateOcIcustomerArgs = {
  data: OciCustomerUpdateInput;
  where: OciCustomerWhereUniqueInput;
};

export type MutationUpdatePercentageVoucherArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  brandConstraints?: InputMaybe<Array<BrandConstraintInput>>;
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  materialGroupConstraints?: InputMaybe<Array<MaterialGroupConstraintInput>>;
  minOrderValue?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  navisionAccount?: InputMaybe<Scalars["Int"]>;
  percentage?: InputMaybe<Scalars["Int"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  unique?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdatePermissionArgs = {
  data: UpdatePermissionAttributeInput;
  where: UpdatePermissionWhere;
};

export type MutationUpdatePhotoModelArgs = {
  data: PhotoModelUpdateInput;
  where: PhotoModelWhereUniqueInput;
};

export type MutationUpdatePhotoModelContractArgs = {
  data: PhotoModelContractUpdateInput;
  where: PhotoModelContractWhereUniqueInput;
};

export type MutationUpdateProductFixedDiscountVoucherArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  minOrderValue?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  navisionAccount?: InputMaybe<Scalars["Int"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  unique?: InputMaybe<Scalars["Boolean"]>;
  validForModel?: InputMaybe<Scalars["Boolean"]>;
  validForVariantId?: InputMaybe<Scalars["Int"]>;
  validOnlyForOneVariant?: InputMaybe<Scalars["Boolean"]>;
  value?: InputMaybe<Scalars["Float"]>;
};

export type MutationUpdateProductPercentageDiscountVoucherArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  minOrderValue?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  navisionAccount?: InputMaybe<Scalars["Int"]>;
  percentage?: InputMaybe<Scalars["Int"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  unique?: InputMaybe<Scalars["Boolean"]>;
  validForModel?: InputMaybe<Scalars["Boolean"]>;
  validForVariantId?: InputMaybe<Scalars["Int"]>;
  validOnlyForOneVariant?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateResourceArgs = {
  data?: InputMaybe<ResourceUpdateInput>;
  products?: InputMaybe<
    Array<InputMaybe<ResourceProductCreateWithoutResourceInput>>
  >;
  resourcePdfCategoryId?: InputMaybe<Scalars["Int"]>;
  slugs?: InputMaybe<Array<InputMaybe<SlugCreateWithoutResourceInput>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  texts?: InputMaybe<Array<InputMaybe<ResourceTextCreate>>>;
  variants?: InputMaybe<
    Array<InputMaybe<ResourceVariantCreateWithoutResourceInput>>
  >;
  versions?: InputMaybe<
    Array<InputMaybe<ResourceVersionCreateWithoutResourceInput>>
  >;
  where: UpdateResourceWhere;
};

export type MutationUpdateResourceCollectionArgs = {
  id: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationUpdateResourceCollectionAccessGrantArgs = {
  id: Scalars["Int"];
  validUntil?: InputMaybe<Scalars["DateTime"]>;
};

export type MutationUpdateResourceLicenseArgs = {
  data: ResourceLicenseUpdateInput;
  where: ResourceLicenseWhereUniqueInput;
};

export type MutationUpdateResourceLicenseDefinitionArgs = {
  data: ResourceLicenseDefinitionUpdateInput;
  where: ResourceLicenseDefinitionWhereUniqueInput;
};

export type MutationUpdateResourcePhotoModelArgs = {
  data: ResourcePhotoModelUpdateInput;
  where: ResourcePhotoModelWhereUniqueInput;
};

export type MutationUpdateResourceTemplateArgs = {
  data: ResourceTemplateUpdateInput;
  where: ResourceTemplateWhereUniqueInput;
};

export type MutationUpdateResourceTextArgs = {
  data: ResourceTextUpdateInput;
  where: ResourceTextUpdateIdLang;
};

export type MutationUpdateResourceVersionArgs = {
  data: UpdateResourceVersionInput;
  where: UpdateResourceVersionWhereInput;
};

export type MutationUpdateRoleArgs = {
  data: UpdateRoleAttributeInput;
  where: UpdateRoleWhere;
};

export type MutationUpdateServiceArgs = {
  data?: InputMaybe<ServiceAttributeUpdateInput>;
  where: UpdateServiceWhere;
};

export type MutationUpdateShippingCostsVoucherArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  minOrderValue?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  navisionAccount?: InputMaybe<Scalars["Int"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  unique?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateTemplateArgs = {
  data: TemplateUpdateInput;
  where: TemplateWhereUniqueInput;
};

export type MutationUpdateTextArgs = {
  data?: InputMaybe<TextUpdateInput>;
  where?: InputMaybe<UpdateTextWhere>;
};

export type MutationUpdateTextAttributeArgs = {
  data?: InputMaybe<TextAttributeUpdateInput>;
  where: UpdateTextAttributeWhere;
};

export type MutationUpdateTypeCatalogArgs = {
  data?: InputMaybe<TypeCatalogAttributeUpdateInput>;
  where: UpdateTypeCatalogWhere;
};

export type MutationUpdateValueVoucherArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  brandConstraints?: InputMaybe<Array<BrandConstraintInput>>;
  category?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  minOrderValue?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  navisionAccount?: InputMaybe<Scalars["Int"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  unique?: InputMaybe<Scalars["Boolean"]>;
  value?: InputMaybe<Scalars["Float"]>;
};

export type MutationUpdateVariantMaterialArgs = {
  data: VariantMaterialUpdateInput;
  where: VariantMaterialWhereUniqueInput;
};

export type MutationUpdateVariantMaterialDefinitionArgs = {
  data: VariantMaterialDefinitionUpdateInput;
  where: VariantMaterialDefinitionWhereUniqueInput;
};

export type MutationUpdateVariantSupplierCarbonFootprintArgs = {
  method?: InputMaybe<SupplierProductCarbonFootprintMethod>;
  value?: InputMaybe<Scalars["Float"]>;
  variantId: Scalars["Int"];
};

export type MutationUpdateVoucherCodeArgs = {
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  voucherCodeId: Scalars["Int"];
};

export type MutationUpsertEmailTemplateCodeArgs = {
  bcc?: InputMaybe<Scalars["String"]>;
  cc?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  languageId: Scalars["Int"];
  replyTo?: InputMaybe<Scalars["String"]>;
  shopId: Scalars["Int"];
  subject?: InputMaybe<Scalars["String"]>;
  to?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EmailTemplateEntryType>;
};

export type MutationUpsertMaintenanceUserInformationArgs = {
  data: MaintenanceUserInformationUpsertDataInput;
  where: MaintenanceUserInformationUpsertWhereInput;
};

export type MutationUpsertMemberGroupArgs = {
  data?: InputMaybe<MemberGroupAttributeUpsertInput>;
  where: UpsertMemberGroupWhere;
};

export type MutationUpsertMemberGroupsArgs = {
  data?: InputMaybe<UpsertMemberGroupsData>;
  where: UpsertMemberGroupsWhere;
};

export type MutationUpsertOfficeVisitArgs = {
  data: UpsertOfficeVisitInput;
};

export type MutationUpsertPermissionArgs = {
  where: UpsertPermissionWhere;
};

export type MutationUpsertTemplateCodeArgs = {
  code: Scalars["String"];
  id: Scalars["Int"];
  isGzipped?: InputMaybe<Scalars["Boolean"]>;
  languageId: Scalars["Int"];
  shopId: Scalars["Int"];
};

export type MutationZipImagesArgs = {
  resources: Array<ImageZipResourceInput>;
};

export type MutationZipImagesConsumeArgs = {
  key: Scalars["String"];
};

export type MutationLog = {
  date?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  ip?: Maybe<Scalars["String"]>;
  /** This is the operation name in gql or the rest context + fieldName  */
  operation?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
};

export type MutationLogFilterInput = {
  email?: InputMaybe<Scalars["String"]>;
  ip?: InputMaybe<Scalars["String"]>;
  /** Search yourself with a mongodb search object. Keep in mind, that the graphql structure is not the mongodb document. */
  json?: InputMaybe<Scalars["String"]>;
  operation?: InputMaybe<Scalars["String"]>;
  range?: InputMaybe<FilterDateRange>;
  referrer?: InputMaybe<Scalars["String"]>;
};

export enum MutationLogOriginEnum {
  Graphql = "graphql",
  Rest = "rest",
}

export type NavisionData = {
  __typename?: "NavisionData";
  customerGroup?: Maybe<Scalars["String"]>;
  customerNumber?: Maybe<Scalars["String"]>;
  documents?: Maybe<Array<Maybe<NavisionDocument>>>;
  orderNumber?: Maybe<Scalars["String"]>;
  shipments?: Maybe<Array<Maybe<Shipment>>>;
};

export type NavisionDocument = {
  __typename?: "NavisionDocument";
  createDate?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  pdfDownloadUrl?: Maybe<Scalars["String"]>;
  pdfPreviewUrl?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  type?: Maybe<NavisionDocumentType>;
};

export enum NavisionDocumentType {
  CreditNote = "CREDIT_NOTE",
  DeliveryNote = "DELIVERY_NOTE",
  Invoice = "INVOICE",
  Offer = "OFFER",
}

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedEnumEmailTemplateEntryTypeFilter = {
  equals?: InputMaybe<EmailTemplateEntryType>;
  in?: InputMaybe<Array<EmailTemplateEntryType>>;
  not?: InputMaybe<NestedEnumEmailTemplateEntryTypeFilter>;
  notIn?: InputMaybe<Array<EmailTemplateEntryType>>;
};

export type NestedEnumMaintenanceGroupUserTypeFilter = {
  equals?: InputMaybe<MaintenanceGroupUserType>;
  in?: InputMaybe<Array<MaintenanceGroupUserType>>;
  not?: InputMaybe<NestedEnumMaintenanceGroupUserTypeFilter>;
  notIn?: InputMaybe<Array<MaintenanceGroupUserType>>;
};

export type NestedEnumMaintenanceReportServiceStatusFilter = {
  equals?: InputMaybe<MaintenanceReportServiceStatus>;
  in?: InputMaybe<Array<MaintenanceReportServiceStatus>>;
  not?: InputMaybe<NestedEnumMaintenanceReportServiceStatusFilter>;
  notIn?: InputMaybe<Array<MaintenanceReportServiceStatus>>;
};

export type NestedEnumMaintenanceReportTypeFilter = {
  equals?: InputMaybe<MaintenanceReportType>;
  in?: InputMaybe<Array<MaintenanceReportType>>;
  not?: InputMaybe<NestedEnumMaintenanceReportTypeFilter>;
  notIn?: InputMaybe<Array<MaintenanceReportType>>;
};

export type NestedEnumMaintenanceSecondRepairReasonFilter = {
  equals?: InputMaybe<MaintenanceSecondRepairReason>;
  in?: InputMaybe<Array<MaintenanceSecondRepairReason>>;
  not?: InputMaybe<NestedEnumMaintenanceSecondRepairReasonFilter>;
  notIn?: InputMaybe<Array<MaintenanceSecondRepairReason>>;
};

export type NestedEnumPermissionStatusFilter = {
  equals?: InputMaybe<PermissionStatus>;
  in?: InputMaybe<Array<PermissionStatus>>;
  not?: InputMaybe<NestedEnumPermissionStatusFilter>;
  notIn?: InputMaybe<Array<PermissionStatus>>;
};

export type NestedEnumProductIncentiveTypeFilter = {
  equals?: InputMaybe<ProductIncentiveType>;
  in?: InputMaybe<Array<ProductIncentiveType>>;
  not?: InputMaybe<NestedEnumProductIncentiveTypeFilter>;
  notIn?: InputMaybe<Array<ProductIncentiveType>>;
};

export type NestedEnumProductTypeNullableFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeNullableFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type NestedEnumReportServiceSparePartResourceTypeFilter = {
  equals?: InputMaybe<ReportServiceSparePartResourceType>;
  in?: InputMaybe<Array<ReportServiceSparePartResourceType>>;
  not?: InputMaybe<NestedEnumReportServiceSparePartResourceTypeFilter>;
  notIn?: InputMaybe<Array<ReportServiceSparePartResourceType>>;
};

export type NestedEnumResourceProductGroupTypeFilter = {
  equals?: InputMaybe<ResourceProductGroupType>;
  in?: InputMaybe<Array<ResourceProductGroupType>>;
  not?: InputMaybe<NestedEnumResourceProductGroupTypeFilter>;
  notIn?: InputMaybe<Array<ResourceProductGroupType>>;
};

export type NestedEnumResourceTextTypeFilter = {
  equals?: InputMaybe<ResourceTextType>;
  in?: InputMaybe<Array<ResourceTextType>>;
  not?: InputMaybe<NestedEnumResourceTextTypeFilter>;
  notIn?: InputMaybe<Array<ResourceTextType>>;
};

export type NestedEnumResourceVariantTypeNullableFilter = {
  equals?: InputMaybe<ResourceVariantType>;
  in?: InputMaybe<Array<ResourceVariantType>>;
  not?: InputMaybe<NestedEnumResourceVariantTypeNullableFilter>;
  notIn?: InputMaybe<Array<ResourceVariantType>>;
};

export type NestedEnumSupplierProductCarbonFootprintMethodNullableFilter = {
  equals?: InputMaybe<SupplierProductCarbonFootprintMethod>;
  in?: InputMaybe<Array<SupplierProductCarbonFootprintMethod>>;
  not?: InputMaybe<NestedEnumSupplierProductCarbonFootprintMethodNullableFilter>;
  notIn?: InputMaybe<Array<SupplierProductCarbonFootprintMethod>>;
};

export type NestedEnumTextAttributeTypeNullableFilter = {
  equals?: InputMaybe<TextAttributeType>;
  in?: InputMaybe<Array<TextAttributeType>>;
  not?: InputMaybe<NestedEnumTextAttributeTypeNullableFilter>;
  notIn?: InputMaybe<Array<TextAttributeType>>;
};

export type NestedEnumVariantMaterialDataQualityTypeFilter = {
  equals?: InputMaybe<VariantMaterialDataQualityType>;
  in?: InputMaybe<Array<VariantMaterialDataQualityType>>;
  not?: InputMaybe<NestedEnumVariantMaterialDataQualityTypeFilter>;
  notIn?: InputMaybe<Array<VariantMaterialDataQualityType>>;
};

export type NestedEnumVariantShippingTypeNullableFilter = {
  equals?: InputMaybe<VariantShippingType>;
  in?: InputMaybe<Array<VariantShippingType>>;
  not?: InputMaybe<NestedEnumVariantShippingTypeNullableFilter>;
  notIn?: InputMaybe<Array<VariantShippingType>>;
};

export type NestedEnumVariantTypeNullableFilter = {
  equals?: InputMaybe<VariantType>;
  in?: InputMaybe<Array<VariantType>>;
  not?: InputMaybe<NestedEnumVariantTypeNullableFilter>;
  notIn?: InputMaybe<Array<VariantType>>;
};

export type NestedEnumVoucherTypeFilter = {
  equals?: InputMaybe<VoucherType>;
  in?: InputMaybe<Array<VoucherType>>;
  not?: InputMaybe<NestedEnumVoucherTypeFilter>;
  notIn?: InputMaybe<Array<VoucherType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type NetAmountRange = {
  max?: InputMaybe<Scalars["Float"]>;
  min?: InputMaybe<Scalars["Float"]>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["DateTime"]>;
};

export type NullableEnumProductTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductType>;
};

export type NullableEnumResourceVariantTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ResourceVariantType>;
};

export type NullableEnumTextAttributeTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TextAttributeType>;
};

export type NullableEnumVariantShippingTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<VariantShippingType>;
};

export type NullableEnumVariantTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<VariantType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Float"]>;
  divide?: InputMaybe<Scalars["Float"]>;
  increment?: InputMaybe<Scalars["Float"]>;
  multiply?: InputMaybe<Scalars["Float"]>;
  set?: InputMaybe<Scalars["Float"]>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]>;
  divide?: InputMaybe<Scalars["Int"]>;
  increment?: InputMaybe<Scalars["Int"]>;
  multiply?: InputMaybe<Scalars["Int"]>;
  set?: InputMaybe<Scalars["Int"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]>;
};

export type OciCustomer = {
  __typename?: "OCICustomer";
  campaignDefinition?: Maybe<CampaignDefinition>;
  campaignDefinitionId?: Maybe<Scalars["Int"]>;
  cartMinValueSurcharge?: Maybe<Scalars["Float"]>;
  classification?: Maybe<Classification>;
  classificationId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  coreItemLabel?: Maybe<Scalars["Boolean"]>;
  customMinValueSurcharge?: Maybe<Scalars["Float"]>;
  deletedOn?: Maybe<Scalars["DateTime"]>;
  eclassShippingCosts?: Maybe<Scalars["String"]>;
  extraReference?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  includeMinValueSurcharge: Scalars["Boolean"];
  includeShippingCosts: Scalars["Boolean"];
  mainCatalog?: Maybe<Catalog>;
  mainCatalogId?: Maybe<Scalars["Int"]>;
  mainReference?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  prefix?: Maybe<Scalars["String"]>;
  shopId?: Maybe<Scalars["Int"]>;
  specialCatalog?: Maybe<Catalog>;
  specialCatalogId?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
  useNetPrices?: Maybe<Scalars["Boolean"]>;
  useReference?: Maybe<Scalars["Boolean"]>;
  useShopCampaigns?: Maybe<Scalars["Boolean"]>;
};

export type OciCustomerCreateInput = {
  ShopEligibleForOCI?: InputMaybe<ShopEligibleForOciCreateNestedOneWithoutCustomersInput>;
  campaignDefinition?: InputMaybe<CampaignDefinitionCreateNestedOneWithoutCustomersInput>;
  cartMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  classification?: InputMaybe<ClassificationCreateNestedOneWithoutOciCustomerInput>;
  comment?: InputMaybe<Scalars["String"]>;
  coreItemLabel?: InputMaybe<Scalars["Boolean"]>;
  customMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  deletedOn?: InputMaybe<Scalars["DateTime"]>;
  eclassShippingCosts?: InputMaybe<Scalars["String"]>;
  extraReference?: InputMaybe<Scalars["String"]>;
  includeMinValueSurcharge: Scalars["Boolean"];
  includeShippingCosts: Scalars["Boolean"];
  mainCatalog?: InputMaybe<CatalogCreateNestedOneWithoutMainCatalogInput>;
  mainReference?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  prefix?: InputMaybe<Scalars["String"]>;
  specialCatalog?: InputMaybe<CatalogCreateNestedOneWithoutSpecialCatalogInput>;
  urlIdentifier?: InputMaybe<Scalars["String"]>;
  useNetPrices?: InputMaybe<Scalars["Boolean"]>;
  useReference?: InputMaybe<Scalars["Boolean"]>;
  useShopCampaigns?: InputMaybe<Scalars["Boolean"]>;
};

export type OciCustomerCreateManyMainCatalogInput = {
  campaignDefinitionId?: InputMaybe<Scalars["Int"]>;
  cartMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  classificationId?: InputMaybe<Scalars["Int"]>;
  comment?: InputMaybe<Scalars["String"]>;
  coreItemLabel?: InputMaybe<Scalars["Boolean"]>;
  customMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  deletedOn?: InputMaybe<Scalars["DateTime"]>;
  eclassShippingCosts?: InputMaybe<Scalars["String"]>;
  extraReference?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  includeMinValueSurcharge: Scalars["Boolean"];
  includeShippingCosts: Scalars["Boolean"];
  mainReference?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  prefix?: InputMaybe<Scalars["String"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  specialCatalogId?: InputMaybe<Scalars["Int"]>;
  urlIdentifier?: InputMaybe<Scalars["String"]>;
  useNetPrices?: InputMaybe<Scalars["Boolean"]>;
  useReference?: InputMaybe<Scalars["Boolean"]>;
  useShopCampaigns?: InputMaybe<Scalars["Boolean"]>;
};

export type OciCustomerCreateManyMainCatalogInputEnvelope = {
  data?: InputMaybe<Array<OciCustomerCreateManyMainCatalogInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OciCustomerCreateManySpecialCatalogInput = {
  campaignDefinitionId?: InputMaybe<Scalars["Int"]>;
  cartMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  classificationId?: InputMaybe<Scalars["Int"]>;
  comment?: InputMaybe<Scalars["String"]>;
  coreItemLabel?: InputMaybe<Scalars["Boolean"]>;
  customMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  deletedOn?: InputMaybe<Scalars["DateTime"]>;
  eclassShippingCosts?: InputMaybe<Scalars["String"]>;
  extraReference?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  includeMinValueSurcharge: Scalars["Boolean"];
  includeShippingCosts: Scalars["Boolean"];
  mainCatalogId?: InputMaybe<Scalars["Int"]>;
  mainReference?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  prefix?: InputMaybe<Scalars["String"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  urlIdentifier?: InputMaybe<Scalars["String"]>;
  useNetPrices?: InputMaybe<Scalars["Boolean"]>;
  useReference?: InputMaybe<Scalars["Boolean"]>;
  useShopCampaigns?: InputMaybe<Scalars["Boolean"]>;
};

export type OciCustomerCreateManySpecialCatalogInputEnvelope = {
  data?: InputMaybe<Array<OciCustomerCreateManySpecialCatalogInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OciCustomerCreateNestedManyWithoutMainCatalogInput = {
  connect?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OciCustomerCreateOrConnectWithoutMainCatalogInput>
  >;
  create?: InputMaybe<Array<OciCustomerCreateWithoutMainCatalogInput>>;
  createMany?: InputMaybe<OciCustomerCreateManyMainCatalogInputEnvelope>;
};

export type OciCustomerCreateNestedManyWithoutSpecialCatalogInput = {
  connect?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OciCustomerCreateOrConnectWithoutSpecialCatalogInput>
  >;
  create?: InputMaybe<Array<OciCustomerCreateWithoutSpecialCatalogInput>>;
  createMany?: InputMaybe<OciCustomerCreateManySpecialCatalogInputEnvelope>;
};

export type OciCustomerCreateOrConnectWithoutMainCatalogInput = {
  create: OciCustomerCreateWithoutMainCatalogInput;
  where: OciCustomerWhereUniqueInput;
};

export type OciCustomerCreateOrConnectWithoutSpecialCatalogInput = {
  create: OciCustomerCreateWithoutSpecialCatalogInput;
  where: OciCustomerWhereUniqueInput;
};

export type OciCustomerCreateWithoutMainCatalogInput = {
  ShopEligibleForOCI?: InputMaybe<ShopEligibleForOciCreateNestedOneWithoutCustomersInput>;
  campaignDefinition?: InputMaybe<CampaignDefinitionCreateNestedOneWithoutCustomersInput>;
  cartMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  classification?: InputMaybe<ClassificationCreateNestedOneWithoutOciCustomerInput>;
  comment?: InputMaybe<Scalars["String"]>;
  coreItemLabel?: InputMaybe<Scalars["Boolean"]>;
  customMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  deletedOn?: InputMaybe<Scalars["DateTime"]>;
  eclassShippingCosts?: InputMaybe<Scalars["String"]>;
  extraReference?: InputMaybe<Scalars["String"]>;
  includeMinValueSurcharge: Scalars["Boolean"];
  includeShippingCosts: Scalars["Boolean"];
  mainReference?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  prefix?: InputMaybe<Scalars["String"]>;
  specialCatalog?: InputMaybe<CatalogCreateNestedOneWithoutSpecialCatalogInput>;
  urlIdentifier?: InputMaybe<Scalars["String"]>;
  useNetPrices?: InputMaybe<Scalars["Boolean"]>;
  useReference?: InputMaybe<Scalars["Boolean"]>;
  useShopCampaigns?: InputMaybe<Scalars["Boolean"]>;
};

export type OciCustomerCreateWithoutSpecialCatalogInput = {
  ShopEligibleForOCI?: InputMaybe<ShopEligibleForOciCreateNestedOneWithoutCustomersInput>;
  campaignDefinition?: InputMaybe<CampaignDefinitionCreateNestedOneWithoutCustomersInput>;
  cartMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  classification?: InputMaybe<ClassificationCreateNestedOneWithoutOciCustomerInput>;
  comment?: InputMaybe<Scalars["String"]>;
  coreItemLabel?: InputMaybe<Scalars["Boolean"]>;
  customMinValueSurcharge?: InputMaybe<Scalars["Float"]>;
  deletedOn?: InputMaybe<Scalars["DateTime"]>;
  eclassShippingCosts?: InputMaybe<Scalars["String"]>;
  extraReference?: InputMaybe<Scalars["String"]>;
  includeMinValueSurcharge: Scalars["Boolean"];
  includeShippingCosts: Scalars["Boolean"];
  mainCatalog?: InputMaybe<CatalogCreateNestedOneWithoutMainCatalogInput>;
  mainReference?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  prefix?: InputMaybe<Scalars["String"]>;
  urlIdentifier?: InputMaybe<Scalars["String"]>;
  useNetPrices?: InputMaybe<Scalars["Boolean"]>;
  useReference?: InputMaybe<Scalars["Boolean"]>;
  useShopCampaigns?: InputMaybe<Scalars["Boolean"]>;
};

export type OciCustomerListRelationFilter = {
  every?: InputMaybe<OciCustomerWhereInput>;
  none?: InputMaybe<OciCustomerWhereInput>;
  some?: InputMaybe<OciCustomerWhereInput>;
};

export type OciCustomerOrderByInput = {
  campaignDefinitionId?: InputMaybe<SortOrder>;
  cartMinValueSurcharge?: InputMaybe<SortOrder>;
  classificationId?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  coreItemLabel?: InputMaybe<SortOrder>;
  customMinValueSurcharge?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  eclassShippingCosts?: InputMaybe<SortOrder>;
  extraReference?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  includeMinValueSurcharge?: InputMaybe<SortOrder>;
  includeShippingCosts?: InputMaybe<SortOrder>;
  mainCatalogId?: InputMaybe<SortOrder>;
  mainReference?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  prefix?: InputMaybe<SortOrder>;
  shopId?: InputMaybe<SortOrder>;
  specialCatalogId?: InputMaybe<SortOrder>;
  urlIdentifier?: InputMaybe<SortOrder>;
  useNetPrices?: InputMaybe<SortOrder>;
  useReference?: InputMaybe<SortOrder>;
  useShopCampaigns?: InputMaybe<SortOrder>;
};

export type OciCustomerScalarWhereInput = {
  AND?: InputMaybe<Array<OciCustomerScalarWhereInput>>;
  NOT?: InputMaybe<Array<OciCustomerScalarWhereInput>>;
  OR?: InputMaybe<Array<OciCustomerScalarWhereInput>>;
  campaignDefinitionId?: InputMaybe<IntNullableFilter>;
  cartMinValueSurcharge?: InputMaybe<FloatNullableFilter>;
  classificationId?: InputMaybe<IntNullableFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  coreItemLabel?: InputMaybe<BoolNullableFilter>;
  customMinValueSurcharge?: InputMaybe<FloatNullableFilter>;
  deletedOn?: InputMaybe<DateTimeNullableFilter>;
  eclassShippingCosts?: InputMaybe<StringNullableFilter>;
  extraReference?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  includeMinValueSurcharge?: InputMaybe<BoolFilter>;
  includeShippingCosts?: InputMaybe<BoolFilter>;
  mainCatalogId?: InputMaybe<IntNullableFilter>;
  mainReference?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  prefix?: InputMaybe<StringNullableFilter>;
  shopId?: InputMaybe<IntNullableFilter>;
  specialCatalogId?: InputMaybe<IntNullableFilter>;
  urlIdentifier?: InputMaybe<StringNullableFilter>;
  useNetPrices?: InputMaybe<BoolNullableFilter>;
  useReference?: InputMaybe<BoolNullableFilter>;
  useShopCampaigns?: InputMaybe<BoolNullableFilter>;
};

export type OciCustomerUpdateInput = {
  ShopEligibleForOCI?: InputMaybe<ShopEligibleForOciUpdateOneWithoutCustomersInput>;
  campaignDefinition?: InputMaybe<CampaignDefinitionUpdateOneWithoutCustomersInput>;
  cartMinValueSurcharge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  classification?: InputMaybe<ClassificationUpdateOneWithoutOciCustomerInput>;
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coreItemLabel?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  customMinValueSurcharge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  deletedOn?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eclassShippingCosts?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  extraReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  includeMinValueSurcharge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  includeShippingCosts?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mainCatalog?: InputMaybe<CatalogUpdateOneWithoutMainCatalogInput>;
  mainReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prefix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  specialCatalog?: InputMaybe<CatalogUpdateOneWithoutSpecialCatalogInput>;
  urlIdentifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  useNetPrices?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  useReference?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  useShopCampaigns?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type OciCustomerUpdateManyMutationInput = {
  cartMinValueSurcharge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coreItemLabel?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  customMinValueSurcharge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  deletedOn?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eclassShippingCosts?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  extraReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  includeMinValueSurcharge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  includeShippingCosts?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mainReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prefix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  urlIdentifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  useNetPrices?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  useReference?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  useShopCampaigns?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type OciCustomerUpdateManyWithWhereWithoutMainCatalogInput = {
  data: OciCustomerUpdateManyMutationInput;
  where: OciCustomerScalarWhereInput;
};

export type OciCustomerUpdateManyWithWhereWithoutSpecialCatalogInput = {
  data: OciCustomerUpdateManyMutationInput;
  where: OciCustomerScalarWhereInput;
};

export type OciCustomerUpdateManyWithoutMainCatalogInput = {
  connect?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OciCustomerCreateOrConnectWithoutMainCatalogInput>
  >;
  create?: InputMaybe<Array<OciCustomerCreateWithoutMainCatalogInput>>;
  createMany?: InputMaybe<OciCustomerCreateManyMainCatalogInputEnvelope>;
  delete?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OciCustomerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  set?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  update?: InputMaybe<
    Array<OciCustomerUpdateWithWhereUniqueWithoutMainCatalogInput>
  >;
  updateMany?: InputMaybe<
    Array<OciCustomerUpdateManyWithWhereWithoutMainCatalogInput>
  >;
  upsert?: InputMaybe<
    Array<OciCustomerUpsertWithWhereUniqueWithoutMainCatalogInput>
  >;
};

export type OciCustomerUpdateManyWithoutSpecialCatalogInput = {
  connect?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OciCustomerCreateOrConnectWithoutSpecialCatalogInput>
  >;
  create?: InputMaybe<Array<OciCustomerCreateWithoutSpecialCatalogInput>>;
  createMany?: InputMaybe<OciCustomerCreateManySpecialCatalogInputEnvelope>;
  delete?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OciCustomerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  set?: InputMaybe<Array<OciCustomerWhereUniqueInput>>;
  update?: InputMaybe<
    Array<OciCustomerUpdateWithWhereUniqueWithoutSpecialCatalogInput>
  >;
  updateMany?: InputMaybe<
    Array<OciCustomerUpdateManyWithWhereWithoutSpecialCatalogInput>
  >;
  upsert?: InputMaybe<
    Array<OciCustomerUpsertWithWhereUniqueWithoutSpecialCatalogInput>
  >;
};

export type OciCustomerUpdateWithWhereUniqueWithoutMainCatalogInput = {
  data: OciCustomerUpdateWithoutMainCatalogInput;
  where: OciCustomerWhereUniqueInput;
};

export type OciCustomerUpdateWithWhereUniqueWithoutSpecialCatalogInput = {
  data: OciCustomerUpdateWithoutSpecialCatalogInput;
  where: OciCustomerWhereUniqueInput;
};

export type OciCustomerUpdateWithoutMainCatalogInput = {
  ShopEligibleForOCI?: InputMaybe<ShopEligibleForOciUpdateOneWithoutCustomersInput>;
  campaignDefinition?: InputMaybe<CampaignDefinitionUpdateOneWithoutCustomersInput>;
  cartMinValueSurcharge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  classification?: InputMaybe<ClassificationUpdateOneWithoutOciCustomerInput>;
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coreItemLabel?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  customMinValueSurcharge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  deletedOn?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eclassShippingCosts?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  extraReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  includeMinValueSurcharge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  includeShippingCosts?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mainReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prefix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  specialCatalog?: InputMaybe<CatalogUpdateOneWithoutSpecialCatalogInput>;
  urlIdentifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  useNetPrices?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  useReference?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  useShopCampaigns?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type OciCustomerUpdateWithoutSpecialCatalogInput = {
  ShopEligibleForOCI?: InputMaybe<ShopEligibleForOciUpdateOneWithoutCustomersInput>;
  campaignDefinition?: InputMaybe<CampaignDefinitionUpdateOneWithoutCustomersInput>;
  cartMinValueSurcharge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  classification?: InputMaybe<ClassificationUpdateOneWithoutOciCustomerInput>;
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coreItemLabel?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  customMinValueSurcharge?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  deletedOn?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eclassShippingCosts?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  extraReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  includeMinValueSurcharge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  includeShippingCosts?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mainCatalog?: InputMaybe<CatalogUpdateOneWithoutMainCatalogInput>;
  mainReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prefix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  urlIdentifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  useNetPrices?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  useReference?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  useShopCampaigns?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type OciCustomerUpsertWithWhereUniqueWithoutMainCatalogInput = {
  create: OciCustomerCreateWithoutMainCatalogInput;
  update: OciCustomerUpdateWithoutMainCatalogInput;
  where: OciCustomerWhereUniqueInput;
};

export type OciCustomerUpsertWithWhereUniqueWithoutSpecialCatalogInput = {
  create: OciCustomerCreateWithoutSpecialCatalogInput;
  update: OciCustomerUpdateWithoutSpecialCatalogInput;
  where: OciCustomerWhereUniqueInput;
};

export type OciCustomerWhereInput = {
  AND?: InputMaybe<Array<OciCustomerWhereInput>>;
  NOT?: InputMaybe<Array<OciCustomerWhereInput>>;
  OR?: InputMaybe<Array<OciCustomerWhereInput>>;
  ShopEligibleForOCI?: InputMaybe<ShopEligibleForOciWhereInput>;
  campaignDefinition?: InputMaybe<CampaignDefinitionWhereInput>;
  campaignDefinitionId?: InputMaybe<IntNullableFilter>;
  cartMinValueSurcharge?: InputMaybe<FloatNullableFilter>;
  classification?: InputMaybe<ClassificationWhereInput>;
  classificationId?: InputMaybe<IntNullableFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  coreItemLabel?: InputMaybe<BoolNullableFilter>;
  customMinValueSurcharge?: InputMaybe<FloatNullableFilter>;
  deletedOn?: InputMaybe<DateTimeNullableFilter>;
  eclassShippingCosts?: InputMaybe<StringNullableFilter>;
  extraReference?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  includeMinValueSurcharge?: InputMaybe<BoolFilter>;
  includeShippingCosts?: InputMaybe<BoolFilter>;
  mainCatalog?: InputMaybe<CatalogWhereInput>;
  mainCatalogId?: InputMaybe<IntNullableFilter>;
  mainReference?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  prefix?: InputMaybe<StringNullableFilter>;
  shopId?: InputMaybe<IntNullableFilter>;
  specialCatalog?: InputMaybe<CatalogWhereInput>;
  specialCatalogId?: InputMaybe<IntNullableFilter>;
  urlIdentifier?: InputMaybe<StringNullableFilter>;
  useNetPrices?: InputMaybe<BoolNullableFilter>;
  useReference?: InputMaybe<BoolNullableFilter>;
  useShopCampaigns?: InputMaybe<BoolNullableFilter>;
};

export type OciCustomerWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type OfficeLocation = {
  __typename?: "OfficeLocation";
  id: Scalars["Int"];
  location: Scalars["String"];
};

export type OfficeLocationOrderByInput = {
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
};

export type OfficeLocationWhereInput = {
  AND?: InputMaybe<Array<OfficeLocationWhereInput>>;
  NOT?: InputMaybe<Array<OfficeLocationWhereInput>>;
  OR?: InputMaybe<Array<OfficeLocationWhereInput>>;
  id?: InputMaybe<IntFilter>;
  location?: InputMaybe<StringFilter>;
  officeVisits?: InputMaybe<OfficeVisitListRelationFilter>;
};

export type OfficeLocationWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
};

export type OfficeVisit = {
  __typename?: "OfficeVisit";
  date: Scalars["DateTime"];
  email: Scalars["String"];
  officeLocation: OfficeLocation;
  userImage?: Maybe<Scalars["String"]>;
};

export type OfficeVisitUserImageArgs = {
  size?: InputMaybe<UserImageSize>;
};

export type OfficeVisitDateEmailLocationIdCompoundUniqueInput = {
  date: Scalars["DateTime"];
  email: Scalars["String"];
  locationId: Scalars["Int"];
};

export type OfficeVisitListRelationFilter = {
  every?: InputMaybe<OfficeVisitWhereInput>;
  none?: InputMaybe<OfficeVisitWhereInput>;
  some?: InputMaybe<OfficeVisitWhereInput>;
};

export type OfficeVisitOrderByInput = {
  date?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
};

export type OfficeVisitWhereInput = {
  AND?: InputMaybe<Array<OfficeVisitWhereInput>>;
  NOT?: InputMaybe<Array<OfficeVisitWhereInput>>;
  OR?: InputMaybe<Array<OfficeVisitWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<IntFilter>;
  officeLocation?: InputMaybe<OfficeLocationWhereInput>;
};

export type OfficeVisitWhereUniqueInput = {
  date_email_locationId?: InputMaybe<OfficeVisitDateEmailLocationIdCompoundUniqueInput>;
};

export type OnePermissionsInput = {
  status: PermissionStatus;
  typeCatalogId: Scalars["Int"];
  /** Upsert all permissions that are related by type. [default: TRUE] */
  upsertRelatedPermissions?: InputMaybe<Scalars["Boolean"]>;
};

export type Order = {
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type OrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type OrderDateRange = {
  max?: InputMaybe<Scalars["String"]>;
  min?: InputMaybe<Scalars["String"]>;
};

export type OrderEntryAddress = {
  __typename?: "OrderEntryAddress";
  addressAddition?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  connections?: Maybe<Array<OrderEntryAddressConnection>>;
  country?: Maybe<Scalars["String"]>;
  customerNumber?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  employees?: Maybe<Array<OrderEntryAddressConnection>>;
  fax?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  salutation?: Maybe<OrderEntrySalutationEnum>;
  street?: Maybe<Scalars["String"]>;
  title?: Maybe<OrderEntryTitleEnum>;
  useNettoPrices?: Maybe<Scalars["Boolean"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type OrderEntryAddressConnection = {
  __typename?: "OrderEntryAddressConnection";
  address?: Maybe<OrderEntryAddress>;
  type?: Maybe<Scalars["String"]>;
};

export enum OrderEntryCrmSearchEntityTypeEnum {
  Account = "ACCOUNT",
  Contact = "CONTACT",
}

export type OrderEntryCrmSearchHighlight = {
  __typename?: "OrderEntryCrmSearchHighlight";
  crmField?: Maybe<Scalars["String"]>;
  field?: Maybe<Scalars["String"]>;
  highlight?: Maybe<Scalars["String"]>;
};

export type OrderEntryCrmSearchResult = {
  __typename?: "OrderEntryCrmSearchResult";
  accountId?: Maybe<Scalars["String"]>;
  address?: Maybe<OrderEntryAddress>;
  company?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  entityType?: Maybe<OrderEntryCrmSearchEntityTypeEnum>;
  fullName?: Maybe<Scalars["String"]>;
  highlight?: Maybe<Scalars["String"]>;
  highlights?: Maybe<Array<Maybe<OrderEntryCrmSearchHighlight>>>;
};

export type OrderEntryDeliveryAddressInput = {
  addressAddition?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  company?: InputMaybe<Scalars["String"]>;
  country: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  salutation?: InputMaybe<OrderEntrySalutationEnum>;
  street?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<OrderEntryTitleEnum>;
  zip: Scalars["String"];
};

export enum OrderEntryDeliveryDateTypeEnum {
  DeliveryFrom = "DeliveryFrom",
  DeliveryOn = "DeliveryOn",
  DeliveryUntil = "DeliveryUntil",
}

export type OrderEntryEmailAddressInput = {
  deliveryDelayEmail?: InputMaybe<Scalars["String"]>;
  navisionDocumentEmail?: InputMaybe<Scalars["String"]>;
  orderConfirmationEmail?: InputMaybe<Scalars["String"]>;
  shipmentTrackingEmail?: InputMaybe<Scalars["String"]>;
};

export type OrderEntryInvoiceAddressInput = {
  addressAddition?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  company?: InputMaybe<Scalars["String"]>;
  country: Scalars["String"];
  customerNumber?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  fax?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  salutation?: InputMaybe<OrderEntrySalutationEnum>;
  street?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<OrderEntryTitleEnum>;
  vatNumber?: InputMaybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export enum OrderEntryLanguageCodeEnum {
  Be = "BE",
  De = "DE",
  En = "EN",
  Fr = "FR",
  It = "IT",
  Nl = "NL",
  No = "NO",
  Se = "SE",
}

export type OrderEntryNominalAccount = {
  __typename?: "OrderEntryNominalAccount";
  account: Scalars["String"];
  id?: Maybe<Scalars["ID"]>;
  name: Scalars["String"];
  quantity: Scalars["Float"];
  totalPriceGross: Scalars["Float"];
  unitPriceGross: Scalars["Float"];
  unitPriceNet: Scalars["Float"];
  unitPriceTax: Scalars["Float"];
};

export type OrderEntryOrder = Order & {
  __typename?: "OrderEntryOrder";
  billingAddress?: Maybe<Address>;
  createdBy?: Maybe<Scalars["String"]>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  paymentType?: Maybe<ShopPaymentType>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  remark?: Maybe<Scalars["String"]>;
  salesVouchers?: Maybe<Array<Maybe<SalesVoucher>>>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type OrderEntryOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export enum OrderEntryPaymentCodeEnum {
  AdvancePayment = "AdvancePayment",
  CashOnDelivery = "CashOnDelivery",
  CreditCard = "CreditCard",
  CreditCardCaptured = "CreditCardCaptured",
  DirectDebit = "DirectDebit",
  InternetPortalInvoice = "InternetPortalInvoice",
  Invoice = "Invoice",
  KlarnaInvoice = "KlarnaInvoice",
  PayPal = "PayPal",
  PayPalPrepayment = "PayPalPrepayment",
  Sofort = "Sofort",
}

export type OrderEntryPosition = {
  __typename?: "OrderEntryPosition";
  availableStock: Scalars["Int"];
  brandId?: Maybe<Scalars["Int"]>;
  catalogId: Scalars["Int"];
  catalogName: Scalars["String"];
  deliveryEstimations?: Maybe<Array<Maybe<DeliveryEstimation>>>;
  dispoStatus?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  isDropShipment: Scalars["Boolean"];
  lowerMaterialGroup?: Maybe<Scalars["String"]>;
  mainMaterialGroup?: Maybe<Scalars["String"]>;
  marketingChannel?: Maybe<Scalars["String"]>;
  materialGroup?: Maybe<Scalars["String"]>;
  productId: Scalars["Int"];
  productName: Scalars["String"];
  quantity: Scalars["Float"];
  reference: Scalars["String"];
  shippingType?: Maybe<OrderEntryPositionShippingTypeEnum>;
  totalPriceGross: Scalars["Float"];
  totalPriceNet: Scalars["Float"];
  unit: Scalars["String"];
  unitPriceGross: Scalars["Float"];
  unitPriceNet: Scalars["Float"];
  unitPriceTax: Scalars["Float"];
  variantId: Scalars["Int"];
  variantNumber: Scalars["String"];
};

export type OrderEntryPositionError = {
  __typename?: "OrderEntryPositionError";
  id?: Maybe<Scalars["ID"]>;
  message?: Maybe<Scalars["String"]>;
};

export type OrderEntryPositionInput = {
  id: Scalars["ID"];
  quantity: Scalars["Float"];
  variantNumberWithReferenceAndChannel: Scalars["String"];
};

export type OrderEntryPositionOrError =
  | OrderEntryPosition
  | OrderEntryPositionError;

export enum OrderEntryPositionShippingTypeEnum {
  Freight = "Freight",
  Parcel = "Parcel",
}

export type OrderEntryProductEstimationInput = {
  allInOneDelivery: Scalars["Boolean"];
  forceFreeShipping: Scalars["Boolean"];
  forceWithoutMinQuantitySurcharge: Scalars["Boolean"];
  invoiceAddressCountryCode: Scalars["String"];
  languageCode?: InputMaybe<Scalars["String"]>;
  products: Array<OrderEntryPositionInput>;
  shippingAddressCountryCode: Scalars["String"];
  shippingDate?: InputMaybe<Scalars["String"]>;
  shopId: Scalars["Int"];
  useNettoPrices: Scalars["Boolean"];
  vouchers?: InputMaybe<Array<Scalars["String"]>>;
};

export enum OrderEntrySalutationEnum {
  DamenUndHerren = "Damen_und_Herren",
  Familie = "Familie",
  Firma = "Firma",
  Frau = "Frau",
  FrauHerrn = "Frau_Herrn",
  Herr = "Herr",
  Herren = "Herren",
  HerrnUndFrau = "Herrn_und_Frau",
  Inhaber = "Inhaber",
}

export type OrderEntrySaveOrderInput = {
  allInOneDelivery: Scalars["Boolean"];
  customerReference?: InputMaybe<Scalars["String"]>;
  deliveryDate?: InputMaybe<Scalars["String"]>;
  deliveryDateType?: InputMaybe<OrderEntryDeliveryDateTypeEnum>;
  deliveryNotice?: InputMaybe<Scalars["String"]>;
  deliveryPhoneNumber?: InputMaybe<Scalars["String"]>;
  emailAddresses?: InputMaybe<OrderEntryEmailAddressInput>;
  forceFreeShipping: Scalars["Boolean"];
  forceWithoutMinQuantitySurcharge: Scalars["Boolean"];
  invoiceAddress: OrderEntryInvoiceAddressInput;
  languageCode: OrderEntryLanguageCodeEnum;
  marketingChannel?: InputMaybe<Scalars["String"]>;
  orderRemark?: InputMaybe<Scalars["String"]>;
  paymentCode: OrderEntryPaymentCodeEnum;
  products: Array<OrderEntryPositionInput>;
  shippingAddress?: InputMaybe<OrderEntryDeliveryAddressInput>;
  shopId: Scalars["Int"];
  useNettoPrices: Scalars["Boolean"];
  vouchers?: InputMaybe<Array<Scalars["String"]>>;
};

export enum OrderEntryTitleEnum {
  Dipl = "Dipl",
  DiplIng = "Dipl_Ing",
  DiplKfm = "Dipl_Kfm",
  Dr = "Dr",
  DrMed = "Dr_Med",
  Prof = "Prof",
  ProfDr = "Prof_Dr",
}

export type OrderEntryVoucher = {
  __typename?: "OrderEntryVoucher";
  account?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["ID"]>;
  incentiveVariantNumber?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  type: OrderEntryVoucherType;
  value: Scalars["Float"];
};

export type OrderEntryVoucherOrError =
  | OrderEntryPositionError
  | OrderEntryVoucher;

export enum OrderEntryVoucherType {
  DiscountCode = "DiscountCode",
  GiftCard = "GiftCard",
}

export type OrderEstimation = {
  __typename?: "OrderEstimation";
  currencyCode: Scalars["String"];
  products?: Maybe<Array<Maybe<OrderEntryPositionOrError>>>;
  shippingAndSurcharges?: Maybe<Array<Maybe<OrderEntryNominalAccount>>>;
  totalPriceGross: Scalars["Float"];
  totalPriceNet: Scalars["Float"];
  vouchers?: Maybe<Array<Maybe<OrderEntryVoucherOrError>>>;
};

export type OrderLineEstimation = {
  __typename?: "OrderLineEstimation";
  bestDeliveryDate: Scalars["String"];
  deliveryEstimationUpdates?: Maybe<Array<Maybe<DeliveryEstimationUpdate>>>;
  departureDate: Scalars["String"];
  isDesiredDeliveryDate?: Maybe<Scalars["Boolean"]>;
  quantity: Scalars["Float"];
  worstDeliveryDate: Scalars["String"];
};

export type OrderPosition = {
  __typename?: "OrderPosition";
  allDeliveryEstimationUpdates?: Maybe<Array<Maybe<DeliveryEstimationUpdate>>>;
  deliveryEstimations?: Maybe<Array<Maybe<OrderLineEstimation>>>;
  isDropshipping?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  productNumber: Scalars["String"];
  quantity: Scalars["Float"];
  reference?: Maybe<Scalars["String"]>;
  returnedQuantity?: Maybe<Scalars["Float"]>;
  shippedQuantity?: Maybe<Scalars["Float"]>;
  type?: Maybe<OrderPositionType>;
  unitPrice?: Maybe<Scalars["Float"]>;
  variantId?: Maybe<Scalars["Float"]>;
};

export enum OrderPositionType {
  Account = "ACCOUNT",
  Incentive = "INCENTIVE",
  Product = "PRODUCT",
  Voucher = "VOUCHER",
}

export enum OrderStatus {
  Address = "ADDRESS",
  Cart = "CART",
  Check = "CHECK",
  Confirmation = "CONFIRMATION",
  InquirySent = "INQUIRY_SENT",
  Payment = "PAYMENT",
  Returned = "RETURNED",
  Shipped = "SHIPPED",
}

export type OrdersExportResult = {
  __typename?: "OrdersExportResult";
  orderCount?: Maybe<Scalars["Int"]>;
  success?: Maybe<Scalars["Boolean"]>;
  url?: Maybe<Scalars["String"]>;
};

export type OrdersFilter = {
  address?: InputMaybe<AddressInput>;
  campaign?: InputMaybe<Scalars["String"]>;
  couponCode?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["String"]>;
  customerGroup?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  hasRemark?: InputMaybe<Scalars["Boolean"]>;
  hasSalesVoucher?: InputMaybe<Scalars["Boolean"]>;
  ip?: InputMaybe<Scalars["String"]>;
  itemCount?: InputMaybe<Scalars["Int"]>;
  itemCountRange?: InputMaybe<ItemCountRange>;
  itemNo?: InputMaybe<Scalars["String"]>;
  marketingChannel?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navisionCustomerNo?: InputMaybe<Scalars["String"]>;
  netAmountRange?: InputMaybe<NetAmountRange>;
  orderDate?: InputMaybe<Scalars["String"]>;
  orderDateRange?: InputMaybe<OrderDateRange>;
  orderId?: InputMaybe<Scalars["String"]>;
  paymentMethod?: InputMaybe<Array<InputMaybe<ShopPaymentType>>>;
  queryString?: InputMaybe<Scalars["String"]>;
  shippingType?: InputMaybe<Array<InputMaybe<ShippingType>>>;
  smallQuantitySurcharge?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Array<InputMaybe<PrincipalSource>>>;
  status?: InputMaybe<Array<InputMaybe<OrderStatus>>>;
  testOrder?: InputMaybe<Scalars["Boolean"]>;
};

export type OrdersList = {
  __typename?: "OrdersList";
  orders?: Maybe<Array<Maybe<Order>>>;
  resultDateRange?: Maybe<ResultDateRange>;
  resultTotals?: Maybe<ResultTotals>;
  totalProductSum?: Maybe<Array<Maybe<GrossNetAmount>>>;
  totalShippingSum?: Maybe<Array<Maybe<GrossNetAmount>>>;
  totalSum?: Maybe<Array<Maybe<GrossNetAmount>>>;
};

export type OrdersOrderBy = {
  ip?: InputMaybe<OrdersOrderDirection>;
  itemCount?: InputMaybe<OrdersOrderDirection>;
  orderDate?: InputMaybe<OrdersOrderDirection>;
  paymentType?: InputMaybe<OrdersOrderDirection>;
  shop?: InputMaybe<OrdersOrderDirection>;
  status?: InputMaybe<OrdersOrderDirection>;
  totalNetAmount?: InputMaybe<OrdersOrderDirection>;
};

export enum OrdersOrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export type OttoOrder = Order & {
  __typename?: "OttoOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type OttoOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type PaginationVoucher = {
  __typename?: "PaginationVoucher";
  page?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type ParcelLabMail = {
  __typename?: "ParcelLabMail";
  body?: Maybe<Scalars["String"]>;
  emailId?: Maybe<Scalars["ID"]>;
  from?: Maybe<Scalars["String"]>;
  sendingDate?: Maybe<Scalars["String"]>;
  status?: Maybe<ShopMailStatus>;
  subject?: Maybe<Scalars["String"]>;
  to?: Maybe<Scalars["String"]>;
  /** @deprecated only used to determine email type */
  type?: Maybe<Scalars["String"]>;
};

export type PaypalPaymentData = {
  __typename?: "PaypalPaymentData";
  email?: Maybe<Scalars["String"]>;
  payerId?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["String"]>;
};

export enum PdfType {
  ImagesForCustomer = "imagesForCustomer",
  Offer = "offer",
  SmallPartList = "smallPartList",
}

export type PercentageVoucher = Voucher & {
  __typename?: "PercentageVoucher";
  active?: Maybe<Scalars["Boolean"]>;
  brandConstraints?: Maybe<Array<BrandEligibleForVoucher>>;
  category?: Maybe<Scalars["String"]>;
  codes?: Maybe<VoucherCodePagination>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  materialGroupConstraints?: Maybe<Array<Maybe<MaterialGroupConstraint>>>;
  minOrderValue?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  navisionAccount?: Maybe<Scalars["Int"]>;
  percentage?: Maybe<Scalars["Float"]>;
  shopId?: Maybe<Scalars["Int"]>;
  statistics?: Maybe<VoucherStatistic>;
  /** Total number of times this voucher has been redeemed in an confirmed order. */
  totalRedeemCount?: Maybe<Scalars["Int"]>;
  /** Total number of redeemed codes. Please keep in mind that this number might seem wrong if codes can redeem multiple times. This query uses the boolean on voucher code. */
  totalRedeemdedCodes?: Maybe<Scalars["Int"]>;
  unique?: Maybe<Scalars["Boolean"]>;
};

export type PercentageVoucherCodesArgs = {
  code?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  expired?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  redeemed?: InputMaybe<Scalars["Boolean"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VoucherCodeWhereInput>;
};

export type PercentageVoucherStatisticsArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type Permission = {
  __typename?: "Permission";
  role: Role;
  roleId: Scalars["Int"];
  status: PermissionStatus;
  typeCatalog: TypeCatalog;
  typeCatalogId: Scalars["Int"];
};

export type PermissionId = {
  roleId: Scalars["Int"];
  typeCatalogId: Scalars["Int"];
};

export type PermissionListRelationFilter = {
  every?: InputMaybe<PermissionWhereInput>;
  none?: InputMaybe<PermissionWhereInput>;
  some?: InputMaybe<PermissionWhereInput>;
};

export type PermissionOrderByInput = {
  roleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  typeCatalogId?: InputMaybe<SortOrder>;
};

export type PermissionRoleIdTypeCatalogIdCompoundUniqueInput = {
  roleId: Scalars["Int"];
  typeCatalogId: Scalars["Int"];
};

export enum PermissionStatus {
  Allow = "ALLOW",
  Deny = "DENY",
}

export type PermissionUserWhereInput = {
  AND?: InputMaybe<Array<PermissionUserWhereInput>>;
  MemberGroup?: InputMaybe<MemberGroupListRelationFilter>;
  NOT?: InputMaybe<Array<PermissionUserWhereInput>>;
  OR?: InputMaybe<Array<PermissionUserWhereInput>>;
  azureId?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
};

export type PermissionWhereInput = {
  AND?: InputMaybe<Array<PermissionWhereInput>>;
  NOT?: InputMaybe<Array<PermissionWhereInput>>;
  OR?: InputMaybe<Array<PermissionWhereInput>>;
  role?: InputMaybe<RoleWhereInput>;
  roleId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumPermissionStatusFilter>;
  typeCatalog?: InputMaybe<TypeCatalogWhereInput>;
  typeCatalogId?: InputMaybe<IntFilter>;
};

export type PermissionWhereUniqueInput = {
  roleId_typeCatalogId?: InputMaybe<PermissionRoleIdTypeCatalogIdCompoundUniqueInput>;
};

export type PhotoModel = {
  __typename?: "PhotoModel";
  description?: Maybe<Scalars["String"]>;
  firstname: Scalars["String"];
  id: Scalars["Int"];
  lastname: Scalars["String"];
  photoModelContracts: Array<PhotoModelContract>;
  resourcePhotoModels: Array<ResourcePhotoModel>;
};

export type PhotoModelPhotoModelContractsArgs = {
  after?: InputMaybe<PhotoModelContractWhereUniqueInput>;
  before?: InputMaybe<PhotoModelContractWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PhotoModelResourcePhotoModelsArgs = {
  after?: InputMaybe<ResourcePhotoModelWhereUniqueInput>;
  before?: InputMaybe<ResourcePhotoModelWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PhotoModelContract = {
  __typename?: "PhotoModelContract";
  contract: Contract;
  contractId: Scalars["Int"];
  id: Scalars["Int"];
  model: PhotoModel;
  modelId: Scalars["Int"];
};

export type PhotoModelContractCreateInput = {
  contract: ContractCreateNestedOneWithoutPhotoModelContractsInput;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  model: PhotoModelCreateNestedOneWithoutPhotoModelContractsInput;
};

export type PhotoModelContractCreateManyContractInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
  modelId: Scalars["Int"];
};

export type PhotoModelContractCreateManyContractInputEnvelope = {
  data?: InputMaybe<Array<PhotoModelContractCreateManyContractInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type PhotoModelContractCreateManyModelInput = {
  contractId: Scalars["Int"];
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

export type PhotoModelContractCreateManyModelInputEnvelope = {
  data?: InputMaybe<Array<PhotoModelContractCreateManyModelInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type PhotoModelContractCreateNestedManyWithoutContractInput = {
  connect?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PhotoModelContractCreateOrConnectWithoutContractInput>
  >;
  create?: InputMaybe<Array<PhotoModelContractCreateWithoutContractInput>>;
  createMany?: InputMaybe<PhotoModelContractCreateManyContractInputEnvelope>;
};

export type PhotoModelContractCreateNestedManyWithoutModelInput = {
  connect?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PhotoModelContractCreateOrConnectWithoutModelInput>
  >;
  create?: InputMaybe<Array<PhotoModelContractCreateWithoutModelInput>>;
  createMany?: InputMaybe<PhotoModelContractCreateManyModelInputEnvelope>;
};

export type PhotoModelContractCreateOrConnectWithoutContractInput = {
  create: PhotoModelContractCreateWithoutContractInput;
  where: PhotoModelContractWhereUniqueInput;
};

export type PhotoModelContractCreateOrConnectWithoutModelInput = {
  create: PhotoModelContractCreateWithoutModelInput;
  where: PhotoModelContractWhereUniqueInput;
};

export type PhotoModelContractCreateWithoutContractInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  model: PhotoModelCreateNestedOneWithoutPhotoModelContractsInput;
};

export type PhotoModelContractCreateWithoutModelInput = {
  contract: ContractCreateNestedOneWithoutPhotoModelContractsInput;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PhotoModelContractListRelationFilter = {
  every?: InputMaybe<PhotoModelContractWhereInput>;
  none?: InputMaybe<PhotoModelContractWhereInput>;
  some?: InputMaybe<PhotoModelContractWhereInput>;
};

export type PhotoModelContractOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type PhotoModelContractOrderByWithRelationInput = {
  contract?: InputMaybe<ContractOrderByWithRelationInput>;
  contractId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model?: InputMaybe<PhotoModelOrderByWithRelationInput>;
  modelId?: InputMaybe<SortOrder>;
};

export type PhotoModelContractPhotoModelContractUniqueCompoundUniqueInput = {
  contractId: Scalars["Int"];
  modelId: Scalars["Int"];
};

export type PhotoModelContractScalarWhereInput = {
  AND?: InputMaybe<Array<PhotoModelContractScalarWhereInput>>;
  NOT?: InputMaybe<Array<PhotoModelContractScalarWhereInput>>;
  OR?: InputMaybe<Array<PhotoModelContractScalarWhereInput>>;
  contractId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  modelId?: InputMaybe<IntFilter>;
};

export type PhotoModelContractUpdateInput = {
  contract?: InputMaybe<ContractUpdateOneRequiredWithoutPhotoModelContractsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<PhotoModelUpdateOneRequiredWithoutPhotoModelContractsInput>;
};

export type PhotoModelContractUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PhotoModelContractUpdateManyWithWhereWithoutContractInput = {
  data: PhotoModelContractUpdateManyMutationInput;
  where: PhotoModelContractScalarWhereInput;
};

export type PhotoModelContractUpdateManyWithWhereWithoutModelInput = {
  data: PhotoModelContractUpdateManyMutationInput;
  where: PhotoModelContractScalarWhereInput;
};

export type PhotoModelContractUpdateManyWithoutContractInput = {
  connect?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PhotoModelContractCreateOrConnectWithoutContractInput>
  >;
  create?: InputMaybe<Array<PhotoModelContractCreateWithoutContractInput>>;
  createMany?: InputMaybe<PhotoModelContractCreateManyContractInputEnvelope>;
  delete?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PhotoModelContractScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  set?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PhotoModelContractUpdateWithWhereUniqueWithoutContractInput>
  >;
  updateMany?: InputMaybe<
    Array<PhotoModelContractUpdateManyWithWhereWithoutContractInput>
  >;
  upsert?: InputMaybe<
    Array<PhotoModelContractUpsertWithWhereUniqueWithoutContractInput>
  >;
};

export type PhotoModelContractUpdateManyWithoutModelInput = {
  connect?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PhotoModelContractCreateOrConnectWithoutModelInput>
  >;
  create?: InputMaybe<Array<PhotoModelContractCreateWithoutModelInput>>;
  createMany?: InputMaybe<PhotoModelContractCreateManyModelInputEnvelope>;
  delete?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PhotoModelContractScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  set?: InputMaybe<Array<PhotoModelContractWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PhotoModelContractUpdateWithWhereUniqueWithoutModelInput>
  >;
  updateMany?: InputMaybe<
    Array<PhotoModelContractUpdateManyWithWhereWithoutModelInput>
  >;
  upsert?: InputMaybe<
    Array<PhotoModelContractUpsertWithWhereUniqueWithoutModelInput>
  >;
};

export type PhotoModelContractUpdateWithWhereUniqueWithoutContractInput = {
  data: PhotoModelContractUpdateWithoutContractInput;
  where: PhotoModelContractWhereUniqueInput;
};

export type PhotoModelContractUpdateWithWhereUniqueWithoutModelInput = {
  data: PhotoModelContractUpdateWithoutModelInput;
  where: PhotoModelContractWhereUniqueInput;
};

export type PhotoModelContractUpdateWithoutContractInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<PhotoModelUpdateOneRequiredWithoutPhotoModelContractsInput>;
};

export type PhotoModelContractUpdateWithoutModelInput = {
  contract?: InputMaybe<ContractUpdateOneRequiredWithoutPhotoModelContractsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PhotoModelContractUpsertWithWhereUniqueWithoutContractInput = {
  create: PhotoModelContractCreateWithoutContractInput;
  update: PhotoModelContractUpdateWithoutContractInput;
  where: PhotoModelContractWhereUniqueInput;
};

export type PhotoModelContractUpsertWithWhereUniqueWithoutModelInput = {
  create: PhotoModelContractCreateWithoutModelInput;
  update: PhotoModelContractUpdateWithoutModelInput;
  where: PhotoModelContractWhereUniqueInput;
};

export type PhotoModelContractWhereInput = {
  AND?: InputMaybe<Array<PhotoModelContractWhereInput>>;
  NOT?: InputMaybe<Array<PhotoModelContractWhereInput>>;
  OR?: InputMaybe<Array<PhotoModelContractWhereInput>>;
  contract?: InputMaybe<ContractWhereInput>;
  contractId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  model?: InputMaybe<PhotoModelWhereInput>;
  modelId?: InputMaybe<IntFilter>;
};

export type PhotoModelContractWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  photoModelContractUnique?: InputMaybe<PhotoModelContractPhotoModelContractUniqueCompoundUniqueInput>;
};

export type PhotoModelCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  photoModelContracts?: InputMaybe<PhotoModelContractCreateNestedManyWithoutModelInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutModelInput>;
};

export type PhotoModelCreateNestedOneWithoutPhotoModelContractsInput = {
  connect?: InputMaybe<PhotoModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PhotoModelCreateOrConnectWithoutPhotoModelContractsInput>;
  create?: InputMaybe<PhotoModelCreateWithoutPhotoModelContractsInput>;
};

export type PhotoModelCreateNestedOneWithoutResourcePhotoModelsInput = {
  connect?: InputMaybe<PhotoModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PhotoModelCreateOrConnectWithoutResourcePhotoModelsInput>;
  create?: InputMaybe<PhotoModelCreateWithoutResourcePhotoModelsInput>;
};

export type PhotoModelCreateOrConnectWithoutPhotoModelContractsInput = {
  create: PhotoModelCreateWithoutPhotoModelContractsInput;
  where: PhotoModelWhereUniqueInput;
};

export type PhotoModelCreateOrConnectWithoutResourcePhotoModelsInput = {
  create: PhotoModelCreateWithoutResourcePhotoModelsInput;
  where: PhotoModelWhereUniqueInput;
};

export type PhotoModelCreateWithoutPhotoModelContractsInput = {
  description?: InputMaybe<Scalars["String"]>;
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutModelInput>;
};

export type PhotoModelCreateWithoutResourcePhotoModelsInput = {
  description?: InputMaybe<Scalars["String"]>;
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  photoModelContracts?: InputMaybe<PhotoModelContractCreateNestedManyWithoutModelInput>;
};

export type PhotoModelOrderByWithRelationInput = {
  description?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  photoModelContracts?: InputMaybe<PhotoModelContractOrderByRelationAggregateInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelOrderByRelationAggregateInput>;
};

export type PhotoModelUpdateInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  photoModelContracts?: InputMaybe<PhotoModelContractUpdateManyWithoutModelInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutModelInput>;
};

export type PhotoModelUpdateOneRequiredWithoutPhotoModelContractsInput = {
  connect?: InputMaybe<PhotoModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PhotoModelCreateOrConnectWithoutPhotoModelContractsInput>;
  create?: InputMaybe<PhotoModelCreateWithoutPhotoModelContractsInput>;
  update?: InputMaybe<PhotoModelUpdateWithoutPhotoModelContractsInput>;
  upsert?: InputMaybe<PhotoModelUpsertWithoutPhotoModelContractsInput>;
};

export type PhotoModelUpdateOneRequiredWithoutResourcePhotoModelsInput = {
  connect?: InputMaybe<PhotoModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PhotoModelCreateOrConnectWithoutResourcePhotoModelsInput>;
  create?: InputMaybe<PhotoModelCreateWithoutResourcePhotoModelsInput>;
  update?: InputMaybe<PhotoModelUpdateWithoutResourcePhotoModelsInput>;
  upsert?: InputMaybe<PhotoModelUpsertWithoutResourcePhotoModelsInput>;
};

export type PhotoModelUpdateWithoutPhotoModelContractsInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutModelInput>;
};

export type PhotoModelUpdateWithoutResourcePhotoModelsInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  photoModelContracts?: InputMaybe<PhotoModelContractUpdateManyWithoutModelInput>;
};

export type PhotoModelUpsertWithoutPhotoModelContractsInput = {
  create: PhotoModelCreateWithoutPhotoModelContractsInput;
  update: PhotoModelUpdateWithoutPhotoModelContractsInput;
};

export type PhotoModelUpsertWithoutResourcePhotoModelsInput = {
  create: PhotoModelCreateWithoutResourcePhotoModelsInput;
  update: PhotoModelUpdateWithoutResourcePhotoModelsInput;
};

export type PhotoModelWhereInput = {
  AND?: InputMaybe<Array<PhotoModelWhereInput>>;
  NOT?: InputMaybe<Array<PhotoModelWhereInput>>;
  OR?: InputMaybe<Array<PhotoModelWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  firstname?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastname?: InputMaybe<StringFilter>;
  photoModelContracts?: InputMaybe<PhotoModelContractListRelationFilter>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelListRelationFilter>;
};

export type PhotoModelWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export enum PrincipalSource {
  Amazon = "AMAZON",
  AmazonDe = "AMAZON_DE",
  At = "AT",
  Be = "BE",
  Ch = "CH",
  Com = "COM",
  De = "DE",
  DecathlonDe = "DECATHLON_DE",
  Dk = "DK",
  Fr = "FR",
  GalaxusCh = "GALAXUS_CH",
  HoffmannDe = "HOFFMANN_DE",
  Hr = "HR",
  KauflandDe = "KAUFLAND_DE",
  MercateoDe = "MERCATEO_DE",
  MercateoElbkinderDe = "MERCATEO_ELBKINDER_DE",
  MercateoUniteDe = "MERCATEO_UNITE_DE",
  Nl = "NL",
  Offline = "OFFLINE",
  Online = "ONLINE",
  OrderEntry = "ORDER_ENTRY",
  OttoDe = "OTTO_DE",
  Pl = "PL",
  Se = "SE",
  Shop = "SHOP",
  SlashpipeDe = "SLASHPIPE_DE",
  SportimeAt = "SPORTIME_AT",
  SportimeDe = "SPORTIME_DE",
  SportThiemeAt = "SPORT_THIEME_AT",
  SportThiemeBe = "SPORT_THIEME_BE",
  SportThiemeCh = "SPORT_THIEME_CH",
  SportThiemeCom = "SPORT_THIEME_COM",
  SportThiemeDe = "SPORT_THIEME_DE",
  SportThiemeDk = "SPORT_THIEME_DK",
  SportThiemeNl = "SPORT_THIEME_NL",
  SportThiemeSe = "SPORT_THIEME_SE",
  SportThiemeUk = "SPORT_THIEME_UK",
  Tcc = "TCC",
  TccAt = "TCC_AT",
  TccBe = "TCC_BE",
  TccDe = "TCC_DE",
  TccFr = "TCC_FR",
  TccHr = "TCC_HR",
  TccPl = "TCC_PL",
  Uk = "UK",
}

export type Product = ProductInterface & {
  __typename?: "Product";
  description?: Maybe<Scalars["String"]>;
  firstVariantNumber?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  productId: Scalars["Int"];
  variants: Array<Variant>;
};

export type ProductVariantsArgs = {
  after?: InputMaybe<VariantWhereUniqueInput>;
  before?: InputMaybe<VariantWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ProductCreateNestedOneWithoutProductResourceInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductResourceInput>;
  create?: InputMaybe<ProductCreateWithoutProductResourceInput>;
};

export type ProductCreateNestedOneWithoutVariantsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutVariantsInput>;
  create?: InputMaybe<ProductCreateWithoutVariantsInput>;
};

export type ProductCreateOrConnectWithoutProductResourceInput = {
  create: ProductCreateWithoutProductResourceInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutVariantsInput = {
  create: ProductCreateWithoutVariantsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutProductResourceInput = {
  brandId?: InputMaybe<Scalars["Int"]>;
  incentive: ProductIncentiveType;
  variants?: InputMaybe<VariantCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutVariantsInput = {
  brandId?: InputMaybe<Scalars["Int"]>;
  incentive: ProductIncentiveType;
  productResource?: InputMaybe<ResourceProductCreateNestedManyWithoutProductInput>;
};

export type ProductFixedDiscountVoucher = Voucher & {
  __typename?: "ProductFixedDiscountVoucher";
  active?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<Scalars["String"]>;
  codes?: Maybe<VoucherCodePagination>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  discountedProduct?: Maybe<DiscountedProduct>;
  id?: Maybe<Scalars["ID"]>;
  minOrderValue?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  navisionAccount?: Maybe<Scalars["Int"]>;
  shopId?: Maybe<Scalars["Int"]>;
  statistics?: Maybe<VoucherStatistic>;
  /** Total number of times this voucher has been redeemed in an confirmed order. */
  totalRedeemCount?: Maybe<Scalars["Int"]>;
  /** Total number of redeemed codes. Please keep in mind that this number might seem wrong if codes can redeem multiple times. This query uses the boolean on voucher code. */
  totalRedeemdedCodes?: Maybe<Scalars["Int"]>;
  unique?: Maybe<Scalars["Boolean"]>;
  validForModel?: Maybe<Scalars["Boolean"]>;
  validOnlyForOneVariant?: Maybe<Scalars["Boolean"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type ProductFixedDiscountVoucherCodesArgs = {
  code?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  expired?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  redeemed?: InputMaybe<Scalars["Boolean"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VoucherCodeWhereInput>;
};

export type ProductFixedDiscountVoucherStatisticsArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export enum ProductIncentiveType {
  FreeSupplement = "FreeSupplement",
  MultiIncentive = "MultiIncentive",
  NoIncentive = "NoIncentive",
  SingleIncentive = "SingleIncentive",
}

export type ProductInterface = {
  productId?: Maybe<Scalars["Int"]>;
};

export type ProductPercentageDiscountVoucher = Voucher & {
  __typename?: "ProductPercentageDiscountVoucher";
  active?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<Scalars["String"]>;
  codes?: Maybe<VoucherCodePagination>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  discountedProduct?: Maybe<DiscountedProduct>;
  id?: Maybe<Scalars["ID"]>;
  minOrderValue?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  navisionAccount?: Maybe<Scalars["Int"]>;
  percentage?: Maybe<Scalars["Float"]>;
  shopId?: Maybe<Scalars["Int"]>;
  statistics?: Maybe<VoucherStatistic>;
  /** Total number of times this voucher has been redeemed in an confirmed order. */
  totalRedeemCount?: Maybe<Scalars["Int"]>;
  /** Total number of redeemed codes. Please keep in mind that this number might seem wrong if codes can redeem multiple times. This query uses the boolean on voucher code. */
  totalRedeemdedCodes?: Maybe<Scalars["Int"]>;
  unique?: Maybe<Scalars["Boolean"]>;
  validForModel?: Maybe<Scalars["Boolean"]>;
  validOnlyForOneVariant?: Maybe<Scalars["Boolean"]>;
};

export type ProductPercentageDiscountVoucherCodesArgs = {
  code?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  expired?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  redeemed?: InputMaybe<Scalars["Boolean"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VoucherCodeWhereInput>;
};

export type ProductPercentageDiscountVoucherStatisticsArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export enum ProductType {
  BrandImage = "brand_image",
  ExtraImage = "extra_image",
  ListImage = "list_image",
  Pdf = "pdf",
  ProductImage = "product_image",
  Qualitysign = "qualitysign",
  SafetyPictogram = "safety_pictogram",
  Video = "video",
  Youtubevideo = "youtubevideo",
}

export type ProductUpdateOneRequiredWithoutProductResourceInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductResourceInput>;
  create?: InputMaybe<ProductCreateWithoutProductResourceInput>;
  update?: InputMaybe<ProductUpdateWithoutProductResourceInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductResourceInput>;
};

export type ProductUpdateOneWithoutVariantsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutVariantsInput>;
  create?: InputMaybe<ProductCreateWithoutVariantsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ProductUpdateWithoutVariantsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutVariantsInput>;
};

export type ProductUpdateWithoutProductResourceInput = {
  brandId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  incentive?: InputMaybe<EnumProductIncentiveTypeFieldUpdateOperationsInput>;
  variants?: InputMaybe<VariantUpdateManyWithoutProductInput>;
};

export type ProductUpdateWithoutVariantsInput = {
  brandId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  incentive?: InputMaybe<EnumProductIncentiveTypeFieldUpdateOperationsInput>;
  productResource?: InputMaybe<ResourceProductUpdateManyWithoutProductInput>;
};

export type ProductUpsertWithoutProductResourceInput = {
  create: ProductCreateWithoutProductResourceInput;
  update: ProductUpdateWithoutProductResourceInput;
};

export type ProductUpsertWithoutVariantsInput = {
  create: ProductCreateWithoutVariantsInput;
  update: ProductUpdateWithoutVariantsInput;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  brandId?: InputMaybe<IntNullableFilter>;
  incentive?: InputMaybe<EnumProductIncentiveTypeFilter>;
  productId?: InputMaybe<IntFilter>;
  productResource?: InputMaybe<ResourceProductListRelationFilter>;
  variants?: InputMaybe<VariantListRelationFilter>;
};

export type ProductWhereUniqueInput = {
  productId?: InputMaybe<Scalars["Int"]>;
};

export type QualitySign = {
  __typename?: "QualitySign";
  description?: Maybe<Text>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  resources?: Maybe<Array<Maybe<Resource>>>;
};

export type QualitySignCreateManyDescriptionInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type QualitySignCreateManyDescriptionInputEnvelope = {
  data?: InputMaybe<Array<QualitySignCreateManyDescriptionInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type QualitySignCreateNestedManyWithoutDescriptionInput = {
  connect?: InputMaybe<Array<QualitySignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<QualitySignCreateOrConnectWithoutDescriptionInput>
  >;
  create?: InputMaybe<Array<QualitySignCreateWithoutDescriptionInput>>;
  createMany?: InputMaybe<QualitySignCreateManyDescriptionInputEnvelope>;
};

export type QualitySignCreateNestedOneWithoutQualitysignResourceInput = {
  connect?: InputMaybe<QualitySignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QualitySignCreateOrConnectWithoutQualitysignResourceInput>;
  create?: InputMaybe<QualitySignCreateWithoutQualitysignResourceInput>;
};

export type QualitySignCreateOrConnectWithoutDescriptionInput = {
  create: QualitySignCreateWithoutDescriptionInput;
  where: QualitySignWhereUniqueInput;
};

export type QualitySignCreateOrConnectWithoutQualitysignResourceInput = {
  create: QualitySignCreateWithoutQualitysignResourceInput;
  where: QualitySignWhereUniqueInput;
};

export type QualitySignCreateWithoutDescriptionInput = {
  name?: InputMaybe<Scalars["String"]>;
  qualitysignResource?: InputMaybe<QualitySignResourceCreateNestedOneWithoutQualitysignInput>;
};

export type QualitySignCreateWithoutQualitysignResourceInput = {
  description?: InputMaybe<TextCreateNestedOneWithoutQualitySignInput>;
  name?: InputMaybe<Scalars["String"]>;
};

export type QualitySignInterface = {
  id?: Maybe<Scalars["Int"]>;
};

export type QualitySignListRelationFilter = {
  every?: InputMaybe<QualitySignWhereInput>;
  none?: InputMaybe<QualitySignWhereInput>;
  some?: InputMaybe<QualitySignWhereInput>;
};

export type QualitySignResource = {
  __typename?: "QualitySignResource";
  qualitysign?: Maybe<QualitySign>;
  resource?: Maybe<Resource>;
};

export type QualitySignResourceCreateManyResourceInput = {
  qualitysign_id?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type QualitySignResourceCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<QualitySignResourceCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type QualitySignResourceCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<QualitySignResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<QualitySignResourceCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<QualitySignResourceCreateWithoutResourceInput>>;
  createMany?: InputMaybe<QualitySignResourceCreateManyResourceInputEnvelope>;
};

export type QualitySignResourceCreateNestedOneWithoutQualitysignInput = {
  connect?: InputMaybe<QualitySignResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QualitySignResourceCreateOrConnectWithoutQualitysignInput>;
  create?: InputMaybe<QualitySignResourceCreateWithoutQualitysignInput>;
};

export type QualitySignResourceCreateOrConnectWithoutQualitysignInput = {
  create: QualitySignResourceCreateWithoutQualitysignInput;
  where: QualitySignResourceWhereUniqueInput;
};

export type QualitySignResourceCreateOrConnectWithoutResourceInput = {
  create: QualitySignResourceCreateWithoutResourceInput;
  where: QualitySignResourceWhereUniqueInput;
};

export type QualitySignResourceCreateWithoutQualitysignInput = {
  resource?: InputMaybe<ResourceCreateNestedOneWithoutQualitySignResourceInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type QualitySignResourceCreateWithoutResourceInput = {
  qualitysign?: InputMaybe<QualitySignCreateNestedOneWithoutQualitysignResourceInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type QualitySignResourceListRelationFilter = {
  every?: InputMaybe<QualitySignResourceWhereInput>;
  none?: InputMaybe<QualitySignResourceWhereInput>;
  some?: InputMaybe<QualitySignResourceWhereInput>;
};

export type QualitySignResourceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type QualitySignResourceScalarWhereInput = {
  AND?: InputMaybe<Array<QualitySignResourceScalarWhereInput>>;
  NOT?: InputMaybe<Array<QualitySignResourceScalarWhereInput>>;
  OR?: InputMaybe<Array<QualitySignResourceScalarWhereInput>>;
  qualitysign_id?: InputMaybe<IntFilter>;
  resource_id?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type QualitySignResourceUpdateManyMutationInput = {
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QualitySignResourceUpdateManyWithWhereWithoutResourceInput = {
  data: QualitySignResourceUpdateManyMutationInput;
  where: QualitySignResourceScalarWhereInput;
};

export type QualitySignResourceUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<QualitySignResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<QualitySignResourceCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<QualitySignResourceCreateWithoutResourceInput>>;
  createMany?: InputMaybe<QualitySignResourceCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<QualitySignResourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QualitySignResourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QualitySignResourceWhereUniqueInput>>;
  set?: InputMaybe<Array<QualitySignResourceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<QualitySignResourceUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<QualitySignResourceUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<QualitySignResourceUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type QualitySignResourceUpdateOneRequiredWithoutQualitysignInput = {
  connect?: InputMaybe<QualitySignResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QualitySignResourceCreateOrConnectWithoutQualitysignInput>;
  create?: InputMaybe<QualitySignResourceCreateWithoutQualitysignInput>;
  update?: InputMaybe<QualitySignResourceUpdateWithoutQualitysignInput>;
  upsert?: InputMaybe<QualitySignResourceUpsertWithoutQualitysignInput>;
};

export type QualitySignResourceUpdateWithWhereUniqueWithoutResourceInput = {
  data: QualitySignResourceUpdateWithoutResourceInput;
  where: QualitySignResourceWhereUniqueInput;
};

export type QualitySignResourceUpdateWithoutQualitysignInput = {
  resource?: InputMaybe<ResourceUpdateOneWithoutQualitySignResourceInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QualitySignResourceUpdateWithoutResourceInput = {
  qualitysign?: InputMaybe<QualitySignUpdateOneWithoutQualitysignResourceInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type QualitySignResourceUpsertWithWhereUniqueWithoutResourceInput = {
  create: QualitySignResourceCreateWithoutResourceInput;
  update: QualitySignResourceUpdateWithoutResourceInput;
  where: QualitySignResourceWhereUniqueInput;
};

export type QualitySignResourceUpsertWithoutQualitysignInput = {
  create: QualitySignResourceCreateWithoutQualitysignInput;
  update: QualitySignResourceUpdateWithoutQualitysignInput;
};

export type QualitySignResourceWhereInput = {
  AND?: InputMaybe<Array<QualitySignResourceWhereInput>>;
  NOT?: InputMaybe<Array<QualitySignResourceWhereInput>>;
  OR?: InputMaybe<Array<QualitySignResourceWhereInput>>;
  qualitysign?: InputMaybe<QualitySignWhereInput>;
  qualitysign_id?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type QualitySignResourceWhereUniqueInput = {
  qualitysign_id?: InputMaybe<Scalars["Int"]>;
};

export type QualitySignScalarWhereInput = {
  AND?: InputMaybe<Array<QualitySignScalarWhereInput>>;
  NOT?: InputMaybe<Array<QualitySignScalarWhereInput>>;
  OR?: InputMaybe<Array<QualitySignScalarWhereInput>>;
  description_text_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
};

export type QualitySignUpdateManyMutationInput = {
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type QualitySignUpdateManyWithWhereWithoutDescriptionInput = {
  data: QualitySignUpdateManyMutationInput;
  where: QualitySignScalarWhereInput;
};

export type QualitySignUpdateManyWithoutDescriptionInput = {
  connect?: InputMaybe<Array<QualitySignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<QualitySignCreateOrConnectWithoutDescriptionInput>
  >;
  create?: InputMaybe<Array<QualitySignCreateWithoutDescriptionInput>>;
  createMany?: InputMaybe<QualitySignCreateManyDescriptionInputEnvelope>;
  delete?: InputMaybe<Array<QualitySignWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QualitySignScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QualitySignWhereUniqueInput>>;
  set?: InputMaybe<Array<QualitySignWhereUniqueInput>>;
  update?: InputMaybe<
    Array<QualitySignUpdateWithWhereUniqueWithoutDescriptionInput>
  >;
  updateMany?: InputMaybe<
    Array<QualitySignUpdateManyWithWhereWithoutDescriptionInput>
  >;
  upsert?: InputMaybe<
    Array<QualitySignUpsertWithWhereUniqueWithoutDescriptionInput>
  >;
};

export type QualitySignUpdateOneWithoutQualitysignResourceInput = {
  connect?: InputMaybe<QualitySignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QualitySignCreateOrConnectWithoutQualitysignResourceInput>;
  create?: InputMaybe<QualitySignCreateWithoutQualitysignResourceInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<QualitySignUpdateWithoutQualitysignResourceInput>;
  upsert?: InputMaybe<QualitySignUpsertWithoutQualitysignResourceInput>;
};

export type QualitySignUpdateWithWhereUniqueWithoutDescriptionInput = {
  data: QualitySignUpdateWithoutDescriptionInput;
  where: QualitySignWhereUniqueInput;
};

export type QualitySignUpdateWithoutDescriptionInput = {
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  qualitysignResource?: InputMaybe<QualitySignResourceUpdateOneRequiredWithoutQualitysignInput>;
};

export type QualitySignUpdateWithoutQualitysignResourceInput = {
  description?: InputMaybe<TextUpdateOneWithoutQualitySignInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type QualitySignUpsertWithWhereUniqueWithoutDescriptionInput = {
  create: QualitySignCreateWithoutDescriptionInput;
  update: QualitySignUpdateWithoutDescriptionInput;
  where: QualitySignWhereUniqueInput;
};

export type QualitySignUpsertWithoutQualitysignResourceInput = {
  create: QualitySignCreateWithoutQualitysignResourceInput;
  update: QualitySignUpdateWithoutQualitysignResourceInput;
};

export type QualitySignWhereInput = {
  AND?: InputMaybe<Array<QualitySignWhereInput>>;
  NOT?: InputMaybe<Array<QualitySignWhereInput>>;
  OR?: InputMaybe<Array<QualitySignWhereInput>>;
  description?: InputMaybe<TextWhereInput>;
  description_text_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  qualitysignResource?: InputMaybe<QualitySignResourceWhereInput>;
};

export type QualitySignWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type Query = {
  __typename?: "Query";
  activeDirectoryGroup?: Maybe<ActiveDirectoryGroup>;
  activeDirectoryGroups: Array<ActiveDirectoryGroup>;
  /** Lists all users from AD; */
  adUsers?: Maybe<Array<Maybe<AdUser>>>;
  app?: Maybe<App>;
  apps: Array<App>;
  archiveNavigation?: Maybe<Array<Maybe<ArchiveNavItem>>>;
  auroraHealth?: Maybe<AuroraHealthResult>;
  brand?: Maybe<Brand>;
  brands: Array<Brand>;
  brandsEligibleForVouchers: Array<Maybe<BrandEligibleForVoucher>>;
  campaignDefinition?: Maybe<CampaignDefinition>;
  campaignDefinitions: Array<CampaignDefinition>;
  catalog?: Maybe<Catalog>;
  catalogs: Array<Catalog>;
  classification?: Maybe<Classification>;
  classifications: Array<Classification>;
  /** Lists all users from AWS Cognito; */
  cognitoUsers?: Maybe<Array<Maybe<CognitoUser>>>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  countryInformation?: Maybe<CountryInformation>;
  countryInformations: Array<CountryInformation>;
  /** Get Current User */
  currentUser?: Maybe<CurrentUser>;
  customerJourney?: Maybe<Array<Maybe<CustomerJourney>>>;
  domainForLanguageRelationMediaEndpoint?: Maybe<DomainForLanguageRelationMediaEndpoint>;
  domainForLanguageRelationMediaEndpoints: Array<DomainForLanguageRelationMediaEndpoint>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplates: Array<EmailTemplate>;
  exportOrders?: Maybe<Scalars["String"]>;
  favouriteApp?: Maybe<FavouriteApp>;
  /** Get all FavouriteApp by user email */
  favouriteAppByUser?: Maybe<Array<Maybe<FavouriteApp>>>;
  favouriteApps: Array<FavouriteApp>;
  /** Get a AD User from the ID; */
  getAdUserById?: Maybe<AdUser>;
  /** Get a list of AD Users from the IDs; */
  getAdUsersByIds?: Maybe<Array<Maybe<AdUser>>>;
  /** Get a list of classifications by shop */
  getClassifications?: Maybe<Array<Maybe<Classification>>>;
  getCurrentCatalogs?: Maybe<Array<Maybe<Catalog>>>;
  /** Get a list of Groups with AD Users */
  getGroupsWithUser?: Maybe<Array<Maybe<Group>>>;
  getOrder?: Maybe<Order>;
  /** Retrieve address for a given crm account */
  getOrderEntryAddressFromCrmAccount?: Maybe<OrderEntryAddress>;
  /** Retrieve address for a given crm contact */
  getOrderEntryAddressFromCrmContact?: Maybe<OrderEntryAddress>;
  /** Retrieve address for a given navision customer number */
  getOrderEntryAddressFromNavisionCustomerNumber?: Maybe<OrderEntryAddress>;
  /** Retrieve values for a given search parameter */
  getOrderEntryCrmSearchResults?: Maybe<
    Array<Maybe<OrderEntryCrmSearchResult>>
  >;
  /** get all additional data for products within an order */
  getOrderEntryProductsEstimation?: Maybe<OrderEstimation>;
  getReferences?: Maybe<Array<Maybe<Reference>>>;
  /** Get Base64 encoded image of user */
  getUserImage?: Maybe<Scalars["String"]>;
  group?: Maybe<Group>;
  groupCountries: Array<GroupCountry>;
  groupCountry?: Maybe<GroupCountry>;
  groupRole?: Maybe<GroupRole>;
  groupRoles: Array<GroupRole>;
  groups: Array<Group>;
  language?: Maybe<Language>;
  languages: Array<Language>;
  languagesEligibleForResources?: Maybe<Array<Maybe<Language>>>;
  listEmailTemplates?: Maybe<ListEmailTemplates>;
  listMaintenanceTourStops?: Maybe<ListMaintenanceTourStops>;
  listMutationLogs?: Maybe<ListMutationLogs>;
  listOrders?: Maybe<OrdersList>;
  listTemplates?: Maybe<ListTemplates>;
  listVouchers?: Maybe<VoucherList>;
  lowerMaterialGroups?: Maybe<Array<Maybe<MaterialGroup>>>;
  mainMaterialGroups?: Maybe<Array<Maybe<MaterialGroup>>>;
  /** Returns all the absences, except the type overtime reduction. If you want to enable it, please use the parameter includeOvertimeReduction */
  maintenanceAbsences?: Maybe<Array<Maybe<MaintenanceAbsence>>>;
  maintenanceAppointment?: Maybe<MaintenanceAppointment>;
  maintenanceAppointments: Array<MaintenanceAppointment>;
  maintenanceBlueprint?: Maybe<MaintenanceBlueprint>;
  maintenanceBlueprints: Array<MaintenanceBlueprint>;
  maintenanceCustomerStopsPdf?: Maybe<MaintenanceCustomerStopsPdf>;
  maintenanceEditors?: Maybe<Array<Maybe<MaintenanceUser>>>;
  maintenanceGroup?: Maybe<MaintenanceGroup>;
  maintenanceGroupUser?: Maybe<MaintenanceGroupUser>;
  maintenanceGroupUsers: Array<MaintenanceGroupUser>;
  maintenanceGroups: Array<MaintenanceGroup>;
  maintenanceInspectionVsRepairStatistic?: Maybe<
    Array<Maybe<MaintenanceInspectionVsRepairStatisticPoint>>
  >;
  maintenanceOfferVsOrderStatistic?: Maybe<
    Array<Maybe<MaintenanceOfferVsOrderStatisticPoint>>
  >;
  maintenanceProduct?: Maybe<MaintenanceProduct>;
  maintenanceProductService?: Maybe<MaintenanceProductService>;
  maintenanceProductServices: Array<MaintenanceProductService>;
  maintenanceProducts: Array<MaintenanceProduct>;
  maintenanceReport?: Maybe<MaintenanceReport>;
  maintenanceReportPdf?: Maybe<MaintenancePdf>;
  maintenanceReportServiceSmallPart?: Maybe<MaintenanceReportServiceSmallPart>;
  maintenanceReportServiceSmallParts: Array<MaintenanceReportServiceSmallPart>;
  maintenanceReportSparePartBlueprint?: Maybe<MaintenanceReportSparePartBlueprint>;
  maintenanceReportSparePartBlueprints: Array<MaintenanceReportSparePartBlueprint>;
  maintenanceReports: Array<MaintenanceReport>;
  maintenanceResource?: Maybe<MaintenanceResource>;
  maintenanceResources: Array<MaintenanceResource>;
  maintenanceRevenueForecastStatistic?: Maybe<
    Array<Maybe<MaintenanceDateBasedStatisticPoint>>
  >;
  maintenanceRevenueStatistic?: Maybe<
    Array<Maybe<MaintenanceDateBasedStatisticPoint>>
  >;
  /** Value is a percentage of second repairs to all repairs */
  maintenanceSecondRepairStatistic?: Maybe<
    Array<Maybe<MaintenanceSecondRepairStatisticPoint>>
  >;
  maintenanceServiceSparePart?: Maybe<MaintenanceServiceSparePart>;
  maintenanceServiceSpareParts: Array<MaintenanceServiceSparePart>;
  maintenanceSmallPart?: Maybe<MaintenanceSmallPart>;
  maintenanceSmallParts: Array<MaintenanceSmallPart>;
  maintenanceSynchronizationLog?: Maybe<MaintenanceSynchronizationLog>;
  maintenanceSynchronizationLogs: Array<MaintenanceSynchronizationLog>;
  maintenanceTechnicianHolidays?: Maybe<Array<Maybe<HolidayDate>>>;
  /** Returns all maintenance technicians for the current user, based of the maintenance group. Provide forEditor to return specific editor technicians based on the maintenance information or leave the array empty to show all technicians. */
  maintenanceTechnicians?: Maybe<Array<Maybe<MaintenanceUser>>>;
  maintenanceTourStop?: Maybe<MaintenanceTourStop>;
  maintenanceTourStopAttachment?: Maybe<MaintenanceTourStopAttachment>;
  maintenanceTourStopAttachments: Array<MaintenanceTourStopAttachment>;
  maintenanceTourStopPassenger?: Maybe<MaintenanceTourStopPassenger>;
  maintenanceTourStopPassengers: Array<MaintenanceTourStopPassenger>;
  maintenanceTourStopRelationship?: Maybe<MaintenanceTourStopRelationship>;
  maintenanceTourStopRelationships: Array<MaintenanceTourStopRelationship>;
  maintenanceTourStops: Array<MaintenanceTourStop>;
  maintenanceUser?: Maybe<MaintenanceUser>;
  maintenanceUserInformation?: Maybe<MaintenanceUserInformation>;
  maintenanceUserInformations: Array<MaintenanceUserInformation>;
  maintenanceUsers: Array<MaintenanceUser>;
  maintenanceVariant?: Maybe<MaintenanceVariant>;
  maintenanceVariants: Array<MaintenanceVariant>;
  marketingChannel?: Maybe<MarketingChannel>;
  marketingChannels: Array<MarketingChannel>;
  materialGroups?: Maybe<Array<Maybe<MaterialGroup>>>;
  memberGroup?: Maybe<MemberGroup>;
  memberGroups: Array<MemberGroup>;
  /** Returns all open maintenance tour stops for the current user where start date is greater than 14 days ago and no reports are done. Please note, that you also get the tour stops of other technicians if the current user is a passenger. */
  myOpenMaintenanceTourStops?: Maybe<Array<Maybe<MaintenanceTourStop>>>;
  ociCustomer?: Maybe<OciCustomer>;
  ociCustomers: Array<OciCustomer>;
  officeLocations: Array<OfficeLocation>;
  officeVisit?: Maybe<OfficeVisit>;
  officeVisits: Array<OfficeVisit>;
  permission?: Maybe<Permission>;
  permissions: Array<Permission>;
  photoModel?: Maybe<PhotoModel>;
  photoModelContract?: Maybe<PhotoModelContract>;
  photoModelContracts: Array<PhotoModelContract>;
  photoModels: Array<PhotoModel>;
  product?: Maybe<Product>;
  products: Array<Product>;
  qualitySign?: Maybe<QualitySign>;
  qualitySignResource?: Maybe<QualitySignResource>;
  qualitySignResources: Array<QualitySignResource>;
  qualitySigns: Array<QualitySign>;
  resource?: Maybe<Resource>;
  resourceCollection?: Maybe<ResourceCollection>;
  resourceCollectionTag?: Maybe<ResourceCollectionTag>;
  resourceCollectionTags: Array<ResourceCollectionTag>;
  resourceCollections: Array<ResourceCollection>;
  resourceLanguage?: Maybe<ResourceLanguage>;
  resourceLanguages: Array<ResourceLanguage>;
  resourceLicense?: Maybe<ResourceLicense>;
  resourceLicenseDefinition?: Maybe<ResourceLicenseDefinition>;
  resourceLicenseDefinitions: Array<ResourceLicenseDefinition>;
  resourceLicenses: Array<ResourceLicense>;
  resourcePdfCategories?: Maybe<Array<Maybe<ResourcePdfCategory>>>;
  resourcePhotoModel?: Maybe<ResourcePhotoModel>;
  resourcePhotoModels: Array<ResourcePhotoModel>;
  resourceProduct?: Maybe<ResourceProduct>;
  resourceProducts: Array<ResourceProduct>;
  resourceTag?: Maybe<ResourceTag>;
  resourceTags: Array<ResourceTag>;
  resourceTemplate?: Maybe<ResourceTemplate>;
  resourceTemplates: Array<ResourceTemplate>;
  resourceText?: Maybe<CResourceText>;
  /** first and last might not work as expected, more resource texts will be returned (grouped by id) */
  resourceTexts?: Maybe<Array<Maybe<CResourceText>>>;
  resourceVersion?: Maybe<ResourceVersion>;
  resourceVersionByFilename?: Maybe<Array<Maybe<ResourceVersion>>>;
  resourceVersions: Array<ResourceVersion>;
  resources: Array<Resource>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  salesVoucherCodes?: Maybe<SalesVoucherCodePagination>;
  searchCollections?: Maybe<Array<Maybe<ResourceCollection>>>;
  /** Searches for groups and roles by name and description. Returns five results by default */
  searchGroupRoles?: Maybe<SearchGroupRole>;
  /** Searches for groups by slug. Returns five results by default */
  searchGroups?: Maybe<SearchGroup>;
  searchResources?: Maybe<Array<Maybe<Resource>>>;
  /** Searches for roles by name and description. Returns five results by default */
  searchRoles?: Maybe<SearchRole>;
  /** Searches for TypeCatalogs by Service,Type and Field. Returns five first results by default */
  searchTypeCatalogs?: Maybe<SearchTypeCatalogs>;
  service?: Maybe<Service>;
  /** Get the permissions using the email and the serviceId */
  servicePermissionsByUserEmail?: Maybe<Array<Maybe<Permission>>>;
  services: Array<Service>;
  shopEligibleForOci?: Maybe<ShopEligibleForOci>;
  shopEligibleForOcis: Array<ShopEligibleForOci>;
  shopsEligibleForVouchers: Array<Maybe<ShopEligibleForVouchers>>;
  /** query for tags that start with [keyword], returns maximum 10 by default */
  similarTags?: Maybe<Array<Maybe<Tag>>>;
  slug?: Maybe<Slug>;
  /** Returns a collection of tags aka. labels by aws rekognition */
  suggestResourceTags?: Maybe<Array<Maybe<ResourceTagSuggestion>>>;
  tags?: Maybe<Tags>;
  template?: Maybe<Template>;
  templates: Array<Template>;
  typeCatalog?: Maybe<TypeCatalog>;
  typeCatalogs: Array<TypeCatalog>;
  /** Get all TypeCatalogs by serviceId */
  typeCatalogsByService?: Maybe<Array<Maybe<TypeCatalog>>>;
  user?: Maybe<User>;
  users: Array<User>;
  /** takes an array of user ids and returns the number of users, and the users with those ids */
  usersById: Users;
  variant?: Maybe<Variant>;
  variantCo2Information?: Maybe<VariantCo2Information>;
  variantCo2Informations: Array<VariantCo2Information>;
  variantMaterial?: Maybe<VariantMaterial>;
  variantMaterialDefinition?: Maybe<VariantMaterialDefinition>;
  variantMaterialDefinitions: Array<VariantMaterialDefinition>;
  variantMaterials: Array<VariantMaterial>;
  /** Returns all variants that do not have 100% of their materials assigned. */
  variantWithout100PercentMaterials?: Maybe<
    Array<Maybe<VariantWithout100PercentMaterials>>
  >;
  variants: Array<Variant>;
  voucher?: Maybe<Voucher>;
  voucherCategories: Array<Maybe<Scalars["String"]>>;
  voucherCodes: Array<VoucherCode>;
  zipImagesStatus: ImageZipStatus;
};

export type QueryActiveDirectoryGroupArgs = {
  where: ActiveDirectoryGroupWhereUniqueInput;
};

export type QueryActiveDirectoryGroupsArgs = {
  after?: InputMaybe<ActiveDirectoryGroupWhereUniqueInput>;
  before?: InputMaybe<ActiveDirectoryGroupWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ActiveDirectoryGroupOrderByInput>>;
};

export type QueryAppArgs = {
  where: AppWhereUniqueInput;
};

export type QueryAppsArgs = {
  after?: InputMaybe<AppWhereUniqueInput>;
  before?: InputMaybe<AppWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppOrderByInput>>;
};

export type QueryArchiveNavigationArgs = {
  in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type QueryBrandArgs = {
  where: BrandWhereUniqueInput;
};

export type QueryBrandsArgs = {
  after?: InputMaybe<BrandWhereUniqueInput>;
  before?: InputMaybe<BrandWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<BrandWhereInput>;
};

export type QueryCampaignDefinitionArgs = {
  where: CampaignDefinitionWhereUniqueInput;
};

export type QueryCampaignDefinitionsArgs = {
  after?: InputMaybe<CampaignDefinitionWhereUniqueInput>;
  before?: InputMaybe<CampaignDefinitionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CampaignDefinitionOrderByInput>>;
  where?: InputMaybe<CampaignDefinitionWhereInput>;
};

export type QueryCatalogArgs = {
  where: CatalogWhereUniqueInput;
};

export type QueryCatalogsArgs = {
  after?: InputMaybe<CatalogWhereUniqueInput>;
  before?: InputMaybe<CatalogWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CatalogOrderByInput>>;
  where?: InputMaybe<CatalogWhereInput>;
};

export type QueryClassificationArgs = {
  where: ClassificationWhereUniqueInput;
};

export type QueryClassificationsArgs = {
  after?: InputMaybe<ClassificationWhereUniqueInput>;
  before?: InputMaybe<ClassificationWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ClassificationOrderByInput>>;
  where?: InputMaybe<ClassificationWhereInput>;
};

export type QueryCognitoUsersArgs = {
  filterField?: InputMaybe<Scalars["String"]>;
  filterValue?: InputMaybe<Scalars["String"]>;
};

export type QueryContractArgs = {
  where: ContractWhereUniqueInput;
};

export type QueryContractsArgs = {
  after?: InputMaybe<ContractWhereUniqueInput>;
  before?: InputMaybe<ContractWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractOrderByWithRelationInput>>;
  where?: InputMaybe<ContractWhereInput>;
};

export type QueryCountryInformationArgs = {
  where: CountryInformationWhereUniqueInput;
};

export type QueryCountryInformationsArgs = {
  after?: InputMaybe<CountryInformationWhereUniqueInput>;
  before?: InputMaybe<CountryInformationWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CountryInformationOrderByInput>>;
  where?: InputMaybe<CountryInformationWhereInput>;
};

export type QueryCustomerJourneyArgs = {
  id: Scalars["String"];
};

export type QueryDomainForLanguageRelationMediaEndpointArgs = {
  where: DomainForLanguageRelationMediaEndpointWhereUniqueInput;
};

export type QueryDomainForLanguageRelationMediaEndpointsArgs = {
  after?: InputMaybe<DomainForLanguageRelationMediaEndpointWhereUniqueInput>;
  before?: InputMaybe<DomainForLanguageRelationMediaEndpointWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<DomainForLanguageRelationMediaEndpointOrderByWithRelationInput>
  >;
  where?: InputMaybe<DomainForLanguageRelationMediaEndpointWhereInput>;
};

export type QueryEmailTemplateArgs = {
  where: EmailTemplateWhereUniqueInput;
};

export type QueryEmailTemplatesArgs = {
  after?: InputMaybe<EmailTemplateWhereUniqueInput>;
  before?: InputMaybe<EmailTemplateWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EmailTemplateOrderByInput>>;
  where?: InputMaybe<EmailTemplateWhereInput>;
};

export type QueryExportOrdersArgs = {
  email: Scalars["String"];
  exportName: Scalars["String"];
  fieldLabels?: InputMaybe<Array<Scalars["String"]>>;
  includedFields: Array<InputMaybe<Scalars["String"]>>;
  where?: InputMaybe<OrdersFilter>;
};

export type QueryFavouriteAppArgs = {
  where: FavouriteAppWhereUniqueInput;
};

export type QueryFavouriteAppByUserArgs = {
  email: Scalars["String"];
};

export type QueryFavouriteAppsArgs = {
  after?: InputMaybe<FavouriteAppWhereUniqueInput>;
  before?: InputMaybe<FavouriteAppWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetAdUserByIdArgs = {
  id: Scalars["String"];
};

export type QueryGetAdUsersByIdsArgs = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryGetClassificationsArgs = {
  shopId: Scalars["Int"];
};

export type QueryGetCurrentCatalogsArgs = {
  shopId: Scalars["Int"];
};

export type QueryGetGroupsWithUserArgs = {
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetOrderArgs = {
  id: Scalars["String"];
};

export type QueryGetOrderEntryAddressFromCrmAccountArgs = {
  uuid: Scalars["String"];
};

export type QueryGetOrderEntryAddressFromCrmContactArgs = {
  uuid: Scalars["String"];
};

export type QueryGetOrderEntryAddressFromNavisionCustomerNumberArgs = {
  customerNumber: Scalars["String"];
};

export type QueryGetOrderEntryCrmSearchResultsArgs = {
  query: Scalars["String"];
};

export type QueryGetOrderEntryProductsEstimationArgs = {
  orderInput: OrderEntryProductEstimationInput;
};

export type QueryGetReferencesArgs = {
  shopId: Scalars["Int"];
};

export type QueryGetUserImageArgs = {
  size?: InputMaybe<UserImageSize>;
  userId: Scalars["String"];
};

export type QueryGroupArgs = {
  where: GroupWhereUniqueInput;
};

export type QueryGroupCountriesArgs = {
  after?: InputMaybe<GroupCountryWhereUniqueInput>;
  before?: InputMaybe<GroupCountryWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GroupCountryWhereInput>;
};

export type QueryGroupCountryArgs = {
  where: GroupCountryWhereUniqueInput;
};

export type QueryGroupRoleArgs = {
  where: GroupRoleWhereUniqueInput;
};

export type QueryGroupRolesArgs = {
  after?: InputMaybe<GroupRoleWhereUniqueInput>;
  before?: InputMaybe<GroupRoleWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<GroupRoleWhereInput>;
};

export type QueryGroupsArgs = {
  after?: InputMaybe<GroupWhereUniqueInput>;
  before?: InputMaybe<GroupWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupOrderByInput>>;
};

export type QueryLanguageArgs = {
  where: LanguageWhereUniqueInput;
};

export type QueryLanguagesArgs = {
  orderBy?: InputMaybe<Array<LanguageOrderByWithRelationInput>>;
  where?: InputMaybe<LanguageWhereInput>;
};

export type QueryLanguagesEligibleForResourcesArgs = {
  orderBy?: InputMaybe<LanguageOrderByWithRelationInput>;
};

export type QueryListEmailTemplatesArgs = {
  skip?: Scalars["Int"];
  take?: Scalars["Int"];
  where?: InputMaybe<EmailTemplateWhereInput>;
};

export type QueryListMaintenanceTourStopsArgs = {
  fullTextSearch?: InputMaybe<Scalars["String"]>;
  isSecondRepair?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<MaintenanceTourStopOrderByInput>;
  skip?: Scalars["Int"];
  take?: Scalars["Int"];
  where?: InputMaybe<MaintenanceTourStopWhereInput>;
};

export type QueryListMutationLogsArgs = {
  filter?: InputMaybe<MutationLogFilterInput>;
  origin?: InputMaybe<MutationLogOriginEnum>;
  skip?: Scalars["Int"];
  take?: Scalars["Int"];
};

export type QueryListOrdersArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<Array<InputMaybe<OrdersOrderBy>>>;
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<OrdersFilter>;
};

export type QueryListTemplatesArgs = {
  skip?: Scalars["Int"];
  take?: Scalars["Int"];
  where?: InputMaybe<TemplateWhereInput>;
};

export type QueryListVouchersArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  allCodesExpired?: InputMaybe<Scalars["Boolean"]>;
  allCodesRedeemed?: InputMaybe<Scalars["Boolean"]>;
  categoryName?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["DateTime"]>;
  navisionAccount?: InputMaybe<Scalars["Int"]>;
  shopId?: InputMaybe<Scalars["Int"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
  type?: InputMaybe<VoucherType>;
  variantId?: InputMaybe<Scalars["Int"]>;
  voucherName?: InputMaybe<Scalars["String"]>;
};

export type QueryLowerMaterialGroupsArgs = {
  mainMaterialGroup: Scalars["String"];
  materialGroup: Scalars["String"];
};

export type QueryMaintenanceAbsencesArgs = {
  end?: InputMaybe<Scalars["DateTime"]>;
  includeOvertimeReduction?: Scalars["Boolean"];
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  start?: InputMaybe<Scalars["DateTime"]>;
  userId?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type QueryMaintenanceAppointmentArgs = {
  where: MaintenanceAppointmentWhereUniqueInput;
};

export type QueryMaintenanceAppointmentsArgs = {
  after?: InputMaybe<MaintenanceAppointmentWhereUniqueInput>;
  before?: InputMaybe<MaintenanceAppointmentWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceAppointmentOrderByInput>>;
  where?: InputMaybe<MaintenanceAppointmentWhereInput>;
};

export type QueryMaintenanceBlueprintArgs = {
  where: MaintenanceBlueprintWhereUniqueInput;
};

export type QueryMaintenanceBlueprintsArgs = {
  after?: InputMaybe<MaintenanceBlueprintWhereUniqueInput>;
  before?: InputMaybe<MaintenanceBlueprintWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceBlueprintOrderByInput>>;
  where?: InputMaybe<MaintenanceBlueprintWhereInput>;
};

export type QueryMaintenanceCustomerStopsPdfArgs = {
  where: MaintenanceCustomerStopsPdfInput;
};

export type QueryMaintenanceGroupArgs = {
  where: MaintenanceGroupWhereUniqueInput;
};

export type QueryMaintenanceGroupUserArgs = {
  where: MaintenanceGroupUserWhereUniqueInput;
};

export type QueryMaintenanceGroupUsersArgs = {
  after?: InputMaybe<MaintenanceGroupUserWhereUniqueInput>;
  before?: InputMaybe<MaintenanceGroupUserWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceGroupUserOrderByInput>>;
  where?: InputMaybe<MaintenanceGroupUserWhereInput>;
};

export type QueryMaintenanceGroupsArgs = {
  after?: InputMaybe<MaintenanceGroupWhereUniqueInput>;
  before?: InputMaybe<MaintenanceGroupWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceGroupOrderByInput>>;
  where?: InputMaybe<MaintenanceGroupWhereInput>;
};

export type QueryMaintenanceInspectionVsRepairStatisticArgs = {
  where?: InputMaybe<MaintenanceStatisticFilterInput>;
};

export type QueryMaintenanceOfferVsOrderStatisticArgs = {
  where?: InputMaybe<MaintenanceStatisticFilterInput>;
};

export type QueryMaintenanceProductArgs = {
  where: MaintenanceProductWhereUniqueInput;
};

export type QueryMaintenanceProductServiceArgs = {
  where: MaintenanceProductServiceWhereUniqueInput;
};

export type QueryMaintenanceProductServicesArgs = {
  after?: InputMaybe<MaintenanceProductServiceWhereUniqueInput>;
  before?: InputMaybe<MaintenanceProductServiceWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceProductServiceOrderByInput>>;
  where?: InputMaybe<MaintenanceProductServiceWhereInput>;
};

export type QueryMaintenanceProductsArgs = {
  after?: InputMaybe<MaintenanceProductWhereUniqueInput>;
  before?: InputMaybe<MaintenanceProductWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceProductOrderByInput>>;
  where?: InputMaybe<MaintenanceProductWhereInput>;
};

export type QueryMaintenanceReportArgs = {
  where: MaintenanceReportWhereUniqueInput;
};

export type QueryMaintenanceReportPdfArgs = {
  isInternal?: Scalars["Boolean"];
  pdfType?: InputMaybe<PdfType>;
  where: MaintenanceReportWhereUniqueInput;
};

export type QueryMaintenanceReportServiceSmallPartArgs = {
  where: MaintenanceReportServiceSmallPartWhereUniqueInput;
};

export type QueryMaintenanceReportServiceSmallPartsArgs = {
  after?: InputMaybe<MaintenanceReportServiceSmallPartWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportServiceSmallPartWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceReportServiceSmallPartOrderByInput>>;
  where?: InputMaybe<MaintenanceReportServiceSmallPartWhereInput>;
};

export type QueryMaintenanceReportSparePartBlueprintArgs = {
  where: MaintenanceReportSparePartBlueprintWhereUniqueInput;
};

export type QueryMaintenanceReportSparePartBlueprintsArgs = {
  after?: InputMaybe<MaintenanceReportSparePartBlueprintWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportSparePartBlueprintWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceReportSparePartBlueprintOrderByInput>>;
  where?: InputMaybe<MaintenanceReportSparePartBlueprintWhereInput>;
};

export type QueryMaintenanceReportsArgs = {
  after?: InputMaybe<MaintenanceReportWhereUniqueInput>;
  before?: InputMaybe<MaintenanceReportWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceReportOrderByInput>>;
  where?: InputMaybe<MaintenanceReportWhereInput>;
};

export type QueryMaintenanceResourceArgs = {
  where: MaintenanceResourceWhereUniqueInput;
};

export type QueryMaintenanceResourcesArgs = {
  after?: InputMaybe<MaintenanceResourceWhereUniqueInput>;
  before?: InputMaybe<MaintenanceResourceWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceResourceOrderByInput>>;
  where?: InputMaybe<MaintenanceResourceWhereInput>;
};

export type QueryMaintenanceRevenueForecastStatisticArgs = {
  where?: InputMaybe<MaintenanceStatisticFilterInput>;
};

export type QueryMaintenanceRevenueStatisticArgs = {
  where?: InputMaybe<MaintenanceStatisticFilterInput>;
};

export type QueryMaintenanceSecondRepairStatisticArgs = {
  where?: InputMaybe<MaintenanceSecondRepairStatisticFilterInput>;
};

export type QueryMaintenanceServiceSparePartArgs = {
  where: MaintenanceServiceSparePartWhereUniqueInput;
};

export type QueryMaintenanceServiceSparePartsArgs = {
  after?: InputMaybe<MaintenanceServiceSparePartWhereUniqueInput>;
  before?: InputMaybe<MaintenanceServiceSparePartWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceServiceSparePartOrderByInput>>;
  where?: InputMaybe<MaintenanceServiceSparePartWhereInput>;
};

export type QueryMaintenanceSmallPartArgs = {
  where: MaintenanceSmallPartWhereUniqueInput;
};

export type QueryMaintenanceSmallPartsArgs = {
  after?: InputMaybe<MaintenanceSmallPartWhereUniqueInput>;
  before?: InputMaybe<MaintenanceSmallPartWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceSmallPartOrderByInput>>;
  where?: InputMaybe<MaintenanceSmallPartWhereInput>;
};

export type QueryMaintenanceSynchronizationLogArgs = {
  where: MaintenanceSynchronizationLogWhereUniqueInput;
};

export type QueryMaintenanceSynchronizationLogsArgs = {
  after?: InputMaybe<MaintenanceSynchronizationLogWhereUniqueInput>;
  before?: InputMaybe<MaintenanceSynchronizationLogWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceSynchronizationLogOrderByInput>>;
  where?: InputMaybe<MaintenanceSynchronizationLogWhereInput>;
};

export type QueryMaintenanceTechnicianHolidaysArgs = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
  userId: Scalars["Int"];
};

export type QueryMaintenanceTechniciansArgs = {
  forEditor?: InputMaybe<Array<Scalars["Int"]>>;
};

export type QueryMaintenanceTourStopArgs = {
  where: MaintenanceTourStopWhereUniqueInput;
};

export type QueryMaintenanceTourStopAttachmentArgs = {
  where: MaintenanceTourStopAttachmentWhereUniqueInput;
};

export type QueryMaintenanceTourStopAttachmentsArgs = {
  after?: InputMaybe<MaintenanceTourStopAttachmentWhereUniqueInput>;
  before?: InputMaybe<MaintenanceTourStopAttachmentWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceTourStopAttachmentOrderByInput>>;
  where?: InputMaybe<MaintenanceTourStopAttachmentWhereInput>;
};

export type QueryMaintenanceTourStopPassengerArgs = {
  where: MaintenanceTourStopPassengerWhereUniqueInput;
};

export type QueryMaintenanceTourStopPassengersArgs = {
  after?: InputMaybe<MaintenanceTourStopPassengerWhereUniqueInput>;
  before?: InputMaybe<MaintenanceTourStopPassengerWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceTourStopPassengerOrderByInput>>;
  where?: InputMaybe<MaintenanceTourStopPassengerWhereInput>;
};

export type QueryMaintenanceTourStopRelationshipArgs = {
  where: MaintenanceTourStopRelationshipWhereUniqueInput;
};

export type QueryMaintenanceTourStopRelationshipsArgs = {
  after?: InputMaybe<MaintenanceTourStopRelationshipWhereUniqueInput>;
  before?: InputMaybe<MaintenanceTourStopRelationshipWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceTourStopRelationshipOrderByInput>>;
  where?: InputMaybe<MaintenanceTourStopRelationshipWhereInput>;
};

export type QueryMaintenanceTourStopsArgs = {
  after?: InputMaybe<MaintenanceTourStopWhereUniqueInput>;
  before?: InputMaybe<MaintenanceTourStopWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceTourStopOrderByInput>>;
  where?: InputMaybe<MaintenanceTourStopWhereInput>;
};

export type QueryMaintenanceUserArgs = {
  where: MaintenanceUserWhereUniqueInput;
};

export type QueryMaintenanceUserInformationArgs = {
  where: MaintenanceUserInformationWhereUniqueInput;
};

export type QueryMaintenanceUserInformationsArgs = {
  after?: InputMaybe<MaintenanceUserInformationWhereUniqueInput>;
  before?: InputMaybe<MaintenanceUserInformationWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceUserInformationOrderByInput>>;
  where?: InputMaybe<MaintenanceUserInformationWhereInput>;
};

export type QueryMaintenanceUsersArgs = {
  after?: InputMaybe<MaintenanceUserWhereUniqueInput>;
  before?: InputMaybe<MaintenanceUserWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceUserOrderByInput>>;
  where?: InputMaybe<MaintenanceUserWhereInput>;
};

export type QueryMaintenanceVariantArgs = {
  where: MaintenanceVariantWhereUniqueInput;
};

export type QueryMaintenanceVariantsArgs = {
  after?: InputMaybe<MaintenanceVariantWhereUniqueInput>;
  before?: InputMaybe<MaintenanceVariantWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MaintenanceVariantOrderByInput>>;
  where?: InputMaybe<MaintenanceVariantWhereInput>;
};

export type QueryMarketingChannelArgs = {
  where: MarketingChannelWhereUniqueInput;
};

export type QueryMarketingChannelsArgs = {
  after?: InputMaybe<MarketingChannelWhereUniqueInput>;
  before?: InputMaybe<MarketingChannelWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MarketingChannelOrderByInput>>;
  where?: InputMaybe<MarketingChannelWhereInput>;
};

export type QueryMaterialGroupsArgs = {
  mainMaterialGroup: Scalars["String"];
};

export type QueryMemberGroupArgs = {
  where: MemberGroupWhereUniqueInput;
};

export type QueryMemberGroupsArgs = {
  after?: InputMaybe<MemberGroupWhereUniqueInput>;
  before?: InputMaybe<MemberGroupWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryOciCustomerArgs = {
  where: OciCustomerWhereUniqueInput;
};

export type QueryOciCustomersArgs = {
  after?: InputMaybe<OciCustomerWhereUniqueInput>;
  before?: InputMaybe<OciCustomerWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OciCustomerOrderByInput>>;
  where?: InputMaybe<OciCustomerWhereInput>;
};

export type QueryOfficeLocationsArgs = {
  after?: InputMaybe<OfficeLocationWhereUniqueInput>;
  before?: InputMaybe<OfficeLocationWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OfficeLocationOrderByInput>>;
  where?: InputMaybe<OfficeLocationWhereInput>;
};

export type QueryOfficeVisitArgs = {
  where: OfficeVisitWhereUniqueInput;
};

export type QueryOfficeVisitsArgs = {
  after?: InputMaybe<OfficeVisitWhereUniqueInput>;
  before?: InputMaybe<OfficeVisitWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OfficeVisitOrderByInput>>;
  where?: InputMaybe<OfficeVisitWhereInput>;
};

export type QueryPermissionArgs = {
  where: PermissionWhereUniqueInput;
};

export type QueryPermissionsArgs = {
  after?: InputMaybe<PermissionWhereUniqueInput>;
  before?: InputMaybe<PermissionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionOrderByInput>>;
  where?: InputMaybe<PermissionWhereInput>;
};

export type QueryPhotoModelArgs = {
  where: PhotoModelWhereUniqueInput;
};

export type QueryPhotoModelContractArgs = {
  where: PhotoModelContractWhereUniqueInput;
};

export type QueryPhotoModelContractsArgs = {
  after?: InputMaybe<PhotoModelContractWhereUniqueInput>;
  before?: InputMaybe<PhotoModelContractWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PhotoModelContractOrderByWithRelationInput>>;
  where?: InputMaybe<PhotoModelContractWhereInput>;
};

export type QueryPhotoModelsArgs = {
  after?: InputMaybe<PhotoModelWhereUniqueInput>;
  before?: InputMaybe<PhotoModelWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PhotoModelOrderByWithRelationInput>>;
  where?: InputMaybe<PhotoModelWhereInput>;
};

export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};

export type QueryProductsArgs = {
  after?: InputMaybe<ProductWhereUniqueInput>;
  before?: InputMaybe<ProductWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductWhereInput>;
};

export type QueryQualitySignArgs = {
  where: QualitySignWhereUniqueInput;
};

export type QueryQualitySignResourceArgs = {
  where: QualitySignResourceWhereUniqueInput;
};

export type QueryQualitySignResourcesArgs = {
  after?: InputMaybe<QualitySignResourceWhereUniqueInput>;
  before?: InputMaybe<QualitySignResourceWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<QualitySignResourceWhereInput>;
};

export type QueryQualitySignsArgs = {
  after?: InputMaybe<QualitySignWhereUniqueInput>;
  before?: InputMaybe<QualitySignWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<QualitySignWhereInput>;
};

export type QueryResourceArgs = {
  where: ResourceWhereUniqueInput;
};

export type QueryResourceCollectionArgs = {
  where: ResourceCollectionWhereUniqueInput;
};

export type QueryResourceCollectionTagArgs = {
  where: ResourceCollectionTagWhereUniqueInput;
};

export type QueryResourceCollectionTagsArgs = {
  after?: InputMaybe<ResourceCollectionTagWhereUniqueInput>;
  before?: InputMaybe<ResourceCollectionTagWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceCollectionTagWhereInput>;
};

export type QueryResourceCollectionsArgs = {
  after?: InputMaybe<ResourceCollectionWhereUniqueInput>;
  before?: InputMaybe<ResourceCollectionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryResourceLanguageArgs = {
  where: ResourceLanguageWhereUniqueInput;
};

export type QueryResourceLanguagesArgs = {
  after?: InputMaybe<ResourceLanguageWhereUniqueInput>;
  before?: InputMaybe<ResourceLanguageWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceLanguageWhereInput>;
};

export type QueryResourceLicenseArgs = {
  where: ResourceLicenseWhereUniqueInput;
};

export type QueryResourceLicenseDefinitionArgs = {
  where: ResourceLicenseDefinitionWhereUniqueInput;
};

export type QueryResourceLicenseDefinitionsArgs = {
  after?: InputMaybe<ResourceLicenseDefinitionWhereUniqueInput>;
  before?: InputMaybe<ResourceLicenseDefinitionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<ResourceLicenseDefinitionOrderByWithRelationInput>
  >;
  where?: InputMaybe<ResourceLicenseDefinitionWhereInput>;
};

export type QueryResourceLicensesArgs = {
  after?: InputMaybe<ResourceLicenseWhereUniqueInput>;
  before?: InputMaybe<ResourceLicenseWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourceLicenseOrderByWithRelationInput>>;
  where?: InputMaybe<ResourceLicenseWhereInput>;
};

export type QueryResourcePdfCategoriesArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryResourcePhotoModelArgs = {
  where: ResourcePhotoModelWhereUniqueInput;
};

export type QueryResourcePhotoModelsArgs = {
  after?: InputMaybe<ResourcePhotoModelWhereUniqueInput>;
  before?: InputMaybe<ResourcePhotoModelWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourcePhotoModelOrderByWithRelationInput>>;
  where?: InputMaybe<ResourcePhotoModelWhereInput>;
};

export type QueryResourceProductArgs = {
  where: ResourceProductWhereUniqueInput;
};

export type QueryResourceProductsArgs = {
  after?: InputMaybe<ResourceProductWhereUniqueInput>;
  before?: InputMaybe<ResourceProductWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceProductWhereInput>;
};

export type QueryResourceTagArgs = {
  where: ResourceTagWhereUniqueInput;
};

export type QueryResourceTagsArgs = {
  after?: InputMaybe<ResourceTagWhereUniqueInput>;
  before?: InputMaybe<ResourceTagWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceTagWhereInput>;
};

export type QueryResourceTemplateArgs = {
  where: ResourceTemplateWhereUniqueInput;
};

export type QueryResourceTemplatesArgs = {
  after?: InputMaybe<ResourceTemplateWhereUniqueInput>;
  before?: InputMaybe<ResourceTemplateWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourceTemplateOrderByWithRelationInput>>;
  where?: InputMaybe<ResourceTemplateWhereInput>;
};

export type QueryResourceTextArgs = {
  id: Scalars["Int"];
  languageId: Scalars["Int"];
};

export type QueryResourceTextsArgs = {
  after?: InputMaybe<ResourceTextWhereUniqueInput>;
  before?: InputMaybe<ResourceTextWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceTextWhereInput>;
};

export type QueryResourceVersionArgs = {
  where: ResourceVersionWhereUniqueInput;
};

export type QueryResourceVersionByFilenameArgs = {
  fileName?: InputMaybe<Scalars["String"]>;
};

export type QueryResourceVersionsArgs = {
  after?: InputMaybe<ResourceVersionWhereUniqueInput>;
  before?: InputMaybe<ResourceVersionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryResourcesArgs = {
  after?: InputMaybe<ResourceWhereUniqueInput>;
  before?: InputMaybe<ResourceWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourceOrderByWithRelationInput>>;
  where?: InputMaybe<ResourceWhereInput>;
};

export type QueryRoleArgs = {
  where: RoleWhereUniqueInput;
};

export type QueryRolesArgs = {
  after?: InputMaybe<RoleWhereUniqueInput>;
  before?: InputMaybe<RoleWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<RoleOrderByInput>>;
};

export type QuerySalesVoucherCodesArgs = {
  code?: InputMaybe<StringFilter>;
  page?: InputMaybe<Scalars["Int"]>;
  shopId: Scalars["Int"];
  take?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchCollectionsArgs = {
  last?: InputMaybe<Scalars["Int"]>;
  q: Scalars["String"];
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchGroupRolesArgs = {
  order?: InputMaybe<SortOrder>;
  pageGroup?: InputMaybe<Scalars["Int"]>;
  pageRole?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
  take?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchGroupsArgs = {
  order?: InputMaybe<SortOrder>;
  page?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
  take?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchResourcesArgs = {
  last?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchRolesArgs = {
  order?: InputMaybe<SortOrder>;
  page: Scalars["Int"];
  query: Scalars["String"];
  take?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchTypeCatalogsArgs = {
  orderBy?: InputMaybe<TypeCatalogOrderByInput>;
  page?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TypeCatalogWhereInput>;
};

export type QueryServiceArgs = {
  where: ServiceWhereUniqueInput;
};

export type QueryServicePermissionsByUserEmailArgs = {
  email: Scalars["String"];
  serviceId: Scalars["Int"];
};

export type QueryServicesArgs = {
  after?: InputMaybe<ServiceWhereUniqueInput>;
  before?: InputMaybe<ServiceWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryShopEligibleForOciArgs = {
  where: ShopEligibleForOciWhereUniqueInput;
};

export type QueryShopEligibleForOcisArgs = {
  after?: InputMaybe<ShopEligibleForOciWhereUniqueInput>;
  before?: InputMaybe<ShopEligibleForOciWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShopEligibleForOciOrderByInput>>;
  where?: InputMaybe<ShopEligibleForOciWhereInput>;
};

export type QuerySimilarTagsArgs = {
  keyword: Scalars["String"];
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QuerySlugArgs = {
  where: SlugWhereUniqueInput;
};

export type QuerySuggestResourceTagsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  minimumConfidence?: InputMaybe<Scalars["Int"]>;
  resourceId: Scalars["Int"];
};

export type QueryTagsArgs = {
  orderBy?: InputMaybe<TagOrderByWithRelationInput>;
  skip?: Scalars["Int"];
  take?: Scalars["Int"];
  where?: InputMaybe<TagWhereInput>;
};

export type QueryTemplateArgs = {
  where: TemplateWhereUniqueInput;
};

export type QueryTemplatesArgs = {
  after?: InputMaybe<TemplateWhereUniqueInput>;
  before?: InputMaybe<TemplateWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TemplateOrderByInput>>;
  where?: InputMaybe<TemplateWhereInput>;
};

export type QueryTypeCatalogArgs = {
  where: TypeCatalogWhereUniqueInput;
};

export type QueryTypeCatalogsArgs = {
  after?: InputMaybe<TypeCatalogWhereUniqueInput>;
  before?: InputMaybe<TypeCatalogWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TypeCatalogOrderByInput>>;
  where?: InputMaybe<TypeCatalogWhereInput>;
};

export type QueryTypeCatalogsByServiceArgs = {
  serviceId: Scalars["Int"];
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryUsersByIdArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
};

export type QueryVariantArgs = {
  where: VariantWhereUniqueInput;
};

export type QueryVariantCo2InformationArgs = {
  where: VariantCo2InformationWhereUniqueInput;
};

export type QueryVariantCo2InformationsArgs = {
  after?: InputMaybe<VariantCo2InformationWhereUniqueInput>;
  before?: InputMaybe<VariantCo2InformationWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<VariantCo2InformationOrderByInput>>;
  where?: InputMaybe<VariantCo2InformationWhereInput>;
};

export type QueryVariantMaterialArgs = {
  where: VariantMaterialWhereUniqueInput;
};

export type QueryVariantMaterialDefinitionArgs = {
  where: VariantMaterialDefinitionWhereUniqueInput;
};

export type QueryVariantMaterialDefinitionsArgs = {
  after?: InputMaybe<VariantMaterialDefinitionWhereUniqueInput>;
  before?: InputMaybe<VariantMaterialDefinitionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<VariantMaterialDefinitionOrderByInput>>;
  where?: InputMaybe<VariantMaterialDefinitionWhereInput>;
};

export type QueryVariantMaterialsArgs = {
  after?: InputMaybe<VariantMaterialWhereUniqueInput>;
  before?: InputMaybe<VariantMaterialWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<VariantMaterialOrderByInput>>;
  where?: InputMaybe<VariantMaterialWhereInput>;
};

export type QueryVariantWithout100PercentMaterialsArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryVariantsArgs = {
  after?: InputMaybe<VariantWhereUniqueInput>;
  before?: InputMaybe<VariantWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VariantWhereInput>;
};

export type QueryVoucherArgs = {
  id: Scalars["Int"];
};

export type QueryVoucherCodesArgs = {
  after?: InputMaybe<VoucherCodeWhereUniqueInput>;
  before?: InputMaybe<VoucherCodeWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<VoucherCodeOrderByInput>>;
  where?: InputMaybe<VoucherCodeWhereInput>;
};

export type QueryZipImagesStatusArgs = {
  key: Scalars["String"];
};

export type Reference = {
  __typename?: "Reference";
  reference?: Maybe<Scalars["String"]>;
  runtimeFrom?: Maybe<Scalars["DateTime"]>;
  runtimeTo?: Maybe<Scalars["DateTime"]>;
};

export enum ReportServiceSparePartResourceType {
  Image = "IMAGE",
  Signature = "SIGNATURE",
  Sketch = "SKETCH",
}

export type Resource = {
  __typename?: "Resource";
  blocked: Scalars["Boolean"];
  brand: Array<Brand>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  collections?: Maybe<Array<Maybe<ResourceCollection>>>;
  connections?: Maybe<Array<Maybe<ResourceConnection>>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  currentVersionId?: Maybe<Scalars["Int"]>;
  downloadUrl?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  image?: Maybe<Scalars["String"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
  isUserGenerated: Scalars["Boolean"];
  languages?: Maybe<Array<Maybe<Language>>>;
  noIndex: Scalars["Boolean"];
  productGroups: Array<ResourceProductGroup>;
  products: Array<ResourceProduct>;
  qualitySigns?: Maybe<Array<Maybe<QualitySign>>>;
  releaseDate?: Maybe<Scalars["DateTime"]>;
  resourceLanguage: Array<ResourceLanguage>;
  resourceLicenses: Array<ResourceLicense>;
  resourcePdfCategory?: Maybe<ResourcePdfCategory>;
  resourcePhotoModels: Array<ResourcePhotoModel>;
  slugs: Array<Slug>;
  tags: Array<ResourceTag>;
  texts?: Maybe<Array<Maybe<CResourceText>>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  validUntilDate?: Maybe<Scalars["DateTime"]>;
  variants: Array<ResourceVariant>;
  versions: Array<ResourceVersion>;
};

export type ResourceBrandArgs = {
  after?: InputMaybe<BrandWhereUniqueInput>;
  before?: InputMaybe<BrandWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ResourceImageArgs = {
  template?: InputMaybe<Scalars["String"]>;
};

export type ResourceProductGroupsArgs = {
  after?: InputMaybe<ResourceProductGroupWhereUniqueInput>;
  before?: InputMaybe<ResourceProductGroupWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceProductGroupWhereInput>;
};

export type ResourceProductsArgs = {
  after?: InputMaybe<ResourceProductWhereUniqueInput>;
  before?: InputMaybe<ResourceProductWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceProductWhereInput>;
};

export type ResourceResourceLanguageArgs = {
  after?: InputMaybe<ResourceLanguageWhereUniqueInput>;
  before?: InputMaybe<ResourceLanguageWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ResourceResourceLicensesArgs = {
  after?: InputMaybe<ResourceLicenseWhereUniqueInput>;
  before?: InputMaybe<ResourceLicenseWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourceLicenseOrderByWithRelationInput>>;
  where?: InputMaybe<ResourceLicenseWhereInput>;
};

export type ResourceResourcePhotoModelsArgs = {
  after?: InputMaybe<ResourcePhotoModelWhereUniqueInput>;
  before?: InputMaybe<ResourcePhotoModelWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourcePhotoModelOrderByWithRelationInput>>;
  where?: InputMaybe<ResourcePhotoModelWhereInput>;
};

export type ResourceSlugsArgs = {
  after?: InputMaybe<SlugWhereUniqueInput>;
  before?: InputMaybe<SlugWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<SlugWhereInput>;
};

export type ResourceTagsArgs = {
  after?: InputMaybe<ResourceTagWhereUniqueInput>;
  before?: InputMaybe<ResourceTagWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourceTagOrderByWithRelationInput>>;
  where?: InputMaybe<ResourceTagWhereInput>;
};

export type ResourceTextsArgs = {
  languageId?: InputMaybe<Scalars["Int"]>;
};

export type ResourceVariantsArgs = {
  after?: InputMaybe<ResourceVariantWhereUniqueInput>;
  before?: InputMaybe<ResourceVariantWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceVariantWhereInput>;
};

export type ResourceVersionsArgs = {
  after?: InputMaybe<ResourceVersionWhereUniqueInput>;
  before?: InputMaybe<ResourceVersionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ResourceVersionWhereInput>;
};

/** A collection of resources that can be granted access to */
export type ResourceCollection = {
  __typename?: "ResourceCollection";
  collectionsTags: Array<ResourceCollectionTag>;
  createdAt: Scalars["DateTime"];
  creator: User;
  grants: Array<ResourceCollectionAccess>;
  id: Scalars["Int"];
  image?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  resources?: Maybe<Array<Maybe<Resource>>>;
};

/** A collection of resources that can be granted access to */
export type ResourceCollectionCollectionsTagsArgs = {
  after?: InputMaybe<ResourceCollectionTagWhereUniqueInput>;
  before?: InputMaybe<ResourceCollectionTagWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourceCollectionTagOrderByWithRelationInput>>;
  where?: InputMaybe<ResourceCollectionTagWhereInput>;
};

/** A collection of resources that can be granted access to */
export type ResourceCollectionGrantsArgs = {
  after?: InputMaybe<ResourceCollectionAccessWhereUniqueInput>;
  before?: InputMaybe<ResourceCollectionAccessWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A collection of resources that can be granted access to */
export type ResourceCollectionResourcesArgs = {
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type ResourceCollectionAccess = {
  __typename?: "ResourceCollectionAccess";
  collection: ResourceCollection;
  company?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  firstname?: Maybe<Scalars["String"]>;
  grantedBy: User;
  id: Scalars["Int"];
  lastname?: Maybe<Scalars["String"]>;
  privateNote?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  url: Scalars["String"];
  validUntil?: Maybe<Scalars["DateTime"]>;
};

export type ResourceCollectionAccessCreateManyCollectionInput = {
  company?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  firstname?: InputMaybe<Scalars["String"]>;
  grantedById: Scalars["Int"];
  id?: InputMaybe<Scalars["Int"]>;
  lastname?: InputMaybe<Scalars["String"]>;
  privateNote?: InputMaybe<Scalars["String"]>;
  token: Scalars["String"];
  url: Scalars["String"];
  validUntil?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceCollectionAccessCreateManyCollectionInputEnvelope = {
  data?: InputMaybe<Array<ResourceCollectionAccessCreateManyCollectionInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceCollectionAccessCreateManyGrantedByInput = {
  collectionId: Scalars["Int"];
  company?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  firstname?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  lastname?: InputMaybe<Scalars["String"]>;
  privateNote?: InputMaybe<Scalars["String"]>;
  token: Scalars["String"];
  url: Scalars["String"];
  validUntil?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceCollectionAccessCreateManyGrantedByInputEnvelope = {
  data?: InputMaybe<Array<ResourceCollectionAccessCreateManyGrantedByInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceCollectionAccessCreateNestedManyWithoutCollectionInput = {
  connect?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionAccessCreateOrConnectWithoutCollectionInput>
  >;
  create?: InputMaybe<
    Array<ResourceCollectionAccessCreateWithoutCollectionInput>
  >;
  createMany?: InputMaybe<ResourceCollectionAccessCreateManyCollectionInputEnvelope>;
};

export type ResourceCollectionAccessCreateNestedManyWithoutGrantedByInput = {
  connect?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionAccessCreateOrConnectWithoutGrantedByInput>
  >;
  create?: InputMaybe<
    Array<ResourceCollectionAccessCreateWithoutGrantedByInput>
  >;
  createMany?: InputMaybe<ResourceCollectionAccessCreateManyGrantedByInputEnvelope>;
};

export type ResourceCollectionAccessCreateOrConnectWithoutCollectionInput = {
  create: ResourceCollectionAccessCreateWithoutCollectionInput;
  where: ResourceCollectionAccessWhereUniqueInput;
};

export type ResourceCollectionAccessCreateOrConnectWithoutGrantedByInput = {
  create: ResourceCollectionAccessCreateWithoutGrantedByInput;
  where: ResourceCollectionAccessWhereUniqueInput;
};

export type ResourceCollectionAccessCreateWithoutCollectionInput = {
  company?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  firstname?: InputMaybe<Scalars["String"]>;
  grantedBy: UserCreateNestedOneWithoutResourceCollectionGrantsInput;
  lastname?: InputMaybe<Scalars["String"]>;
  privateNote?: InputMaybe<Scalars["String"]>;
  token: Scalars["String"];
  url: Scalars["String"];
  validUntil?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceCollectionAccessCreateWithoutGrantedByInput = {
  collection: ResourceCollectionCreateNestedOneWithoutGrantsInput;
  company?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  firstname?: InputMaybe<Scalars["String"]>;
  lastname?: InputMaybe<Scalars["String"]>;
  privateNote?: InputMaybe<Scalars["String"]>;
  token: Scalars["String"];
  url: Scalars["String"];
  validUntil?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceCollectionAccessListRelationFilter = {
  every?: InputMaybe<ResourceCollectionAccessWhereInput>;
  none?: InputMaybe<ResourceCollectionAccessWhereInput>;
  some?: InputMaybe<ResourceCollectionAccessWhereInput>;
};

export type ResourceCollectionAccessOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceCollectionAccessScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceCollectionAccessScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCollectionAccessScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceCollectionAccessScalarWhereInput>>;
  collectionId?: InputMaybe<IntFilter>;
  company?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  firstname?: InputMaybe<StringNullableFilter>;
  grantedById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  lastname?: InputMaybe<StringNullableFilter>;
  privateNote?: InputMaybe<StringNullableFilter>;
  token?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
  validUntil?: InputMaybe<DateTimeNullableFilter>;
};

export type ResourceCollectionAccessUpdateManyMutationInput = {
  company?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privateNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  validUntil?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceCollectionAccessUpdateManyWithWhereWithoutCollectionInput =
  {
    data: ResourceCollectionAccessUpdateManyMutationInput;
    where: ResourceCollectionAccessScalarWhereInput;
  };

export type ResourceCollectionAccessUpdateManyWithWhereWithoutGrantedByInput = {
  data: ResourceCollectionAccessUpdateManyMutationInput;
  where: ResourceCollectionAccessScalarWhereInput;
};

export type ResourceCollectionAccessUpdateManyWithoutCollectionInput = {
  connect?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionAccessCreateOrConnectWithoutCollectionInput>
  >;
  create?: InputMaybe<
    Array<ResourceCollectionAccessCreateWithoutCollectionInput>
  >;
  createMany?: InputMaybe<ResourceCollectionAccessCreateManyCollectionInputEnvelope>;
  delete?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceCollectionAccessScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceCollectionAccessUpdateWithWhereUniqueWithoutCollectionInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceCollectionAccessUpdateManyWithWhereWithoutCollectionInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceCollectionAccessUpsertWithWhereUniqueWithoutCollectionInput>
  >;
};

export type ResourceCollectionAccessUpdateManyWithoutGrantedByInput = {
  connect?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionAccessCreateOrConnectWithoutGrantedByInput>
  >;
  create?: InputMaybe<
    Array<ResourceCollectionAccessCreateWithoutGrantedByInput>
  >;
  createMany?: InputMaybe<ResourceCollectionAccessCreateManyGrantedByInputEnvelope>;
  delete?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceCollectionAccessScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceCollectionAccessWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceCollectionAccessUpdateWithWhereUniqueWithoutGrantedByInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceCollectionAccessUpdateManyWithWhereWithoutGrantedByInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceCollectionAccessUpsertWithWhereUniqueWithoutGrantedByInput>
  >;
};

export type ResourceCollectionAccessUpdateWithWhereUniqueWithoutCollectionInput =
  {
    data: ResourceCollectionAccessUpdateWithoutCollectionInput;
    where: ResourceCollectionAccessWhereUniqueInput;
  };

export type ResourceCollectionAccessUpdateWithWhereUniqueWithoutGrantedByInput =
  {
    data: ResourceCollectionAccessUpdateWithoutGrantedByInput;
    where: ResourceCollectionAccessWhereUniqueInput;
  };

export type ResourceCollectionAccessUpdateWithoutCollectionInput = {
  company?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  grantedBy?: InputMaybe<UserUpdateOneRequiredWithoutResourceCollectionGrantsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privateNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  validUntil?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceCollectionAccessUpdateWithoutGrantedByInput = {
  collection?: InputMaybe<ResourceCollectionUpdateOneRequiredWithoutGrantsInput>;
  company?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privateNote?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  validUntil?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceCollectionAccessUpsertWithWhereUniqueWithoutCollectionInput =
  {
    create: ResourceCollectionAccessCreateWithoutCollectionInput;
    update: ResourceCollectionAccessUpdateWithoutCollectionInput;
    where: ResourceCollectionAccessWhereUniqueInput;
  };

export type ResourceCollectionAccessUpsertWithWhereUniqueWithoutGrantedByInput =
  {
    create: ResourceCollectionAccessCreateWithoutGrantedByInput;
    update: ResourceCollectionAccessUpdateWithoutGrantedByInput;
    where: ResourceCollectionAccessWhereUniqueInput;
  };

export type ResourceCollectionAccessWhereInput = {
  AND?: InputMaybe<Array<ResourceCollectionAccessWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCollectionAccessWhereInput>>;
  OR?: InputMaybe<Array<ResourceCollectionAccessWhereInput>>;
  collection?: InputMaybe<ResourceCollectionWhereInput>;
  collectionId?: InputMaybe<IntFilter>;
  company?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  firstname?: InputMaybe<StringNullableFilter>;
  grantedBy?: InputMaybe<UserWhereInput>;
  grantedById?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  lastname?: InputMaybe<StringNullableFilter>;
  privateNote?: InputMaybe<StringNullableFilter>;
  token?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
  validUntil?: InputMaybe<DateTimeNullableFilter>;
};

export type ResourceCollectionAccessWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

export type ResourceCollectionCreateManyCreatorInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type ResourceCollectionCreateManyCreatorInputEnvelope = {
  data?: InputMaybe<Array<ResourceCollectionCreateManyCreatorInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceCollectionCreateNestedManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<ResourceCollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionCreateOrConnectWithoutCreatorInput>
  >;
  create?: InputMaybe<Array<ResourceCollectionCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<ResourceCollectionCreateManyCreatorInputEnvelope>;
};

export type ResourceCollectionCreateNestedOneWithoutCollectionsTagsInput = {
  connect?: InputMaybe<ResourceCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCollectionCreateOrConnectWithoutCollectionsTagsInput>;
  create?: InputMaybe<ResourceCollectionCreateWithoutCollectionsTagsInput>;
};

export type ResourceCollectionCreateNestedOneWithoutGrantsInput = {
  connect?: InputMaybe<ResourceCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCollectionCreateOrConnectWithoutGrantsInput>;
  create?: InputMaybe<ResourceCollectionCreateWithoutGrantsInput>;
};

export type ResourceCollectionCreateNestedOneWithoutResourcesInput = {
  connect?: InputMaybe<ResourceCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCollectionCreateOrConnectWithoutResourcesInput>;
  create?: InputMaybe<ResourceCollectionCreateWithoutResourcesInput>;
};

export type ResourceCollectionCreateOrConnectWithoutCollectionsTagsInput = {
  create: ResourceCollectionCreateWithoutCollectionsTagsInput;
  where: ResourceCollectionWhereUniqueInput;
};

export type ResourceCollectionCreateOrConnectWithoutCreatorInput = {
  create: ResourceCollectionCreateWithoutCreatorInput;
  where: ResourceCollectionWhereUniqueInput;
};

export type ResourceCollectionCreateOrConnectWithoutGrantsInput = {
  create: ResourceCollectionCreateWithoutGrantsInput;
  where: ResourceCollectionWhereUniqueInput;
};

export type ResourceCollectionCreateOrConnectWithoutResourcesInput = {
  create: ResourceCollectionCreateWithoutResourcesInput;
  where: ResourceCollectionWhereUniqueInput;
};

export type ResourceCollectionCreateWithoutCollectionsTagsInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  creator: UserCreateNestedOneWithoutResourceCollectionsInput;
  grants?: InputMaybe<ResourceCollectionAccessCreateNestedManyWithoutCollectionInput>;
  name: Scalars["String"];
  resources?: InputMaybe<CollectionResourceCreateNestedManyWithoutCollectionInput>;
};

export type ResourceCollectionCreateWithoutCreatorInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagCreateNestedManyWithoutCollectionInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  grants?: InputMaybe<ResourceCollectionAccessCreateNestedManyWithoutCollectionInput>;
  name: Scalars["String"];
  resources?: InputMaybe<CollectionResourceCreateNestedManyWithoutCollectionInput>;
};

export type ResourceCollectionCreateWithoutGrantsInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagCreateNestedManyWithoutCollectionInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  creator: UserCreateNestedOneWithoutResourceCollectionsInput;
  name: Scalars["String"];
  resources?: InputMaybe<CollectionResourceCreateNestedManyWithoutCollectionInput>;
};

export type ResourceCollectionCreateWithoutResourcesInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagCreateNestedManyWithoutCollectionInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  creator: UserCreateNestedOneWithoutResourceCollectionsInput;
  grants?: InputMaybe<ResourceCollectionAccessCreateNestedManyWithoutCollectionInput>;
  name: Scalars["String"];
};

export type ResourceCollectionListRelationFilter = {
  every?: InputMaybe<ResourceCollectionWhereInput>;
  none?: InputMaybe<ResourceCollectionWhereInput>;
  some?: InputMaybe<ResourceCollectionWhereInput>;
};

export type ResourceCollectionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceCollectionOrderByWithRelationInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  creator?: InputMaybe<UserOrderByWithRelationInput>;
  creatorId?: InputMaybe<SortOrder>;
  grants?: InputMaybe<ResourceCollectionAccessOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  resources?: InputMaybe<CollectionResourceOrderByRelationAggregateInput>;
};

export type ResourceCollectionScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceCollectionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCollectionScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceCollectionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ResourceCollectionTag = {
  __typename?: "ResourceCollectionTag";
  collection: ResourceCollection;
  collection_id: Scalars["Int"];
  tag?: Maybe<Scalars["String"]>;
  tag_id: Scalars["Int"];
};

export type ResourceCollectionTagCollection_IdTag_IdCompoundUniqueInput = {
  collection_id: Scalars["Int"];
  tag_id: Scalars["Int"];
};

export type ResourceCollectionTagCreateManyCollectionInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  tag_id: Scalars["Int"];
};

export type ResourceCollectionTagCreateManyCollectionInputEnvelope = {
  data?: InputMaybe<Array<ResourceCollectionTagCreateManyCollectionInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceCollectionTagCreateManyTagInput = {
  collection_id: Scalars["Int"];
  createdAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceCollectionTagCreateManyTagInputEnvelope = {
  data?: InputMaybe<Array<ResourceCollectionTagCreateManyTagInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceCollectionTagCreateNestedManyWithoutCollectionInput = {
  connect?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionTagCreateOrConnectWithoutCollectionInput>
  >;
  create?: InputMaybe<Array<ResourceCollectionTagCreateWithoutCollectionInput>>;
  createMany?: InputMaybe<ResourceCollectionTagCreateManyCollectionInputEnvelope>;
};

export type ResourceCollectionTagCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionTagCreateOrConnectWithoutTagInput>
  >;
  create?: InputMaybe<Array<ResourceCollectionTagCreateWithoutTagInput>>;
  createMany?: InputMaybe<ResourceCollectionTagCreateManyTagInputEnvelope>;
};

export type ResourceCollectionTagCreateOrConnectWithoutCollectionInput = {
  create: ResourceCollectionTagCreateWithoutCollectionInput;
  where: ResourceCollectionTagWhereUniqueInput;
};

export type ResourceCollectionTagCreateOrConnectWithoutTagInput = {
  create: ResourceCollectionTagCreateWithoutTagInput;
  where: ResourceCollectionTagWhereUniqueInput;
};

export type ResourceCollectionTagCreateWithoutCollectionInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  tag: TagCreateNestedOneWithoutCollectionsTagsInput;
};

export type ResourceCollectionTagCreateWithoutTagInput = {
  collection: ResourceCollectionCreateNestedOneWithoutCollectionsTagsInput;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceCollectionTagListRelationFilter = {
  every?: InputMaybe<ResourceCollectionTagWhereInput>;
  none?: InputMaybe<ResourceCollectionTagWhereInput>;
  some?: InputMaybe<ResourceCollectionTagWhereInput>;
};

export type ResourceCollectionTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceCollectionTagOrderByWithRelationInput = {
  collection?: InputMaybe<ResourceCollectionOrderByWithRelationInput>;
  collection_id?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  tag?: InputMaybe<TagOrderByWithRelationInput>;
  tag_id?: InputMaybe<SortOrder>;
};

export type ResourceCollectionTagScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceCollectionTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCollectionTagScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceCollectionTagScalarWhereInput>>;
  collection_id?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  tag_id?: InputMaybe<IntFilter>;
};

export type ResourceCollectionTagUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceCollectionTagUpdateManyWithWhereWithoutCollectionInput = {
  data: ResourceCollectionTagUpdateManyMutationInput;
  where: ResourceCollectionTagScalarWhereInput;
};

export type ResourceCollectionTagUpdateManyWithWhereWithoutTagInput = {
  data: ResourceCollectionTagUpdateManyMutationInput;
  where: ResourceCollectionTagScalarWhereInput;
};

export type ResourceCollectionTagUpdateManyWithoutCollectionInput = {
  connect?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionTagCreateOrConnectWithoutCollectionInput>
  >;
  create?: InputMaybe<Array<ResourceCollectionTagCreateWithoutCollectionInput>>;
  createMany?: InputMaybe<ResourceCollectionTagCreateManyCollectionInputEnvelope>;
  delete?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceCollectionTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceCollectionTagUpdateWithWhereUniqueWithoutCollectionInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceCollectionTagUpdateManyWithWhereWithoutCollectionInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceCollectionTagUpsertWithWhereUniqueWithoutCollectionInput>
  >;
};

export type ResourceCollectionTagUpdateManyWithoutTagInput = {
  connect?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionTagCreateOrConnectWithoutTagInput>
  >;
  create?: InputMaybe<Array<ResourceCollectionTagCreateWithoutTagInput>>;
  createMany?: InputMaybe<ResourceCollectionTagCreateManyTagInputEnvelope>;
  delete?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceCollectionTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceCollectionTagWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceCollectionTagUpdateWithWhereUniqueWithoutTagInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceCollectionTagUpdateManyWithWhereWithoutTagInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceCollectionTagUpsertWithWhereUniqueWithoutTagInput>
  >;
};

export type ResourceCollectionTagUpdateWithWhereUniqueWithoutCollectionInput = {
  data: ResourceCollectionTagUpdateWithoutCollectionInput;
  where: ResourceCollectionTagWhereUniqueInput;
};

export type ResourceCollectionTagUpdateWithWhereUniqueWithoutTagInput = {
  data: ResourceCollectionTagUpdateWithoutTagInput;
  where: ResourceCollectionTagWhereUniqueInput;
};

export type ResourceCollectionTagUpdateWithoutCollectionInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  tag?: InputMaybe<TagUpdateOneRequiredWithoutCollectionsTagsInput>;
};

export type ResourceCollectionTagUpdateWithoutTagInput = {
  collection?: InputMaybe<ResourceCollectionUpdateOneRequiredWithoutCollectionsTagsInput>;
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceCollectionTagUpsertWithWhereUniqueWithoutCollectionInput = {
  create: ResourceCollectionTagCreateWithoutCollectionInput;
  update: ResourceCollectionTagUpdateWithoutCollectionInput;
  where: ResourceCollectionTagWhereUniqueInput;
};

export type ResourceCollectionTagUpsertWithWhereUniqueWithoutTagInput = {
  create: ResourceCollectionTagCreateWithoutTagInput;
  update: ResourceCollectionTagUpdateWithoutTagInput;
  where: ResourceCollectionTagWhereUniqueInput;
};

export type ResourceCollectionTagWhereInput = {
  AND?: InputMaybe<Array<ResourceCollectionTagWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCollectionTagWhereInput>>;
  OR?: InputMaybe<Array<ResourceCollectionTagWhereInput>>;
  collection?: InputMaybe<ResourceCollectionWhereInput>;
  collection_id?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  tag?: InputMaybe<TagWhereInput>;
  tag_id?: InputMaybe<IntFilter>;
};

export type ResourceCollectionTagWhereUniqueInput = {
  collection_id_tag_id?: InputMaybe<ResourceCollectionTagCollection_IdTag_IdCompoundUniqueInput>;
};

export type ResourceCollectionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceCollectionUpdateManyWithWhereWithoutCreatorInput = {
  data: ResourceCollectionUpdateManyMutationInput;
  where: ResourceCollectionScalarWhereInput;
};

export type ResourceCollectionUpdateManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<ResourceCollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCollectionCreateOrConnectWithoutCreatorInput>
  >;
  create?: InputMaybe<Array<ResourceCollectionCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<ResourceCollectionCreateManyCreatorInputEnvelope>;
  delete?: InputMaybe<Array<ResourceCollectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceCollectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceCollectionWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceCollectionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceCollectionUpdateWithWhereUniqueWithoutCreatorInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceCollectionUpdateManyWithWhereWithoutCreatorInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceCollectionUpsertWithWhereUniqueWithoutCreatorInput>
  >;
};

export type ResourceCollectionUpdateOneRequiredWithoutCollectionsTagsInput = {
  connect?: InputMaybe<ResourceCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCollectionCreateOrConnectWithoutCollectionsTagsInput>;
  create?: InputMaybe<ResourceCollectionCreateWithoutCollectionsTagsInput>;
  update?: InputMaybe<ResourceCollectionUpdateWithoutCollectionsTagsInput>;
  upsert?: InputMaybe<ResourceCollectionUpsertWithoutCollectionsTagsInput>;
};

export type ResourceCollectionUpdateOneRequiredWithoutGrantsInput = {
  connect?: InputMaybe<ResourceCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCollectionCreateOrConnectWithoutGrantsInput>;
  create?: InputMaybe<ResourceCollectionCreateWithoutGrantsInput>;
  update?: InputMaybe<ResourceCollectionUpdateWithoutGrantsInput>;
  upsert?: InputMaybe<ResourceCollectionUpsertWithoutGrantsInput>;
};

export type ResourceCollectionUpdateOneRequiredWithoutResourcesInput = {
  connect?: InputMaybe<ResourceCollectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCollectionCreateOrConnectWithoutResourcesInput>;
  create?: InputMaybe<ResourceCollectionCreateWithoutResourcesInput>;
  update?: InputMaybe<ResourceCollectionUpdateWithoutResourcesInput>;
  upsert?: InputMaybe<ResourceCollectionUpsertWithoutResourcesInput>;
};

export type ResourceCollectionUpdateWithWhereUniqueWithoutCreatorInput = {
  data: ResourceCollectionUpdateWithoutCreatorInput;
  where: ResourceCollectionWhereUniqueInput;
};

export type ResourceCollectionUpdateWithoutCollectionsTagsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutResourceCollectionsInput>;
  grants?: InputMaybe<ResourceCollectionAccessUpdateManyWithoutCollectionInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  resources?: InputMaybe<CollectionResourceUpdateManyWithoutCollectionInput>;
};

export type ResourceCollectionUpdateWithoutCreatorInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagUpdateManyWithoutCollectionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  grants?: InputMaybe<ResourceCollectionAccessUpdateManyWithoutCollectionInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  resources?: InputMaybe<CollectionResourceUpdateManyWithoutCollectionInput>;
};

export type ResourceCollectionUpdateWithoutGrantsInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagUpdateManyWithoutCollectionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutResourceCollectionsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  resources?: InputMaybe<CollectionResourceUpdateManyWithoutCollectionInput>;
};

export type ResourceCollectionUpdateWithoutResourcesInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagUpdateManyWithoutCollectionInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutResourceCollectionsInput>;
  grants?: InputMaybe<ResourceCollectionAccessUpdateManyWithoutCollectionInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ResourceCollectionUpsertWithWhereUniqueWithoutCreatorInput = {
  create: ResourceCollectionCreateWithoutCreatorInput;
  update: ResourceCollectionUpdateWithoutCreatorInput;
  where: ResourceCollectionWhereUniqueInput;
};

export type ResourceCollectionUpsertWithoutCollectionsTagsInput = {
  create: ResourceCollectionCreateWithoutCollectionsTagsInput;
  update: ResourceCollectionUpdateWithoutCollectionsTagsInput;
};

export type ResourceCollectionUpsertWithoutGrantsInput = {
  create: ResourceCollectionCreateWithoutGrantsInput;
  update: ResourceCollectionUpdateWithoutGrantsInput;
};

export type ResourceCollectionUpsertWithoutResourcesInput = {
  create: ResourceCollectionCreateWithoutResourcesInput;
  update: ResourceCollectionUpdateWithoutResourcesInput;
};

export type ResourceCollectionWhereInput = {
  AND?: InputMaybe<Array<ResourceCollectionWhereInput>>;
  NOT?: InputMaybe<Array<ResourceCollectionWhereInput>>;
  OR?: InputMaybe<Array<ResourceCollectionWhereInput>>;
  collectionsTags?: InputMaybe<ResourceCollectionTagListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserWhereInput>;
  creatorId?: InputMaybe<IntFilter>;
  grants?: InputMaybe<ResourceCollectionAccessListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  resources?: InputMaybe<CollectionResourceListRelationFilter>;
};

export type ResourceCollectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceConnection =
  | ResourceProduct
  | ResourceProductGroup
  | ResourceVariant;

export type ResourceCreateInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceCreateManyParentResourceInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceCreateManyParentResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceCreateManyParentResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceCreateNestedManyWithoutParentResourceInput = {
  connect?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCreateOrConnectWithoutParentResourceInput>
  >;
  create?: InputMaybe<Array<ResourceCreateWithoutParentResourceInput>>;
  createMany?: InputMaybe<ResourceCreateManyParentResourceInputEnvelope>;
};

export type ResourceCreateNestedOneWithoutBrandInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutBrandInput>;
  create?: InputMaybe<ResourceCreateWithoutBrandInput>;
};

export type ResourceCreateNestedOneWithoutChildResourcesInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutChildResourcesInput>;
  create?: InputMaybe<ResourceCreateWithoutChildResourcesInput>;
};

export type ResourceCreateNestedOneWithoutCollectionsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutCollectionsInput>;
  create?: InputMaybe<ResourceCreateWithoutCollectionsInput>;
};

export type ResourceCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<ResourceCreateWithoutProductsInput>;
};

export type ResourceCreateNestedOneWithoutQualitySignResourceInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutQualitySignResourceInput>;
  create?: InputMaybe<ResourceCreateWithoutQualitySignResourceInput>;
};

export type ResourceCreateNestedOneWithoutResourceLanguageInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutResourceLanguageInput>;
  create?: InputMaybe<ResourceCreateWithoutResourceLanguageInput>;
};

export type ResourceCreateNestedOneWithoutResourceLicensesInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutResourceLicensesInput>;
  create?: InputMaybe<ResourceCreateWithoutResourceLicensesInput>;
};

export type ResourceCreateNestedOneWithoutResourcePhotoModelsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutResourcePhotoModelsInput>;
  create?: InputMaybe<ResourceCreateWithoutResourcePhotoModelsInput>;
};

export type ResourceCreateNestedOneWithoutSlugsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutSlugsInput>;
  create?: InputMaybe<ResourceCreateWithoutSlugsInput>;
};

export type ResourceCreateNestedOneWithoutTagsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutTagsInput>;
  create?: InputMaybe<ResourceCreateWithoutTagsInput>;
};

export type ResourceCreateNestedOneWithoutTextsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutTextsInput>;
  create?: InputMaybe<ResourceCreateWithoutTextsInput>;
};

export type ResourceCreateNestedOneWithoutVariantsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutVariantsInput>;
  create?: InputMaybe<ResourceCreateWithoutVariantsInput>;
};

export type ResourceCreateNestedOneWithoutVersionsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutVersionsInput>;
  create?: InputMaybe<ResourceCreateWithoutVersionsInput>;
};

export type ResourceCreateOrConnectWithoutBrandInput = {
  create: ResourceCreateWithoutBrandInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutChildResourcesInput = {
  create: ResourceCreateWithoutChildResourcesInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutCollectionsInput = {
  create: ResourceCreateWithoutCollectionsInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutParentResourceInput = {
  create: ResourceCreateWithoutParentResourceInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutProductsInput = {
  create: ResourceCreateWithoutProductsInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutQualitySignResourceInput = {
  create: ResourceCreateWithoutQualitySignResourceInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutResourceLanguageInput = {
  create: ResourceCreateWithoutResourceLanguageInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutResourceLicensesInput = {
  create: ResourceCreateWithoutResourceLicensesInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutResourcePhotoModelsInput = {
  create: ResourceCreateWithoutResourcePhotoModelsInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutSlugsInput = {
  create: ResourceCreateWithoutSlugsInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutTagsInput = {
  create: ResourceCreateWithoutTagsInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutTextsInput = {
  create: ResourceCreateWithoutTextsInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutVariantsInput = {
  create: ResourceCreateWithoutVariantsInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateOrConnectWithoutVersionsInput = {
  create: ResourceCreateWithoutVersionsInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceCreateWithoutBrandInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutChildResourcesInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutCollectionsInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutParentResourceInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutProductsInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutQualitySignResourceInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutResourceLanguageInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutResourceLicensesInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutResourcePhotoModelsInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutSlugsInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutTagsInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutTextsInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutVariantsInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  versions?: InputMaybe<ResourceVersionCreateNestedManyWithoutResourceInput>;
};

export type ResourceCreateWithoutVersionsInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  brand?: InputMaybe<BrandCreateNestedManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceCreateNestedManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceCreateNestedManyWithoutResourceInput>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  parentResource?: InputMaybe<ResourceCreateNestedOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupCreateNestedManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductCreateNestedManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceCreateNestedManyWithoutResourceInput>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  resourceLanguage?: InputMaybe<ResourceLanguageCreateNestedManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelCreateNestedManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugCreateNestedManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagCreateNestedManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextCreateNestedManyWithoutResourceInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  variants?: InputMaybe<ResourceVariantCreateNestedManyWithoutResourceInput>;
};

export type ResourceDeleteInput = {
  id: Scalars["Int"];
};

export type ResourceLanguage = {
  __typename?: "ResourceLanguage";
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with languageId */
  language_id?: Maybe<Scalars["Int"]>;
  resource?: Maybe<Resource>;
  resourceId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with resourceId */
  resource_id?: Maybe<Scalars["Int"]>;
};

export type ResourceLanguageCreateInput = {
  language: LanguageCreateNestedOneWithoutResourceLanguageInput;
  resource: ResourceCreateNestedOneWithoutResourceLanguageInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceLanguageCreateManyLanguageInput = {
  resource_id: Scalars["Int"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceLanguageCreateManyLanguageInputEnvelope = {
  data?: InputMaybe<Array<ResourceLanguageCreateManyLanguageInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceLanguageCreateManyResourceInput = {
  language_id: Scalars["Int"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceLanguageCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceLanguageCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceLanguageCreateNestedManyWithoutLanguageInput = {
  connect?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceLanguageCreateOrConnectWithoutLanguageInput>
  >;
  create?: InputMaybe<Array<ResourceLanguageCreateWithoutLanguageInput>>;
  createMany?: InputMaybe<ResourceLanguageCreateManyLanguageInputEnvelope>;
};

export type ResourceLanguageCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceLanguageCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceLanguageCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceLanguageCreateManyResourceInputEnvelope>;
};

export type ResourceLanguageCreateOrConnectWithoutLanguageInput = {
  create: ResourceLanguageCreateWithoutLanguageInput;
  where: ResourceLanguageWhereUniqueInput;
};

export type ResourceLanguageCreateOrConnectWithoutResourceInput = {
  create: ResourceLanguageCreateWithoutResourceInput;
  where: ResourceLanguageWhereUniqueInput;
};

export type ResourceLanguageCreateWithoutLanguageInput = {
  resource: ResourceCreateNestedOneWithoutResourceLanguageInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceLanguageCreateWithoutResourceInput = {
  language: LanguageCreateNestedOneWithoutResourceLanguageInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceLanguageListRelationFilter = {
  every?: InputMaybe<ResourceLanguageWhereInput>;
  none?: InputMaybe<ResourceLanguageWhereInput>;
  some?: InputMaybe<ResourceLanguageWhereInput>;
};

export type ResourceLanguageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceLanguageResource_IdLanguage_IdCompoundUniqueInput = {
  language_id: Scalars["Int"];
  resource_id: Scalars["Int"];
};

export type ResourceLanguageScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceLanguageScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceLanguageScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceLanguageScalarWhereInput>>;
  language_id?: InputMaybe<IntFilter>;
  resource_id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ResourceLanguageUpdateManyMutationInput = {
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceLanguageUpdateManyWithWhereWithoutLanguageInput = {
  data: ResourceLanguageUpdateManyMutationInput;
  where: ResourceLanguageScalarWhereInput;
};

export type ResourceLanguageUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceLanguageUpdateManyMutationInput;
  where: ResourceLanguageScalarWhereInput;
};

export type ResourceLanguageUpdateManyWithoutLanguageInput = {
  connect?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceLanguageCreateOrConnectWithoutLanguageInput>
  >;
  create?: InputMaybe<Array<ResourceLanguageCreateWithoutLanguageInput>>;
  createMany?: InputMaybe<ResourceLanguageCreateManyLanguageInputEnvelope>;
  delete?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceLanguageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceLanguageUpdateWithWhereUniqueWithoutLanguageInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceLanguageUpdateManyWithWhereWithoutLanguageInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceLanguageUpsertWithWhereUniqueWithoutLanguageInput>
  >;
};

export type ResourceLanguageUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceLanguageCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceLanguageCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceLanguageCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceLanguageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceLanguageWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceLanguageUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceLanguageUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceLanguageUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourceLanguageUpdateWithWhereUniqueWithoutLanguageInput = {
  data: ResourceLanguageUpdateWithoutLanguageInput;
  where: ResourceLanguageWhereUniqueInput;
};

export type ResourceLanguageUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceLanguageUpdateWithoutResourceInput;
  where: ResourceLanguageWhereUniqueInput;
};

export type ResourceLanguageUpdateWithoutLanguageInput = {
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutResourceLanguageInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceLanguageUpdateWithoutResourceInput = {
  language?: InputMaybe<LanguageUpdateOneRequiredWithoutResourceLanguageInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceLanguageUpsertWithWhereUniqueWithoutLanguageInput = {
  create: ResourceLanguageCreateWithoutLanguageInput;
  update: ResourceLanguageUpdateWithoutLanguageInput;
  where: ResourceLanguageWhereUniqueInput;
};

export type ResourceLanguageUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceLanguageCreateWithoutResourceInput;
  update: ResourceLanguageUpdateWithoutResourceInput;
  where: ResourceLanguageWhereUniqueInput;
};

export type ResourceLanguageWhereInput = {
  AND?: InputMaybe<Array<ResourceLanguageWhereInput>>;
  NOT?: InputMaybe<Array<ResourceLanguageWhereInput>>;
  OR?: InputMaybe<Array<ResourceLanguageWhereInput>>;
  language?: InputMaybe<LanguageWhereInput>;
  language_id?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ResourceLanguageWhereUniqueInput = {
  resource_id_language_id?: InputMaybe<ResourceLanguageResource_IdLanguage_IdCompoundUniqueInput>;
};

export type ResourceLicense = {
  __typename?: "ResourceLicense";
  id: Scalars["Int"];
  licenseDefinition: ResourceLicenseDefinition;
  licenseDefinitionId: Scalars["Int"];
  resource: Resource;
  resourceId: Scalars["Int"];
};

export type ResourceLicenseCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  licenseDefinition: ResourceLicenseDefinitionCreateNestedOneWithoutResourceLicensesInput;
  resource: ResourceCreateNestedOneWithoutResourceLicensesInput;
};

export type ResourceLicenseCreateManyLicenseDefinitionInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
  resourceId: Scalars["Int"];
};

export type ResourceLicenseCreateManyLicenseDefinitionInputEnvelope = {
  data?: InputMaybe<Array<ResourceLicenseCreateManyLicenseDefinitionInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceLicenseCreateManyResourceInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
  licenseDefinitionId: Scalars["Int"];
};

export type ResourceLicenseCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceLicenseCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceLicenseCreateNestedManyWithoutLicenseDefinitionInput = {
  connect?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceLicenseCreateOrConnectWithoutLicenseDefinitionInput>
  >;
  create?: InputMaybe<
    Array<ResourceLicenseCreateWithoutLicenseDefinitionInput>
  >;
  createMany?: InputMaybe<ResourceLicenseCreateManyLicenseDefinitionInputEnvelope>;
};

export type ResourceLicenseCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceLicenseCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceLicenseCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceLicenseCreateManyResourceInputEnvelope>;
};

export type ResourceLicenseCreateOrConnectWithoutLicenseDefinitionInput = {
  create: ResourceLicenseCreateWithoutLicenseDefinitionInput;
  where: ResourceLicenseWhereUniqueInput;
};

export type ResourceLicenseCreateOrConnectWithoutResourceInput = {
  create: ResourceLicenseCreateWithoutResourceInput;
  where: ResourceLicenseWhereUniqueInput;
};

export type ResourceLicenseCreateWithoutLicenseDefinitionInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  resource: ResourceCreateNestedOneWithoutResourceLicensesInput;
};

export type ResourceLicenseCreateWithoutResourceInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  licenseDefinition: ResourceLicenseDefinitionCreateNestedOneWithoutResourceLicensesInput;
};

export type ResourceLicenseDefinition = {
  __typename?: "ResourceLicenseDefinition";
  description?: Maybe<Scalars["String"]>;
  from: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
  resourceLicenses: Array<ResourceLicense>;
  showDescription: Scalars["Boolean"];
  to?: Maybe<Scalars["DateTime"]>;
  usageBlog: Scalars["Boolean"];
  usagePrint: Scalars["Boolean"];
  usageShop: Scalars["Boolean"];
  usageSocialMedia: Scalars["Boolean"];
  usageThirdParty: Scalars["Boolean"];
};

export type ResourceLicenseDefinitionResourceLicensesArgs = {
  after?: InputMaybe<ResourceLicenseWhereUniqueInput>;
  before?: InputMaybe<ResourceLicenseWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ResourceLicenseDefinitionCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  from: Scalars["DateTime"];
  name: Scalars["String"];
  resourceLicenses?: InputMaybe<ResourceLicenseCreateNestedManyWithoutLicenseDefinitionInput>;
  showDescription: Scalars["Boolean"];
  to?: InputMaybe<Scalars["DateTime"]>;
  usageBlog: Scalars["Boolean"];
  usagePrint: Scalars["Boolean"];
  usageShop: Scalars["Boolean"];
  usageSocialMedia: Scalars["Boolean"];
  usageThirdParty: Scalars["Boolean"];
};

export type ResourceLicenseDefinitionCreateNestedOneWithoutResourceLicensesInput =
  {
    connect?: InputMaybe<ResourceLicenseDefinitionWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ResourceLicenseDefinitionCreateOrConnectWithoutResourceLicensesInput>;
    create?: InputMaybe<ResourceLicenseDefinitionCreateWithoutResourceLicensesInput>;
  };

export type ResourceLicenseDefinitionCreateOrConnectWithoutResourceLicensesInput =
  {
    create: ResourceLicenseDefinitionCreateWithoutResourceLicensesInput;
    where: ResourceLicenseDefinitionWhereUniqueInput;
  };

export type ResourceLicenseDefinitionCreateWithoutResourceLicensesInput = {
  description?: InputMaybe<Scalars["String"]>;
  from: Scalars["DateTime"];
  name: Scalars["String"];
  showDescription: Scalars["Boolean"];
  to?: InputMaybe<Scalars["DateTime"]>;
  usageBlog: Scalars["Boolean"];
  usagePrint: Scalars["Boolean"];
  usageShop: Scalars["Boolean"];
  usageSocialMedia: Scalars["Boolean"];
  usageThirdParty: Scalars["Boolean"];
};

export type ResourceLicenseDefinitionOrderByWithRelationInput = {
  description?: InputMaybe<SortOrder>;
  from?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  resourceLicenses?: InputMaybe<ResourceLicenseOrderByRelationAggregateInput>;
  showDescription?: InputMaybe<SortOrder>;
  to?: InputMaybe<SortOrder>;
  usageBlog?: InputMaybe<SortOrder>;
  usagePrint?: InputMaybe<SortOrder>;
  usageShop?: InputMaybe<SortOrder>;
  usageSocialMedia?: InputMaybe<SortOrder>;
  usageThirdParty?: InputMaybe<SortOrder>;
};

export type ResourceLicenseDefinitionUpdateInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutLicenseDefinitionInput>;
  showDescription?: InputMaybe<BoolFieldUpdateOperationsInput>;
  to?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  usageBlog?: InputMaybe<BoolFieldUpdateOperationsInput>;
  usagePrint?: InputMaybe<BoolFieldUpdateOperationsInput>;
  usageShop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  usageSocialMedia?: InputMaybe<BoolFieldUpdateOperationsInput>;
  usageThirdParty?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ResourceLicenseDefinitionUpdateOneRequiredWithoutResourceLicensesInput =
  {
    connect?: InputMaybe<ResourceLicenseDefinitionWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ResourceLicenseDefinitionCreateOrConnectWithoutResourceLicensesInput>;
    create?: InputMaybe<ResourceLicenseDefinitionCreateWithoutResourceLicensesInput>;
    update?: InputMaybe<ResourceLicenseDefinitionUpdateWithoutResourceLicensesInput>;
    upsert?: InputMaybe<ResourceLicenseDefinitionUpsertWithoutResourceLicensesInput>;
  };

export type ResourceLicenseDefinitionUpdateWithoutResourceLicensesInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  showDescription?: InputMaybe<BoolFieldUpdateOperationsInput>;
  to?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  usageBlog?: InputMaybe<BoolFieldUpdateOperationsInput>;
  usagePrint?: InputMaybe<BoolFieldUpdateOperationsInput>;
  usageShop?: InputMaybe<BoolFieldUpdateOperationsInput>;
  usageSocialMedia?: InputMaybe<BoolFieldUpdateOperationsInput>;
  usageThirdParty?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ResourceLicenseDefinitionUpsertWithoutResourceLicensesInput = {
  create: ResourceLicenseDefinitionCreateWithoutResourceLicensesInput;
  update: ResourceLicenseDefinitionUpdateWithoutResourceLicensesInput;
};

export type ResourceLicenseDefinitionWhereInput = {
  AND?: InputMaybe<Array<ResourceLicenseDefinitionWhereInput>>;
  NOT?: InputMaybe<Array<ResourceLicenseDefinitionWhereInput>>;
  OR?: InputMaybe<Array<ResourceLicenseDefinitionWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  from?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  resourceLicenses?: InputMaybe<ResourceLicenseListRelationFilter>;
  showDescription?: InputMaybe<BoolFilter>;
  to?: InputMaybe<DateTimeNullableFilter>;
  usageBlog?: InputMaybe<BoolFilter>;
  usagePrint?: InputMaybe<BoolFilter>;
  usageShop?: InputMaybe<BoolFilter>;
  usageSocialMedia?: InputMaybe<BoolFilter>;
  usageThirdParty?: InputMaybe<BoolFilter>;
};

export type ResourceLicenseDefinitionWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type ResourceLicenseListRelationFilter = {
  every?: InputMaybe<ResourceLicenseWhereInput>;
  none?: InputMaybe<ResourceLicenseWhereInput>;
  some?: InputMaybe<ResourceLicenseWhereInput>;
};

export type ResourceLicenseOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceLicenseOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  licenseDefinition?: InputMaybe<ResourceLicenseDefinitionOrderByWithRelationInput>;
  licenseDefinitionId?: InputMaybe<SortOrder>;
  resource?: InputMaybe<ResourceOrderByWithRelationInput>;
  resourceId?: InputMaybe<SortOrder>;
};

export type ResourceLicenseResourceLicenseUniqueCompoundUniqueInput = {
  licenseDefinitionId: Scalars["Int"];
  resourceId: Scalars["Int"];
};

export type ResourceLicenseScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceLicenseScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceLicenseScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceLicenseScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  licenseDefinitionId?: InputMaybe<IntFilter>;
  resourceId?: InputMaybe<IntFilter>;
};

export type ResourceLicenseUpdateInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  licenseDefinition?: InputMaybe<ResourceLicenseDefinitionUpdateOneRequiredWithoutResourceLicensesInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutResourceLicensesInput>;
};

export type ResourceLicenseUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceLicenseUpdateManyWithWhereWithoutLicenseDefinitionInput = {
  data: ResourceLicenseUpdateManyMutationInput;
  where: ResourceLicenseScalarWhereInput;
};

export type ResourceLicenseUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceLicenseUpdateManyMutationInput;
  where: ResourceLicenseScalarWhereInput;
};

export type ResourceLicenseUpdateManyWithoutLicenseDefinitionInput = {
  connect?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceLicenseCreateOrConnectWithoutLicenseDefinitionInput>
  >;
  create?: InputMaybe<
    Array<ResourceLicenseCreateWithoutLicenseDefinitionInput>
  >;
  createMany?: InputMaybe<ResourceLicenseCreateManyLicenseDefinitionInputEnvelope>;
  delete?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceLicenseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceLicenseUpdateWithWhereUniqueWithoutLicenseDefinitionInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceLicenseUpdateManyWithWhereWithoutLicenseDefinitionInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceLicenseUpsertWithWhereUniqueWithoutLicenseDefinitionInput>
  >;
};

export type ResourceLicenseUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceLicenseCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceLicenseCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceLicenseCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceLicenseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceLicenseWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceLicenseUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceLicenseUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceLicenseUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourceLicenseUpdateWithWhereUniqueWithoutLicenseDefinitionInput =
  {
    data: ResourceLicenseUpdateWithoutLicenseDefinitionInput;
    where: ResourceLicenseWhereUniqueInput;
  };

export type ResourceLicenseUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceLicenseUpdateWithoutResourceInput;
  where: ResourceLicenseWhereUniqueInput;
};

export type ResourceLicenseUpdateWithoutLicenseDefinitionInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutResourceLicensesInput>;
};

export type ResourceLicenseUpdateWithoutResourceInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  licenseDefinition?: InputMaybe<ResourceLicenseDefinitionUpdateOneRequiredWithoutResourceLicensesInput>;
};

export type ResourceLicenseUpsertWithWhereUniqueWithoutLicenseDefinitionInput =
  {
    create: ResourceLicenseCreateWithoutLicenseDefinitionInput;
    update: ResourceLicenseUpdateWithoutLicenseDefinitionInput;
    where: ResourceLicenseWhereUniqueInput;
  };

export type ResourceLicenseUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceLicenseCreateWithoutResourceInput;
  update: ResourceLicenseUpdateWithoutResourceInput;
  where: ResourceLicenseWhereUniqueInput;
};

export type ResourceLicenseWhereInput = {
  AND?: InputMaybe<Array<ResourceLicenseWhereInput>>;
  NOT?: InputMaybe<Array<ResourceLicenseWhereInput>>;
  OR?: InputMaybe<Array<ResourceLicenseWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  licenseDefinition?: InputMaybe<ResourceLicenseDefinitionWhereInput>;
  licenseDefinitionId?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resourceId?: InputMaybe<IntFilter>;
};

export type ResourceLicenseWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  resourceLicenseUnique?: InputMaybe<ResourceLicenseResourceLicenseUniqueCompoundUniqueInput>;
};

export type ResourceListRelationFilter = {
  every?: InputMaybe<ResourceWhereInput>;
  none?: InputMaybe<ResourceWhereInput>;
  some?: InputMaybe<ResourceWhereInput>;
};

export type ResourceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceOrderByWithRelationInput = {
  blocked?: InputMaybe<SortOrder>;
  brand?: InputMaybe<BrandOrderByRelationAggregateInput>;
  childResources?: InputMaybe<ResourceOrderByRelationAggregateInput>;
  collections?: InputMaybe<CollectionResourceOrderByRelationAggregateInput>;
  create_date?: InputMaybe<SortOrder>;
  delete_date?: InputMaybe<SortOrder>;
  deleted_by_user_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  in_archive?: InputMaybe<SortOrder>;
  is_user_generated_content?: InputMaybe<SortOrder>;
  noindex?: InputMaybe<SortOrder>;
  parentResource?: InputMaybe<ResourceOrderByWithRelationInput>;
  parent_resource_id?: InputMaybe<SortOrder>;
  productGroups?: InputMaybe<ResourceProductGroupOrderByRelationAggregateInput>;
  products?: InputMaybe<ResourceProductOrderByRelationAggregateInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceOrderByRelationAggregateInput>;
  release_date?: InputMaybe<SortOrder>;
  release_end_date?: InputMaybe<SortOrder>;
  resourceLanguage?: InputMaybe<ResourceLanguageOrderByRelationAggregateInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseOrderByRelationAggregateInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelOrderByRelationAggregateInput>;
  slugs?: InputMaybe<SlugOrderByRelationAggregateInput>;
  tags?: InputMaybe<ResourceTagOrderByRelationAggregateInput>;
  texts?: InputMaybe<ResourceTextOrderByRelationAggregateInput>;
  update_date?: InputMaybe<SortOrder>;
  variants?: InputMaybe<ResourceVariantOrderByRelationAggregateInput>;
  versions?: InputMaybe<ResourceVersionOrderByRelationAggregateInput>;
};

export type ResourcePdfCategory = {
  __typename?: "ResourcePdfCategory";
  /** The ID of the text in the text_attribute and text database table. This represents a text with the type MediaCategory */
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ResourcePhotoModel = {
  __typename?: "ResourcePhotoModel";
  id: Scalars["Int"];
  model: PhotoModel;
  modelId: Scalars["Int"];
  resource: Resource;
  resourceId: Scalars["Int"];
};

export type ResourcePhotoModelCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  model: PhotoModelCreateNestedOneWithoutResourcePhotoModelsInput;
  resource: ResourceCreateNestedOneWithoutResourcePhotoModelsInput;
};

export type ResourcePhotoModelCreateManyModelInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
  resourceId: Scalars["Int"];
};

export type ResourcePhotoModelCreateManyModelInputEnvelope = {
  data?: InputMaybe<Array<ResourcePhotoModelCreateManyModelInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourcePhotoModelCreateManyResourceInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["Int"]>;
  modelId: Scalars["Int"];
};

export type ResourcePhotoModelCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourcePhotoModelCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourcePhotoModelCreateNestedManyWithoutModelInput = {
  connect?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourcePhotoModelCreateOrConnectWithoutModelInput>
  >;
  create?: InputMaybe<Array<ResourcePhotoModelCreateWithoutModelInput>>;
  createMany?: InputMaybe<ResourcePhotoModelCreateManyModelInputEnvelope>;
};

export type ResourcePhotoModelCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourcePhotoModelCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourcePhotoModelCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourcePhotoModelCreateManyResourceInputEnvelope>;
};

export type ResourcePhotoModelCreateOrConnectWithoutModelInput = {
  create: ResourcePhotoModelCreateWithoutModelInput;
  where: ResourcePhotoModelWhereUniqueInput;
};

export type ResourcePhotoModelCreateOrConnectWithoutResourceInput = {
  create: ResourcePhotoModelCreateWithoutResourceInput;
  where: ResourcePhotoModelWhereUniqueInput;
};

export type ResourcePhotoModelCreateWithoutModelInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  resource: ResourceCreateNestedOneWithoutResourcePhotoModelsInput;
};

export type ResourcePhotoModelCreateWithoutResourceInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  model: PhotoModelCreateNestedOneWithoutResourcePhotoModelsInput;
};

export type ResourcePhotoModelListRelationFilter = {
  every?: InputMaybe<ResourcePhotoModelWhereInput>;
  none?: InputMaybe<ResourcePhotoModelWhereInput>;
  some?: InputMaybe<ResourcePhotoModelWhereInput>;
};

export type ResourcePhotoModelOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourcePhotoModelOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model?: InputMaybe<PhotoModelOrderByWithRelationInput>;
  modelId?: InputMaybe<SortOrder>;
  resource?: InputMaybe<ResourceOrderByWithRelationInput>;
  resourceId?: InputMaybe<SortOrder>;
};

export type ResourcePhotoModelResourcePhotoModelUniqueCompoundUniqueInput = {
  modelId: Scalars["Int"];
  resourceId: Scalars["Int"];
};

export type ResourcePhotoModelScalarWhereInput = {
  AND?: InputMaybe<Array<ResourcePhotoModelScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourcePhotoModelScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourcePhotoModelScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  modelId?: InputMaybe<IntFilter>;
  resourceId?: InputMaybe<IntFilter>;
};

export type ResourcePhotoModelUpdateInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<PhotoModelUpdateOneRequiredWithoutResourcePhotoModelsInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutResourcePhotoModelsInput>;
};

export type ResourcePhotoModelUpdateManyMutationInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourcePhotoModelUpdateManyWithWhereWithoutModelInput = {
  data: ResourcePhotoModelUpdateManyMutationInput;
  where: ResourcePhotoModelScalarWhereInput;
};

export type ResourcePhotoModelUpdateManyWithWhereWithoutResourceInput = {
  data: ResourcePhotoModelUpdateManyMutationInput;
  where: ResourcePhotoModelScalarWhereInput;
};

export type ResourcePhotoModelUpdateManyWithoutModelInput = {
  connect?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourcePhotoModelCreateOrConnectWithoutModelInput>
  >;
  create?: InputMaybe<Array<ResourcePhotoModelCreateWithoutModelInput>>;
  createMany?: InputMaybe<ResourcePhotoModelCreateManyModelInputEnvelope>;
  delete?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourcePhotoModelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourcePhotoModelUpdateWithWhereUniqueWithoutModelInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourcePhotoModelUpdateManyWithWhereWithoutModelInput>
  >;
  upsert?: InputMaybe<
    Array<ResourcePhotoModelUpsertWithWhereUniqueWithoutModelInput>
  >;
};

export type ResourcePhotoModelUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourcePhotoModelCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourcePhotoModelCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourcePhotoModelCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourcePhotoModelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourcePhotoModelWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourcePhotoModelUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourcePhotoModelUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourcePhotoModelUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourcePhotoModelUpdateWithWhereUniqueWithoutModelInput = {
  data: ResourcePhotoModelUpdateWithoutModelInput;
  where: ResourcePhotoModelWhereUniqueInput;
};

export type ResourcePhotoModelUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourcePhotoModelUpdateWithoutResourceInput;
  where: ResourcePhotoModelWhereUniqueInput;
};

export type ResourcePhotoModelUpdateWithoutModelInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutResourcePhotoModelsInput>;
};

export type ResourcePhotoModelUpdateWithoutResourceInput = {
  createdAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<PhotoModelUpdateOneRequiredWithoutResourcePhotoModelsInput>;
};

export type ResourcePhotoModelUpsertWithWhereUniqueWithoutModelInput = {
  create: ResourcePhotoModelCreateWithoutModelInput;
  update: ResourcePhotoModelUpdateWithoutModelInput;
  where: ResourcePhotoModelWhereUniqueInput;
};

export type ResourcePhotoModelUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourcePhotoModelCreateWithoutResourceInput;
  update: ResourcePhotoModelUpdateWithoutResourceInput;
  where: ResourcePhotoModelWhereUniqueInput;
};

export type ResourcePhotoModelWhereInput = {
  AND?: InputMaybe<Array<ResourcePhotoModelWhereInput>>;
  NOT?: InputMaybe<Array<ResourcePhotoModelWhereInput>>;
  OR?: InputMaybe<Array<ResourcePhotoModelWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  model?: InputMaybe<PhotoModelWhereInput>;
  modelId?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resourceId?: InputMaybe<IntFilter>;
};

export type ResourcePhotoModelWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  resourcePhotoModelUnique?: InputMaybe<ResourcePhotoModelResourcePhotoModelUniqueCompoundUniqueInput>;
};

export type ResourceProduct = {
  __typename?: "ResourceProduct";
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars["Int"]>;
  product: Product;
  productId: Scalars["Int"];
  /** @deprecated replaced with productId */
  product_id?: Maybe<Scalars["Int"]>;
  resource?: Maybe<Resource>;
  type?: Maybe<ProductType>;
  userId?: Maybe<Scalars["Int"]>;
};

export type ResourceProductCreateInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceProductInput>;
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceProductInput>;
  product: ProductCreateNestedOneWithoutProductResourceInput;
  resource?: InputMaybe<ResourceCreateNestedOneWithoutProductsInput>;
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductCreateManyCompanyInput = {
  id?: InputMaybe<Scalars["Int"]>;
  language_id?: InputMaybe<Scalars["Int"]>;
  product_id: Scalars["Int"];
  resource_id?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductCreateManyCompanyInputEnvelope = {
  data?: InputMaybe<Array<ResourceProductCreateManyCompanyInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceProductCreateManyLanguageInput = {
  company_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  product_id: Scalars["Int"];
  resource_id?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductCreateManyLanguageInputEnvelope = {
  data?: InputMaybe<Array<ResourceProductCreateManyLanguageInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceProductCreateManyProductInput = {
  company_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  language_id?: InputMaybe<Scalars["Int"]>;
  resource_id?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductCreateManyProductInputEnvelope = {
  data?: InputMaybe<Array<ResourceProductCreateManyProductInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceProductCreateManyResourceInput = {
  company_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  language_id?: InputMaybe<Scalars["Int"]>;
  product_id: Scalars["Int"];
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceProductCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceProductCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<ResourceProductCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<ResourceProductCreateManyCompanyInputEnvelope>;
};

export type ResourceProductCreateNestedManyWithoutLanguageInput = {
  connect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductCreateOrConnectWithoutLanguageInput>
  >;
  create?: InputMaybe<Array<ResourceProductCreateWithoutLanguageInput>>;
  createMany?: InputMaybe<ResourceProductCreateManyLanguageInputEnvelope>;
};

export type ResourceProductCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductCreateOrConnectWithoutProductInput>
  >;
  create?: InputMaybe<Array<ResourceProductCreateWithoutProductInput>>;
  createMany?: InputMaybe<ResourceProductCreateManyProductInputEnvelope>;
};

export type ResourceProductCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceProductCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceProductCreateManyResourceInputEnvelope>;
};

export type ResourceProductCreateOrConnectWithoutCompanyInput = {
  create: ResourceProductCreateWithoutCompanyInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductCreateOrConnectWithoutLanguageInput = {
  create: ResourceProductCreateWithoutLanguageInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductCreateOrConnectWithoutProductInput = {
  create: ResourceProductCreateWithoutProductInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductCreateOrConnectWithoutResourceInput = {
  create: ResourceProductCreateWithoutResourceInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductCreateWithoutCompanyInput = {
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceProductInput>;
  product: ProductCreateNestedOneWithoutProductResourceInput;
  resource?: InputMaybe<ResourceCreateNestedOneWithoutProductsInput>;
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductCreateWithoutLanguageInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceProductInput>;
  product: ProductCreateNestedOneWithoutProductResourceInput;
  resource?: InputMaybe<ResourceCreateNestedOneWithoutProductsInput>;
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductCreateWithoutProductInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceProductInput>;
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceProductInput>;
  resource?: InputMaybe<ResourceCreateNestedOneWithoutProductsInput>;
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductCreateWithoutResourceInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceProductInput>;
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceProductInput>;
  product: ProductCreateNestedOneWithoutProductResourceInput;
  type?: InputMaybe<ProductType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductGroup = {
  __typename?: "ResourceProductGroup";
  hwg: Scalars["String"];
  id: Scalars["Int"];
  resourceId: Scalars["Int"];
  type: ResourceProductGroupType;
  uwg?: Maybe<Scalars["String"]>;
  wg?: Maybe<Scalars["String"]>;
};

export type ResourceProductGroupCreateManyResourceInput = {
  hwg: Scalars["String"];
  id?: InputMaybe<Scalars["Int"]>;
  type: ResourceProductGroupType;
  uwg?: InputMaybe<Scalars["String"]>;
  wg?: InputMaybe<Scalars["String"]>;
};

export type ResourceProductGroupCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceProductGroupCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceProductGroupCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceProductGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductGroupCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceProductGroupCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceProductGroupCreateManyResourceInputEnvelope>;
};

export type ResourceProductGroupCreateOrConnectWithoutResourceInput = {
  create: ResourceProductGroupCreateWithoutResourceInput;
  where: ResourceProductGroupWhereUniqueInput;
};

export type ResourceProductGroupCreateWithoutResourceInput = {
  hwg: Scalars["String"];
  type: ResourceProductGroupType;
  uwg?: InputMaybe<Scalars["String"]>;
  wg?: InputMaybe<Scalars["String"]>;
};

export type ResourceProductGroupListRelationFilter = {
  every?: InputMaybe<ResourceProductGroupWhereInput>;
  none?: InputMaybe<ResourceProductGroupWhereInput>;
  some?: InputMaybe<ResourceProductGroupWhereInput>;
};

export type ResourceProductGroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceProductGroupScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceProductGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceProductGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceProductGroupScalarWhereInput>>;
  hwg?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  resourceId?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumResourceProductGroupTypeFilter>;
  uwg?: InputMaybe<StringNullableFilter>;
  wg?: InputMaybe<StringNullableFilter>;
};

export enum ResourceProductGroupType {
  ExtraImage = "extra_image",
  Pdf = "pdf",
}

export type ResourceProductGroupUpdateManyMutationInput = {
  hwg?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumResourceProductGroupTypeFieldUpdateOperationsInput>;
  uwg?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  wg?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ResourceProductGroupUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceProductGroupUpdateManyMutationInput;
  where: ResourceProductGroupScalarWhereInput;
};

export type ResourceProductGroupUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceProductGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductGroupCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceProductGroupCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceProductGroupCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceProductGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceProductGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceProductGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceProductGroupWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceProductGroupUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceProductGroupUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceProductGroupUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourceProductGroupUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceProductGroupUpdateWithoutResourceInput;
  where: ResourceProductGroupWhereUniqueInput;
};

export type ResourceProductGroupUpdateWithoutResourceInput = {
  hwg?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumResourceProductGroupTypeFieldUpdateOperationsInput>;
  uwg?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  wg?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ResourceProductGroupUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceProductGroupCreateWithoutResourceInput;
  update: ResourceProductGroupUpdateWithoutResourceInput;
  where: ResourceProductGroupWhereUniqueInput;
};

export type ResourceProductGroupWhereInput = {
  AND?: InputMaybe<Array<ResourceProductGroupWhereInput>>;
  NOT?: InputMaybe<Array<ResourceProductGroupWhereInput>>;
  OR?: InputMaybe<Array<ResourceProductGroupWhereInput>>;
  hwg?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resourceId?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumResourceProductGroupTypeFilter>;
  uwg?: InputMaybe<StringNullableFilter>;
  wg?: InputMaybe<StringNullableFilter>;
};

export type ResourceProductGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceProductListRelationFilter = {
  every?: InputMaybe<ResourceProductWhereInput>;
  none?: InputMaybe<ResourceProductWhereInput>;
  some?: InputMaybe<ResourceProductWhereInput>;
};

export type ResourceProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceProductScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceProductScalarWhereInput>>;
  company_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  language_id?: InputMaybe<IntNullableFilter>;
  product_id?: InputMaybe<IntFilter>;
  resource_id?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumProductTypeNullableFilter>;
  user_id?: InputMaybe<IntNullableFilter>;
};

export type ResourceProductUpdateManyMutationInput = {
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceProductUpdateManyWithWhereWithoutCompanyInput = {
  data: ResourceProductUpdateManyMutationInput;
  where: ResourceProductScalarWhereInput;
};

export type ResourceProductUpdateManyWithWhereWithoutLanguageInput = {
  data: ResourceProductUpdateManyMutationInput;
  where: ResourceProductScalarWhereInput;
};

export type ResourceProductUpdateManyWithWhereWithoutProductInput = {
  data: ResourceProductUpdateManyMutationInput;
  where: ResourceProductScalarWhereInput;
};

export type ResourceProductUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceProductUpdateManyMutationInput;
  where: ResourceProductScalarWhereInput;
};

export type ResourceProductUpdateManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<ResourceProductCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<ResourceProductCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceProductUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceProductUpdateManyWithWhereWithoutCompanyInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceProductUpsertWithWhereUniqueWithoutCompanyInput>
  >;
};

export type ResourceProductUpdateManyWithoutLanguageInput = {
  connect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductCreateOrConnectWithoutLanguageInput>
  >;
  create?: InputMaybe<Array<ResourceProductCreateWithoutLanguageInput>>;
  createMany?: InputMaybe<ResourceProductCreateManyLanguageInputEnvelope>;
  delete?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceProductUpdateWithWhereUniqueWithoutLanguageInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceProductUpdateManyWithWhereWithoutLanguageInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceProductUpsertWithWhereUniqueWithoutLanguageInput>
  >;
};

export type ResourceProductUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductCreateOrConnectWithoutProductInput>
  >;
  create?: InputMaybe<Array<ResourceProductCreateWithoutProductInput>>;
  createMany?: InputMaybe<ResourceProductCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceProductUpdateWithWhereUniqueWithoutProductInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceProductUpdateManyWithWhereWithoutProductInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceProductUpsertWithWhereUniqueWithoutProductInput>
  >;
};

export type ResourceProductUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceProductCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceProductCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceProductCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceProductWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceProductUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceProductUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceProductUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourceProductUpdateWithWhereUniqueWithoutCompanyInput = {
  data: ResourceProductUpdateWithoutCompanyInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductUpdateWithWhereUniqueWithoutLanguageInput = {
  data: ResourceProductUpdateWithoutLanguageInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductUpdateWithWhereUniqueWithoutProductInput = {
  data: ResourceProductUpdateWithoutProductInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceProductUpdateWithoutResourceInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductUpdateWithoutCompanyInput = {
  language?: InputMaybe<LanguageUpdateOneWithoutResourceProductInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutProductResourceInput>;
  resource?: InputMaybe<ResourceUpdateOneWithoutProductsInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceProductUpdateWithoutLanguageInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutResourceProductInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutProductResourceInput>;
  resource?: InputMaybe<ResourceUpdateOneWithoutProductsInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceProductUpdateWithoutProductInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutResourceProductInput>;
  language?: InputMaybe<LanguageUpdateOneWithoutResourceProductInput>;
  resource?: InputMaybe<ResourceUpdateOneWithoutProductsInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceProductUpdateWithoutResourceInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutResourceProductInput>;
  language?: InputMaybe<LanguageUpdateOneWithoutResourceProductInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutProductResourceInput>;
  type?: InputMaybe<NullableEnumProductTypeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceProductUpsertWithWhereUniqueWithoutCompanyInput = {
  create: ResourceProductCreateWithoutCompanyInput;
  update: ResourceProductUpdateWithoutCompanyInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductUpsertWithWhereUniqueWithoutLanguageInput = {
  create: ResourceProductCreateWithoutLanguageInput;
  update: ResourceProductUpdateWithoutLanguageInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductUpsertWithWhereUniqueWithoutProductInput = {
  create: ResourceProductCreateWithoutProductInput;
  update: ResourceProductUpdateWithoutProductInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceProductCreateWithoutResourceInput;
  update: ResourceProductUpdateWithoutResourceInput;
  where: ResourceProductWhereUniqueInput;
};

export type ResourceProductWhereInput = {
  AND?: InputMaybe<Array<ResourceProductWhereInput>>;
  NOT?: InputMaybe<Array<ResourceProductWhereInput>>;
  OR?: InputMaybe<Array<ResourceProductWhereInput>>;
  company?: InputMaybe<CompanyWhereInput>;
  company_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  language?: InputMaybe<LanguageWhereInput>;
  language_id?: InputMaybe<IntNullableFilter>;
  product?: InputMaybe<ProductWhereInput>;
  product_id?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumProductTypeNullableFilter>;
  user_id?: InputMaybe<IntNullableFilter>;
};

export type ResourceProductWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceScalarWhereInput>>;
  blocked?: InputMaybe<BoolFilter>;
  create_date?: InputMaybe<DateTimeNullableFilter>;
  delete_date?: InputMaybe<DateTimeNullableFilter>;
  deleted_by_user_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  in_archive?: InputMaybe<BoolNullableFilter>;
  is_user_generated_content?: InputMaybe<BoolFilter>;
  noindex?: InputMaybe<BoolFilter>;
  parent_resource_id?: InputMaybe<IntNullableFilter>;
  release_date?: InputMaybe<DateTimeNullableFilter>;
  release_end_date?: InputMaybe<DateTimeNullableFilter>;
  update_date?: InputMaybe<DateTimeNullableFilter>;
};

export type ResourceTag = {
  __typename?: "ResourceTag";
  resource?: Maybe<Resource>;
  resourceId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with resourceId */
  resource_id?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
  tagId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with tagId */
  tag_id?: Maybe<Scalars["Int"]>;
};

export type ResourceTagCreateManyResourceInput = {
  tag_id: Scalars["Int"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceTagCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceTagCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceTagCreateManyTagInput = {
  resource_id: Scalars["Int"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceTagCreateManyTagInputEnvelope = {
  data?: InputMaybe<Array<ResourceTagCreateManyTagInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceTagCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTagCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceTagCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceTagCreateManyResourceInputEnvelope>;
};

export type ResourceTagCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTagCreateOrConnectWithoutTagInput>
  >;
  create?: InputMaybe<Array<ResourceTagCreateWithoutTagInput>>;
  createMany?: InputMaybe<ResourceTagCreateManyTagInputEnvelope>;
};

export type ResourceTagCreateOrConnectWithoutResourceInput = {
  create: ResourceTagCreateWithoutResourceInput;
  where: ResourceTagWhereUniqueInput;
};

export type ResourceTagCreateOrConnectWithoutTagInput = {
  create: ResourceTagCreateWithoutTagInput;
  where: ResourceTagWhereUniqueInput;
};

export type ResourceTagCreateWithoutResourceInput = {
  tag: TagCreateNestedOneWithoutResourceInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceTagCreateWithoutTagInput = {
  resource: ResourceCreateNestedOneWithoutTagsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceTagListRelationFilter = {
  every?: InputMaybe<ResourceTagWhereInput>;
  none?: InputMaybe<ResourceTagWhereInput>;
  some?: InputMaybe<ResourceTagWhereInput>;
};

export type ResourceTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceTagOrderByWithRelationInput = {
  resource?: InputMaybe<ResourceOrderByWithRelationInput>;
  resource_id?: InputMaybe<SortOrder>;
  tag?: InputMaybe<TagOrderByWithRelationInput>;
  tag_id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ResourceTagResource_IdTag_IdCompoundUniqueInput = {
  resource_id: Scalars["Int"];
  tag_id: Scalars["Int"];
};

export type ResourceTagScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceTagScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceTagScalarWhereInput>>;
  resource_id?: InputMaybe<IntFilter>;
  tag_id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ResourceTagSuggestion = {
  __typename?: "ResourceTagSuggestion";
  confidence?: Maybe<Scalars["Float"]>;
  /** This is automatically fetched from deepl.com. If this is empty, it might be that the restricted quota of 500k characters was reached. */
  germanTranslation?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  positions: Array<Maybe<ResourceTagSuggestionPosition>>;
  scopes: Array<Maybe<Scalars["String"]>>;
};

export type ResourceTagSuggestionPosition = {
  __typename?: "ResourceTagSuggestionPosition";
  confidence?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Float"]>;
  left?: Maybe<Scalars["Float"]>;
  top?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

export type ResourceTagUpdateManyMutationInput = {
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceTagUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceTagUpdateManyMutationInput;
  where: ResourceTagScalarWhereInput;
};

export type ResourceTagUpdateManyWithWhereWithoutTagInput = {
  data: ResourceTagUpdateManyMutationInput;
  where: ResourceTagScalarWhereInput;
};

export type ResourceTagUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTagCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceTagCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceTagCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceTagUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceTagUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceTagUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourceTagUpdateManyWithoutTagInput = {
  connect?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTagCreateOrConnectWithoutTagInput>
  >;
  create?: InputMaybe<Array<ResourceTagCreateWithoutTagInput>>;
  createMany?: InputMaybe<ResourceTagCreateManyTagInputEnvelope>;
  delete?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceTagWhereUniqueInput>>;
  update?: InputMaybe<Array<ResourceTagUpdateWithWhereUniqueWithoutTagInput>>;
  updateMany?: InputMaybe<Array<ResourceTagUpdateManyWithWhereWithoutTagInput>>;
  upsert?: InputMaybe<Array<ResourceTagUpsertWithWhereUniqueWithoutTagInput>>;
};

export type ResourceTagUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceTagUpdateWithoutResourceInput;
  where: ResourceTagWhereUniqueInput;
};

export type ResourceTagUpdateWithWhereUniqueWithoutTagInput = {
  data: ResourceTagUpdateWithoutTagInput;
  where: ResourceTagWhereUniqueInput;
};

export type ResourceTagUpdateWithoutResourceInput = {
  tag?: InputMaybe<TagUpdateOneRequiredWithoutResourceInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceTagUpdateWithoutTagInput = {
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutTagsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceTagUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceTagCreateWithoutResourceInput;
  update: ResourceTagUpdateWithoutResourceInput;
  where: ResourceTagWhereUniqueInput;
};

export type ResourceTagUpsertWithWhereUniqueWithoutTagInput = {
  create: ResourceTagCreateWithoutTagInput;
  update: ResourceTagUpdateWithoutTagInput;
  where: ResourceTagWhereUniqueInput;
};

export type ResourceTagWhereInput = {
  AND?: InputMaybe<Array<ResourceTagWhereInput>>;
  NOT?: InputMaybe<Array<ResourceTagWhereInput>>;
  OR?: InputMaybe<Array<ResourceTagWhereInput>>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntFilter>;
  tag?: InputMaybe<TagWhereInput>;
  tag_id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ResourceTagWhereUniqueInput = {
  resource_id_tag_id?: InputMaybe<ResourceTagResource_IdTag_IdCompoundUniqueInput>;
};

export type ResourceTemplate = {
  __typename?: "ResourceTemplate";
  name: Scalars["String"];
  parameter?: Maybe<Scalars["String"]>;
  show?: Maybe<Scalars["Boolean"]>;
};

export type ResourceTemplateCreateInput = {
  name: Scalars["String"];
  parameter?: InputMaybe<Scalars["String"]>;
  show?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceTemplateOrderByWithRelationInput = {
  name?: InputMaybe<SortOrder>;
  parameter?: InputMaybe<SortOrder>;
  show?: InputMaybe<SortOrder>;
};

export type ResourceTemplateUpdateInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ResourceTemplateWhereInput = {
  AND?: InputMaybe<Array<ResourceTemplateWhereInput>>;
  NOT?: InputMaybe<Array<ResourceTemplateWhereInput>>;
  OR?: InputMaybe<Array<ResourceTemplateWhereInput>>;
  name?: InputMaybe<StringFilter>;
  parameter?: InputMaybe<StringNullableFilter>;
  show?: InputMaybe<BoolNullableFilter>;
};

export type ResourceTemplateWhereUniqueInput = {
  name?: InputMaybe<Scalars["String"]>;
};

export type ResourceText = {
  __typename?: "ResourceText";
  id: Scalars["Int"];
};

export type ResourceTextCreate = {
  languageId: Scalars["Int"];
  resourceId: Scalars["Int"];
  text: Scalars["String"];
  textAttribute?: InputMaybe<TextAttributeCreateWithoutResourceTextInput>;
  translate?: InputMaybe<Scalars["Boolean"]>;
  type: ResourceTextType;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ResourceTextCreateManyResourceInput = {
  id?: InputMaybe<Scalars["Int"]>;
  text_id?: InputMaybe<Scalars["Int"]>;
  type: ResourceTextType;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ResourceTextCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceTextCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceTextCreateManyTextAttributeInput = {
  id?: InputMaybe<Scalars["Int"]>;
  resource_id?: InputMaybe<Scalars["Int"]>;
  type: ResourceTextType;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ResourceTextCreateManyTextAttributeInputEnvelope = {
  data?: InputMaybe<Array<ResourceTextCreateManyTextAttributeInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceTextCreateManyTextInput = {
  id?: InputMaybe<Scalars["Int"]>;
  resource_id?: InputMaybe<Scalars["Int"]>;
  type: ResourceTextType;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ResourceTextCreateManyTextInputEnvelope = {
  data?: InputMaybe<Array<ResourceTextCreateManyTextInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceTextCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTextCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceTextCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceTextCreateManyResourceInputEnvelope>;
};

export type ResourceTextCreateNestedManyWithoutTextAttributeInput = {
  connect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTextCreateOrConnectWithoutTextAttributeInput>
  >;
  create?: InputMaybe<Array<ResourceTextCreateWithoutTextAttributeInput>>;
  createMany?: InputMaybe<ResourceTextCreateManyTextAttributeInputEnvelope>;
};

export type ResourceTextCreateNestedManyWithoutTextInput = {
  connect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTextCreateOrConnectWithoutTextInput>
  >;
  create?: InputMaybe<Array<ResourceTextCreateWithoutTextInput>>;
  createMany?: InputMaybe<ResourceTextCreateManyTextInputEnvelope>;
};

export type ResourceTextCreateOrConnectWithoutResourceInput = {
  create: ResourceTextCreateWithoutResourceInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextCreateOrConnectWithoutTextAttributeInput = {
  create: ResourceTextCreateWithoutTextAttributeInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextCreateOrConnectWithoutTextInput = {
  create: ResourceTextCreateWithoutTextInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextCreateWithoutResourceInput = {
  text?: InputMaybe<TextCreateNestedOneWithoutResourceTextInput>;
  textAttribute?: InputMaybe<TextAttributeCreateNestedOneWithoutResourceTextInput>;
  type: ResourceTextType;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ResourceTextCreateWithoutTextAttributeInput = {
  resource?: InputMaybe<ResourceCreateNestedOneWithoutTextsInput>;
  text?: InputMaybe<TextCreateNestedOneWithoutResourceTextInput>;
  type: ResourceTextType;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ResourceTextCreateWithoutTextInput = {
  resource?: InputMaybe<ResourceCreateNestedOneWithoutTextsInput>;
  textAttribute?: InputMaybe<TextAttributeCreateNestedOneWithoutResourceTextInput>;
  type: ResourceTextType;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ResourceTextListRelationFilter = {
  every?: InputMaybe<ResourceTextWhereInput>;
  none?: InputMaybe<ResourceTextWhereInput>;
  some?: InputMaybe<ResourceTextWhereInput>;
};

export type ResourceTextOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceTextScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceTextScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceTextScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceTextScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  resource_id?: InputMaybe<IntNullableFilter>;
  text_id?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumResourceTextTypeFilter>;
  x?: InputMaybe<IntNullableFilter>;
  y?: InputMaybe<IntNullableFilter>;
};

export enum ResourceTextType {
  AltTag = "alt_tag",
  Category = "category",
  Description = "description",
  ImageMap = "image_map",
  ImageMapOpen = "image_map_open",
  Name = "name",
  TitleTag = "title_tag",
}

export type ResourceTextUpdateIdLang = {
  id: Scalars["Int"];
  languageId: Scalars["Int"];
};

export type ResourceTextUpdateInput = {
  text?: InputMaybe<Scalars["String"]>;
  translate?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<ResourceTextType>;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ResourceTextUpdateManyMutationInput = {
  type?: InputMaybe<EnumResourceTextTypeFieldUpdateOperationsInput>;
  x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceTextUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceTextUpdateManyMutationInput;
  where: ResourceTextScalarWhereInput;
};

export type ResourceTextUpdateManyWithWhereWithoutTextAttributeInput = {
  data: ResourceTextUpdateManyMutationInput;
  where: ResourceTextScalarWhereInput;
};

export type ResourceTextUpdateManyWithWhereWithoutTextInput = {
  data: ResourceTextUpdateManyMutationInput;
  where: ResourceTextScalarWhereInput;
};

export type ResourceTextUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTextCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceTextCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceTextCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceTextScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceTextUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceTextUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceTextUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourceTextUpdateManyWithoutTextAttributeInput = {
  connect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTextCreateOrConnectWithoutTextAttributeInput>
  >;
  create?: InputMaybe<Array<ResourceTextCreateWithoutTextAttributeInput>>;
  createMany?: InputMaybe<ResourceTextCreateManyTextAttributeInputEnvelope>;
  delete?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceTextScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceTextUpdateWithWhereUniqueWithoutTextAttributeInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceTextUpdateManyWithWhereWithoutTextAttributeInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceTextUpsertWithWhereUniqueWithoutTextAttributeInput>
  >;
};

export type ResourceTextUpdateManyWithoutTextInput = {
  connect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceTextCreateOrConnectWithoutTextInput>
  >;
  create?: InputMaybe<Array<ResourceTextCreateWithoutTextInput>>;
  createMany?: InputMaybe<ResourceTextCreateManyTextInputEnvelope>;
  delete?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceTextScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceTextWhereUniqueInput>>;
  update?: InputMaybe<Array<ResourceTextUpdateWithWhereUniqueWithoutTextInput>>;
  updateMany?: InputMaybe<
    Array<ResourceTextUpdateManyWithWhereWithoutTextInput>
  >;
  upsert?: InputMaybe<Array<ResourceTextUpsertWithWhereUniqueWithoutTextInput>>;
};

export type ResourceTextUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceTextUpdateWithoutResourceInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextUpdateWithWhereUniqueWithoutTextAttributeInput = {
  data: ResourceTextUpdateWithoutTextAttributeInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextUpdateWithWhereUniqueWithoutTextInput = {
  data: ResourceTextUpdateWithoutTextInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextUpdateWithoutResourceInput = {
  text?: InputMaybe<TextUpdateOneWithoutResourceTextInput>;
  textAttribute?: InputMaybe<TextAttributeUpdateOneWithoutResourceTextInput>;
  type?: InputMaybe<EnumResourceTextTypeFieldUpdateOperationsInput>;
  x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceTextUpdateWithoutTextAttributeInput = {
  resource?: InputMaybe<ResourceUpdateOneWithoutTextsInput>;
  text?: InputMaybe<TextUpdateOneWithoutResourceTextInput>;
  type?: InputMaybe<EnumResourceTextTypeFieldUpdateOperationsInput>;
  x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceTextUpdateWithoutTextInput = {
  resource?: InputMaybe<ResourceUpdateOneWithoutTextsInput>;
  textAttribute?: InputMaybe<TextAttributeUpdateOneWithoutResourceTextInput>;
  type?: InputMaybe<EnumResourceTextTypeFieldUpdateOperationsInput>;
  x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceTextUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceTextCreateWithoutResourceInput;
  update: ResourceTextUpdateWithoutResourceInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextUpsertWithWhereUniqueWithoutTextAttributeInput = {
  create: ResourceTextCreateWithoutTextAttributeInput;
  update: ResourceTextUpdateWithoutTextAttributeInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextUpsertWithWhereUniqueWithoutTextInput = {
  create: ResourceTextCreateWithoutTextInput;
  update: ResourceTextUpdateWithoutTextInput;
  where: ResourceTextWhereUniqueInput;
};

export type ResourceTextWhereInput = {
  AND?: InputMaybe<Array<ResourceTextWhereInput>>;
  NOT?: InputMaybe<Array<ResourceTextWhereInput>>;
  OR?: InputMaybe<Array<ResourceTextWhereInput>>;
  id?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<TextWhereInput>;
  textAttribute?: InputMaybe<TextAttributeWhereInput>;
  text_id?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumResourceTextTypeFilter>;
  x?: InputMaybe<IntNullableFilter>;
  y?: InputMaybe<IntNullableFilter>;
};

export type ResourceTextWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceUpdateInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  delete_date?: InputMaybe<Scalars["DateTime"]>;
  deleted_by_user_id?: InputMaybe<Scalars["Int"]>;
  in_archive?: InputMaybe<Scalars["Boolean"]>;
  is_user_generated_content?: InputMaybe<Scalars["Boolean"]>;
  noindex?: InputMaybe<Scalars["Boolean"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  release_end_date?: InputMaybe<Scalars["DateTime"]>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
};

export type ResourceUpdateManyMutationInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ResourceUpdateManyWithWhereWithoutParentResourceInput = {
  data: ResourceUpdateManyMutationInput;
  where: ResourceScalarWhereInput;
};

export type ResourceUpdateManyWithoutParentResourceInput = {
  connect?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceCreateOrConnectWithoutParentResourceInput>
  >;
  create?: InputMaybe<Array<ResourceCreateWithoutParentResourceInput>>;
  createMany?: InputMaybe<ResourceCreateManyParentResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceUpdateWithWhereUniqueWithoutParentResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceUpdateManyWithWhereWithoutParentResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceUpsertWithWhereUniqueWithoutParentResourceInput>
  >;
};

export type ResourceUpdateOneRequiredWithoutCollectionsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutCollectionsInput>;
  create?: InputMaybe<ResourceCreateWithoutCollectionsInput>;
  update?: InputMaybe<ResourceUpdateWithoutCollectionsInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutCollectionsInput>;
};

export type ResourceUpdateOneRequiredWithoutResourceLanguageInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutResourceLanguageInput>;
  create?: InputMaybe<ResourceCreateWithoutResourceLanguageInput>;
  update?: InputMaybe<ResourceUpdateWithoutResourceLanguageInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutResourceLanguageInput>;
};

export type ResourceUpdateOneRequiredWithoutResourceLicensesInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutResourceLicensesInput>;
  create?: InputMaybe<ResourceCreateWithoutResourceLicensesInput>;
  update?: InputMaybe<ResourceUpdateWithoutResourceLicensesInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutResourceLicensesInput>;
};

export type ResourceUpdateOneRequiredWithoutResourcePhotoModelsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutResourcePhotoModelsInput>;
  create?: InputMaybe<ResourceCreateWithoutResourcePhotoModelsInput>;
  update?: InputMaybe<ResourceUpdateWithoutResourcePhotoModelsInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutResourcePhotoModelsInput>;
};

export type ResourceUpdateOneRequiredWithoutTagsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutTagsInput>;
  create?: InputMaybe<ResourceCreateWithoutTagsInput>;
  update?: InputMaybe<ResourceUpdateWithoutTagsInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutTagsInput>;
};

export type ResourceUpdateOneRequiredWithoutVariantsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutVariantsInput>;
  create?: InputMaybe<ResourceCreateWithoutVariantsInput>;
  update?: InputMaybe<ResourceUpdateWithoutVariantsInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutVariantsInput>;
};

export type ResourceUpdateOneWithoutBrandInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutBrandInput>;
  create?: InputMaybe<ResourceCreateWithoutBrandInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ResourceUpdateWithoutBrandInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutBrandInput>;
};

export type ResourceUpdateOneWithoutChildResourcesInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutChildResourcesInput>;
  create?: InputMaybe<ResourceCreateWithoutChildResourcesInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ResourceUpdateWithoutChildResourcesInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutChildResourcesInput>;
};

export type ResourceUpdateOneWithoutProductsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<ResourceCreateWithoutProductsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ResourceUpdateWithoutProductsInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutProductsInput>;
};

export type ResourceUpdateOneWithoutQualitySignResourceInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutQualitySignResourceInput>;
  create?: InputMaybe<ResourceCreateWithoutQualitySignResourceInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ResourceUpdateWithoutQualitySignResourceInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutQualitySignResourceInput>;
};

export type ResourceUpdateOneWithoutTextsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutTextsInput>;
  create?: InputMaybe<ResourceCreateWithoutTextsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ResourceUpdateWithoutTextsInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutTextsInput>;
};

export type ResourceUpdateOneWithoutVersionsInput = {
  connect?: InputMaybe<ResourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResourceCreateOrConnectWithoutVersionsInput>;
  create?: InputMaybe<ResourceCreateWithoutVersionsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ResourceUpdateWithoutVersionsInput>;
  upsert?: InputMaybe<ResourceUpsertWithoutVersionsInput>;
};

export type ResourceUpdateWithWhereUniqueWithoutParentResourceInput = {
  data: ResourceUpdateWithoutParentResourceInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceUpdateWithoutBrandInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutChildResourcesInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutCollectionsInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutParentResourceInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutProductsInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutQualitySignResourceInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutResourceLanguageInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutResourceLicensesInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutResourcePhotoModelsInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutTagsInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutTextsInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutVariantsInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  versions?: InputMaybe<ResourceVersionUpdateManyWithoutResourceInput>;
};

export type ResourceUpdateWithoutVersionsInput = {
  blocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  brand?: InputMaybe<BrandUpdateManyWithoutResourceInput>;
  childResources?: InputMaybe<ResourceUpdateManyWithoutParentResourceInput>;
  collections?: InputMaybe<CollectionResourceUpdateManyWithoutResourceInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delete_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted_by_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  in_archive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  is_user_generated_content?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noindex?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentResource?: InputMaybe<ResourceUpdateOneWithoutChildResourcesInput>;
  productGroups?: InputMaybe<ResourceProductGroupUpdateManyWithoutResourceInput>;
  products?: InputMaybe<ResourceProductUpdateManyWithoutResourceInput>;
  qualitySignResource?: InputMaybe<QualitySignResourceUpdateManyWithoutResourceInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  release_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resourceLanguage?: InputMaybe<ResourceLanguageUpdateManyWithoutResourceInput>;
  resourceLicenses?: InputMaybe<ResourceLicenseUpdateManyWithoutResourceInput>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelUpdateManyWithoutResourceInput>;
  slugs?: InputMaybe<SlugUpdateManyWithoutResourceInput>;
  tags?: InputMaybe<ResourceTagUpdateManyWithoutResourceInput>;
  texts?: InputMaybe<ResourceTextUpdateManyWithoutResourceInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  variants?: InputMaybe<ResourceVariantUpdateManyWithoutResourceInput>;
};

export type ResourceUpsertWithWhereUniqueWithoutParentResourceInput = {
  create: ResourceCreateWithoutParentResourceInput;
  update: ResourceUpdateWithoutParentResourceInput;
  where: ResourceWhereUniqueInput;
};

export type ResourceUpsertWithoutBrandInput = {
  create: ResourceCreateWithoutBrandInput;
  update: ResourceUpdateWithoutBrandInput;
};

export type ResourceUpsertWithoutChildResourcesInput = {
  create: ResourceCreateWithoutChildResourcesInput;
  update: ResourceUpdateWithoutChildResourcesInput;
};

export type ResourceUpsertWithoutCollectionsInput = {
  create: ResourceCreateWithoutCollectionsInput;
  update: ResourceUpdateWithoutCollectionsInput;
};

export type ResourceUpsertWithoutProductsInput = {
  create: ResourceCreateWithoutProductsInput;
  update: ResourceUpdateWithoutProductsInput;
};

export type ResourceUpsertWithoutQualitySignResourceInput = {
  create: ResourceCreateWithoutQualitySignResourceInput;
  update: ResourceUpdateWithoutQualitySignResourceInput;
};

export type ResourceUpsertWithoutResourceLanguageInput = {
  create: ResourceCreateWithoutResourceLanguageInput;
  update: ResourceUpdateWithoutResourceLanguageInput;
};

export type ResourceUpsertWithoutResourceLicensesInput = {
  create: ResourceCreateWithoutResourceLicensesInput;
  update: ResourceUpdateWithoutResourceLicensesInput;
};

export type ResourceUpsertWithoutResourcePhotoModelsInput = {
  create: ResourceCreateWithoutResourcePhotoModelsInput;
  update: ResourceUpdateWithoutResourcePhotoModelsInput;
};

export type ResourceUpsertWithoutTagsInput = {
  create: ResourceCreateWithoutTagsInput;
  update: ResourceUpdateWithoutTagsInput;
};

export type ResourceUpsertWithoutTextsInput = {
  create: ResourceCreateWithoutTextsInput;
  update: ResourceUpdateWithoutTextsInput;
};

export type ResourceUpsertWithoutVariantsInput = {
  create: ResourceCreateWithoutVariantsInput;
  update: ResourceUpdateWithoutVariantsInput;
};

export type ResourceUpsertWithoutVersionsInput = {
  create: ResourceCreateWithoutVersionsInput;
  update: ResourceUpdateWithoutVersionsInput;
};

export type ResourceVariant = {
  __typename?: "ResourceVariant";
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars["Int"]>;
  resource: Resource;
  type?: Maybe<ResourceVariantType>;
  userId?: Maybe<Scalars["Int"]>;
  variant: Variant;
  variantId: Scalars["Int"];
};

export type ResourceVariantCreateInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceVariantInput>;
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceVariantInput>;
  resource: ResourceCreateNestedOneWithoutVariantsInput;
  type?: InputMaybe<ResourceVariantType>;
  user?: InputMaybe<UserCreateNestedOneWithoutResourceVariantsInput>;
  variant: VariantCreateNestedOneWithoutResourceVariantInput;
};

export type ResourceVariantCreateManyCompanyInput = {
  id?: InputMaybe<Scalars["Int"]>;
  language_id?: InputMaybe<Scalars["Int"]>;
  resource_id: Scalars["Int"];
  type?: InputMaybe<ResourceVariantType>;
  user_id?: InputMaybe<Scalars["Int"]>;
  variant_id: Scalars["Int"];
};

export type ResourceVariantCreateManyCompanyInputEnvelope = {
  data?: InputMaybe<Array<ResourceVariantCreateManyCompanyInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceVariantCreateManyLanguageInput = {
  company_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  resource_id: Scalars["Int"];
  type?: InputMaybe<ResourceVariantType>;
  user_id?: InputMaybe<Scalars["Int"]>;
  variant_id: Scalars["Int"];
};

export type ResourceVariantCreateManyLanguageInputEnvelope = {
  data?: InputMaybe<Array<ResourceVariantCreateManyLanguageInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceVariantCreateManyResourceInput = {
  company_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  language_id?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<ResourceVariantType>;
  user_id?: InputMaybe<Scalars["Int"]>;
  variant_id: Scalars["Int"];
};

export type ResourceVariantCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceVariantCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceVariantCreateManyUserInput = {
  company_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  language_id?: InputMaybe<Scalars["Int"]>;
  resource_id: Scalars["Int"];
  type?: InputMaybe<ResourceVariantType>;
  variant_id: Scalars["Int"];
};

export type ResourceVariantCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<ResourceVariantCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceVariantCreateManyVariantInput = {
  company_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  language_id?: InputMaybe<Scalars["Int"]>;
  resource_id: Scalars["Int"];
  type?: InputMaybe<ResourceVariantType>;
  user_id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceVariantCreateManyVariantInputEnvelope = {
  data?: InputMaybe<Array<ResourceVariantCreateManyVariantInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceVariantCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyCompanyInputEnvelope>;
};

export type ResourceVariantCreateNestedManyWithoutLanguageInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutLanguageInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutLanguageInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyLanguageInputEnvelope>;
};

export type ResourceVariantCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyResourceInputEnvelope>;
};

export type ResourceVariantCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutUserInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyUserInputEnvelope>;
};

export type ResourceVariantCreateNestedManyWithoutVariantInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutVariantInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutVariantInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyVariantInputEnvelope>;
};

export type ResourceVariantCreateOrConnectWithoutCompanyInput = {
  create: ResourceVariantCreateWithoutCompanyInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantCreateOrConnectWithoutLanguageInput = {
  create: ResourceVariantCreateWithoutLanguageInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantCreateOrConnectWithoutResourceInput = {
  create: ResourceVariantCreateWithoutResourceInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantCreateOrConnectWithoutUserInput = {
  create: ResourceVariantCreateWithoutUserInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantCreateOrConnectWithoutVariantInput = {
  create: ResourceVariantCreateWithoutVariantInput;
  where: ResourceVariantWhereUniqueInput;
};

export enum ResourceVariantCreateTypeEnumInput {
  ExtraImage = "extra_image",
  VariantImage = "variant_image",
}

export type ResourceVariantCreateWithoutCompanyInput = {
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceVariantInput>;
  resource: ResourceCreateNestedOneWithoutVariantsInput;
  type?: InputMaybe<ResourceVariantType>;
  user?: InputMaybe<UserCreateNestedOneWithoutResourceVariantsInput>;
  variant: VariantCreateNestedOneWithoutResourceVariantInput;
};

export type ResourceVariantCreateWithoutLanguageInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceVariantInput>;
  resource: ResourceCreateNestedOneWithoutVariantsInput;
  type?: InputMaybe<ResourceVariantType>;
  user?: InputMaybe<UserCreateNestedOneWithoutResourceVariantsInput>;
  variant: VariantCreateNestedOneWithoutResourceVariantInput;
};

export type ResourceVariantCreateWithoutResourceInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceVariantInput>;
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceVariantInput>;
  type?: InputMaybe<ResourceVariantType>;
  user?: InputMaybe<UserCreateNestedOneWithoutResourceVariantsInput>;
  variant: VariantCreateNestedOneWithoutResourceVariantInput;
};

export type ResourceVariantCreateWithoutUserInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceVariantInput>;
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceVariantInput>;
  resource: ResourceCreateNestedOneWithoutVariantsInput;
  type?: InputMaybe<ResourceVariantType>;
  variant: VariantCreateNestedOneWithoutResourceVariantInput;
};

export type ResourceVariantCreateWithoutVariantInput = {
  company?: InputMaybe<CompanyCreateNestedOneWithoutResourceVariantInput>;
  language?: InputMaybe<LanguageCreateNestedOneWithoutResourceVariantInput>;
  resource: ResourceCreateNestedOneWithoutVariantsInput;
  type?: InputMaybe<ResourceVariantType>;
  user?: InputMaybe<UserCreateNestedOneWithoutResourceVariantsInput>;
};

export type ResourceVariantListRelationFilter = {
  every?: InputMaybe<ResourceVariantWhereInput>;
  none?: InputMaybe<ResourceVariantWhereInput>;
  some?: InputMaybe<ResourceVariantWhereInput>;
};

export type ResourceVariantOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceVariantScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceVariantScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceVariantScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceVariantScalarWhereInput>>;
  company_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  language_id?: InputMaybe<IntNullableFilter>;
  resource_id?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumResourceVariantTypeNullableFilter>;
  user_id?: InputMaybe<IntNullableFilter>;
  variant_id?: InputMaybe<IntFilter>;
};

export enum ResourceVariantType {
  AmazonImage = "amazon_image",
  ExtraImage = "extra_image",
  Pdf = "pdf",
  VariantImage = "variant_image",
}

export type ResourceVariantUpdateManyMutationInput = {
  type?: InputMaybe<NullableEnumResourceVariantTypeFieldUpdateOperationsInput>;
};

export type ResourceVariantUpdateManyWithWhereWithoutCompanyInput = {
  data: ResourceVariantUpdateManyMutationInput;
  where: ResourceVariantScalarWhereInput;
};

export type ResourceVariantUpdateManyWithWhereWithoutLanguageInput = {
  data: ResourceVariantUpdateManyMutationInput;
  where: ResourceVariantScalarWhereInput;
};

export type ResourceVariantUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceVariantUpdateManyMutationInput;
  where: ResourceVariantScalarWhereInput;
};

export type ResourceVariantUpdateManyWithWhereWithoutUserInput = {
  data: ResourceVariantUpdateManyMutationInput;
  where: ResourceVariantScalarWhereInput;
};

export type ResourceVariantUpdateManyWithWhereWithoutVariantInput = {
  data: ResourceVariantUpdateManyMutationInput;
  where: ResourceVariantScalarWhereInput;
};

export type ResourceVariantUpdateManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutCompanyInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceVariantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceVariantUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceVariantUpdateManyWithWhereWithoutCompanyInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceVariantUpsertWithWhereUniqueWithoutCompanyInput>
  >;
};

export type ResourceVariantUpdateManyWithoutLanguageInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutLanguageInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutLanguageInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyLanguageInputEnvelope>;
  delete?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceVariantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceVariantUpdateWithWhereUniqueWithoutLanguageInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceVariantUpdateManyWithWhereWithoutLanguageInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceVariantUpsertWithWhereUniqueWithoutLanguageInput>
  >;
};

export type ResourceVariantUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceVariantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceVariantUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceVariantUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceVariantUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourceVariantUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutUserInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceVariantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceVariantUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceVariantUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceVariantUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type ResourceVariantUpdateManyWithoutVariantInput = {
  connect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVariantCreateOrConnectWithoutVariantInput>
  >;
  create?: InputMaybe<Array<ResourceVariantCreateWithoutVariantInput>>;
  createMany?: InputMaybe<ResourceVariantCreateManyVariantInputEnvelope>;
  delete?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceVariantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceVariantWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceVariantUpdateWithWhereUniqueWithoutVariantInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceVariantUpdateManyWithWhereWithoutVariantInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceVariantUpsertWithWhereUniqueWithoutVariantInput>
  >;
};

export type ResourceVariantUpdateWithWhereUniqueWithoutCompanyInput = {
  data: ResourceVariantUpdateWithoutCompanyInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpdateWithWhereUniqueWithoutLanguageInput = {
  data: ResourceVariantUpdateWithoutLanguageInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceVariantUpdateWithoutResourceInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpdateWithWhereUniqueWithoutUserInput = {
  data: ResourceVariantUpdateWithoutUserInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpdateWithWhereUniqueWithoutVariantInput = {
  data: ResourceVariantUpdateWithoutVariantInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpdateWithoutCompanyInput = {
  language?: InputMaybe<LanguageUpdateOneWithoutResourceVariantInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutVariantsInput>;
  type?: InputMaybe<NullableEnumResourceVariantTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutResourceVariantsInput>;
  variant?: InputMaybe<VariantUpdateOneRequiredWithoutResourceVariantInput>;
};

export type ResourceVariantUpdateWithoutLanguageInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutResourceVariantInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutVariantsInput>;
  type?: InputMaybe<NullableEnumResourceVariantTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutResourceVariantsInput>;
  variant?: InputMaybe<VariantUpdateOneRequiredWithoutResourceVariantInput>;
};

export type ResourceVariantUpdateWithoutResourceInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutResourceVariantInput>;
  language?: InputMaybe<LanguageUpdateOneWithoutResourceVariantInput>;
  type?: InputMaybe<NullableEnumResourceVariantTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutResourceVariantsInput>;
  variant?: InputMaybe<VariantUpdateOneRequiredWithoutResourceVariantInput>;
};

export type ResourceVariantUpdateWithoutUserInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutResourceVariantInput>;
  language?: InputMaybe<LanguageUpdateOneWithoutResourceVariantInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutVariantsInput>;
  type?: InputMaybe<NullableEnumResourceVariantTypeFieldUpdateOperationsInput>;
  variant?: InputMaybe<VariantUpdateOneRequiredWithoutResourceVariantInput>;
};

export type ResourceVariantUpdateWithoutVariantInput = {
  company?: InputMaybe<CompanyUpdateOneWithoutResourceVariantInput>;
  language?: InputMaybe<LanguageUpdateOneWithoutResourceVariantInput>;
  resource?: InputMaybe<ResourceUpdateOneRequiredWithoutVariantsInput>;
  type?: InputMaybe<NullableEnumResourceVariantTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutResourceVariantsInput>;
};

export type ResourceVariantUpsertWithWhereUniqueWithoutCompanyInput = {
  create: ResourceVariantCreateWithoutCompanyInput;
  update: ResourceVariantUpdateWithoutCompanyInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpsertWithWhereUniqueWithoutLanguageInput = {
  create: ResourceVariantCreateWithoutLanguageInput;
  update: ResourceVariantUpdateWithoutLanguageInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceVariantCreateWithoutResourceInput;
  update: ResourceVariantUpdateWithoutResourceInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpsertWithWhereUniqueWithoutUserInput = {
  create: ResourceVariantCreateWithoutUserInput;
  update: ResourceVariantUpdateWithoutUserInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantUpsertWithWhereUniqueWithoutVariantInput = {
  create: ResourceVariantCreateWithoutVariantInput;
  update: ResourceVariantUpdateWithoutVariantInput;
  where: ResourceVariantWhereUniqueInput;
};

export type ResourceVariantWhereInput = {
  AND?: InputMaybe<Array<ResourceVariantWhereInput>>;
  NOT?: InputMaybe<Array<ResourceVariantWhereInput>>;
  OR?: InputMaybe<Array<ResourceVariantWhereInput>>;
  company?: InputMaybe<CompanyWhereInput>;
  company_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  language?: InputMaybe<LanguageWhereInput>;
  language_id?: InputMaybe<IntNullableFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumResourceVariantTypeNullableFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<IntNullableFilter>;
  variant?: InputMaybe<VariantWhereInput>;
  variant_id?: InputMaybe<IntFilter>;
};

export type ResourceVariantWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceVersion = {
  __typename?: "ResourceVersion";
  active: Scalars["Boolean"];
  blocked?: Maybe<Scalars["Boolean"]>;
  bucket: Scalars["String"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  creator?: Maybe<User>;
  downloadUrl?: Maybe<Scalars["String"]>;
  filename: Scalars["String"];
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  image?: Maybe<Scalars["String"]>;
  parameter?: Maybe<Scalars["String"]>;
  path: Scalars["String"];
  /**
   * This generates a pimage token to request an image by version id.
   *       For more information see [Pimage documentation](https://docs.sport-thieme.de/en/dev/pimage#resource-version-instead-of-file)
   */
  pimageToken?: Maybe<Scalars["String"]>;
  processed: Scalars["Boolean"];
  rawHeight?: Maybe<Scalars["Int"]>;
  rawWidth?: Maybe<Scalars["Int"]>;
  resourceId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with resourceId */
  resource_id?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  userId?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with userId */
  user_id?: Maybe<Scalars["Int"]>;
  width?: Maybe<Scalars["Int"]>;
};

export type ResourceVersionImageArgs = {
  template?: InputMaybe<Scalars["String"]>;
};

export type ResourceVersionCreateInput = {
  active: Scalars["Boolean"];
  blocked?: InputMaybe<Scalars["Boolean"]>;
  bucket: Scalars["String"];
  create_date?: InputMaybe<Scalars["DateTime"]>;
  file_size?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Int"]>;
  mime_type?: InputMaybe<Scalars["String"]>;
  offset_x?: InputMaybe<Scalars["Int"]>;
  offset_y?: InputMaybe<Scalars["Int"]>;
  original_filename: Scalars["String"];
  parameter?: InputMaybe<Scalars["String"]>;
  path: Scalars["String"];
  raw_height?: InputMaybe<Scalars["Int"]>;
  raw_width?: InputMaybe<Scalars["Int"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  resolution_unit?: InputMaybe<Scalars["String"]>;
  resolution_x?: InputMaybe<Scalars["Int"]>;
  resolution_y?: InputMaybe<Scalars["Int"]>;
  resource?: InputMaybe<ResourceCreateNestedOneWithoutVersionsInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  upload_processed?: InputMaybe<Scalars["Boolean"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutResourceVersionsInput>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type ResourceVersionCreateManyResourceInput = {
  active: Scalars["Boolean"];
  blocked?: InputMaybe<Scalars["Boolean"]>;
  bucket: Scalars["String"];
  create_date?: InputMaybe<Scalars["DateTime"]>;
  file_size?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  mime_type?: InputMaybe<Scalars["String"]>;
  offset_x?: InputMaybe<Scalars["Int"]>;
  offset_y?: InputMaybe<Scalars["Int"]>;
  original_filename: Scalars["String"];
  parameter?: InputMaybe<Scalars["String"]>;
  path: Scalars["String"];
  raw_height?: InputMaybe<Scalars["Int"]>;
  raw_width?: InputMaybe<Scalars["Int"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  resolution_unit?: InputMaybe<Scalars["String"]>;
  resolution_x?: InputMaybe<Scalars["Int"]>;
  resolution_y?: InputMaybe<Scalars["Int"]>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  upload_processed?: InputMaybe<Scalars["Boolean"]>;
  user_id?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type ResourceVersionCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<ResourceVersionCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceVersionCreateManyUserInput = {
  active: Scalars["Boolean"];
  blocked?: InputMaybe<Scalars["Boolean"]>;
  bucket: Scalars["String"];
  create_date?: InputMaybe<Scalars["DateTime"]>;
  file_size?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  mime_type?: InputMaybe<Scalars["String"]>;
  offset_x?: InputMaybe<Scalars["Int"]>;
  offset_y?: InputMaybe<Scalars["Int"]>;
  original_filename: Scalars["String"];
  parameter?: InputMaybe<Scalars["String"]>;
  path: Scalars["String"];
  raw_height?: InputMaybe<Scalars["Int"]>;
  raw_width?: InputMaybe<Scalars["Int"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  resolution_unit?: InputMaybe<Scalars["String"]>;
  resolution_x?: InputMaybe<Scalars["Int"]>;
  resolution_y?: InputMaybe<Scalars["Int"]>;
  resource_id?: InputMaybe<Scalars["Int"]>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  upload_processed?: InputMaybe<Scalars["Boolean"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type ResourceVersionCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<ResourceVersionCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceVersionCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVersionCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceVersionCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceVersionCreateManyResourceInputEnvelope>;
};

export type ResourceVersionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVersionCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<ResourceVersionCreateWithoutUserInput>>;
  createMany?: InputMaybe<ResourceVersionCreateManyUserInputEnvelope>;
};

export type ResourceVersionCreateOrConnectWithoutResourceInput = {
  create: ResourceVersionCreateWithoutResourceInput;
  where: ResourceVersionWhereUniqueInput;
};

export type ResourceVersionCreateOrConnectWithoutUserInput = {
  create: ResourceVersionCreateWithoutUserInput;
  where: ResourceVersionWhereUniqueInput;
};

export type ResourceVersionCreateWithoutResourceInput = {
  active: Scalars["Boolean"];
  blocked?: InputMaybe<Scalars["Boolean"]>;
  bucket: Scalars["String"];
  create_date?: InputMaybe<Scalars["DateTime"]>;
  file_size?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Int"]>;
  mime_type?: InputMaybe<Scalars["String"]>;
  offset_x?: InputMaybe<Scalars["Int"]>;
  offset_y?: InputMaybe<Scalars["Int"]>;
  original_filename: Scalars["String"];
  parameter?: InputMaybe<Scalars["String"]>;
  path: Scalars["String"];
  raw_height?: InputMaybe<Scalars["Int"]>;
  raw_width?: InputMaybe<Scalars["Int"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  resolution_unit?: InputMaybe<Scalars["String"]>;
  resolution_x?: InputMaybe<Scalars["Int"]>;
  resolution_y?: InputMaybe<Scalars["Int"]>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  upload_processed?: InputMaybe<Scalars["Boolean"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutResourceVersionsInput>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type ResourceVersionCreateWithoutUserInput = {
  active: Scalars["Boolean"];
  blocked?: InputMaybe<Scalars["Boolean"]>;
  bucket: Scalars["String"];
  create_date?: InputMaybe<Scalars["DateTime"]>;
  file_size?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Int"]>;
  mime_type?: InputMaybe<Scalars["String"]>;
  offset_x?: InputMaybe<Scalars["Int"]>;
  offset_y?: InputMaybe<Scalars["Int"]>;
  original_filename: Scalars["String"];
  parameter?: InputMaybe<Scalars["String"]>;
  path: Scalars["String"];
  raw_height?: InputMaybe<Scalars["Int"]>;
  raw_width?: InputMaybe<Scalars["Int"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  resolution_unit?: InputMaybe<Scalars["String"]>;
  resolution_x?: InputMaybe<Scalars["Int"]>;
  resolution_y?: InputMaybe<Scalars["Int"]>;
  resource?: InputMaybe<ResourceCreateNestedOneWithoutVersionsInput>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  upload_processed?: InputMaybe<Scalars["Boolean"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type ResourceVersionListRelationFilter = {
  every?: InputMaybe<ResourceVersionWhereInput>;
  none?: InputMaybe<ResourceVersionWhereInput>;
  some?: InputMaybe<ResourceVersionWhereInput>;
};

export type ResourceVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ResourceVersionScalarWhereInput = {
  AND?: InputMaybe<Array<ResourceVersionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResourceVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<ResourceVersionScalarWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  blocked?: InputMaybe<BoolNullableFilter>;
  bucket?: InputMaybe<StringFilter>;
  create_date?: InputMaybe<DateTimeNullableFilter>;
  file_size?: InputMaybe<IntNullableFilter>;
  height?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  mime_type?: InputMaybe<StringNullableFilter>;
  offset_x?: InputMaybe<IntNullableFilter>;
  offset_y?: InputMaybe<IntNullableFilter>;
  original_filename?: InputMaybe<StringFilter>;
  parameter?: InputMaybe<StringNullableFilter>;
  path?: InputMaybe<StringFilter>;
  raw_height?: InputMaybe<IntNullableFilter>;
  raw_width?: InputMaybe<IntNullableFilter>;
  release_date?: InputMaybe<DateTimeNullableFilter>;
  resolution_unit?: InputMaybe<StringNullableFilter>;
  resolution_x?: InputMaybe<IntNullableFilter>;
  resolution_y?: InputMaybe<IntNullableFilter>;
  resource_id?: InputMaybe<IntNullableFilter>;
  update_date?: InputMaybe<DateTimeNullableFilter>;
  upload_processed?: InputMaybe<BoolFilter>;
  user_id?: InputMaybe<IntNullableFilter>;
  width?: InputMaybe<IntNullableFilter>;
};

export type ResourceVersionUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blocked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  file_size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mime_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  offset_x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  offset_y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  original_filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  path?: InputMaybe<StringFieldUpdateOperationsInput>;
  raw_height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  raw_width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resolution_unit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resolution_x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  resolution_y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  upload_processed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceVersionUpdateManyWithWhereWithoutResourceInput = {
  data: ResourceVersionUpdateManyMutationInput;
  where: ResourceVersionScalarWhereInput;
};

export type ResourceVersionUpdateManyWithWhereWithoutUserInput = {
  data: ResourceVersionUpdateManyMutationInput;
  where: ResourceVersionScalarWhereInput;
};

export type ResourceVersionUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVersionCreateOrConnectWithoutResourceInput>
  >;
  create?: InputMaybe<Array<ResourceVersionCreateWithoutResourceInput>>;
  createMany?: InputMaybe<ResourceVersionCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceVersionUpdateWithWhereUniqueWithoutResourceInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceVersionUpdateManyWithWhereWithoutResourceInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceVersionUpsertWithWhereUniqueWithoutResourceInput>
  >;
};

export type ResourceVersionUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ResourceVersionCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<ResourceVersionCreateWithoutUserInput>>;
  createMany?: InputMaybe<ResourceVersionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResourceVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<ResourceVersionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ResourceVersionUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<ResourceVersionUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<ResourceVersionUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type ResourceVersionUpdateWithWhereUniqueWithoutResourceInput = {
  data: ResourceVersionUpdateWithoutResourceInput;
  where: ResourceVersionWhereUniqueInput;
};

export type ResourceVersionUpdateWithWhereUniqueWithoutUserInput = {
  data: ResourceVersionUpdateWithoutUserInput;
  where: ResourceVersionWhereUniqueInput;
};

export type ResourceVersionUpdateWithoutResourceInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blocked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  file_size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mime_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  offset_x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  offset_y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  original_filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  path?: InputMaybe<StringFieldUpdateOperationsInput>;
  raw_height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  raw_width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resolution_unit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resolution_x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  resolution_y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  upload_processed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutResourceVersionsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceVersionUpdateWithoutUserInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  blocked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  create_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  file_size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mime_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  offset_x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  offset_y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  original_filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  path?: InputMaybe<StringFieldUpdateOperationsInput>;
  raw_height?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  raw_width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  release_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resolution_unit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resolution_x?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  resolution_y?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  resource?: InputMaybe<ResourceUpdateOneWithoutVersionsInput>;
  update_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  upload_processed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  width?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type ResourceVersionUpsertWithWhereUniqueWithoutResourceInput = {
  create: ResourceVersionCreateWithoutResourceInput;
  update: ResourceVersionUpdateWithoutResourceInput;
  where: ResourceVersionWhereUniqueInput;
};

export type ResourceVersionUpsertWithWhereUniqueWithoutUserInput = {
  create: ResourceVersionCreateWithoutUserInput;
  update: ResourceVersionUpdateWithoutUserInput;
  where: ResourceVersionWhereUniqueInput;
};

export type ResourceVersionWhereInput = {
  AND?: InputMaybe<Array<ResourceVersionWhereInput>>;
  NOT?: InputMaybe<Array<ResourceVersionWhereInput>>;
  OR?: InputMaybe<Array<ResourceVersionWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  blocked?: InputMaybe<BoolNullableFilter>;
  bucket?: InputMaybe<StringFilter>;
  create_date?: InputMaybe<DateTimeNullableFilter>;
  file_size?: InputMaybe<IntNullableFilter>;
  height?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  mime_type?: InputMaybe<StringNullableFilter>;
  offset_x?: InputMaybe<IntNullableFilter>;
  offset_y?: InputMaybe<IntNullableFilter>;
  original_filename?: InputMaybe<StringFilter>;
  parameter?: InputMaybe<StringNullableFilter>;
  path?: InputMaybe<StringFilter>;
  raw_height?: InputMaybe<IntNullableFilter>;
  raw_width?: InputMaybe<IntNullableFilter>;
  release_date?: InputMaybe<DateTimeNullableFilter>;
  resolution_unit?: InputMaybe<StringNullableFilter>;
  resolution_x?: InputMaybe<IntNullableFilter>;
  resolution_y?: InputMaybe<IntNullableFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntNullableFilter>;
  update_date?: InputMaybe<DateTimeNullableFilter>;
  upload_processed?: InputMaybe<BoolFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<IntNullableFilter>;
  width?: InputMaybe<IntNullableFilter>;
};

export type ResourceVersionWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ResourceWhereInput = {
  AND?: InputMaybe<Array<ResourceWhereInput>>;
  NOT?: InputMaybe<Array<ResourceWhereInput>>;
  OR?: InputMaybe<Array<ResourceWhereInput>>;
  blocked?: InputMaybe<BoolFilter>;
  brand?: InputMaybe<BrandListRelationFilter>;
  childResources?: InputMaybe<ResourceListRelationFilter>;
  collections?: InputMaybe<CollectionResourceListRelationFilter>;
  create_date?: InputMaybe<DateTimeNullableFilter>;
  delete_date?: InputMaybe<DateTimeNullableFilter>;
  deleted_by_user_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  in_archive?: InputMaybe<BoolNullableFilter>;
  is_user_generated_content?: InputMaybe<BoolFilter>;
  noindex?: InputMaybe<BoolFilter>;
  parentResource?: InputMaybe<ResourceWhereInput>;
  parent_resource_id?: InputMaybe<IntNullableFilter>;
  productGroups?: InputMaybe<ResourceProductGroupListRelationFilter>;
  products?: InputMaybe<ResourceProductListRelationFilter>;
  qualitySignResource?: InputMaybe<QualitySignResourceListRelationFilter>;
  release_date?: InputMaybe<DateTimeNullableFilter>;
  release_end_date?: InputMaybe<DateTimeNullableFilter>;
  resourceLanguage?: InputMaybe<ResourceLanguageListRelationFilter>;
  resourceLicenses?: InputMaybe<ResourceLicenseListRelationFilter>;
  resourcePhotoModels?: InputMaybe<ResourcePhotoModelListRelationFilter>;
  slugs?: InputMaybe<SlugListRelationFilter>;
  tags?: InputMaybe<ResourceTagListRelationFilter>;
  texts?: InputMaybe<ResourceTextListRelationFilter>;
  update_date?: InputMaybe<DateTimeNullableFilter>;
  variants?: InputMaybe<ResourceVariantListRelationFilter>;
  versions?: InputMaybe<ResourceVersionListRelationFilter>;
};

export type ResourceWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ResourcesDeleteInput = {
  id?: InputMaybe<Array<Scalars["Int"]>>;
};

export type RestMutationLog = MutationLog & {
  __typename?: "RestMutationLog";
  date?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  ip?: Maybe<Scalars["String"]>;
  languageId?: Maybe<Scalars["Int"]>;
  /** This is the operation name in gql or the rest context + fieldName  */
  operation?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
  /** JSON representation of the post or get data of the save request */
  request?: Maybe<Scalars["String"]>;
  /** JSON representation of the response from the server */
  response?: Maybe<Scalars["String"]>;
  shopId?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["Int"]>;
};

export type ResultDateRange = {
  __typename?: "ResultDateRange";
  maxDate?: Maybe<Scalars["String"]>;
  minDate?: Maybe<Scalars["String"]>;
};

export type ResultTotals = {
  __typename?: "ResultTotals";
  paginationLimit?: Maybe<Scalars["Int"]>;
  paginationLimitRelation?: Maybe<ResultTotalsRelation>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export enum ResultTotalsRelation {
  Exact = "EXACT",
  MoreThan = "MORE_THAN",
}

export type Role = {
  __typename?: "Role";
  creator: Scalars["String"];
  /** Active Directory User */
  creatorADUser?: Maybe<AdUser>;
  description: Scalars["String"];
  groupRole: Array<GroupRole>;
  id: Scalars["Int"];
  name: Scalars["String"];
  permission: Array<Permission>;
};

export type RoleGroupRoleArgs = {
  after?: InputMaybe<GroupRoleWhereUniqueInput>;
  before?: InputMaybe<GroupRoleWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type RolePermissionArgs = {
  after?: InputMaybe<PermissionWhereUniqueInput>;
  before?: InputMaybe<PermissionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type RoleOrderByInput = {
  creator?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  creator?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  groupRole?: InputMaybe<GroupRoleListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  permission?: InputMaybe<PermissionListRelationFilter>;
};

export type RoleWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type SalesVoucher = {
  __typename?: "SalesVoucher";
  amount: Scalars["Float"];
  code: Scalars["String"];
};

export type SalesVoucherCode = {
  __typename?: "SalesVoucherCode";
  code: Scalars["String"];
  currency: Scalars["String"];
  id: Scalars["Int"];
  orderReservation: Scalars["Boolean"];
  redeems?: Maybe<Array<VoucherRedeem>>;
  remainingVoucherAmount: Scalars["Float"];
  voucherAmount: Scalars["Float"];
};

export type SalesVoucherCodePagination = {
  __typename?: "SalesVoucherCodePagination";
  data?: Maybe<Array<Maybe<SalesVoucherCode>>>;
  pagination?: Maybe<PaginationVoucher>;
};

export enum Salutation {
  Mr = "MR",
  Mrs = "MRS",
  Unknown = "UNKNOWN",
}

export type SearchGroup = {
  __typename?: "SearchGroup";
  groups?: Maybe<Array<Maybe<Group>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type SearchGroupRole = {
  __typename?: "SearchGroupRole";
  SearchGroup?: Maybe<SearchGroup>;
  SearchRole?: Maybe<SearchRole>;
};

export type SearchRole = {
  __typename?: "SearchRole";
  roles?: Maybe<Array<Maybe<Role>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type SearchTypeCatalogs = {
  __typename?: "SearchTypeCatalogs";
  totalCount: Scalars["Int"];
  typeCatalogs?: Maybe<Array<Maybe<TypeCatalog>>>;
};

export type Service = {
  __typename?: "Service";
  app: Array<App>;
  id: Scalars["Int"];
  name: Scalars["String"];
  typeCatalog: Array<TypeCatalog>;
};

export type ServiceAppArgs = {
  after?: InputMaybe<AppWhereUniqueInput>;
  before?: InputMaybe<AppWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ServiceTypeCatalogArgs = {
  after?: InputMaybe<TypeCatalogWhereUniqueInput>;
  before?: InputMaybe<TypeCatalogWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ServiceAttributeUpdateInput = {
  appId?: InputMaybe<Array<Scalars["Int"]>>;
  name?: InputMaybe<Scalars["String"]>;
};

export type ServiceListRelationFilter = {
  every?: InputMaybe<ServiceWhereInput>;
  none?: InputMaybe<ServiceWhereInput>;
  some?: InputMaybe<ServiceWhereInput>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  app?: InputMaybe<AppListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  typeCatalog?: InputMaybe<TypeCatalogListRelationFilter>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Shipment = {
  __typename?: "Shipment";
  carrier?: Maybe<Carrier>;
  events?: Maybe<Array<Maybe<ShipmentEvent>>>;
  packingDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  trackingNumber?: Maybe<Scalars["String"]>;
  trackingUrl?: Maybe<Scalars["String"]>;
};

export type ShipmentEvent = {
  __typename?: "ShipmentEvent";
  event?: Maybe<ShipmentEventType>;
  eventDate?: Maybe<Scalars["String"]>;
};

export enum ShipmentEventType {
  Delay = "DELAY",
  Delivered = "DELIVERED",
  Dispatch = "DISPATCH",
  Failedattempt = "FAILEDATTEMPT",
  Outfordelivery = "OUTFORDELIVERY",
  Pickupready = "PICKUPREADY",
  Return = "RETURN",
  Scheduled = "SCHEDULED",
}

export type ShippingCostsVoucher = Voucher & {
  __typename?: "ShippingCostsVoucher";
  active?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<Scalars["String"]>;
  codes?: Maybe<VoucherCodePagination>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  minOrderValue?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  navisionAccount?: Maybe<Scalars["Int"]>;
  shopId?: Maybe<Scalars["Int"]>;
  statistics?: Maybe<VoucherStatistic>;
  /** Total number of times this voucher has been redeemed in an confirmed order. */
  totalRedeemCount?: Maybe<Scalars["Int"]>;
  /** Total number of redeemed codes. Please keep in mind that this number might seem wrong if codes can redeem multiple times. This query uses the boolean on voucher code. */
  totalRedeemdedCodes?: Maybe<Scalars["Int"]>;
  unique?: Maybe<Scalars["Boolean"]>;
};

export type ShippingCostsVoucherCodesArgs = {
  code?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  expired?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  redeemed?: InputMaybe<Scalars["Boolean"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VoucherCodeWhereInput>;
};

export type ShippingCostsVoucherStatisticsArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export enum ShippingType {
  Freight = "FREIGHT",
  Parcel = "PARCEL",
}

export type ShopEligibleForOci = {
  __typename?: "ShopEligibleForOCI";
  customers: Array<OciCustomer>;
  domainName?: Maybe<Scalars["String"]>;
  domainNameSsl: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ShopEligibleForOciCustomersArgs = {
  after?: InputMaybe<OciCustomerWhereUniqueInput>;
  before?: InputMaybe<OciCustomerWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ShopEligibleForOciCreateNestedOneWithoutCustomersInput = {
  connect?: InputMaybe<ShopEligibleForOciWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShopEligibleForOciCreateOrConnectWithoutCustomersInput>;
  create?: InputMaybe<ShopEligibleForOciCreateWithoutCustomersInput>;
};

export type ShopEligibleForOciCreateOrConnectWithoutCustomersInput = {
  create: ShopEligibleForOciCreateWithoutCustomersInput;
  where: ShopEligibleForOciWhereUniqueInput;
};

export type ShopEligibleForOciCreateWithoutCustomersInput = {
  domainName?: InputMaybe<Scalars["String"]>;
  domainNameSsl: Scalars["String"];
  name: Scalars["String"];
};

export type ShopEligibleForOciOrderByInput = {
  domainName?: InputMaybe<SortOrder>;
  domainNameSsl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ShopEligibleForOciUpdateOneWithoutCustomersInput = {
  connect?: InputMaybe<ShopEligibleForOciWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShopEligibleForOciCreateOrConnectWithoutCustomersInput>;
  create?: InputMaybe<ShopEligibleForOciCreateWithoutCustomersInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ShopEligibleForOciUpdateWithoutCustomersInput>;
  upsert?: InputMaybe<ShopEligibleForOciUpsertWithoutCustomersInput>;
};

export type ShopEligibleForOciUpdateWithoutCustomersInput = {
  domainName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domainNameSsl?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ShopEligibleForOciUpsertWithoutCustomersInput = {
  create: ShopEligibleForOciCreateWithoutCustomersInput;
  update: ShopEligibleForOciUpdateWithoutCustomersInput;
};

export type ShopEligibleForOciWhereInput = {
  AND?: InputMaybe<Array<ShopEligibleForOciWhereInput>>;
  NOT?: InputMaybe<Array<ShopEligibleForOciWhereInput>>;
  OR?: InputMaybe<Array<ShopEligibleForOciWhereInput>>;
  customers?: InputMaybe<OciCustomerListRelationFilter>;
  domainName?: InputMaybe<StringNullableFilter>;
  domainNameSsl?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ShopEligibleForOciWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ShopEligibleForVouchers = {
  __typename?: "ShopEligibleForVouchers";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ShopLanguagesCreateNestedOneWithoutClassificationInput = {
  connect?: InputMaybe<ShopLanguagesWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShopLanguagesCreateOrConnectWithoutClassificationInput>;
  create?: InputMaybe<ShopLanguagesCreateWithoutClassificationInput>;
};

export type ShopLanguagesCreateOrConnectWithoutClassificationInput = {
  create: ShopLanguagesCreateWithoutClassificationInput;
  where: ShopLanguagesWhereUniqueInput;
};

export type ShopLanguagesCreateWithoutClassificationInput = {
  languageId: Scalars["Int"];
  shopId: Scalars["Int"];
};

export type ShopLanguagesUpdateOneWithoutClassificationInput = {
  connect?: InputMaybe<ShopLanguagesWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShopLanguagesCreateOrConnectWithoutClassificationInput>;
  create?: InputMaybe<ShopLanguagesCreateWithoutClassificationInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<ShopLanguagesUpdateWithoutClassificationInput>;
  upsert?: InputMaybe<ShopLanguagesUpsertWithoutClassificationInput>;
};

export type ShopLanguagesUpdateWithoutClassificationInput = {
  languageId?: InputMaybe<IntFieldUpdateOperationsInput>;
  shopId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ShopLanguagesUpsertWithoutClassificationInput = {
  create: ShopLanguagesCreateWithoutClassificationInput;
  update: ShopLanguagesUpdateWithoutClassificationInput;
};

export type ShopLanguagesWhereInput = {
  AND?: InputMaybe<Array<ShopLanguagesWhereInput>>;
  Classification?: InputMaybe<ClassificationListRelationFilter>;
  NOT?: InputMaybe<Array<ShopLanguagesWhereInput>>;
  OR?: InputMaybe<Array<ShopLanguagesWhereInput>>;
  id?: InputMaybe<IntFilter>;
  languageId?: InputMaybe<IntFilter>;
  shopId?: InputMaybe<IntFilter>;
};

export type ShopLanguagesWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type ShopMail = {
  __typename?: "ShopMail";
  body?: Maybe<Scalars["String"]>;
  emailId?: Maybe<Scalars["ID"]>;
  from?: Maybe<Scalars["String"]>;
  sendingDate?: Maybe<Scalars["String"]>;
  status?: Maybe<ShopMailStatus>;
  subject?: Maybe<Scalars["String"]>;
  to?: Maybe<Scalars["String"]>;
};

export enum ShopMailStatus {
  Enqueued = "ENQUEUED",
  Error = "ERROR",
  Future = "FUTURE",
  FutureUnreleased = "FUTURE_UNRELEASED",
  RecipientBlacklisted = "RECIPIENT_BLACKLISTED",
  Sent = "SENT",
}

export type ShopOrder = Order & {
  __typename?: "ShopOrder";
  adyenPaymentData?: Maybe<Array<Maybe<AdyenPaymentData>>>;
  allInOneDelivery?: Maybe<Scalars["Boolean"]>;
  baseUrl?: Maybe<Scalars["String"]>;
  billingAddress?: Maybe<Address>;
  buergelInfo?: Maybe<BuergelInfo>;
  campaign?: Maybe<Scalars["String"]>;
  creditscoreLog?: Maybe<Scalars["String"]>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  ip?: Maybe<Scalars["String"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  klarnaPaymentData?: Maybe<KlarnaPaymentData>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  paymentType?: Maybe<ShopPaymentType>;
  paypalPaymentData?: Maybe<PaypalPaymentData>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  remark?: Maybe<Scalars["String"]>;
  salesVouchers?: Maybe<Array<Maybe<SalesVoucher>>>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type ShopOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export enum ShopPaymentType {
  AdvancePayment = "ADVANCE_PAYMENT",
  ApplePay = "APPLE_PAY",
  Bancontact = "BANCONTACT",
  BancontactDirect = "BANCONTACT_DIRECT",
  Cash = "CASH",
  Creditcard = "CREDITCARD",
  Dankort = "DANKORT",
  Eps = "EPS",
  GooglePay = "GOOGLE_PAY",
  Ideal = "IDEAL",
  IdealDirect = "IDEAL_DIRECT",
  Invoice = "INVOICE",
  Klarna = "KLARNA",
  MasterPayment = "MASTER_PAYMENT",
  OnDelivery = "ON_DELIVERY",
  Paypal = "PAYPAL",
  PaypalExpress = "PAYPAL_EXPRESS",
  Paysolution = "PAYSOLUTION",
  Sofort = "SOFORT",
  Twint = "TWINT",
  Voucher = "VOUCHER",
  WirecardApplePay = "WIRECARD_APPLE_PAY",
  WirecardBancontact = "WIRECARD_BANCONTACT",
  WirecardCreditcard = "WIRECARD_CREDITCARD",
  WirecardEps = "WIRECARD_EPS",
  WirecardGooglePay = "WIRECARD_GOOGLE_PAY",
  WirecardIdeal = "WIRECARD_IDEAL",
  WirecardSofort = "WIRECARD_SOFORT",
}

export type ShopWhereInput = {
  AND?: InputMaybe<Array<ShopWhereInput>>;
  NOT?: InputMaybe<Array<ShopWhereInput>>;
  OR?: InputMaybe<Array<ShopWhereInput>>;
  currency?: InputMaybe<StringNullableFilter>;
  currencyCode?: InputMaybe<StringNullableFilter>;
  domains?: InputMaybe<DomainListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  vouchers?: InputMaybe<VoucherListRelationFilter>;
};

export type Slug = {
  __typename?: "Slug";
  /** @deprecated replaced with isDefaultSlug */
  default_slug?: Maybe<Scalars["Boolean"]>;
  isDefaultSlug: Scalars["Boolean"];
  resource?: Maybe<Resource>;
  slug: Scalars["String"];
};

export type SlugCreateInput = {
  default_slug: Scalars["Boolean"];
  resource?: InputMaybe<ResourceCreateNestedOneWithoutSlugsInput>;
  slug: Scalars["String"];
};

export type SlugCreateManyResourceInput = {
  default_slug: Scalars["Boolean"];
  slug: Scalars["String"];
};

export type SlugCreateManyResourceInputEnvelope = {
  data?: InputMaybe<Array<SlugCreateManyResourceInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type SlugCreateNestedManyWithoutResourceInput = {
  connect?: InputMaybe<Array<SlugWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SlugCreateOrConnectWithoutResourceInput>>;
  create?: InputMaybe<Array<SlugCreateWithoutResourceInput>>;
  createMany?: InputMaybe<SlugCreateManyResourceInputEnvelope>;
};

export type SlugCreateOrConnectWithoutResourceInput = {
  create: SlugCreateWithoutResourceInput;
  where: SlugWhereUniqueInput;
};

export type SlugCreateWithoutResourceInput = {
  default_slug: Scalars["Boolean"];
  slug: Scalars["String"];
};

export type SlugListRelationFilter = {
  every?: InputMaybe<SlugWhereInput>;
  none?: InputMaybe<SlugWhereInput>;
  some?: InputMaybe<SlugWhereInput>;
};

export type SlugOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SlugScalarWhereInput = {
  AND?: InputMaybe<Array<SlugScalarWhereInput>>;
  NOT?: InputMaybe<Array<SlugScalarWhereInput>>;
  OR?: InputMaybe<Array<SlugScalarWhereInput>>;
  default_slug?: InputMaybe<BoolFilter>;
  resource_id?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type SlugUpdateManyMutationInput = {
  default_slug?: InputMaybe<BoolFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SlugUpdateManyWithWhereWithoutResourceInput = {
  data: SlugUpdateManyMutationInput;
  where: SlugScalarWhereInput;
};

export type SlugUpdateManyWithoutResourceInput = {
  connect?: InputMaybe<Array<SlugWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SlugCreateOrConnectWithoutResourceInput>>;
  create?: InputMaybe<Array<SlugCreateWithoutResourceInput>>;
  createMany?: InputMaybe<SlugCreateManyResourceInputEnvelope>;
  delete?: InputMaybe<Array<SlugWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SlugScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SlugWhereUniqueInput>>;
  set?: InputMaybe<Array<SlugWhereUniqueInput>>;
  update?: InputMaybe<Array<SlugUpdateWithWhereUniqueWithoutResourceInput>>;
  updateMany?: InputMaybe<Array<SlugUpdateManyWithWhereWithoutResourceInput>>;
  upsert?: InputMaybe<Array<SlugUpsertWithWhereUniqueWithoutResourceInput>>;
};

export type SlugUpdateWithWhereUniqueWithoutResourceInput = {
  data: SlugUpdateWithoutResourceInput;
  where: SlugWhereUniqueInput;
};

export type SlugUpdateWithoutResourceInput = {
  default_slug?: InputMaybe<BoolFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SlugUpsertWithWhereUniqueWithoutResourceInput = {
  create: SlugCreateWithoutResourceInput;
  update: SlugUpdateWithoutResourceInput;
  where: SlugWhereUniqueInput;
};

export type SlugWhereInput = {
  AND?: InputMaybe<Array<SlugWhereInput>>;
  NOT?: InputMaybe<Array<SlugWhereInput>>;
  OR?: InputMaybe<Array<SlugWhereInput>>;
  default_slug?: InputMaybe<BoolFilter>;
  resource?: InputMaybe<ResourceWhereInput>;
  resource_id?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type SlugWhereUniqueInput = {
  slug?: InputMaybe<Scalars["String"]>;
};

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export enum SupplierProductCarbonFootprintMethod {
  CradleToGate = "CradleToGate",
  CradleToGrave = "CradleToGrave",
  MaterialBased = "MaterialBased",
}

export type Tag = {
  __typename?: "Tag";
  id: Scalars["Int"];
  resource: Array<ResourceTag>;
  resourceCount: Scalars["Int"];
  tag: Scalars["String"];
};

export type TagResourceArgs = {
  after?: InputMaybe<ResourceTagWhereUniqueInput>;
  before?: InputMaybe<ResourceTagWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type TagCreateNestedOneWithoutCollectionsTagsInput = {
  connect?: InputMaybe<TagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TagCreateOrConnectWithoutCollectionsTagsInput>;
  create?: InputMaybe<TagCreateWithoutCollectionsTagsInput>;
};

export type TagCreateNestedOneWithoutResourceInput = {
  connect?: InputMaybe<TagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TagCreateOrConnectWithoutResourceInput>;
  create?: InputMaybe<TagCreateWithoutResourceInput>;
};

export type TagCreateOrConnectWithoutCollectionsTagsInput = {
  create: TagCreateWithoutCollectionsTagsInput;
  where: TagWhereUniqueInput;
};

export type TagCreateOrConnectWithoutResourceInput = {
  create: TagCreateWithoutResourceInput;
  where: TagWhereUniqueInput;
};

export type TagCreateWithoutCollectionsTagsInput = {
  resource?: InputMaybe<ResourceTagCreateNestedManyWithoutTagInput>;
  tag: Scalars["String"];
};

export type TagCreateWithoutResourceInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagCreateNestedManyWithoutTagInput>;
  tag: Scalars["String"];
};

export type TagOrderByWithRelationInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  resource?: InputMaybe<ResourceTagOrderByRelationAggregateInput>;
  tag?: InputMaybe<SortOrder>;
};

export type TagUpdateOneRequiredWithoutCollectionsTagsInput = {
  connect?: InputMaybe<TagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TagCreateOrConnectWithoutCollectionsTagsInput>;
  create?: InputMaybe<TagCreateWithoutCollectionsTagsInput>;
  update?: InputMaybe<TagUpdateWithoutCollectionsTagsInput>;
  upsert?: InputMaybe<TagUpsertWithoutCollectionsTagsInput>;
};

export type TagUpdateOneRequiredWithoutResourceInput = {
  connect?: InputMaybe<TagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TagCreateOrConnectWithoutResourceInput>;
  create?: InputMaybe<TagCreateWithoutResourceInput>;
  update?: InputMaybe<TagUpdateWithoutResourceInput>;
  upsert?: InputMaybe<TagUpsertWithoutResourceInput>;
};

export type TagUpdateWithoutCollectionsTagsInput = {
  resource?: InputMaybe<ResourceTagUpdateManyWithoutTagInput>;
  tag?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TagUpdateWithoutResourceInput = {
  collectionsTags?: InputMaybe<ResourceCollectionTagUpdateManyWithoutTagInput>;
  tag?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TagUpsertWithoutCollectionsTagsInput = {
  create: TagCreateWithoutCollectionsTagsInput;
  update: TagUpdateWithoutCollectionsTagsInput;
};

export type TagUpsertWithoutResourceInput = {
  create: TagCreateWithoutResourceInput;
  update: TagUpdateWithoutResourceInput;
};

export type TagWhereInput = {
  AND?: InputMaybe<Array<TagWhereInput>>;
  NOT?: InputMaybe<Array<TagWhereInput>>;
  OR?: InputMaybe<Array<TagWhereInput>>;
  collectionsTags?: InputMaybe<ResourceCollectionTagListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  resource?: InputMaybe<ResourceTagListRelationFilter>;
  tag?: InputMaybe<StringFilter>;
};

export type TagWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  tag?: InputMaybe<Scalars["String"]>;
};

export type Tags = {
  __typename?: "Tags";
  count: Scalars["Int"];
  tags: Array<Maybe<Tag>>;
};

export type TccOrder = Order & {
  __typename?: "TccOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  poNumber?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  soNumber?: Maybe<Scalars["String"]>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type TccOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type Template = {
  __typename?: "Template";
  entries: Array<Maybe<TemplateCode>>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type TemplateCode = {
  __typename?: "TemplateCode";
  code?: Maybe<Scalars["String"]>;
  languageId: Scalars["Int"];
  languageName?: Maybe<Scalars["String"]>;
  shopId: Scalars["Int"];
  shopName?: Maybe<Scalars["String"]>;
};

export type TemplateCreateInput = {
  comment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  templateEntry?: InputMaybe<TemplateEntryCreateNestedManyWithoutTemplateInput>;
};

export type TemplateEntryCreateManyTemplateInput = {
  code?: InputMaybe<Scalars["String"]>;
  languageId: Scalars["Int"];
  shopId: Scalars["Int"];
};

export type TemplateEntryCreateManyTemplateInputEnvelope = {
  data?: InputMaybe<Array<TemplateEntryCreateManyTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TemplateEntryCreateNestedManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<TemplateEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TemplateEntryCreateOrConnectWithoutTemplateInput>
  >;
  create?: InputMaybe<Array<TemplateEntryCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<TemplateEntryCreateManyTemplateInputEnvelope>;
};

export type TemplateEntryCreateOrConnectWithoutTemplateInput = {
  create: TemplateEntryCreateWithoutTemplateInput;
  where: TemplateEntryWhereUniqueInput;
};

export type TemplateEntryCreateWithoutTemplateInput = {
  code?: InputMaybe<Scalars["String"]>;
  languageId: Scalars["Int"];
  shopId: Scalars["Int"];
};

export type TemplateEntryListRelationFilter = {
  every?: InputMaybe<TemplateEntryWhereInput>;
  none?: InputMaybe<TemplateEntryWhereInput>;
  some?: InputMaybe<TemplateEntryWhereInput>;
};

export type TemplateEntryScalarWhereInput = {
  AND?: InputMaybe<Array<TemplateEntryScalarWhereInput>>;
  NOT?: InputMaybe<Array<TemplateEntryScalarWhereInput>>;
  OR?: InputMaybe<Array<TemplateEntryScalarWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  languageId?: InputMaybe<IntFilter>;
  shopId?: InputMaybe<IntFilter>;
};

export type TemplateEntryTemplateEntryIdCompoundUniqueInput = {
  id: Scalars["Int"];
  languageId: Scalars["Int"];
  shopId: Scalars["Int"];
};

export type TemplateEntryUpdateManyMutationInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  languageId?: InputMaybe<IntFieldUpdateOperationsInput>;
  shopId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TemplateEntryUpdateManyWithWhereWithoutTemplateInput = {
  data: TemplateEntryUpdateManyMutationInput;
  where: TemplateEntryScalarWhereInput;
};

export type TemplateEntryUpdateManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<TemplateEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TemplateEntryCreateOrConnectWithoutTemplateInput>
  >;
  create?: InputMaybe<Array<TemplateEntryCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<TemplateEntryCreateManyTemplateInputEnvelope>;
  delete?: InputMaybe<Array<TemplateEntryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TemplateEntryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TemplateEntryWhereUniqueInput>>;
  set?: InputMaybe<Array<TemplateEntryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TemplateEntryUpdateWithWhereUniqueWithoutTemplateInput>
  >;
  updateMany?: InputMaybe<
    Array<TemplateEntryUpdateManyWithWhereWithoutTemplateInput>
  >;
  upsert?: InputMaybe<
    Array<TemplateEntryUpsertWithWhereUniqueWithoutTemplateInput>
  >;
};

export type TemplateEntryUpdateWithWhereUniqueWithoutTemplateInput = {
  data: TemplateEntryUpdateWithoutTemplateInput;
  where: TemplateEntryWhereUniqueInput;
};

export type TemplateEntryUpdateWithoutTemplateInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  languageId?: InputMaybe<IntFieldUpdateOperationsInput>;
  shopId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type TemplateEntryUpsertWithWhereUniqueWithoutTemplateInput = {
  create: TemplateEntryCreateWithoutTemplateInput;
  update: TemplateEntryUpdateWithoutTemplateInput;
  where: TemplateEntryWhereUniqueInput;
};

export type TemplateEntryWhereInput = {
  AND?: InputMaybe<Array<TemplateEntryWhereInput>>;
  NOT?: InputMaybe<Array<TemplateEntryWhereInput>>;
  OR?: InputMaybe<Array<TemplateEntryWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  languageId?: InputMaybe<IntFilter>;
  shopId?: InputMaybe<IntFilter>;
  template?: InputMaybe<TemplateWhereInput>;
};

export type TemplateEntryWhereUniqueInput = {
  templateEntryId?: InputMaybe<TemplateEntryTemplateEntryIdCompoundUniqueInput>;
};

export type TemplateOrderByInput = {
  comment?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type TemplateUpdateInput = {
  comment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  templateEntry?: InputMaybe<TemplateEntryUpdateManyWithoutTemplateInput>;
};

export type TemplateWhereInput = {
  AND?: InputMaybe<Array<TemplateWhereInput>>;
  NOT?: InputMaybe<Array<TemplateWhereInput>>;
  OR?: InputMaybe<Array<TemplateWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  templateEntry?: InputMaybe<TemplateEntryListRelationFilter>;
};

export type TemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Text = {
  __typename?: "Text";
  id: Scalars["Int"];
  language?: Maybe<Language>;
  languageId: Scalars["Int"];
  /** @deprecated replaced with languageId */
  language_id?: Maybe<Scalars["Int"]>;
  text?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with id */
  text_id?: Maybe<Scalars["Int"]>;
  translate: Scalars["Boolean"];
  /** @deprecated replaced with updatedAt */
  update_timestamp?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

/**
 * ATENTION! To use this end point you need to use a correct type:
 * * AnhangBezeichnung
 * * CatalogBenefit
 * * Bezeichnung
 * * Marke
 * * Breadcrumb
 * * Catalog1
 * * Catalog2
 * * Catalog3
 * * Einheit
 * * Facette
 * * Facetten-Pfad
 * * Facetten-Wert
 * * Key
 * * Kurz-Url
 * * Liefer-Art
 * * Liefer-Bedingung
 * * Lieferzeit
 * * Navigation
 * * Sportarten
 * * NavDef
 * * Optionswert
 * * Options-Bezeichnung
 * * Shop-Bezeichnung
 * * Sicherheitshinweis
 * * Katalog-Text
 * * Text
 * * Shop-Text
 * * TextWeb
 * * xPress
 * * MediaCategory
 * * CatalogKeyword
 * * ShopKeyword
 * * LinkBezeichnung
 * * LinkURL
 * * UspShop
 * * UspCatalog
 * * PrintText
 * * ShopBenefit
 * * FacettenWert
 * * FacettenName
 * * CatalogTemplate
 * * MediaName
 * * MediaDescription
 * * MediaAltTag
 * * MediaTitleTag
 * * ExtraText
 * * ShopBenefit
 * * AnnotationQuestion
 * * AnnotationAnswer
 * * MediaInfoPoint
 * * BrandDescription
 * * QualitySignDescription
 * * VideoTitle
 * * RecreatedFromTranslation
 * * ProductType
 * * ProductName
 */
export type TextAttribute = {
  __typename?: "TextAttribute";
  createdInLanguage?: Maybe<Scalars["Int"]>;
  /** @deprecated replaced with createdInLanguage */
  created_in_language?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  textId: Scalars["Int"];
  /** @deprecated replaced with textId */
  text_id?: Maybe<Scalars["Int"]>;
  type?: Maybe<TextAttributeType>;
};

export type TextAttributeCreateNestedOneWithoutResourceTextInput = {
  connect?: InputMaybe<TextAttributeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextAttributeCreateOrConnectWithoutResourceTextInput>;
  create?: InputMaybe<TextAttributeCreateWithoutResourceTextInput>;
};

export type TextAttributeCreateNestedOneWithoutTextInput = {
  connect?: InputMaybe<TextAttributeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextAttributeCreateOrConnectWithoutTextInput>;
  create?: InputMaybe<TextAttributeCreateWithoutTextInput>;
};

export type TextAttributeCreateOrConnectWithoutResourceTextInput = {
  create: TextAttributeCreateWithoutResourceTextInput;
  where: TextAttributeWhereUniqueInput;
};

export type TextAttributeCreateOrConnectWithoutTextInput = {
  create: TextAttributeCreateWithoutTextInput;
  where: TextAttributeWhereUniqueInput;
};

export type TextAttributeCreateWithoutResourceTextInput = {
  Text?: InputMaybe<TextCreateNestedManyWithoutTextAttributeInput>;
  created_in_language?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<TextAttributeType>;
};

export type TextAttributeCreateWithoutTextInput = {
  created_in_language?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  resourceText?: InputMaybe<ResourceTextCreateNestedManyWithoutTextAttributeInput>;
  type?: InputMaybe<TextAttributeType>;
};

export enum TextAttributeType {
  AnhangBezeichnung = "AnhangBezeichnung",
  AnnotationAnswer = "AnnotationAnswer",
  AnnotationQuestion = "AnnotationQuestion",
  Bezeichnung = "Bezeichnung",
  BrandDescription = "BrandDescription",
  Breadcrumb = "Breadcrumb",
  Catalog1 = "Catalog1",
  Catalog2 = "Catalog2",
  Catalog3 = "Catalog3",
  CatalogBenefit = "CatalogBenefit",
  CatalogKeyword = "CatalogKeyword",
  CatalogTemplate = "CatalogTemplate",
  Einheit = "Einheit",
  ExtraText = "ExtraText",
  Facette = "Facette",
  FacettenName = "FacettenName",
  FacettenPfad = "FacettenPfad",
  FacettenWert = "FacettenWert",
  KatalogText = "KatalogText",
  Key = "Key",
  KurzUrl = "KurzUrl",
  LieferArt = "LieferArt",
  LieferBedingung = "LieferBedingung",
  Lieferzeit = "Lieferzeit",
  LinkBezeichnung = "LinkBezeichnung",
  LinkUrl = "LinkURL",
  Marke = "Marke",
  MediaAltTag = "MediaAltTag",
  MediaCategory = "MediaCategory",
  MediaDescription = "MediaDescription",
  MediaInfoPoint = "MediaInfoPoint",
  MediaName = "MediaName",
  MediaTitleTag = "MediaTitleTag",
  NavDef = "NavDef",
  Navigation = "Navigation",
  OptionsBezeichnung = "OptionsBezeichnung",
  Optionswert = "Optionswert",
  PrintText = "PrintText",
  ProductName = "ProductName",
  ProductType = "ProductType",
  QualitySignDescription = "QualitySignDescription",
  RecreatedFromTranslation = "RecreatedFromTranslation",
  ShopBenefit = "ShopBenefit",
  ShopBezeichnung = "ShopBezeichnung",
  ShopKeyword = "ShopKeyword",
  ShopText = "ShopText",
  Sicherheitshinweis = "Sicherheitshinweis",
  Sportarten = "Sportarten",
  Text = "Text",
  TextWeb = "TextWeb",
  UspCatalog = "UspCatalog",
  UspShop = "UspShop",
  VideoTitle = "VideoTitle",
  XPress = "xPress",
}

export type TextAttributeUpdateInput = {
  created_in_language?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type TextAttributeUpdateOneRequiredWithoutTextInput = {
  connect?: InputMaybe<TextAttributeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextAttributeCreateOrConnectWithoutTextInput>;
  create?: InputMaybe<TextAttributeCreateWithoutTextInput>;
  update?: InputMaybe<TextAttributeUpdateWithoutTextInput>;
  upsert?: InputMaybe<TextAttributeUpsertWithoutTextInput>;
};

export type TextAttributeUpdateOneWithoutResourceTextInput = {
  connect?: InputMaybe<TextAttributeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextAttributeCreateOrConnectWithoutResourceTextInput>;
  create?: InputMaybe<TextAttributeCreateWithoutResourceTextInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<TextAttributeUpdateWithoutResourceTextInput>;
  upsert?: InputMaybe<TextAttributeUpsertWithoutResourceTextInput>;
};

export type TextAttributeUpdateWithoutResourceTextInput = {
  Text?: InputMaybe<TextUpdateManyWithoutTextAttributeInput>;
  created_in_language?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumTextAttributeTypeFieldUpdateOperationsInput>;
};

export type TextAttributeUpdateWithoutTextInput = {
  created_in_language?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resourceText?: InputMaybe<ResourceTextUpdateManyWithoutTextAttributeInput>;
  type?: InputMaybe<NullableEnumTextAttributeTypeFieldUpdateOperationsInput>;
};

export type TextAttributeUpsertWithoutResourceTextInput = {
  create: TextAttributeCreateWithoutResourceTextInput;
  update: TextAttributeUpdateWithoutResourceTextInput;
};

export type TextAttributeUpsertWithoutTextInput = {
  create: TextAttributeCreateWithoutTextInput;
  update: TextAttributeUpdateWithoutTextInput;
};

export type TextAttributeWhereInput = {
  AND?: InputMaybe<Array<TextAttributeWhereInput>>;
  NOT?: InputMaybe<Array<TextAttributeWhereInput>>;
  OR?: InputMaybe<Array<TextAttributeWhereInput>>;
  Text?: InputMaybe<TextListRelationFilter>;
  created_in_language?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  resourceText?: InputMaybe<ResourceTextListRelationFilter>;
  text_id?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumTextAttributeTypeNullableFilter>;
};

export type TextAttributeWhereUniqueInput = {
  text_id?: InputMaybe<Scalars["Int"]>;
};

export type TextCreateManyTextAttributeInput = {
  language_id: Scalars["Int"];
  text?: InputMaybe<Scalars["String"]>;
  translate: Scalars["Boolean"];
  update_timestamp?: InputMaybe<Scalars["DateTime"]>;
};

export type TextCreateManyTextAttributeInputEnvelope = {
  data?: InputMaybe<Array<TextCreateManyTextAttributeInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TextCreateNestedManyWithoutTextAttributeInput = {
  connect?: InputMaybe<Array<TextWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TextCreateOrConnectWithoutTextAttributeInput>
  >;
  create?: InputMaybe<Array<TextCreateWithoutTextAttributeInput>>;
  createMany?: InputMaybe<TextCreateManyTextAttributeInputEnvelope>;
};

export type TextCreateNestedOneWithoutBrandInput = {
  connect?: InputMaybe<TextWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextCreateOrConnectWithoutBrandInput>;
  create?: InputMaybe<TextCreateWithoutBrandInput>;
};

export type TextCreateNestedOneWithoutQualitySignInput = {
  connect?: InputMaybe<TextWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextCreateOrConnectWithoutQualitySignInput>;
  create?: InputMaybe<TextCreateWithoutQualitySignInput>;
};

export type TextCreateNestedOneWithoutResourceTextInput = {
  connect?: InputMaybe<TextWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextCreateOrConnectWithoutResourceTextInput>;
  create?: InputMaybe<TextCreateWithoutResourceTextInput>;
};

export type TextCreateOrConnectWithoutBrandInput = {
  create: TextCreateWithoutBrandInput;
  where: TextWhereUniqueInput;
};

export type TextCreateOrConnectWithoutQualitySignInput = {
  create: TextCreateWithoutQualitySignInput;
  where: TextWhereUniqueInput;
};

export type TextCreateOrConnectWithoutResourceTextInput = {
  create: TextCreateWithoutResourceTextInput;
  where: TextWhereUniqueInput;
};

export type TextCreateOrConnectWithoutTextAttributeInput = {
  create: TextCreateWithoutTextAttributeInput;
  where: TextWhereUniqueInput;
};

export type TextCreateWithoutBrandInput = {
  language_id: Scalars["Int"];
  qualitySign?: InputMaybe<QualitySignCreateNestedManyWithoutDescriptionInput>;
  resourceText?: InputMaybe<ResourceTextCreateNestedManyWithoutTextInput>;
  text?: InputMaybe<Scalars["String"]>;
  textAttribute: TextAttributeCreateNestedOneWithoutTextInput;
  translate: Scalars["Boolean"];
  update_timestamp?: InputMaybe<Scalars["DateTime"]>;
};

export type TextCreateWithoutQualitySignInput = {
  brand?: InputMaybe<BrandCreateNestedManyWithoutDescriptionInput>;
  language_id: Scalars["Int"];
  resourceText?: InputMaybe<ResourceTextCreateNestedManyWithoutTextInput>;
  text?: InputMaybe<Scalars["String"]>;
  textAttribute: TextAttributeCreateNestedOneWithoutTextInput;
  translate: Scalars["Boolean"];
  update_timestamp?: InputMaybe<Scalars["DateTime"]>;
};

export type TextCreateWithoutResourceTextInput = {
  brand?: InputMaybe<BrandCreateNestedManyWithoutDescriptionInput>;
  language_id: Scalars["Int"];
  qualitySign?: InputMaybe<QualitySignCreateNestedManyWithoutDescriptionInput>;
  text?: InputMaybe<Scalars["String"]>;
  textAttribute: TextAttributeCreateNestedOneWithoutTextInput;
  translate: Scalars["Boolean"];
  update_timestamp?: InputMaybe<Scalars["DateTime"]>;
};

export type TextCreateWithoutTextAttributeInput = {
  brand?: InputMaybe<BrandCreateNestedManyWithoutDescriptionInput>;
  language_id: Scalars["Int"];
  qualitySign?: InputMaybe<QualitySignCreateNestedManyWithoutDescriptionInput>;
  resourceText?: InputMaybe<ResourceTextCreateNestedManyWithoutTextInput>;
  text?: InputMaybe<Scalars["String"]>;
  translate: Scalars["Boolean"];
  update_timestamp?: InputMaybe<Scalars["DateTime"]>;
};

export type TextListRelationFilter = {
  every?: InputMaybe<TextWhereInput>;
  none?: InputMaybe<TextWhereInput>;
  some?: InputMaybe<TextWhereInput>;
};

export type TextScalarWhereInput = {
  AND?: InputMaybe<Array<TextScalarWhereInput>>;
  NOT?: InputMaybe<Array<TextScalarWhereInput>>;
  OR?: InputMaybe<Array<TextScalarWhereInput>>;
  language_id?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringNullableFilter>;
  text_id?: InputMaybe<IntFilter>;
  translate?: InputMaybe<BoolFilter>;
  update_timestamp?: InputMaybe<DateTimeNullableFilter>;
};

export type TextText_IdLanguage_IdCompoundUniqueInput = {
  language_id: Scalars["Int"];
  text_id: Scalars["Int"];
};

export type TextUpdateInput = {
  text: Scalars["String"];
  translate: Scalars["Boolean"];
};

export type TextUpdateManyMutationInput = {
  language_id?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  translate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  update_timestamp?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type TextUpdateManyWithWhereWithoutTextAttributeInput = {
  data: TextUpdateManyMutationInput;
  where: TextScalarWhereInput;
};

export type TextUpdateManyWithoutTextAttributeInput = {
  connect?: InputMaybe<Array<TextWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TextCreateOrConnectWithoutTextAttributeInput>
  >;
  create?: InputMaybe<Array<TextCreateWithoutTextAttributeInput>>;
  createMany?: InputMaybe<TextCreateManyTextAttributeInputEnvelope>;
  delete?: InputMaybe<Array<TextWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TextScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TextWhereUniqueInput>>;
  set?: InputMaybe<Array<TextWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TextUpdateWithWhereUniqueWithoutTextAttributeInput>
  >;
  updateMany?: InputMaybe<
    Array<TextUpdateManyWithWhereWithoutTextAttributeInput>
  >;
  upsert?: InputMaybe<
    Array<TextUpsertWithWhereUniqueWithoutTextAttributeInput>
  >;
};

export type TextUpdateOneWithoutBrandInput = {
  connect?: InputMaybe<TextWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextCreateOrConnectWithoutBrandInput>;
  create?: InputMaybe<TextCreateWithoutBrandInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<TextUpdateWithoutBrandInput>;
  upsert?: InputMaybe<TextUpsertWithoutBrandInput>;
};

export type TextUpdateOneWithoutQualitySignInput = {
  connect?: InputMaybe<TextWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextCreateOrConnectWithoutQualitySignInput>;
  create?: InputMaybe<TextCreateWithoutQualitySignInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<TextUpdateWithoutQualitySignInput>;
  upsert?: InputMaybe<TextUpsertWithoutQualitySignInput>;
};

export type TextUpdateOneWithoutResourceTextInput = {
  connect?: InputMaybe<TextWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TextCreateOrConnectWithoutResourceTextInput>;
  create?: InputMaybe<TextCreateWithoutResourceTextInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<TextUpdateWithoutResourceTextInput>;
  upsert?: InputMaybe<TextUpsertWithoutResourceTextInput>;
};

export type TextUpdateWithWhereUniqueWithoutTextAttributeInput = {
  data: TextUpdateWithoutTextAttributeInput;
  where: TextWhereUniqueInput;
};

export type TextUpdateWithoutBrandInput = {
  language_id?: InputMaybe<IntFieldUpdateOperationsInput>;
  qualitySign?: InputMaybe<QualitySignUpdateManyWithoutDescriptionInput>;
  resourceText?: InputMaybe<ResourceTextUpdateManyWithoutTextInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textAttribute?: InputMaybe<TextAttributeUpdateOneRequiredWithoutTextInput>;
  translate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  update_timestamp?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type TextUpdateWithoutQualitySignInput = {
  brand?: InputMaybe<BrandUpdateManyWithoutDescriptionInput>;
  language_id?: InputMaybe<IntFieldUpdateOperationsInput>;
  resourceText?: InputMaybe<ResourceTextUpdateManyWithoutTextInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textAttribute?: InputMaybe<TextAttributeUpdateOneRequiredWithoutTextInput>;
  translate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  update_timestamp?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type TextUpdateWithoutResourceTextInput = {
  brand?: InputMaybe<BrandUpdateManyWithoutDescriptionInput>;
  language_id?: InputMaybe<IntFieldUpdateOperationsInput>;
  qualitySign?: InputMaybe<QualitySignUpdateManyWithoutDescriptionInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textAttribute?: InputMaybe<TextAttributeUpdateOneRequiredWithoutTextInput>;
  translate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  update_timestamp?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type TextUpdateWithoutTextAttributeInput = {
  brand?: InputMaybe<BrandUpdateManyWithoutDescriptionInput>;
  language_id?: InputMaybe<IntFieldUpdateOperationsInput>;
  qualitySign?: InputMaybe<QualitySignUpdateManyWithoutDescriptionInput>;
  resourceText?: InputMaybe<ResourceTextUpdateManyWithoutTextInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  translate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  update_timestamp?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type TextUpsertWithWhereUniqueWithoutTextAttributeInput = {
  create: TextCreateWithoutTextAttributeInput;
  update: TextUpdateWithoutTextAttributeInput;
  where: TextWhereUniqueInput;
};

export type TextUpsertWithoutBrandInput = {
  create: TextCreateWithoutBrandInput;
  update: TextUpdateWithoutBrandInput;
};

export type TextUpsertWithoutQualitySignInput = {
  create: TextCreateWithoutQualitySignInput;
  update: TextUpdateWithoutQualitySignInput;
};

export type TextUpsertWithoutResourceTextInput = {
  create: TextCreateWithoutResourceTextInput;
  update: TextUpdateWithoutResourceTextInput;
};

export type TextWhereInput = {
  AND?: InputMaybe<Array<TextWhereInput>>;
  NOT?: InputMaybe<Array<TextWhereInput>>;
  OR?: InputMaybe<Array<TextWhereInput>>;
  brand?: InputMaybe<BrandListRelationFilter>;
  language_id?: InputMaybe<IntFilter>;
  qualitySign?: InputMaybe<QualitySignListRelationFilter>;
  resourceText?: InputMaybe<ResourceTextListRelationFilter>;
  text?: InputMaybe<StringNullableFilter>;
  textAttribute?: InputMaybe<TextAttributeWhereInput>;
  text_id?: InputMaybe<IntFilter>;
  translate?: InputMaybe<BoolFilter>;
  update_timestamp?: InputMaybe<DateTimeNullableFilter>;
};

export type TextWhereUniqueInput = {
  text_id_language_id?: InputMaybe<TextText_IdLanguage_IdCompoundUniqueInput>;
};

/** Please note that Query * will return an empty array as it is related to everything */
export type TypeCatalog = {
  __typename?: "TypeCatalog";
  addedInCommit?: Maybe<Scalars["String"]>;
  deletedInCommit?: Maybe<Scalars["String"]>;
  field: Scalars["String"];
  id: Scalars["Int"];
  permission: Array<Permission>;
  relatedTypeCatalogs?: Maybe<Array<Maybe<TypeCatalog>>>;
  service: Service;
  serviceId: Scalars["Int"];
  type: Scalars["String"];
};

/** Please note that Query * will return an empty array as it is related to everything */
export type TypeCatalogPermissionArgs = {
  after?: InputMaybe<PermissionWhereUniqueInput>;
  before?: InputMaybe<PermissionWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type TypeCatalogAttributeUpdateInput = {
  addedInCommit?: InputMaybe<Scalars["String"]>;
  deletedInCommit?: InputMaybe<Scalars["String"]>;
  field?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type TypeCatalogListRelationFilter = {
  every?: InputMaybe<TypeCatalogWhereInput>;
  none?: InputMaybe<TypeCatalogWhereInput>;
  some?: InputMaybe<TypeCatalogWhereInput>;
};

export type TypeCatalogOrderByInput = {
  addedInCommit?: InputMaybe<SortOrder>;
  deletedInCommit?: InputMaybe<SortOrder>;
  field?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type TypeCatalogWhereInput = {
  AND?: InputMaybe<Array<TypeCatalogWhereInput>>;
  NOT?: InputMaybe<Array<TypeCatalogWhereInput>>;
  OR?: InputMaybe<Array<TypeCatalogWhereInput>>;
  addedInCommit?: InputMaybe<StringNullableFilter>;
  deletedInCommit?: InputMaybe<StringNullableFilter>;
  field?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  permission?: InputMaybe<PermissionListRelationFilter>;
  service?: InputMaybe<ServiceWhereInput>;
  serviceId?: InputMaybe<IntFilter>;
  type?: InputMaybe<StringFilter>;
};

export type TypeCatalogWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type UnknownOrder = Order & {
  __typename?: "UnknownOrder";
  billingAddress?: Maybe<Address>;
  eInvoicingData?: Maybe<EInvoicongData>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  internalId?: Maybe<Scalars["Int"]>;
  isNetto?: Maybe<Scalars["Boolean"]>;
  itemCount?: Maybe<Scalars["Float"]>;
  mails?: Maybe<Array<Maybe<Mail>>>;
  marketingChannel?: Maybe<Scalars["String"]>;
  navisionData?: Maybe<NavisionData>;
  orderDate?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<OrderPosition>>>;
  reference?: Maybe<Scalars["String"]>;
  shippingAddress?: Maybe<Address>;
  shippingType?: Maybe<Array<Maybe<ShippingType>>>;
  source?: Maybe<Array<Maybe<PrincipalSource>>>;
  status?: Maybe<OrderStatus>;
  /** The total amount (including shipping costs and tax) */
  totalAmount?: Maybe<GrossNetAmount>;
  /** The total amount (excluding shipping costs and including tax) */
  totalProductAmount?: Maybe<GrossNetAmount>;
  type?: Maybe<Scalars["String"]>;
};

export type UnknownOrderMailsArgs = {
  sendingDateSortOrder?: InputMaybe<SortDirection>;
  type?: InputMaybe<Scalars["String"]>;
};

export type UpdateAppWhere = {
  id: Scalars["Int"];
};

export type UpdateGroupRoleAttributeInput = {
  groupId: Scalars["Int"];
  roleId: Scalars["Int"];
};

export type UpdateGroupRoleWhere = {
  id: Scalars["Int"];
};

export type UpdateGroupWhere = {
  id: Scalars["Int"];
};

export type UpdateMemberGroupWhere = {
  email: Scalars["String"];
  groupId: Scalars["Int"];
};

export type UpdatePermissionAttributeInput = {
  status?: InputMaybe<PermissionStatus>;
};

export type UpdatePermissionWhere = {
  roleId: Scalars["Int"];
  typeCatalogId: Scalars["Int"];
};

export type UpdateResourceVersionInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  blocked?: InputMaybe<Scalars["Boolean"]>;
  bucket?: InputMaybe<Scalars["String"]>;
  create_date?: InputMaybe<Scalars["DateTime"]>;
  file_size?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Int"]>;
  mime_type?: InputMaybe<Scalars["String"]>;
  offset_x?: InputMaybe<Scalars["Int"]>;
  offset_y?: InputMaybe<Scalars["Int"]>;
  original_filename?: InputMaybe<Scalars["String"]>;
  parameter?: InputMaybe<Scalars["String"]>;
  path?: InputMaybe<Scalars["String"]>;
  raw_height?: InputMaybe<Scalars["Int"]>;
  raw_width?: InputMaybe<Scalars["Int"]>;
  release_date?: InputMaybe<Scalars["DateTime"]>;
  resolution_unit?: InputMaybe<Scalars["String"]>;
  resolution_x?: InputMaybe<Scalars["Int"]>;
  resolution_y?: InputMaybe<Scalars["Int"]>;
  resource_id?: InputMaybe<Scalars["Int"]>;
  update_date?: InputMaybe<Scalars["DateTime"]>;
  upload_processed?: InputMaybe<Scalars["Boolean"]>;
  user_id?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type UpdateResourceVersionWhereInput = {
  id: Scalars["Int"];
};

export type UpdateResourceWhere = {
  id: Scalars["Int"];
};

export type UpdateRoleAttributeInput = {
  creator?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateRoleWhere = {
  id: Scalars["Int"];
};

export type UpdateServiceWhere = {
  id: Scalars["Int"];
};

export type UpdateTypeCatalogWhere = {
  id: Scalars["Int"];
};

export type UpsertMemberGroupWhere = {
  email: Scalars["String"];
  groupId: Scalars["Int"];
};

export type UpsertMemberGroupsData = {
  groupId: Scalars["Int"];
};

export type UpsertMemberGroupsWhere = {
  emails: Array<InputMaybe<Scalars["String"]>>;
  groupId: Scalars["Int"];
};

export type UpsertOfficeVisitInput = {
  /** Date of the office visit */
  date: Scalars["String"];
  /** Email of the user */
  email?: InputMaybe<Scalars["String"]>;
  /** Location of the office visit */
  locationId: Scalars["Int"];
};

export type UpsertPermissionWhere = {
  roleId: Scalars["Int"];
  status: PermissionStatus;
  typeCatalogId: Scalars["Int"];
};

export type User = {
  __typename?: "User";
  _roles: Array<Scalars["String"]>;
  active: Scalars["Boolean"];
  authenticator_secret?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  blog_slug?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  default_language?: Maybe<Scalars["Int"]>;
  default_shop?: Maybe<Scalars["Int"]>;
  email?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  image: Scalars["String"];
  last_login?: Maybe<Scalars["DateTime"]>;
  lastname?: Maybe<Scalars["String"]>;
  navision_name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  product_manager_id?: Maybe<Scalars["Int"]>;
  resource_id?: Maybe<Scalars["Int"]>;
  signature_resource_id?: Maybe<Scalars["Int"]>;
  street?: Maybe<Scalars["String"]>;
  token_phone_number?: Maybe<Scalars["String"]>;
  translate_auto_check?: Maybe<Scalars["Boolean"]>;
  uploadedVersionCount?: Maybe<Scalars["Int"]>;
  uploadedVersions?: Maybe<Array<Maybe<ResourceVersion>>>;
  user_name?: Maybe<Scalars["String"]>;
};

export type UserUploadedVersionsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type UserCreateNestedOneWithoutResourceCollectionGrantsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResourceCollectionGrantsInput>;
  create?: InputMaybe<UserCreateWithoutResourceCollectionGrantsInput>;
};

export type UserCreateNestedOneWithoutResourceCollectionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResourceCollectionsInput>;
  create?: InputMaybe<UserCreateWithoutResourceCollectionsInput>;
};

export type UserCreateNestedOneWithoutResourceVariantsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResourceVariantsInput>;
  create?: InputMaybe<UserCreateWithoutResourceVariantsInput>;
};

export type UserCreateNestedOneWithoutResourceVersionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResourceVersionsInput>;
  create?: InputMaybe<UserCreateWithoutResourceVersionsInput>;
};

export type UserCreateOrConnectWithoutResourceCollectionGrantsInput = {
  create: UserCreateWithoutResourceCollectionGrantsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutResourceCollectionsInput = {
  create: UserCreateWithoutResourceCollectionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutResourceVariantsInput = {
  create: UserCreateWithoutResourceVariantsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutResourceVersionsInput = {
  create: UserCreateWithoutResourceVersionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutResourceCollectionGrantsInput = {
  email: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  resourceCollections?: InputMaybe<ResourceCollectionCreateNestedManyWithoutCreatorInput>;
  resourceVariants?: InputMaybe<ResourceVariantCreateNestedManyWithoutUserInput>;
  resourceVersions?: InputMaybe<ResourceVersionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutResourceCollectionsInput = {
  email: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  resourceCollectionGrants?: InputMaybe<ResourceCollectionAccessCreateNestedManyWithoutGrantedByInput>;
  resourceVariants?: InputMaybe<ResourceVariantCreateNestedManyWithoutUserInput>;
  resourceVersions?: InputMaybe<ResourceVersionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutResourceVariantsInput = {
  email: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  resourceCollectionGrants?: InputMaybe<ResourceCollectionAccessCreateNestedManyWithoutGrantedByInput>;
  resourceCollections?: InputMaybe<ResourceCollectionCreateNestedManyWithoutCreatorInput>;
  resourceVersions?: InputMaybe<ResourceVersionCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutResourceVersionsInput = {
  email: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  resourceCollectionGrants?: InputMaybe<ResourceCollectionAccessCreateNestedManyWithoutGrantedByInput>;
  resourceCollections?: InputMaybe<ResourceCollectionCreateNestedManyWithoutCreatorInput>;
  resourceVariants?: InputMaybe<ResourceVariantCreateNestedManyWithoutUserInput>;
};

export type UserGroup = {
  __typename?: "UserGroup";
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  securityEnabled?: Maybe<Scalars["Boolean"]>;
};

export enum UserImageSize {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export type UserOrderByWithRelationInput = {
  email?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  resourceCollectionGrants?: InputMaybe<ResourceCollectionAccessOrderByRelationAggregateInput>;
  resourceCollections?: InputMaybe<ResourceCollectionOrderByRelationAggregateInput>;
  resourceVariants?: InputMaybe<ResourceVariantOrderByRelationAggregateInput>;
  resourceVersions?: InputMaybe<ResourceVersionOrderByRelationAggregateInput>;
};

export type UserPermissions = {
  __typename?: "UserPermissions";
  field?: Maybe<Scalars["String"]>;
  grant?: Maybe<Scalars["String"]>;
  service?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type UserRole = {
  __typename?: "UserRole";
  activeDirectoryGroup?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type UserUpdateOneRequiredWithoutResourceCollectionGrantsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResourceCollectionGrantsInput>;
  create?: InputMaybe<UserCreateWithoutResourceCollectionGrantsInput>;
  update?: InputMaybe<UserUpdateWithoutResourceCollectionGrantsInput>;
  upsert?: InputMaybe<UserUpsertWithoutResourceCollectionGrantsInput>;
};

export type UserUpdateOneRequiredWithoutResourceCollectionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResourceCollectionsInput>;
  create?: InputMaybe<UserCreateWithoutResourceCollectionsInput>;
  update?: InputMaybe<UserUpdateWithoutResourceCollectionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutResourceCollectionsInput>;
};

export type UserUpdateOneWithoutResourceVariantsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResourceVariantsInput>;
  create?: InputMaybe<UserCreateWithoutResourceVariantsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<UserUpdateWithoutResourceVariantsInput>;
  upsert?: InputMaybe<UserUpsertWithoutResourceVariantsInput>;
};

export type UserUpdateOneWithoutResourceVersionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResourceVersionsInput>;
  create?: InputMaybe<UserCreateWithoutResourceVersionsInput>;
  delete?: InputMaybe<Scalars["Boolean"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  update?: InputMaybe<UserUpdateWithoutResourceVersionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutResourceVersionsInput>;
};

export type UserUpdateWithoutResourceCollectionGrantsInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  resourceCollections?: InputMaybe<ResourceCollectionUpdateManyWithoutCreatorInput>;
  resourceVariants?: InputMaybe<ResourceVariantUpdateManyWithoutUserInput>;
  resourceVersions?: InputMaybe<ResourceVersionUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutResourceCollectionsInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  resourceCollectionGrants?: InputMaybe<ResourceCollectionAccessUpdateManyWithoutGrantedByInput>;
  resourceVariants?: InputMaybe<ResourceVariantUpdateManyWithoutUserInput>;
  resourceVersions?: InputMaybe<ResourceVersionUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutResourceVariantsInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  resourceCollectionGrants?: InputMaybe<ResourceCollectionAccessUpdateManyWithoutGrantedByInput>;
  resourceCollections?: InputMaybe<ResourceCollectionUpdateManyWithoutCreatorInput>;
  resourceVersions?: InputMaybe<ResourceVersionUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutResourceVersionsInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  resourceCollectionGrants?: InputMaybe<ResourceCollectionAccessUpdateManyWithoutGrantedByInput>;
  resourceCollections?: InputMaybe<ResourceCollectionUpdateManyWithoutCreatorInput>;
  resourceVariants?: InputMaybe<ResourceVariantUpdateManyWithoutUserInput>;
};

export type UserUpsertWithoutResourceCollectionGrantsInput = {
  create: UserCreateWithoutResourceCollectionGrantsInput;
  update: UserUpdateWithoutResourceCollectionGrantsInput;
};

export type UserUpsertWithoutResourceCollectionsInput = {
  create: UserCreateWithoutResourceCollectionsInput;
  update: UserUpdateWithoutResourceCollectionsInput;
};

export type UserUpsertWithoutResourceVariantsInput = {
  create: UserCreateWithoutResourceVariantsInput;
  update: UserUpdateWithoutResourceVariantsInput;
};

export type UserUpsertWithoutResourceVersionsInput = {
  create: UserCreateWithoutResourceVersionsInput;
  update: UserUpdateWithoutResourceVersionsInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  email?: InputMaybe<StringFilter>;
  firstname?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastname?: InputMaybe<StringFilter>;
  resourceCollectionGrants?: InputMaybe<ResourceCollectionAccessListRelationFilter>;
  resourceCollections?: InputMaybe<ResourceCollectionListRelationFilter>;
  resourceVariants?: InputMaybe<ResourceVariantListRelationFilter>;
  resourceVersions?: InputMaybe<ResourceVersionListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type Users = {
  __typename?: "Users";
  totalCount: Scalars["Int"];
  users: Array<User>;
};

export type ValueVoucher = Voucher & {
  __typename?: "ValueVoucher";
  active?: Maybe<Scalars["Boolean"]>;
  brandConstraints?: Maybe<Array<BrandEligibleForVoucher>>;
  category?: Maybe<Scalars["String"]>;
  codes?: Maybe<VoucherCodePagination>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  minOrderValue?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  navisionAccount?: Maybe<Scalars["Int"]>;
  shopId?: Maybe<Scalars["Int"]>;
  statistics?: Maybe<VoucherStatistic>;
  /** Total number of times this voucher has been redeemed in an confirmed order. */
  totalRedeemCount?: Maybe<Scalars["Int"]>;
  /** Total number of redeemed codes. Please keep in mind that this number might seem wrong if codes can redeem multiple times. This query uses the boolean on voucher code. */
  totalRedeemdedCodes?: Maybe<Scalars["Int"]>;
  unique?: Maybe<Scalars["Boolean"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type ValueVoucherCodesArgs = {
  code?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  expired?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  redeemed?: InputMaybe<Scalars["Boolean"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VoucherCodeWhereInput>;
};

export type ValueVoucherStatisticsArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type Variant = VariantInterface & {
  __typename?: "Variant";
  attributes?: Maybe<Array<Maybe<AttributeType>>>;
  materialRelatedWeight: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["Int"]>;
  /** @deprecated This field is deprecated and will be removed soon */
  product_id?: Maybe<Scalars["Int"]>;
  variantCo2Information?: Maybe<VariantCo2Information>;
  variantId: Scalars["Int"];
  variantMaterialOverallPercentage: Scalars["Float"];
  variantMaterials: Array<Maybe<VariantMaterial>>;
  variantNumber?: Maybe<Scalars["String"]>;
  variantNumberFull?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with variantNumber */
  variant_number?: Maybe<Scalars["String"]>;
  /** @deprecated replaced with variantNumberFull */
  variant_number_full?: Maybe<Scalars["String"]>;
};

export type VariantCo2Information = {
  __typename?: "VariantCo2Information";
  co2AirInKg?: Maybe<Scalars["Float"]>;
  co2LandInKg?: Maybe<Scalars["Float"]>;
  /** @deprecated Please use co2TransportOverallInKg instead */
  co2OverallInKg?: Maybe<Scalars["Float"]>;
  co2SeaInKg?: Maybe<Scalars["Float"]>;
  /** Overall CO2 in kg for transportation */
  co2TransportOverallInKg?: Maybe<Scalars["Float"]>;
  country?: Maybe<Scalars["String"]>;
  countryIsoCode?: Maybe<Scalars["String"]>;
  distanceByAirInKm?: Maybe<Scalars["Float"]>;
  distanceByLandInKm?: Maybe<Scalars["Float"]>;
  distanceBySeaInKm?: Maybe<Scalars["Float"]>;
  externalShipment: Scalars["Boolean"];
  isProducedByUs: Scalars["Boolean"];
  supplierProductCarbonFootprint?: Maybe<Scalars["Float"]>;
  supplierProductCarbonFootprintMethod?: Maybe<SupplierProductCarbonFootprintMethod>;
  variantId: Scalars["Int"];
  weightInKg: Scalars["Float"];
};

export type VariantCo2InformationOrderByInput = {
  co2AirInKg?: InputMaybe<SortOrder>;
  co2LandInKg?: InputMaybe<SortOrder>;
  co2SeaInKg?: InputMaybe<SortOrder>;
  co2TransportOverallInKg?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  countryIsoCode?: InputMaybe<SortOrder>;
  distanceByAirInKm?: InputMaybe<SortOrder>;
  distanceByLandInKm?: InputMaybe<SortOrder>;
  distanceBySeaInKm?: InputMaybe<SortOrder>;
  externalShipment?: InputMaybe<SortOrder>;
  isProducedByUs?: InputMaybe<SortOrder>;
  variantId?: InputMaybe<SortOrder>;
  weightInKg?: InputMaybe<SortOrder>;
};

export type VariantCo2InformationWhereInput = {
  AND?: InputMaybe<Array<VariantCo2InformationWhereInput>>;
  NOT?: InputMaybe<Array<VariantCo2InformationWhereInput>>;
  OR?: InputMaybe<Array<VariantCo2InformationWhereInput>>;
  co2AirInKg?: InputMaybe<FloatNullableFilter>;
  co2LandInKg?: InputMaybe<FloatNullableFilter>;
  co2SeaInKg?: InputMaybe<FloatNullableFilter>;
  co2TransportOverallInKg?: InputMaybe<FloatNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  countryIsoCode?: InputMaybe<StringNullableFilter>;
  distanceByAirInKm?: InputMaybe<FloatNullableFilter>;
  distanceByLandInKm?: InputMaybe<FloatNullableFilter>;
  distanceBySeaInKm?: InputMaybe<FloatNullableFilter>;
  externalShipment?: InputMaybe<BoolFilter>;
  isProducedByUs?: InputMaybe<BoolFilter>;
  variantId?: InputMaybe<IntFilter>;
  weightInKg?: InputMaybe<FloatFilter>;
};

export type VariantCo2InformationWhereUniqueInput = {
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type VariantCreateManyProductInput = {
  afn_fulfillable_quantity?: InputMaybe<Scalars["Int"]>;
  asin?: InputMaybe<Scalars["String"]>;
  base_unit_code?: InputMaybe<Scalars["String"]>;
  bulk_quantity?: InputMaybe<Scalars["Int"]>;
  create_date: Scalars["DateTime"];
  created_via_navision?: InputMaybe<Scalars["Boolean"]>;
  default_for_list?: InputMaybe<Scalars["Boolean"]>;
  delivery_time?: InputMaybe<Scalars["Int"]>;
  disable_annotations?: InputMaybe<Scalars["Boolean"]>;
  disabled_in_navision?: InputMaybe<Scalars["Boolean"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  external_max_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  external_min_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  external_shipment: Scalars["Boolean"];
  fnsku?: InputMaybe<Scalars["String"]>;
  fulfillment_latency_ontop?: InputMaybe<Scalars["Int"]>;
  gtin?: InputMaybe<Scalars["String"]>;
  gtin_fitness_werk?: InputMaybe<Scalars["String"]>;
  hwg: Scalars["String"];
  isbn: Scalars["String"];
  maintenance_standard_hours?: InputMaybe<Scalars["Int"]>;
  manufacturing_product?: InputMaybe<Scalars["Boolean"]>;
  not_in_navision?: InputMaybe<Scalars["Boolean"]>;
  price_on_request?: InputMaybe<Scalars["Boolean"]>;
  prime_by_merchant?: InputMaybe<Scalars["Boolean"]>;
  product_class: Scalars["Int"];
  purchase_unit_code?: InputMaybe<Scalars["String"]>;
  sales_unit_code?: InputMaybe<Scalars["String"]>;
  sent_to_amazon?: InputMaybe<Scalars["Boolean"]>;
  service_product?: InputMaybe<Scalars["Boolean"]>;
  shipping_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_max_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_min_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_type?: InputMaybe<VariantShippingType>;
  show_stock?: InputMaybe<Scalars["Boolean"]>;
  show_variant_in_catalog?: InputMaybe<Scalars["Boolean"]>;
  size?: InputMaybe<Scalars["String"]>;
  sort_size: Scalars["Int"];
  sort_variant: Scalars["Int"];
  stock: Scalars["Boolean"];
  stock_buffer_quantity?: InputMaybe<Scalars["Int"]>;
  stock_fba?: InputMaybe<Scalars["Int"]>;
  stock_shop_live?: InputMaybe<Scalars["Int"]>;
  supplier_creditor_number?: InputMaybe<Scalars["String"]>;
  supplier_delivery_time?: InputMaybe<Scalars["String"]>;
  supplier_product_number: Scalars["String"];
  type?: InputMaybe<VariantType>;
  units_per_packet?: InputMaybe<Scalars["Int"]>;
  update_timestamp?: InputMaybe<Scalars["DateTime"]>;
  update_user_id?: InputMaybe<Scalars["Int"]>;
  uvp: Scalars["Boolean"];
  uwg: Scalars["String"];
  variantId?: InputMaybe<Scalars["Int"]>;
  variant_number?: InputMaybe<Scalars["String"]>;
  variant_number_full?: InputMaybe<Scalars["String"]>;
  vat_product_booking_group?: InputMaybe<Scalars["String"]>;
  volume: Scalars["Float"];
  wg: Scalars["String"];
};

export type VariantCreateManyProductInputEnvelope = {
  data?: InputMaybe<Array<VariantCreateManyProductInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type VariantCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<VariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VariantCreateOrConnectWithoutProductInput>
  >;
  create?: InputMaybe<Array<VariantCreateWithoutProductInput>>;
  createMany?: InputMaybe<VariantCreateManyProductInputEnvelope>;
};

export type VariantCreateNestedOneWithoutResourceVariantInput = {
  connect?: InputMaybe<VariantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VariantCreateOrConnectWithoutResourceVariantInput>;
  create?: InputMaybe<VariantCreateWithoutResourceVariantInput>;
};

export type VariantCreateOrConnectWithoutProductInput = {
  create: VariantCreateWithoutProductInput;
  where: VariantWhereUniqueInput;
};

export type VariantCreateOrConnectWithoutResourceVariantInput = {
  create: VariantCreateWithoutResourceVariantInput;
  where: VariantWhereUniqueInput;
};

export type VariantCreateWithoutProductInput = {
  afn_fulfillable_quantity?: InputMaybe<Scalars["Int"]>;
  asin?: InputMaybe<Scalars["String"]>;
  base_unit_code?: InputMaybe<Scalars["String"]>;
  bulk_quantity?: InputMaybe<Scalars["Int"]>;
  create_date: Scalars["DateTime"];
  created_via_navision?: InputMaybe<Scalars["Boolean"]>;
  default_for_list?: InputMaybe<Scalars["Boolean"]>;
  delivery_time?: InputMaybe<Scalars["Int"]>;
  disable_annotations?: InputMaybe<Scalars["Boolean"]>;
  disabled_in_navision?: InputMaybe<Scalars["Boolean"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  external_max_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  external_min_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  external_shipment: Scalars["Boolean"];
  fnsku?: InputMaybe<Scalars["String"]>;
  fulfillment_latency_ontop?: InputMaybe<Scalars["Int"]>;
  gtin?: InputMaybe<Scalars["String"]>;
  gtin_fitness_werk?: InputMaybe<Scalars["String"]>;
  hwg: Scalars["String"];
  isbn: Scalars["String"];
  maintenance_standard_hours?: InputMaybe<Scalars["Int"]>;
  manufacturing_product?: InputMaybe<Scalars["Boolean"]>;
  not_in_navision?: InputMaybe<Scalars["Boolean"]>;
  price_on_request?: InputMaybe<Scalars["Boolean"]>;
  prime_by_merchant?: InputMaybe<Scalars["Boolean"]>;
  product_class: Scalars["Int"];
  purchase_unit_code?: InputMaybe<Scalars["String"]>;
  resourceVariant?: InputMaybe<ResourceVariantCreateNestedManyWithoutVariantInput>;
  sales_unit_code?: InputMaybe<Scalars["String"]>;
  sent_to_amazon?: InputMaybe<Scalars["Boolean"]>;
  service_product?: InputMaybe<Scalars["Boolean"]>;
  shipping_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_max_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_min_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_type?: InputMaybe<VariantShippingType>;
  show_stock?: InputMaybe<Scalars["Boolean"]>;
  show_variant_in_catalog?: InputMaybe<Scalars["Boolean"]>;
  size?: InputMaybe<Scalars["String"]>;
  sort_size: Scalars["Int"];
  sort_variant: Scalars["Int"];
  stock: Scalars["Boolean"];
  stock_buffer_quantity?: InputMaybe<Scalars["Int"]>;
  stock_fba?: InputMaybe<Scalars["Int"]>;
  stock_shop_live?: InputMaybe<Scalars["Int"]>;
  supplier_creditor_number?: InputMaybe<Scalars["String"]>;
  supplier_delivery_time?: InputMaybe<Scalars["String"]>;
  supplier_product_number: Scalars["String"];
  type?: InputMaybe<VariantType>;
  units_per_packet?: InputMaybe<Scalars["Int"]>;
  update_timestamp?: InputMaybe<Scalars["DateTime"]>;
  update_user_id?: InputMaybe<Scalars["Int"]>;
  uvp: Scalars["Boolean"];
  uwg: Scalars["String"];
  variant_number?: InputMaybe<Scalars["String"]>;
  variant_number_full?: InputMaybe<Scalars["String"]>;
  vat_product_booking_group?: InputMaybe<Scalars["String"]>;
  volume: Scalars["Float"];
  wg: Scalars["String"];
};

export type VariantCreateWithoutResourceVariantInput = {
  afn_fulfillable_quantity?: InputMaybe<Scalars["Int"]>;
  asin?: InputMaybe<Scalars["String"]>;
  base_unit_code?: InputMaybe<Scalars["String"]>;
  bulk_quantity?: InputMaybe<Scalars["Int"]>;
  create_date: Scalars["DateTime"];
  created_via_navision?: InputMaybe<Scalars["Boolean"]>;
  default_for_list?: InputMaybe<Scalars["Boolean"]>;
  delivery_time?: InputMaybe<Scalars["Int"]>;
  disable_annotations?: InputMaybe<Scalars["Boolean"]>;
  disabled_in_navision?: InputMaybe<Scalars["Boolean"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  external_max_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  external_min_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  external_shipment: Scalars["Boolean"];
  fnsku?: InputMaybe<Scalars["String"]>;
  fulfillment_latency_ontop?: InputMaybe<Scalars["Int"]>;
  gtin?: InputMaybe<Scalars["String"]>;
  gtin_fitness_werk?: InputMaybe<Scalars["String"]>;
  hwg: Scalars["String"];
  isbn: Scalars["String"];
  maintenance_standard_hours?: InputMaybe<Scalars["Int"]>;
  manufacturing_product?: InputMaybe<Scalars["Boolean"]>;
  not_in_navision?: InputMaybe<Scalars["Boolean"]>;
  price_on_request?: InputMaybe<Scalars["Boolean"]>;
  prime_by_merchant?: InputMaybe<Scalars["Boolean"]>;
  product?: InputMaybe<ProductCreateNestedOneWithoutVariantsInput>;
  product_class: Scalars["Int"];
  purchase_unit_code?: InputMaybe<Scalars["String"]>;
  sales_unit_code?: InputMaybe<Scalars["String"]>;
  sent_to_amazon?: InputMaybe<Scalars["Boolean"]>;
  service_product?: InputMaybe<Scalars["Boolean"]>;
  shipping_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_max_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_min_delivery_date?: InputMaybe<Scalars["DateTime"]>;
  shipping_type?: InputMaybe<VariantShippingType>;
  show_stock?: InputMaybe<Scalars["Boolean"]>;
  show_variant_in_catalog?: InputMaybe<Scalars["Boolean"]>;
  size?: InputMaybe<Scalars["String"]>;
  sort_size: Scalars["Int"];
  sort_variant: Scalars["Int"];
  stock: Scalars["Boolean"];
  stock_buffer_quantity?: InputMaybe<Scalars["Int"]>;
  stock_fba?: InputMaybe<Scalars["Int"]>;
  stock_shop_live?: InputMaybe<Scalars["Int"]>;
  supplier_creditor_number?: InputMaybe<Scalars["String"]>;
  supplier_delivery_time?: InputMaybe<Scalars["String"]>;
  supplier_product_number: Scalars["String"];
  type?: InputMaybe<VariantType>;
  units_per_packet?: InputMaybe<Scalars["Int"]>;
  update_timestamp?: InputMaybe<Scalars["DateTime"]>;
  update_user_id?: InputMaybe<Scalars["Int"]>;
  uvp: Scalars["Boolean"];
  uwg: Scalars["String"];
  variant_number?: InputMaybe<Scalars["String"]>;
  variant_number_full?: InputMaybe<Scalars["String"]>;
  vat_product_booking_group?: InputMaybe<Scalars["String"]>;
  volume: Scalars["Float"];
  wg: Scalars["String"];
};

export type VariantInterface = {
  variantId?: Maybe<Scalars["Int"]>;
};

export type VariantListRelationFilter = {
  every?: InputMaybe<VariantWhereInput>;
  none?: InputMaybe<VariantWhereInput>;
  some?: InputMaybe<VariantWhereInput>;
};

export type VariantMaterial = {
  __typename?: "VariantMaterial";
  dataQualityType: VariantMaterialDataQualityType;
  materialDefinition: VariantMaterialDefinition;
  materialId: Scalars["Int"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
  variantId: Scalars["Int"];
  /** This is the weight in kg based on the variants unit weight and the field weightInPercent. For calculating this weight we use the variant unit code VM, which is Versandmaß in german. */
  weightInKg: Scalars["Float"];
  weightInPercent: Scalars["Float"];
};

export type VariantMaterialCreateInput = {
  dataQualityType?: VariantMaterialDataQualityType;
  materialId: Scalars["Int"];
  variantId: Scalars["Int"];
  weightInPercent: Scalars["Float"];
};

export enum VariantMaterialDataQualityType {
  AssuredOrigin = "assuredOrigin",
  Estimated = "estimated",
  EstimatedCategoryBased = "estimatedCategoryBased",
  Supplier = "supplier",
}

export type VariantMaterialDefinition = {
  __typename?: "VariantMaterialDefinition";
  abbrevation: Scalars["String"];
  co2PerKg?: Maybe<Scalars["Float"]>;
  id: Scalars["Int"];
  name: Scalars["String"];
  variantMaterials: Array<VariantMaterial>;
};

export type VariantMaterialDefinitionVariantMaterialsArgs = {
  after?: InputMaybe<VariantMaterialWhereUniqueInput>;
  before?: InputMaybe<VariantMaterialWhereUniqueInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VariantMaterialDefinitionCreateInput = {
  abbrevation: Scalars["String"];
  co2PerKg?: InputMaybe<Scalars["Float"]>;
  name: Scalars["String"];
};

export type VariantMaterialDefinitionOrderByInput = {
  abbrevation?: InputMaybe<SortOrder>;
  co2PerKg?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type VariantMaterialDefinitionUpdateInput = {
  abbrevation?: InputMaybe<Scalars["String"]>;
  co2PerKg?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type VariantMaterialDefinitionWhereInput = {
  AND?: InputMaybe<Array<VariantMaterialDefinitionWhereInput>>;
  NOT?: InputMaybe<Array<VariantMaterialDefinitionWhereInput>>;
  OR?: InputMaybe<Array<VariantMaterialDefinitionWhereInput>>;
  abbrevation?: InputMaybe<StringFilter>;
  co2PerKg?: InputMaybe<FloatNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  variantMaterials?: InputMaybe<VariantMaterialListRelationFilter>;
};

export type VariantMaterialDefinitionWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type VariantMaterialDeleteInput = {
  materialId: Scalars["Int"];
  variantId: Scalars["Int"];
};

export type VariantMaterialListRelationFilter = {
  every?: InputMaybe<VariantMaterialWhereInput>;
  none?: InputMaybe<VariantMaterialWhereInput>;
  some?: InputMaybe<VariantMaterialWhereInput>;
};

export type VariantMaterialOrderByInput = {
  dataQualityType?: InputMaybe<SortOrder>;
  materialId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  variantId?: InputMaybe<SortOrder>;
  weightInPercent?: InputMaybe<SortOrder>;
};

export type VariantMaterialProductWhereInput = {
  AND?: InputMaybe<Array<VariantMaterialProductWhereInput>>;
  NOT?: InputMaybe<Array<VariantMaterialProductWhereInput>>;
  OR?: InputMaybe<Array<VariantMaterialProductWhereInput>>;
  brandId?: InputMaybe<IntNullableFilter>;
  incentive?: InputMaybe<EnumProductIncentiveTypeFilter>;
  productId?: InputMaybe<IntFilter>;
  variants?: InputMaybe<VariantMaterialVariantListRelationFilter>;
};

export type VariantMaterialUpdateInput = {
  materialId: Scalars["Int"];
  variantId: Scalars["Int"];
  weightInPercent: Scalars["Float"];
};

export type VariantMaterialVariantIdMaterialIdCompoundUniqueInput = {
  materialId: Scalars["Int"];
  variantId: Scalars["Int"];
};

export type VariantMaterialVariantListRelationFilter = {
  every?: InputMaybe<VariantMaterialVariantWhereInput>;
  none?: InputMaybe<VariantMaterialVariantWhereInput>;
  some?: InputMaybe<VariantMaterialVariantWhereInput>;
};

export type VariantMaterialVariantUnitListRelationFilter = {
  every?: InputMaybe<VariantMaterialVariantUnitWhereInput>;
  none?: InputMaybe<VariantMaterialVariantUnitWhereInput>;
  some?: InputMaybe<VariantMaterialVariantUnitWhereInput>;
};

export type VariantMaterialVariantUnitWhereInput = {
  AND?: InputMaybe<Array<VariantMaterialVariantUnitWhereInput>>;
  NOT?: InputMaybe<Array<VariantMaterialVariantUnitWhereInput>>;
  OR?: InputMaybe<Array<VariantMaterialVariantUnitWhereInput>>;
  girth?: InputMaybe<FloatFilter>;
  height?: InputMaybe<FloatFilter>;
  length?: InputMaybe<FloatFilter>;
  unitCode?: InputMaybe<StringFilter>;
  variant?: InputMaybe<VariantMaterialVariantWhereInput>;
  variantId?: InputMaybe<IntFilter>;
  weightBrutto?: InputMaybe<FloatFilter>;
  weightNetto?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
};

export type VariantMaterialVariantWhereInput = {
  AND?: InputMaybe<Array<VariantMaterialVariantWhereInput>>;
  NOT?: InputMaybe<Array<VariantMaterialVariantWhereInput>>;
  OR?: InputMaybe<Array<VariantMaterialVariantWhereInput>>;
  VariantMaterial?: InputMaybe<VariantMaterialListRelationFilter>;
  afn_fulfillable_quantity?: InputMaybe<IntFilter>;
  asin?: InputMaybe<StringNullableFilter>;
  base_unit_code?: InputMaybe<StringNullableFilter>;
  bulk_quantity?: InputMaybe<IntNullableFilter>;
  create_date?: InputMaybe<DateTimeFilter>;
  created_via_navision?: InputMaybe<BoolNullableFilter>;
  default_for_list?: InputMaybe<BoolFilter>;
  delivery_time?: InputMaybe<IntNullableFilter>;
  disable_annotations?: InputMaybe<BoolFilter>;
  disabled_in_navision?: InputMaybe<BoolNullableFilter>;
  enabled?: InputMaybe<BoolFilter>;
  external_max_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  external_min_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  external_shipment?: InputMaybe<BoolFilter>;
  fnsku?: InputMaybe<StringNullableFilter>;
  fulfillment_latency_ontop?: InputMaybe<IntNullableFilter>;
  gtin?: InputMaybe<StringNullableFilter>;
  gtin_fitness_werk?: InputMaybe<StringNullableFilter>;
  hwg?: InputMaybe<StringFilter>;
  isbn?: InputMaybe<StringFilter>;
  maintenance_standard_hours?: InputMaybe<IntNullableFilter>;
  manufacturing_product?: InputMaybe<BoolNullableFilter>;
  not_in_navision?: InputMaybe<BoolFilter>;
  price_on_request?: InputMaybe<BoolNullableFilter>;
  prime_by_merchant?: InputMaybe<BoolFilter>;
  product?: InputMaybe<VariantMaterialProductWhereInput>;
  productId?: InputMaybe<IntNullableFilter>;
  product_class?: InputMaybe<IntFilter>;
  purchase_unit_code?: InputMaybe<StringNullableFilter>;
  sales_unit_code?: InputMaybe<StringNullableFilter>;
  sent_to_amazon?: InputMaybe<BoolNullableFilter>;
  service_product?: InputMaybe<BoolNullableFilter>;
  shipping_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_max_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_min_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_type?: InputMaybe<EnumVariantShippingTypeNullableFilter>;
  show_stock?: InputMaybe<BoolNullableFilter>;
  show_variant_in_catalog?: InputMaybe<BoolFilter>;
  size?: InputMaybe<StringNullableFilter>;
  sort_size?: InputMaybe<IntFilter>;
  sort_variant?: InputMaybe<IntFilter>;
  stock?: InputMaybe<BoolFilter>;
  stock_buffer_quantity?: InputMaybe<IntNullableFilter>;
  stock_fba?: InputMaybe<IntFilter>;
  stock_shop_live?: InputMaybe<IntFilter>;
  supplierProductCarbonFootprint?: InputMaybe<FloatNullableFilter>;
  supplierProductCarbonFootprintMethod?: InputMaybe<EnumSupplierProductCarbonFootprintMethodNullableFilter>;
  supplier_creditor_number?: InputMaybe<StringNullableFilter>;
  supplier_delivery_time?: InputMaybe<StringNullableFilter>;
  supplier_product_number?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVariantTypeNullableFilter>;
  units_per_packet?: InputMaybe<IntNullableFilter>;
  update_timestamp?: InputMaybe<DateTimeNullableFilter>;
  update_user_id?: InputMaybe<IntNullableFilter>;
  uvp?: InputMaybe<BoolFilter>;
  uwg?: InputMaybe<StringFilter>;
  variantId?: InputMaybe<IntFilter>;
  variantUnits?: InputMaybe<VariantMaterialVariantUnitListRelationFilter>;
  variant_number?: InputMaybe<StringNullableFilter>;
  variant_number_full?: InputMaybe<StringNullableFilter>;
  vat_product_booking_group?: InputMaybe<StringNullableFilter>;
  volume?: InputMaybe<FloatFilter>;
  wg?: InputMaybe<StringFilter>;
};

export type VariantMaterialWhereInput = {
  AND?: InputMaybe<Array<VariantMaterialWhereInput>>;
  NOT?: InputMaybe<Array<VariantMaterialWhereInput>>;
  OR?: InputMaybe<Array<VariantMaterialWhereInput>>;
  dataQualityType?: InputMaybe<EnumVariantMaterialDataQualityTypeFilter>;
  materialDefinition?: InputMaybe<VariantMaterialDefinitionWhereInput>;
  materialId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  variant?: InputMaybe<VariantMaterialVariantWhereInput>;
  variantId?: InputMaybe<IntFilter>;
  weightInPercent?: InputMaybe<FloatFilter>;
};

export type VariantMaterialWhereUniqueInput = {
  variantId_materialId?: InputMaybe<VariantMaterialVariantIdMaterialIdCompoundUniqueInput>;
};

export type VariantScalarWhereInput = {
  AND?: InputMaybe<Array<VariantScalarWhereInput>>;
  NOT?: InputMaybe<Array<VariantScalarWhereInput>>;
  OR?: InputMaybe<Array<VariantScalarWhereInput>>;
  afn_fulfillable_quantity?: InputMaybe<IntFilter>;
  asin?: InputMaybe<StringNullableFilter>;
  base_unit_code?: InputMaybe<StringNullableFilter>;
  bulk_quantity?: InputMaybe<IntNullableFilter>;
  create_date?: InputMaybe<DateTimeFilter>;
  created_via_navision?: InputMaybe<BoolNullableFilter>;
  default_for_list?: InputMaybe<BoolFilter>;
  delivery_time?: InputMaybe<IntNullableFilter>;
  disable_annotations?: InputMaybe<BoolFilter>;
  disabled_in_navision?: InputMaybe<BoolNullableFilter>;
  enabled?: InputMaybe<BoolFilter>;
  external_max_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  external_min_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  external_shipment?: InputMaybe<BoolFilter>;
  fnsku?: InputMaybe<StringNullableFilter>;
  fulfillment_latency_ontop?: InputMaybe<IntNullableFilter>;
  gtin?: InputMaybe<StringNullableFilter>;
  gtin_fitness_werk?: InputMaybe<StringNullableFilter>;
  hwg?: InputMaybe<StringFilter>;
  isbn?: InputMaybe<StringFilter>;
  maintenance_standard_hours?: InputMaybe<IntNullableFilter>;
  manufacturing_product?: InputMaybe<BoolNullableFilter>;
  not_in_navision?: InputMaybe<BoolFilter>;
  price_on_request?: InputMaybe<BoolNullableFilter>;
  prime_by_merchant?: InputMaybe<BoolFilter>;
  productId?: InputMaybe<IntNullableFilter>;
  product_class?: InputMaybe<IntFilter>;
  purchase_unit_code?: InputMaybe<StringNullableFilter>;
  sales_unit_code?: InputMaybe<StringNullableFilter>;
  sent_to_amazon?: InputMaybe<BoolNullableFilter>;
  service_product?: InputMaybe<BoolNullableFilter>;
  shipping_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_max_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_min_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_type?: InputMaybe<EnumVariantShippingTypeNullableFilter>;
  show_stock?: InputMaybe<BoolNullableFilter>;
  show_variant_in_catalog?: InputMaybe<BoolFilter>;
  size?: InputMaybe<StringNullableFilter>;
  sort_size?: InputMaybe<IntFilter>;
  sort_variant?: InputMaybe<IntFilter>;
  stock?: InputMaybe<BoolFilter>;
  stock_buffer_quantity?: InputMaybe<IntNullableFilter>;
  stock_fba?: InputMaybe<IntFilter>;
  stock_shop_live?: InputMaybe<IntFilter>;
  supplier_creditor_number?: InputMaybe<StringNullableFilter>;
  supplier_delivery_time?: InputMaybe<StringNullableFilter>;
  supplier_product_number?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVariantTypeNullableFilter>;
  units_per_packet?: InputMaybe<IntNullableFilter>;
  update_timestamp?: InputMaybe<DateTimeNullableFilter>;
  update_user_id?: InputMaybe<IntNullableFilter>;
  uvp?: InputMaybe<BoolFilter>;
  uwg?: InputMaybe<StringFilter>;
  variantId?: InputMaybe<IntFilter>;
  variant_number?: InputMaybe<StringNullableFilter>;
  variant_number_full?: InputMaybe<StringNullableFilter>;
  vat_product_booking_group?: InputMaybe<StringNullableFilter>;
  volume?: InputMaybe<FloatFilter>;
  wg?: InputMaybe<StringFilter>;
};

export enum VariantShippingType {
  Fracht = "Fracht",
  Kep = "KEP",
  Spedition = "Spedition",
}

export type VariantSupplierCarbonFootprint = {
  __typename?: "VariantSupplierCarbonFootprint";
  method?: Maybe<SupplierProductCarbonFootprintMethod>;
  value?: Maybe<Scalars["Float"]>;
  variantId: Scalars["Int"];
};

export enum VariantType {
  Normal = "Normal",
  Set = "Set",
}

export type VariantUpdateManyMutationInput = {
  afn_fulfillable_quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  asin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  base_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bulk_quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  create_date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_via_navision?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  default_for_list?: InputMaybe<BoolFieldUpdateOperationsInput>;
  delivery_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  disable_annotations?: InputMaybe<BoolFieldUpdateOperationsInput>;
  disabled_in_navision?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  external_max_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_min_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_shipment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fnsku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fulfillment_latency_ontop?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  gtin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gtin_fitness_werk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hwg?: InputMaybe<StringFieldUpdateOperationsInput>;
  isbn?: InputMaybe<StringFieldUpdateOperationsInput>;
  maintenance_standard_hours?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  manufacturing_product?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  not_in_navision?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price_on_request?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  prime_by_merchant?: InputMaybe<BoolFieldUpdateOperationsInput>;
  product_class?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchase_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sales_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_to_amazon?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  service_product?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shipping_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_max_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_min_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_type?: InputMaybe<NullableEnumVariantShippingTypeFieldUpdateOperationsInput>;
  show_stock?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  show_variant_in_catalog?: InputMaybe<BoolFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sort_size?: InputMaybe<IntFieldUpdateOperationsInput>;
  sort_variant?: InputMaybe<IntFieldUpdateOperationsInput>;
  stock?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stock_buffer_quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  stock_fba?: InputMaybe<IntFieldUpdateOperationsInput>;
  stock_shop_live?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplier_creditor_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplier_delivery_time?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplier_product_number?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumVariantTypeFieldUpdateOperationsInput>;
  units_per_packet?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  update_timestamp?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  update_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  uvp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  uwg?: InputMaybe<StringFieldUpdateOperationsInput>;
  variant_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  variant_number_full?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vat_product_booking_group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  volume?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wg?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type VariantUpdateManyWithWhereWithoutProductInput = {
  data: VariantUpdateManyMutationInput;
  where: VariantScalarWhereInput;
};

export type VariantUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<VariantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VariantCreateOrConnectWithoutProductInput>
  >;
  create?: InputMaybe<Array<VariantCreateWithoutProductInput>>;
  createMany?: InputMaybe<VariantCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<VariantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VariantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VariantWhereUniqueInput>>;
  set?: InputMaybe<Array<VariantWhereUniqueInput>>;
  update?: InputMaybe<Array<VariantUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<VariantUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<VariantUpsertWithWhereUniqueWithoutProductInput>>;
};

export type VariantUpdateOneRequiredWithoutResourceVariantInput = {
  connect?: InputMaybe<VariantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VariantCreateOrConnectWithoutResourceVariantInput>;
  create?: InputMaybe<VariantCreateWithoutResourceVariantInput>;
  update?: InputMaybe<VariantUpdateWithoutResourceVariantInput>;
  upsert?: InputMaybe<VariantUpsertWithoutResourceVariantInput>;
};

export type VariantUpdateWithWhereUniqueWithoutProductInput = {
  data: VariantUpdateWithoutProductInput;
  where: VariantWhereUniqueInput;
};

export type VariantUpdateWithoutProductInput = {
  afn_fulfillable_quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  asin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  base_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bulk_quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  create_date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_via_navision?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  default_for_list?: InputMaybe<BoolFieldUpdateOperationsInput>;
  delivery_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  disable_annotations?: InputMaybe<BoolFieldUpdateOperationsInput>;
  disabled_in_navision?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  external_max_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_min_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_shipment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fnsku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fulfillment_latency_ontop?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  gtin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gtin_fitness_werk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hwg?: InputMaybe<StringFieldUpdateOperationsInput>;
  isbn?: InputMaybe<StringFieldUpdateOperationsInput>;
  maintenance_standard_hours?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  manufacturing_product?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  not_in_navision?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price_on_request?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  prime_by_merchant?: InputMaybe<BoolFieldUpdateOperationsInput>;
  product_class?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchase_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resourceVariant?: InputMaybe<ResourceVariantUpdateManyWithoutVariantInput>;
  sales_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_to_amazon?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  service_product?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shipping_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_max_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_min_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_type?: InputMaybe<NullableEnumVariantShippingTypeFieldUpdateOperationsInput>;
  show_stock?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  show_variant_in_catalog?: InputMaybe<BoolFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sort_size?: InputMaybe<IntFieldUpdateOperationsInput>;
  sort_variant?: InputMaybe<IntFieldUpdateOperationsInput>;
  stock?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stock_buffer_quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  stock_fba?: InputMaybe<IntFieldUpdateOperationsInput>;
  stock_shop_live?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplier_creditor_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplier_delivery_time?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplier_product_number?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumVariantTypeFieldUpdateOperationsInput>;
  units_per_packet?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  update_timestamp?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  update_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  uvp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  uwg?: InputMaybe<StringFieldUpdateOperationsInput>;
  variant_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  variant_number_full?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vat_product_booking_group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  volume?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wg?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type VariantUpdateWithoutResourceVariantInput = {
  afn_fulfillable_quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  asin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  base_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bulk_quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  create_date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_via_navision?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  default_for_list?: InputMaybe<BoolFieldUpdateOperationsInput>;
  delivery_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  disable_annotations?: InputMaybe<BoolFieldUpdateOperationsInput>;
  disabled_in_navision?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  external_max_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_min_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_shipment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fnsku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fulfillment_latency_ontop?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  gtin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gtin_fitness_werk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hwg?: InputMaybe<StringFieldUpdateOperationsInput>;
  isbn?: InputMaybe<StringFieldUpdateOperationsInput>;
  maintenance_standard_hours?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  manufacturing_product?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  not_in_navision?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price_on_request?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  prime_by_merchant?: InputMaybe<BoolFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutVariantsInput>;
  product_class?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchase_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sales_unit_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_to_amazon?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  service_product?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shipping_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_max_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_min_delivery_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shipping_type?: InputMaybe<NullableEnumVariantShippingTypeFieldUpdateOperationsInput>;
  show_stock?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  show_variant_in_catalog?: InputMaybe<BoolFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sort_size?: InputMaybe<IntFieldUpdateOperationsInput>;
  sort_variant?: InputMaybe<IntFieldUpdateOperationsInput>;
  stock?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stock_buffer_quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  stock_fba?: InputMaybe<IntFieldUpdateOperationsInput>;
  stock_shop_live?: InputMaybe<IntFieldUpdateOperationsInput>;
  supplier_creditor_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplier_delivery_time?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplier_product_number?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumVariantTypeFieldUpdateOperationsInput>;
  units_per_packet?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  update_timestamp?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  update_user_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  uvp?: InputMaybe<BoolFieldUpdateOperationsInput>;
  uwg?: InputMaybe<StringFieldUpdateOperationsInput>;
  variant_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  variant_number_full?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vat_product_booking_group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  volume?: InputMaybe<FloatFieldUpdateOperationsInput>;
  wg?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type VariantUpsertWithWhereUniqueWithoutProductInput = {
  create: VariantCreateWithoutProductInput;
  update: VariantUpdateWithoutProductInput;
  where: VariantWhereUniqueInput;
};

export type VariantUpsertWithoutResourceVariantInput = {
  create: VariantCreateWithoutResourceVariantInput;
  update: VariantUpdateWithoutResourceVariantInput;
};

export type VariantWhereInput = {
  AND?: InputMaybe<Array<VariantWhereInput>>;
  NOT?: InputMaybe<Array<VariantWhereInput>>;
  OR?: InputMaybe<Array<VariantWhereInput>>;
  afn_fulfillable_quantity?: InputMaybe<IntFilter>;
  asin?: InputMaybe<StringNullableFilter>;
  base_unit_code?: InputMaybe<StringNullableFilter>;
  bulk_quantity?: InputMaybe<IntNullableFilter>;
  create_date?: InputMaybe<DateTimeFilter>;
  created_via_navision?: InputMaybe<BoolNullableFilter>;
  default_for_list?: InputMaybe<BoolFilter>;
  delivery_time?: InputMaybe<IntNullableFilter>;
  disable_annotations?: InputMaybe<BoolFilter>;
  disabled_in_navision?: InputMaybe<BoolNullableFilter>;
  enabled?: InputMaybe<BoolFilter>;
  external_max_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  external_min_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  external_shipment?: InputMaybe<BoolFilter>;
  fnsku?: InputMaybe<StringNullableFilter>;
  fulfillment_latency_ontop?: InputMaybe<IntNullableFilter>;
  gtin?: InputMaybe<StringNullableFilter>;
  gtin_fitness_werk?: InputMaybe<StringNullableFilter>;
  hwg?: InputMaybe<StringFilter>;
  isbn?: InputMaybe<StringFilter>;
  maintenance_standard_hours?: InputMaybe<IntNullableFilter>;
  manufacturing_product?: InputMaybe<BoolNullableFilter>;
  not_in_navision?: InputMaybe<BoolFilter>;
  price_on_request?: InputMaybe<BoolNullableFilter>;
  prime_by_merchant?: InputMaybe<BoolFilter>;
  product?: InputMaybe<ProductWhereInput>;
  productId?: InputMaybe<IntNullableFilter>;
  product_class?: InputMaybe<IntFilter>;
  purchase_unit_code?: InputMaybe<StringNullableFilter>;
  resourceVariant?: InputMaybe<ResourceVariantListRelationFilter>;
  sales_unit_code?: InputMaybe<StringNullableFilter>;
  sent_to_amazon?: InputMaybe<BoolNullableFilter>;
  service_product?: InputMaybe<BoolNullableFilter>;
  shipping_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_max_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_min_delivery_date?: InputMaybe<DateTimeNullableFilter>;
  shipping_type?: InputMaybe<EnumVariantShippingTypeNullableFilter>;
  show_stock?: InputMaybe<BoolNullableFilter>;
  show_variant_in_catalog?: InputMaybe<BoolFilter>;
  size?: InputMaybe<StringNullableFilter>;
  sort_size?: InputMaybe<IntFilter>;
  sort_variant?: InputMaybe<IntFilter>;
  stock?: InputMaybe<BoolFilter>;
  stock_buffer_quantity?: InputMaybe<IntNullableFilter>;
  stock_fba?: InputMaybe<IntFilter>;
  stock_shop_live?: InputMaybe<IntFilter>;
  supplier_creditor_number?: InputMaybe<StringNullableFilter>;
  supplier_delivery_time?: InputMaybe<StringNullableFilter>;
  supplier_product_number?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVariantTypeNullableFilter>;
  units_per_packet?: InputMaybe<IntNullableFilter>;
  update_timestamp?: InputMaybe<DateTimeNullableFilter>;
  update_user_id?: InputMaybe<IntNullableFilter>;
  uvp?: InputMaybe<BoolFilter>;
  uwg?: InputMaybe<StringFilter>;
  variantId?: InputMaybe<IntFilter>;
  variant_number?: InputMaybe<StringNullableFilter>;
  variant_number_full?: InputMaybe<StringNullableFilter>;
  vat_product_booking_group?: InputMaybe<StringNullableFilter>;
  volume?: InputMaybe<FloatFilter>;
  wg?: InputMaybe<StringFilter>;
};

export type VariantWhereUniqueInput = {
  variantId?: InputMaybe<Scalars["Int"]>;
};

export type VariantWithout100PercentMaterials = {
  __typename?: "VariantWithout100PercentMaterials";
  percentage: Scalars["Float"];
  productId: Scalars["Int"];
  productName: Scalars["String"];
  variantId: Scalars["Int"];
  variantNumber: Scalars["String"];
};

export type Voucher = {
  active?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<Scalars["String"]>;
  codes?: Maybe<VoucherCodePagination>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  minOrderValue?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  navisionAccount?: Maybe<Scalars["Int"]>;
  shopId?: Maybe<Scalars["Int"]>;
  statistics?: Maybe<VoucherStatistic>;
  /** Total number of times this voucher has been redeemed in an confirmed order. */
  totalRedeemCount?: Maybe<Scalars["Int"]>;
  /** Total number of redeemed codes. Please keep in mind that this number might seem wrong if codes can redeem multiple times. This query uses the boolean on voucher code. */
  totalRedeemdedCodes?: Maybe<Scalars["Int"]>;
  unique?: Maybe<Scalars["Boolean"]>;
};

export type VoucherCodesArgs = {
  code?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  expired?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  redeemed?: InputMaybe<Scalars["Boolean"]>;
  take?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VoucherCodeWhereInput>;
};

export type VoucherStatisticsArgs = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type VoucherBrandListRelationFilter = {
  every?: InputMaybe<VoucherBrandWhereInput>;
  none?: InputMaybe<VoucherBrandWhereInput>;
  some?: InputMaybe<VoucherBrandWhereInput>;
};

export type VoucherBrandWhereInput = {
  AND?: InputMaybe<Array<VoucherBrandWhereInput>>;
  NOT?: InputMaybe<Array<VoucherBrandWhereInput>>;
  OR?: InputMaybe<Array<VoucherBrandWhereInput>>;
  brand?: InputMaybe<BrandEligibleForVoucherWhereInput>;
  brandId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  voucher?: InputMaybe<VoucherWhereInput>;
  voucherId?: InputMaybe<IntFilter>;
};

export type VoucherCode = {
  __typename?: "VoucherCode";
  code: Scalars["String"];
  createDate?: Maybe<Scalars["DateTime"]>;
  expirationDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  /** Total number of times this voucher code has been redeemed in an confirmed order. */
  redeemCount?: Maybe<Scalars["Int"]>;
  redeemed: Scalars["Boolean"];
  redeems?: Maybe<Array<VoucherRedeem>>;
  voucher: Voucher;
  voucherId: Scalars["Int"];
};

export type VoucherCodeListRelationFilter = {
  every?: InputMaybe<VoucherCodeWhereInput>;
  none?: InputMaybe<VoucherCodeWhereInput>;
  some?: InputMaybe<VoucherCodeWhereInput>;
};

export type VoucherCodeOrderByInput = {
  code?: InputMaybe<SortOrder>;
  createDate?: InputMaybe<SortOrder>;
  expirationDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  offlineRedeemDate?: InputMaybe<SortOrder>;
  offlineRedeemNavisionOrderId?: InputMaybe<SortOrder>;
  offlineRedeemUser?: InputMaybe<SortOrder>;
  redeemed?: InputMaybe<SortOrder>;
  voucherId?: InputMaybe<SortOrder>;
};

export type VoucherCodePagination = {
  __typename?: "VoucherCodePagination";
  data?: Maybe<Array<Maybe<VoucherCode>>>;
  pagination?: Maybe<PaginationVoucher>;
};

export type VoucherCodeWhereInput = {
  AND?: InputMaybe<Array<VoucherCodeWhereInput>>;
  NOT?: InputMaybe<Array<VoucherCodeWhereInput>>;
  OR?: InputMaybe<Array<VoucherCodeWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createDate?: InputMaybe<DateTimeNullableFilter>;
  expirationDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  offlineRedeemDate?: InputMaybe<DateTimeNullableFilter>;
  offlineRedeemNavisionOrderId?: InputMaybe<StringNullableFilter>;
  offlineRedeemUser?: InputMaybe<StringNullableFilter>;
  redeemed?: InputMaybe<BoolFilter>;
  voucher?: InputMaybe<VoucherWhereInput>;
  voucherId?: InputMaybe<IntFilter>;
};

export type VoucherCodeWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type VoucherList = {
  __typename?: "VoucherList";
  shops?: Maybe<Array<Maybe<VoucherListShop>>>;
};

export type VoucherListCategory = {
  __typename?: "VoucherListCategory";
  name?: Maybe<Scalars["String"]>;
  vouchers?: Maybe<Array<Maybe<Voucher>>>;
};

export type VoucherListRelationFilter = {
  every?: InputMaybe<VoucherWhereInput>;
  none?: InputMaybe<VoucherWhereInput>;
  some?: InputMaybe<VoucherWhereInput>;
};

export type VoucherListShop = {
  __typename?: "VoucherListShop";
  categories: Array<VoucherListCategory>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type VoucherProductGroupListRelationFilter = {
  every?: InputMaybe<VoucherProductGroupWhereInput>;
  none?: InputMaybe<VoucherProductGroupWhereInput>;
  some?: InputMaybe<VoucherProductGroupWhereInput>;
};

export type VoucherProductGroupWhereInput = {
  AND?: InputMaybe<Array<VoucherProductGroupWhereInput>>;
  NOT?: InputMaybe<Array<VoucherProductGroupWhereInput>>;
  OR?: InputMaybe<Array<VoucherProductGroupWhereInput>>;
  id?: InputMaybe<IntFilter>;
  lowerMaterialGroup?: InputMaybe<StringNullableFilter>;
  mainMaterialGroup?: InputMaybe<StringFilter>;
  materialGroup?: InputMaybe<StringNullableFilter>;
  voucher?: InputMaybe<VoucherWhereInput>;
  voucherId?: InputMaybe<IntFilter>;
};

export type VoucherRedeem = {
  __typename?: "VoucherRedeem";
  date?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["String"]>;
  orderName?: Maybe<Scalars["String"]>;
  redeemedBy?: Maybe<Scalars["String"]>;
  type?: Maybe<VoucherRedeemType>;
};

export enum VoucherRedeemType {
  Offline = "offline",
  Online = "online",
}

export type VoucherStatistic = {
  __typename?: "VoucherStatistic";
  averageValue?: Maybe<Scalars["Float"]>;
  offlineCodes?: Maybe<Scalars["Int"]>;
  offlineOrders?: Maybe<Scalars["Int"]>;
  onlineCodes?: Maybe<Scalars["Int"]>;
  onlineOrders?: Maybe<Scalars["Int"]>;
  totalCodes?: Maybe<Scalars["Int"]>;
  totalOrders?: Maybe<Scalars["Int"]>;
  totalSum?: Maybe<Scalars["Float"]>;
};

export enum VoucherType {
  Discount = "Discount",
  Incentive = "Incentive",
  ProductDiscount = "ProductDiscount",
  ProductDiscountFixedValue = "ProductDiscountFixedValue",
  Shipping = "Shipping",
  Value = "Value",
}

export type VoucherWhereInput = {
  AND?: InputMaybe<Array<VoucherWhereInput>>;
  NOT?: InputMaybe<Array<VoucherWhereInput>>;
  OR?: InputMaybe<Array<VoucherWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  category?: InputMaybe<StringNullableFilter>;
  codes?: InputMaybe<VoucherCodeListRelationFilter>;
  companyId?: InputMaybe<IntNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  discount?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  incentive?: InputMaybe<IntNullableFilter>;
  lastModified?: InputMaybe<DateTimeFilter>;
  materialGroupConstraints?: InputMaybe<VoucherProductGroupListRelationFilter>;
  minOrderValue?: InputMaybe<FloatNullableFilter>;
  name?: InputMaybe<StringFilter>;
  navisionAccount?: InputMaybe<IntFilter>;
  postponeValidation?: InputMaybe<BoolNullableFilter>;
  shop?: InputMaybe<ShopWhereInput>;
  shopId?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumVoucherTypeFilter>;
  unique?: InputMaybe<BoolFilter>;
  validForModel?: InputMaybe<BoolFilter>;
  validForVariantId?: InputMaybe<IntNullableFilter>;
  validOnlyForOneVariant?: InputMaybe<BoolFilter>;
  value?: InputMaybe<FloatNullableFilter>;
  voucherBrand?: InputMaybe<VoucherBrandListRelationFilter>;
};

export type ListEmailTemplates = {
  __typename?: "listEmailTemplates";
  count: Scalars["Int"];
  emailTemplates: Array<Maybe<EmailTemplate>>;
};

export type ListMutationLogs = {
  __typename?: "listMutationLogs";
  count: Scalars["Int"];
  logs: Array<Maybe<MutationLog>>;
};

export type ListTemplates = {
  __typename?: "listTemplates";
  count: Scalars["Int"];
  templates: Array<Maybe<Template>>;
};

export type UpdateTextAttributeWhere = {
  text_id: Scalars["Int"];
};

export type UpdateTextWhere = {
  language_id: Scalars["Int"];
  text_id: Scalars["Int"];
};

export type AddTemplateMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type AddTemplateMutation = {
  __typename?: "Mutation";
  addTemplate: {
    __typename?: "Template";
    id: number;
    name?: string | null | undefined;
  };
};

export type ChangeTemplateEntryMutationVariables = Exact<{
  id: Scalars["Int"];
  shopId: Scalars["Int"];
  languageId: Scalars["Int"];
  code: Scalars["String"];
  isGzipped: Scalars["Boolean"];
}>;

export type ChangeTemplateEntryMutation = {
  __typename?: "Mutation";
  changeTemplateEntry?:
    | {
        __typename?: "TemplateCode";
        code?: string | null | undefined;
        shopId: number;
        languageId: number;
      }
    | null
    | undefined;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: "Query";
  currentUser?:
    | {
        __typename?: "CurrentUser";
        firstname?: string | null | undefined;
        image?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteTemplateMutation = {
  __typename?: "Mutation";
  deleteTemplate?: { __typename?: "Template"; id: number } | null | undefined;
};

export type ListTemplatesQueryVariables = Exact<{
  where?: InputMaybe<TemplateWhereInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type ListTemplatesQuery = {
  __typename?: "Query";
  listTemplates?:
    | {
        __typename?: "listTemplates";
        count: number;
        templates: Array<
          | {
              __typename?: "Template";
              id: number;
              name?: string | null | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type RenameTemplateMutationVariables = Exact<{
  id: Scalars["Int"];
  name: Scalars["String"];
}>;

export type RenameTemplateMutation = {
  __typename?: "Mutation";
  updateTemplate?:
    | { __typename?: "Template"; id: number; name?: string | null | undefined }
    | null
    | undefined;
};

export type SingleTemplateQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]>;
}>;

export type SingleTemplateQuery = {
  __typename?: "Query";
  template?:
    | {
        __typename?: "Template";
        id: number;
        name?: string | null | undefined;
        entries: Array<
          | {
              __typename?: "TemplateCode";
              shopId: number;
              languageId: number;
              shopName?: string | null | undefined;
              languageName?: string | null | undefined;
              code?: string | null | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type TranslateTemplateCodeMutationVariables = Exact<{
  id: Scalars["Int"];
  sourceLanguageId: Scalars["Int"];
  targetLanguageIds:
    | Array<InputMaybe<Scalars["Int"]>>
    | InputMaybe<Scalars["Int"]>;
}>;

export type TranslateTemplateCodeMutation = {
  __typename?: "Mutation";
  translateTemplateCode?:
    | { __typename?: "Template"; id: number }
    | null
    | undefined;
};

export const AddTemplateDocument = gql`
  mutation addTemplate($name: String!) {
    addTemplate: createTemplate(data: { name: $name }) {
      id
      name
    }
  }
`;
export type AddTemplateMutationFn = Apollo.MutationFunction<
  AddTemplateMutation,
  AddTemplateMutationVariables
>;

/**
 * __useAddTemplateMutation__
 *
 * To run a mutation, you first call `useAddTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateMutation, { data, loading, error }] = useAddTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTemplateMutation,
    AddTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTemplateMutation, AddTemplateMutationVariables>(
    AddTemplateDocument,
    options
  );
}
export type AddTemplateMutationHookResult = ReturnType<
  typeof useAddTemplateMutation
>;
export type AddTemplateMutationResult =
  Apollo.MutationResult<AddTemplateMutation>;
export type AddTemplateMutationOptions = Apollo.BaseMutationOptions<
  AddTemplateMutation,
  AddTemplateMutationVariables
>;
export const ChangeTemplateEntryDocument = gql`
  mutation changeTemplateEntry(
    $id: Int!
    $shopId: Int!
    $languageId: Int!
    $code: String!
    $isGzipped: Boolean!
  ) {
    changeTemplateEntry: upsertTemplateCode(
      id: $id
      shopId: $shopId
      languageId: $languageId
      code: $code
      isGzipped: $isGzipped
    ) {
      code
      shopId
      languageId
    }
  }
`;
export type ChangeTemplateEntryMutationFn = Apollo.MutationFunction<
  ChangeTemplateEntryMutation,
  ChangeTemplateEntryMutationVariables
>;

/**
 * __useChangeTemplateEntryMutation__
 *
 * To run a mutation, you first call `useChangeTemplateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTemplateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTemplateEntryMutation, { data, loading, error }] = useChangeTemplateEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shopId: // value for 'shopId'
 *      languageId: // value for 'languageId'
 *      code: // value for 'code'
 *      isGzipped: // value for 'isGzipped'
 *   },
 * });
 */
export function useChangeTemplateEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeTemplateEntryMutation,
    ChangeTemplateEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeTemplateEntryMutation,
    ChangeTemplateEntryMutationVariables
  >(ChangeTemplateEntryDocument, options);
}
export type ChangeTemplateEntryMutationHookResult = ReturnType<
  typeof useChangeTemplateEntryMutation
>;
export type ChangeTemplateEntryMutationResult =
  Apollo.MutationResult<ChangeTemplateEntryMutation>;
export type ChangeTemplateEntryMutationOptions = Apollo.BaseMutationOptions<
  ChangeTemplateEntryMutation,
  ChangeTemplateEntryMutationVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      firstname
      image
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const DeleteTemplateDocument = gql`
  mutation deleteTemplate($id: Int!) {
    deleteTemplate(where: { id: $id }) {
      id
    }
  }
`;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables
>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTemplateMutation,
    DeleteTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTemplateMutation,
    DeleteTemplateMutationVariables
  >(DeleteTemplateDocument, options);
}
export type DeleteTemplateMutationHookResult = ReturnType<
  typeof useDeleteTemplateMutation
>;
export type DeleteTemplateMutationResult =
  Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables
>;
export const ListTemplatesDocument = gql`
  query listTemplates(
    $where: TemplateWhereInput
    $limit: Int
    $offset: Int = 0
  ) {
    listTemplates(where: $where, take: $limit, skip: $offset) {
      count
      templates {
        id
        name
      }
    }
  }
`;

/**
 * __useListTemplatesQuery__
 *
 * To run a query within a React component, call `useListTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTemplatesQuery,
    ListTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTemplatesQuery, ListTemplatesQueryVariables>(
    ListTemplatesDocument,
    options
  );
}
export function useListTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTemplatesQuery,
    ListTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTemplatesQuery, ListTemplatesQueryVariables>(
    ListTemplatesDocument,
    options
  );
}
export type ListTemplatesQueryHookResult = ReturnType<
  typeof useListTemplatesQuery
>;
export type ListTemplatesLazyQueryHookResult = ReturnType<
  typeof useListTemplatesLazyQuery
>;
export type ListTemplatesQueryResult = Apollo.QueryResult<
  ListTemplatesQuery,
  ListTemplatesQueryVariables
>;
export const RenameTemplateDocument = gql`
  mutation renameTemplate($id: Int!, $name: String!) {
    updateTemplate(where: { id: $id }, data: { name: { set: $name } }) {
      id
      name
    }
  }
`;
export type RenameTemplateMutationFn = Apollo.MutationFunction<
  RenameTemplateMutation,
  RenameTemplateMutationVariables
>;

/**
 * __useRenameTemplateMutation__
 *
 * To run a mutation, you first call `useRenameTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameTemplateMutation, { data, loading, error }] = useRenameTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameTemplateMutation,
    RenameTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenameTemplateMutation,
    RenameTemplateMutationVariables
  >(RenameTemplateDocument, options);
}
export type RenameTemplateMutationHookResult = ReturnType<
  typeof useRenameTemplateMutation
>;
export type RenameTemplateMutationResult =
  Apollo.MutationResult<RenameTemplateMutation>;
export type RenameTemplateMutationOptions = Apollo.BaseMutationOptions<
  RenameTemplateMutation,
  RenameTemplateMutationVariables
>;
export const SingleTemplateDocument = gql`
  query singleTemplate($id: Int) {
    template(where: { id: $id }) {
      id
      name
      entries {
        shopId
        languageId
        shopName
        languageName
        code
      }
    }
  }
`;

/**
 * __useSingleTemplateQuery__
 *
 * To run a query within a React component, call `useSingleTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSingleTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SingleTemplateQuery,
    SingleTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleTemplateQuery, SingleTemplateQueryVariables>(
    SingleTemplateDocument,
    options
  );
}
export function useSingleTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SingleTemplateQuery,
    SingleTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleTemplateQuery, SingleTemplateQueryVariables>(
    SingleTemplateDocument,
    options
  );
}
export type SingleTemplateQueryHookResult = ReturnType<
  typeof useSingleTemplateQuery
>;
export type SingleTemplateLazyQueryHookResult = ReturnType<
  typeof useSingleTemplateLazyQuery
>;
export type SingleTemplateQueryResult = Apollo.QueryResult<
  SingleTemplateQuery,
  SingleTemplateQueryVariables
>;
export const TranslateTemplateCodeDocument = gql`
  mutation translateTemplateCode(
    $id: Int!
    $sourceLanguageId: Int!
    $targetLanguageIds: [Int]!
  ) {
    translateTemplateCode(
      id: $id
      sourceLanguageId: $sourceLanguageId
      targetLanguageIds: $targetLanguageIds
    ) {
      id
    }
  }
`;
export type TranslateTemplateCodeMutationFn = Apollo.MutationFunction<
  TranslateTemplateCodeMutation,
  TranslateTemplateCodeMutationVariables
>;

/**
 * __useTranslateTemplateCodeMutation__
 *
 * To run a mutation, you first call `useTranslateTemplateCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranslateTemplateCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [translateTemplateCodeMutation, { data, loading, error }] = useTranslateTemplateCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sourceLanguageId: // value for 'sourceLanguageId'
 *      targetLanguageIds: // value for 'targetLanguageIds'
 *   },
 * });
 */
export function useTranslateTemplateCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TranslateTemplateCodeMutation,
    TranslateTemplateCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TranslateTemplateCodeMutation,
    TranslateTemplateCodeMutationVariables
  >(TranslateTemplateCodeDocument, options);
}
export type TranslateTemplateCodeMutationHookResult = ReturnType<
  typeof useTranslateTemplateCodeMutation
>;
export type TranslateTemplateCodeMutationResult =
  Apollo.MutationResult<TranslateTemplateCodeMutation>;
export type TranslateTemplateCodeMutationOptions = Apollo.BaseMutationOptions<
  TranslateTemplateCodeMutation,
  TranslateTemplateCodeMutationVariables
>;
