import React, { useState, useContext, useCallback, useEffect } from "react";

import {
  ThemeContext,
  Paper,
  Icon,
  Button,
  H3,
  IconButton,
  Clipboard,
  Tooltip,
  Box,
  Grid,
  makeStyles,
  SimpleSnackbar,
  PagePreloader,
} from "@sport-thieme/react-ui";
import { localize } from "@sport-thieme/util-localization";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-twig";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-tomorrow_night";
import "ace-builds/src-noconflict/ext-searchbox";
import pretty from "pretty";

import TemplateEntryList, { TemplateEntries } from "./TemplateEntryList";

import singleTemplateQuery from "../../graphql/singleTemplate";

import { AppContext } from "../../AppContext";
import {
  useChangeTemplateEntryMutation,
  useSingleTemplateQuery,
} from "../../graphql/generated/graphql";
import TemplateEditorActions from "./TemplateEditorActions";
import { gzip } from "pako";

async function bufferToBase64(buffer: Uint8Array): Promise<string> {
  const base64url = await new Promise<string>((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result?.toString() ?? '');
    reader.readAsDataURL(new Blob([buffer]));
  });

  return base64url.slice(base64url.indexOf(',') + 1);
}

export default function TemplateEditor({ id }: { id: number }) {
  const { openSnackbar, templateState, setTemplateState } =
    useContext(AppContext);
  const [entryIndex, setEntryIndex] = useState(0);
  const {
    darkState: [darkModeActive],
  } = useContext(ThemeContext);
  const [options] = useState({
    theme: darkModeActive ? "monokai" : "chrome",
    fontSize: 16,
  });

  const classes = useStyles();

  const { data, loading, error } = useSingleTemplateQuery({
    variables: {
      id,
    },
  });

  const [changeTemplate, { loading: loadingChange }] =
    useChangeTemplateEntryMutation({
      onError: err => {
        console.error(err);
        openSnackbar(localize`could not save`, "error");
      },
      onCompleted: res => {
        openSnackbar(localize`succesfully saved`);
        if (res?.changeTemplateEntry == null) return;

        let temp: Record<string, string> = { ...templateState };

        const languageId = res.changeTemplateEntry.languageId;
        const shopId = res.changeTemplateEntry.shopId;

        temp[`${id ?? ""}-${shopId}-${languageId}`] =
          res?.changeTemplateEntry?.code ?? "";

        setTemplateState(temp);
      },
      refetchQueries: () => {
        return [
          {
            query: singleTemplateQuery,
            variables: {
              id,
            },
          },
        ];
      },
    });

  const entries = (data?.template?.entries ?? []) as TemplateEntries;
  const name = data?.template?.name || "";

  const code = entries[entryIndex]?.code;
  const languageId = entries[entryIndex]?.languageId;
  const shopId = entries[entryIndex]?.shopId;

  const changedEditorValue = templateState[`${id}-${shopId}-${languageId}`];
  const editorValue =
    changedEditorValue !== undefined ? changedEditorValue : code;

  const save = useCallback(async () => {
    if (editorValue === code) return;
    const variables = {
      id,
      shopId,
      languageId,
      code: await bufferToBase64(gzip(editorValue ?? '')),
      isGzipped: true,
    };
    changeTemplate({ variables });
  }, [id, shopId, languageId, editorValue, changeTemplate, code]);

  useEffect(() => {
    const keydown = (event: KeyboardEvent) => {
      if ((event.key === "s" && event.metaKey) || event.ctrlKey) {
        event.preventDefault();
        save();
      }
    };
    window.addEventListener("keydown", keydown);

    return () => window.removeEventListener("keydown", keydown);
  }, [save]);

  if (data?.template == null && loading === false) {
    return (
      <div className={classes.templateNotFound}>
        <H3>{localize`The template with the id ${String(
          id
        )} does not exists`}</H3>
      </div>
    );
  }

  return (
    <>
      <SimpleSnackbar error={error?.message} />
      <Paper className={classes.editor}>
        <AceEditor
          {...options}
          width="100%"
          showGutter
          wrapEnabled
          theme={darkModeActive ? "tomorrow_night" : "chrome"}
          height="100%"
          style={{
            fontFamily: '"Fira Code", monospace',
            lineHeight: "1.5em",
          }}
          mode="twig"
          value={editorValue ?? ""}
          setOptions={{
            showLineNumbers: true,
            tabSize: 2,
          }}
          onChange={value => {
            let temp: Record<string, string> = { ...templateState };
            temp[`${id}-${shopId}-${languageId}`] = value;
            setTemplateState(temp);
          }}
          commands={[
            {
              name: "beautify",
              bindKey: {
                win: "Ctrl-Shift-F",
                mac: "Command-Shift-F",
              },
              exec: editor => editor.setValue(pretty(editor.getValue())),
            },
          ]}
        />
      </Paper>
      <Paper square className={classes.navigation}>
        <Box padding={1} paddingX={2}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Button
                fullWidth
                disabled={loadingChange || (code || "") === (editorValue || "")}
                onClick={() => save()}
                startIcon={<Icon.Save />}
                loading={loadingChange}
              >
                {localize`Save`}
              </Button>
            </Grid>
            <Grid item>
              <Clipboard text={name} key={name + Date.now()}>
                <IconButton>
                  <Tooltip title={localize`Copy name`} arrow>
                    <Icon.FileCopyTwoTone />
                  </Tooltip>
                </IconButton>
              </Clipboard>
            </Grid>
            <Grid item>
              <Clipboard text={id.toString()} key={id + Date.now()}>
                <IconButton>
                  <Tooltip title={localize`Copy id`} arrow>
                    <Icon.FingerprintTwoTone />
                  </Tooltip>
                </IconButton>
              </Clipboard>
            </Grid>
            <Grid item>
              <TemplateEditorActions template={data?.template} />
            </Grid>
          </Grid>
        </Box>
        {loading && <PagePreloader fillContainer />}
        {data && !loading ? (
          <div>
            <TemplateEntryList
              templateId={id}
              entries={entries}
              entryIndex={entryIndex}
              setEntryIndex={setEntryIndex}
            />
          </div>
        ) : (
          <></>
        )}
      </Paper>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  editor: {
    gridArea: "editor",

    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "80vh",
    },
  },
  navigation: {
    display: "grid",
    gridTemplateRows: "auto calc(100% - 65px)",
    minHeight: 0,
    overflow: "hidden",
    gridArea: "navigation",
    boxShadow: "-4px 0px 25px -20px #000",
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9,
  },
  templateNotFound: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 200px)",
    "& h3": {
      marginBottom: theme.spacing(2),
    },
  },
}));
