import React, { useState, useContext, useEffect } from "react";
import {
  SimpleDialog,
  TextField,
  FormHelperText,
} from "@sport-thieme/react-ui";
import { useMutation } from "@sport-thieme/react-authenticator";
import addTemplateMutation from "../../graphql/addTemplate";
import { localize } from "@sport-thieme/util-localization";
import { AppContext } from "../../AppContext";
import changeTemplateEntry from "../../graphql/changeTemplateEntry";
import singleTemplateQuery from "../../graphql/singleTemplate";
import { TemplateEntries } from "./TemplateEntryList";
import { SingleTemplateQuery } from "../../graphql/generated/graphql";

export default function DuplicateDialog({
  template,
  open,
  onClose,
}: DuplicateDialogProps) {
  const [name, setName] = useState(localize`Copy of ${template?.name ?? ""}`);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { openSnackbar, openTab } = useContext(AppContext);
  const [newTemplateId, setNewTemplateId] = useState<number | null>(null);
  const [addTemplate] = useMutation(addTemplateMutation, {
    onError: err => {
      setError(true);
      setErrorMessage(err.message);
    },
    onCompleted: res => {
      if (template?.entries && template?.entries.length > 0) {
        setNewTemplateId(res.addTemplate.id);
        duplicateEntriesToTemplate(
          res.addTemplate.id,
          template?.entries.filter(
            entry => entry != null && entry.code !== null
          ) as TemplateEntries
        );
        openTab({ id: res.addTemplate.id, name: res.addTemplate.name });
      } else {
        setError(false);
        setErrorMessage("");
        onClose();
        openSnackbar(localize`sucessfully added: ${name}`);
        openTab({ id: res.addTemplate.id, name: res.addTemplate.name });
      }
    },
  });

  useEffect(() => {
    setName(localize`Copy of ${template?.name ?? ""}`);
  }, [template]);

  const [changeTemplate] = useMutation(changeTemplateEntry, {
    onError: err => {
      console.error(err);
      openSnackbar(localize`could not duplicate`, "error");
    },
    onCompleted: res => {
      setError(false);
      setErrorMessage("");
      onClose();
      openSnackbar(localize`successfully duplicated: ${name}`);
    },
    refetchQueries: () => {
      return [
        {
          query: singleTemplateQuery,
          variables: {
            id: newTemplateId,
          },
        },
      ];
    },
  });

  const duplicateEntriesToTemplate = (
    id: number,
    templateEntries: TemplateEntries
  ) => {
    templateEntries.forEach(({ languageId, shopId, code }) => {
      const variables = {
        id,
        shopId,
        languageId,
        code,
      };
      changeTemplate({ variables });
    });
  };
  const save = () => {
    if (name.length === 0) {
      setError(true);
      setErrorMessage(localize`Please enter a template name`);
      return;
    }
    addTemplate({
      variables: {
        name,
      },
    });
  };

  return (
    <SimpleDialog
      open={open}
      title={localize`Duplicate template`}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      actions={[
        {
          title: localize`cancel`,
          onClick: () => {
            setName("");
            setError(false);
            onClose();
          },
          variant: "outlined",
        },
        { title: `${localize`duplicate`}`, onClick: save },
      ]}
    >
      <TextField
        error={error}
        value={name}
        label={localize`duplicate template name`}
        onChange={event => {
          setName(event.target.value);
        }}
        onEnter={save}
      />
      {error && <FormHelperText error>{errorMessage}</FormHelperText>}
    </SimpleDialog>
  );
}

export type DuplicateDialogProps = {
  template: SingleTemplateQuery["template"];
  open: boolean;
  onClose: () => void;
};
