import React, { useContext } from "react";
import {
  SimpleAppBar,
  CssBaseline,
  SimpleSnackbar,
  makeStyles,
} from "@sport-thieme/react-ui";
import {
  AuthenticatorContext,
  useQuery,
} from "@sport-thieme/react-authenticator";
import currentUser from "./graphql/currentUser";
import Suggest from "./components/Suggest/";
import { AppContext } from "./AppContext";
import TemplateEditor from "./components/TemplateEditor";
import Workspace from "./components/Workspace";
import AddDialog from "./components/TemplateEditor/AddDialog";
import Localization from "@sport-thieme/util-localization";
import de from "./resources/de.json";

Localization.addTranslations("de", de);

function App() {
  const {
    search,
    setSearch,
    setSearchIsFocused,
    tabs,
    activeTab,
    closeSnackbar,
    snackbarMessage,
    snackbarSeverity,
  } = useContext(AppContext);
  const { signOut } = useContext(AuthenticatorContext);
  const { data } = useQuery<{
    currentUser?: { firstname?: string; image?: string };
  }>(currentUser);

  const classes = useStyles();
  return (
    <div className={classes.app}>
      <SimpleAppBar
        signOut={signOut}
        position="static"
        logoImage="https://pimage.sport-thieme.de/icon64/app-icon-templates"
        username={data?.currentUser?.firstname}
        avatarImage={data?.currentUser?.image}
        title="Templates"
        search={{
          clearFilter: () => setSearch(""),
          onFocus: () => setSearchIsFocused(true),
          onBlur: () => {
            setTimeout(() => {
              setSearchIsFocused(false);
            }, 300);
          },
          value: search,
          onChange: ({ target: { value } }) => setSearch(value),
        }}
      />
      <Suggest />
      <CssBaseline />
      <Workspace />
      {tabs.length > 0 ? (
        <TemplateEditor
          id={tabs[activeTab ?? 0].id}
          key={tabs[activeTab ?? 0].id}
        />
      ) : (
        <></>
      )}
      <AddDialog />
      {snackbarSeverity === "error" && (
        <SimpleSnackbar
          error={snackbarMessage}
          onClose={() => closeSnackbar()}
        />
      )}
      {snackbarSeverity === "info" && (
        <SimpleSnackbar
          info={snackbarMessage}
          onClose={() => closeSnackbar()}
        />
      )}
      {snackbarSeverity === "success" && (
        <SimpleSnackbar
          success={snackbarMessage}
          onClose={() => closeSnackbar()}
        />
      )}
      {snackbarSeverity === "warning" && (
        <SimpleSnackbar
          warning={snackbarMessage}
          onClose={() => closeSnackbar()}
        />
      )}
    </div>
  );
}

export default App;

const useStyles = makeStyles(theme => ({
  app: {
    display: "grid",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    gridTemplateAreas: `
    "appbar appbar"
    "tabs tabs"
    "editor navigation"
    `,
    gridTemplateRows: "auto auto 1fr",
    gridTemplateColumns: "1fr auto",

    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      position: "relative",
    },

    "& > :first-child": {
      gridArea: "appbar",
    },
  },
}));
