import { gql } from "@sport-thieme/react-authenticator";

export default gql`
  mutation renameTemplate($id: Int!, $name: String!) {
    updateTemplate(where: { id: $id }, data: { name: { set: $name } }) {
      id
      name
    }
  }
`;
