import React, { ReactNode } from "react";
import { createContext, useState } from "react";

export type TabType = {
  id: number;
  name: string;
};

type AppContextType = {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  searchIsFocused: boolean;
  setSearchIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  tabs: TabType[];
  recentTemplates: TabType[];
  activeTab?: number;
  setActiveTab: (id?: number) => void;
  openTab: (tab: TabType) => void;
  renameTab: (tab: TabType) => void;
  closeTab: (id: number) => void;
  closeAllTabs: () => void;
  closeAllTabsToTheLeft: (id: number) => void;
  closeAllTabsToTheRight: (id: number) => void;
  snackbarMessage: string;
  snackbarIsOpen: boolean;
  openSnackbar: (
    message: string,
    severity?: "success" | "info" | "warning" | "error"
  ) => void;
  closeSnackbar: (timeout?: number) => void;
  removeRecent: (id: number) => void;
  renameRecent: (tab: TabType) => void;
  templateState: Record<string, string>;
  setTemplateState: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  snackbarSeverity: "success" | "info" | "warning" | "error" | undefined;
  switchActiveTab: (id?: number) => void;
};

export const AppContext = createContext<AppContextType>({
  search: "",
  searchIsFocused: false,
  recentTemplates: [],
  setSearch: () => undefined,
  setActiveTab: () => undefined,
  openTab: () => undefined,
  closeTab: () => undefined,
  closeAllTabs: () => undefined,
  closeAllTabsToTheLeft: () => undefined,
  closeAllTabsToTheRight: () => undefined,
  renameTab: () => undefined,
  setSearchIsFocused: () => undefined,
  tabs: [],
  snackbarMessage: "",
  snackbarIsOpen: false,
  snackbarSeverity: undefined,
  openSnackbar: () => undefined,
  closeSnackbar: () => undefined,
  removeRecent: () => undefined,
  renameRecent: () => undefined,
  templateState: {},
  setTemplateState: () => undefined,
  switchActiveTab: () => undefined,
});

type ProviderProps = {
  children: ReactNode;
};

const LOCAL_OPEN_TABS = "templates-app-open-tabs";
const RECENT_TEMPLATES = "templates-app-recent-templates";
const ACTIVE_TAB = "templates-app-active-tab";

// todo: rename for clarity as it does persists more than tabs?
const persistTabs = (tabs: TabType[], key: string = LOCAL_OPEN_TABS) =>
  localStorage.setItem(key, JSON.stringify(tabs));

const persistActiveTab = (activeTab?: number, key: string = ACTIVE_TAB) =>
  localStorage.setItem(key, JSON.stringify(activeTab));

export default function AppContextProvider({ children }: ProviderProps) {
  const [search, setSearch] = useState("");
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const storageActiveTab =
    parseInt(localStorage.getItem(ACTIVE_TAB) || "NaN") || undefined;
  const [activeTab, setActiveTab] = useState<number | undefined>(
    storageActiveTab
  );
  const storageTabs: TabType[] =
    JSON.parse(localStorage.getItem(LOCAL_OPEN_TABS) || "null") || [];
  const storageRecent: TabType[] =
    JSON.parse(localStorage.getItem(RECENT_TEMPLATES) || "null") || [];
  const [tabs, setTabs] = useState(storageTabs);
  const [recentTemplates, setRecentTemplates] = useState(storageRecent);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "info" | "warning" | "error" | undefined
  >();
  const [templateState, setTemplateState] = useState<Record<string, string>>(
    {}
  );

  const openTab = ({ id, name }: TabType) => {
    addRecent({ id, name });
    const newState = [...tabs, { id, name }].filter(
      (value, index, array) =>
        array.findIndex(tab => tab.id === value.id) === index
    );
    setTabs(() => newState);
    // setActiveTab(() => id);
    switchActiveTab(id);
    persistTabs(newState);
  };

  const addRecent = ({ id, name }: TabType) => {
    const newState = [...recentTemplates, { id, name }]
      .filter(
        (value, index, array) =>
          array.findIndex(tab => tab.id === value.id) === index
      )
      .reverse()
      .slice(0, 5)
      .reverse();

    setRecentTemplates(() => newState);
    persistTabs(newState, RECENT_TEMPLATES);
  };

  const renameRecent = ({ id, name }: TabType) => {
    const newState = [...recentTemplates].map(template => {
      if (template.id === id) {
        template.name = name;
      }
      return template;
    });
    setRecentTemplates(newState);
    persistTabs(newState, RECENT_TEMPLATES);
  };

  const removeRecent = (id: number) => {
    const newState = [...recentTemplates];
    newState.splice(
      newState.findIndex(template => template.id === id),
      1
    );
    setRecentTemplates(newState);
  };

  const closeTab = (id: number) => {
    const newState = [...tabs].filter(({ id: tab }) => id !== tab);
    setTabs(() => newState);
    persistTabs(newState);
  };
  const closeAllTabsToTheLeft = (id: number) => {
    const index = tabs.findIndex(({ id: search }) => search === id);
    if (index) {
      const newState = [...tabs].slice(index);
      setTabs(() => newState);
      persistTabs(newState);
    }
  };
  const closeAllTabsToTheRight = (id: number) => {
    const index = tabs.findIndex(({ id: search }) => search === id);
    if (index) {
      const newState = [...tabs].slice(0, index + 1);
      setTabs(() => newState);
      persistTabs(newState);
    }
  };

  const closeAllTabs = () => {
    setTabs(() => []);
    persistTabs([]);
  };

  const renameTab = ({ id, name }: TabType) => {
    const newState = [...tabs].map(tab => {
      if (tab.id === id) {
        tab.name = name;
      }
      return tab;
    });
    setTabs(() => newState);
    persistTabs(newState);
  };

  const openSnackbar = (
    message: string,
    severity?: "success" | "info" | "warning" | "error"
  ) => {
    setSnackbarMessage(message);
    setSnackbarIsOpen(true);
    setSnackbarSeverity(severity);
  };

  const closeSnackbar = (timeout = 1000) => {
    setSnackbarIsOpen(false);
    setTimeout(() => {
      setSnackbarMessage("");
    }, timeout);
  };

  const switchActiveTab = (id?: number) => {
    setActiveTab(id);
    persistActiveTab(id);
  };
  return (
    <AppContext.Provider
      value={{
        search,
        setSearch,
        searchIsFocused,
        setSearchIsFocused,
        tabs,
        activeTab: Math.max(
          0,
          tabs.findIndex(tab => tab.id === activeTab)
        ),
        setActiveTab,
        recentTemplates,
        openTab,
        closeTab,
        closeAllTabs,
        closeAllTabsToTheLeft,
        closeAllTabsToTheRight,
        renameTab,
        snackbarMessage,
        snackbarIsOpen,
        openSnackbar,
        closeSnackbar,
        removeRecent,
        renameRecent,
        templateState,
        setTemplateState,
        snackbarSeverity,
        switchActiveTab,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
